import { Flex, Box, Text, Progress, CircularProgress, CircularProgressLabel } from '@chakra-ui/react'
import { abbreviateNumber } from 'js-abbreviation-number'
import React from 'react'


//export default CampaignWisePerformance
export default function CampaignWisePerformance({ data }) {
    return (
        <Flex flexWrap={'wrap'} w={'100%'} justifyContent={'space-between'} gap={1}>
            {
                !!data.length && data.map(dt => {
                    return <Box display={'flex'} flexDirection={'column'} w={'15%'} alignItems={'center'}>
                        <Text fontSize={'0.6vw'}>{dt.label}</Text>
                        <CircularProgress value={dt.graphValue} size={'2.5vw'} color={!!dt.color ? dt.color : ''}>
                            <CircularProgressLabel title={dt?.value}>{abbreviateNumber(dt?.value)}</CircularProgressLabel>
                        </CircularProgress>
                    </Box>
                })
            }

        </Flex>
    )
}
