import hexToRgba from "hex-to-rgba";
import FlowEditService from "./FlowEditService";
import { Icon } from "@chakra-ui/react";

let getDefaultLink = (cellView, magnet, joint) => {
  let link = new joint.dia.Link({
    attrs: { '.marker-target': { d: 'M 10 0 L 0 5 L 10 10 z' } }
  });
  link.router('orthogonal');
  link.connector('rounded');
  return link;
  /*let link = new joint.shapes.standard.Link();
  link.router('orthogonal');
  link.connector('rounded');
  // link.connector('jumpover', { size: 10 });
  return link;*/

  /*new joint.dia.Link({
    attrs: { '.marker-target': { d: 'M 10 0 L 0 5 L 10 10 z' }}
  })*/
};
// let search = (cell, keyword, group) => {
//   var description = '';

//   let groupAlias = group === FlowEditService.elementTypes.initial ? 'trigger' : group;

//   if (groupAlias.indexOf(keyword.toLowerCase()) > -1) {
//     return true;
//   }

//   !!cell.get('actionData') && !!cell.get('actionData').labelText && (description = cell.get('actionData').labelText.toLowerCase());
//   return typeof description === 'string' && description.indexOf(keyword.toLowerCase()) > -1;
// };
let getCustomShape = (shapeType, shapeColor, strokeColor, iconUrl, shapeLabelText, shapeTooltipDesc, shapeActionType, ports, joint) => {
  let portGroups = {}; /*{groupName, position, labelPosition}*/
  !!ports && !!ports.length > 0
    && (() => {
      ports.forEach(port => {
        portGroups[port.groupName] = {
          markup: [{ tagName: 'circle', selector: 'portBody', attributes: { r: 5, stroke: '#0F62FE', magnet: port.magnet, strokeWidth: 2.5, fill: '#ffffff', opacity: 0 } }],
          z: 1,
          position: { name: port.position },
          label: { position: { name: port.labelPosition } },
          attrs: { text: { text: port.groupName, fontSize: 12 } }
        };
      });
    })();

  let tooltipMarkup = `<div><h6 style="margin: 0;font-size: 14px;color:#393939;">${shapeLabelText}</h6><p style="margin: 0; max-width: 200px;text-overflow: visible;font-size: 11px">${shapeTooltipDesc}</p></div>`;

  let data = {
    attrs: {
      '.': {
        'cursor': 'pointer',
        'data-tooltip': tooltipMarkup,
        'data-tooltip-position': 'left',
        //'data-tooltip-position-selector': '.joint-cells-'
      },
      layout: {
        columns: 2
      },
      body: {
        refWidth: '100%',
        refHeight: '100%',
        strokeWidth: 2,
        stroke: strokeColor || '#643b3b',
        fill: shapeColor || '#05d3f4',
        height: 30, width: 30,
        size: 30,
        'xlink:href': iconUrl
      },
      label: {
        transform: 'matrix(1,0,0,1,40,10)',
        fontSize: shapeActionType === 'fbCustomAudience' ? 11 : 13,
        fill: 'black',
        fontWeight: 'bold'
      },
      root: { magnet: false } // Disable the possibility to connect the body of our shape. Only ports can be connected.
    },
    ports: {
      groups: portGroups
    }
  };

  let markup = {
    markup: [{ tagName: 'image', selector: 'body' }, { tagName: 'text', selector: 'label' }]
  };

  return joint.dia.Element.define(shapeType, data, markup);
};

const RappidService = {
  getPaper: (graph, validateConnection, validateMagnet, joint, color, element) => {
    let paperData = {
      model: graph,
      //  el: element,
      width: 2200,
      height: 2200,
      gridSize: 10,
      drawGrid: true,
      background: { color: hexToRgba(color, .3) },
      snapLinks: { radius: 20 },
      defaultLink: () => {
        let link = new joint.dia.Link({
          attrs: { '.marker-target': { d: 'M 10 0 L 0 5 L 10 10 z' } }
        });
        link.router('orthogonal');
        link.connector('rounded');
        return link;
      },
      validateConnection: validateConnection,
      validateMagnet: validateMagnet,
      markAvailable: true
    }
    // return paperData
    return (new joint.dia.Paper(paperData))
  },
  getPaperScroller: (paper, joint) => {
    return new joint.ui.PaperScroller({ paper });
  },
  getStencil: (paper, dragStartClone, dragEndClone, canDrag, snaplines, joint) => {
    let stencilData = {
      paper: paper,
      width: 200,
      groups: {
        initial: { label: 'Trigger', index: 1, closed: false, layout: { rowHeight: 50, columns: 1 } },
        condition: { label: 'Condition', index: 2, closed: false, layout: { rowHeight: 50, columns: 1 } },
        delay: { label: 'Delay', index: 3, closed: false, layout: { rowHeight: 50, columns: 1 } },
        actions: { label: 'Actions', index: 4, closed: false, layout: { rowHeight: 50, columns: 1 } },
        // thirdParty: { label: 'Third Party (Coming Soon)', index: 5, closed: false, layout: { rowHeight: 50, columns: 1 } },
      },
      dropAnimation: {
        duration: 300,
        easing: 'linear'
      },
      // search,
      label: 'Pallete',
      dragStartClone,
      dragEndClone,
      canDrag,
      snaplines,
      layout: true,
    };

    return new joint.ui.Stencil(stencilData);
  },
  createCustomShapeObject: (shape, joint) => {
    let portItems = [];
    shape.ports.forEach(item => {
      portItems.push({ group: item.groupName })
    });

    let customShapeData = {
      paperSize: { width: 200, height: 200 }, // paper size
      attrs: {
        label: {
          text: shape['labelText'],
          fill: '#fff'
        },
      },
      ports: { items: portItems }
    }
    return new (getCustomShape(shape['elementType'], shape['shapeColor'], shape['strokeColor'], shape['iconUrl'],
      shape['labelText'], shape['tooltipDesc'], shape['actionType'], shape['ports'], joint))(customShapeData);

  },
  setElementProps: (element, props) => {
    let data = {};
    Object.keys(props).forEach(key => {
      data[key] = props[key];
    });
    return element.set('actionData', data);
  },
  getTooltip: (joint) => {
    return new joint.ui.Tooltip({
      rootTarget: document.body,
      target: '[data-tooltip]',
      direction: 'auto',
      padding: 10,
      background_color: '#000000'
    })
  },
  getRappidDeps: ({ joint, stencilContainer, paperContainer, ...params }) => {
    const nameSpace = joint.shapes;
    let graph = new joint.dia.Graph({}, { cellNamespace: nameSpace });
    let tooltip = new joint.ui.Tooltip({
      rootTarget: document.body,
      target: '[data-tooltip]',
      direction: 'auto',
      padding: 10,
      background_color: '#000000'
    });
    let paper = new joint.dia.Paper({
      model: graph,

      width: 2200,
      height: 2200,
      gridSize: 10,
      drawGrid: true,
      background: { color: hexToRgba(params.color, .3) },
      snapLinks: { radius: 20 },
      defaultLink: () => {
        let link = new joint.dia.Link({
          attrs: { '.marker-target': { d: 'M 10 0 L 0 5 L 10 10 z' } }
        });
        link.router('orthogonal');
        link.connector('rounded');
        return link;
      },
      validateConnection: params.validateConnection,
      validateMagnet: params.validateMagnet,
      markAvailable: true,
      el: paperContainer,
    })
    // let paperScroller = new joint.ui.PaperScroller({ paper: paper });
    let snaplines = new joint.ui.Snaplines({ paper: paper });
    let stencil = new joint.ui.Stencil({
      paper: paper,
      // el: stencilContainer,
      width: 200, groups: {
        initial: { label: 'Trigger', index: 1, closed: false, layout: { rowHeight: 50, columns: 1 } },
        condition: { label: 'Condition', index: 2, closed: false, layout: { rowHeight: 50, columns: 1 } },
        delay: { label: 'Delay', index: 3, closed: false, layout: { rowHeight: 50, columns: 1 } },
        actions: { label: 'Actions', index: 4, closed: false, layout: { rowHeight: 50, columns: 1 } },
        // thirdParty: { label: 'Third Party (Coming Soon)', index: 5, closed: false, layout: { rowHeight: 50, columns: 1 } },
      },
      dropAnimation: {
        duration: 300,
        easing: 'linear'
      },
      // search,
      label: 'Pallete',
      dragStartClone: params.dragStartClone,
      dragEndClone: params.dragEndClone,
      canDrag: params.canDrag,
      snaplines,
      layout: true,
    });
    stencilContainer.appendChild(stencil.render().el);

    // paperContainer.appendChild(paper.render().el)
    return { paper, graph, snaplines, tooltip, stencil }


  },
  getHalo: (cellView, joint) => {
    let halo = new joint.ui.Halo({ cellView: cellView });
    halo.removeHandle('clone');
    halo.removeHandle('rotate');
    halo.removeHandle('fork');
    halo.removeHandle('link');
    halo.removeHandle('resize');
    halo.removeHandle('unlink');
    return halo;
  }
}

export default RappidService