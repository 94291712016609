import React, { Fragment } from 'react'
import { default as MultiSelect } from './themeComponents/react-chakra-select';
import { useQuery } from 'react-query';
import axios from 'axios';
import _ from 'lodash';

export default function LanguageDropdwon({ isMulti, value, onLanguageSelect, ...rest}) {
    const getLanguages = () => {
        return axios.get('/json/language.json')
    }
    const languageQuery = useQuery({
        queryFn: async () => {
            let resp = await getLanguages();
            return _.map(resp.data, (datum) => {
                return { ...datum, ...{ label: datum.name, value: datum.code } }
            })
        }
    })
    return (
        <Fragment>
            {
                !!isMulti && <MultiSelect isMulti options={!!languageQuery.data ? languageQuery.data : []} value={value} onChange={(selectedValues) => onLanguageSelect(selectedValues)} colorScheme='brand' isClearable={false} {...rest}></MultiSelect>
            }
        </Fragment>
    )
}
