import { Flex, Icon, Switch, Td, Tr, Text, useToast, useDisclosure } from '@chakra-ui/react'
import React, { Fragment , useState} from 'react'
import { FaTrash } from 'react-icons/fa'
import { OvalSpinner } from '../../components/Loaders'
import { showSwal } from '../../_helpers/helpers'
import Swal from 'sweetalert2'
import { deleteCustomSegment, toggleCustomSegmentStatus } from '../../Services/api'
import ExportCustomAudienceModal from './ExportCustomAudienceModal'
import { CiExport } from "react-icons/ci";
export default function SegmentTable({ list, customSegmentListQuery, orgId }) {
    const {isOpen,onClose,onOpen} = useDisclosure()
    const toast = useToast();
    const [selectedSegmentId,setSelectedSegmentId] = useState("")
    const deleteSegment = (segmentId) => {
        showSwal(Swal, 'Are you sure?', 'Do you want to delete the segment?', true, true, 'Yes', 'No', async () => {
            let resp = await deleteCustomSegment(segmentId, orgId).catch(error => {
                toast({ title: 'Oops!', description: 'Error in deleting the segment. Please try again.', status: 'error' });
                Swal.close()
            });
            if (!!resp.data.success) {
                toast({ title: 'Deleted', description: 'Segment Deleted succesfully.', status: 'success' });
                Swal.close();
                customSegmentListQuery.refetch()
            }

        }, (res) => {
            !!res.isConfirm && (() => {

            })()
        })
    }
    const toggleSegment = (segment) => {
        let swalTextCheck = !!(segment.active_segments_count <= 5 && segment.pre_cache_status == 'DISABLED')
        let segmentStatus = segment.pre_cache_status === 'ENABLED' ? 'DISABLE' : 'ENABLE';
        let swalText = swalTextCheck ? ' The least recently used segment will be disabled' : 'Are You Sure?'
        let swalTitle = swalTextCheck ? " You are about to enable segment more than the maximum limit" : `Do you want to ${segmentStatus} the segment`
        showSwal(Swal, swalTitle, swalText, true, true, 'Continue', 'No', async () => {
            let resp = await toggleCustomSegmentStatus(orgId, segment.id, segment.pre_cache_status).catch(error => {
                toast({title: 'Oops', description: `Something went wrong while ${segment.pre_cache_status === 'ENABLED' ? 'disabling' : 'enabling'} the segment`, status: 'error'})
            });
            let toastTitle = segment.pre_cache_status === 'ENABLED' ? 'Disabled' : 'Enabled';
            let toastDesc = segment.pre_cache_status === 'ENABLED' ? 'Segment is disbaled. You can enable it anytime' :
                'Your segment is being prepared in background. You will be notified via email once the data is ready.';
            toast({title: toastTitle, description: toastDesc, status: 'success'});
            customSegmentListQuery.refetch()

        }, (res) => {

        })
    }
    return (
        <Fragment>
            {
                list.map(lt => (
                    <Tr>
                        <Td>{lt.label}</Td>
                        <Td>{lt.aggregates.count_phone}</Td>
                        <Td>{lt.aggregates.count_email}</Td>
                        <Td>{lt.aggregates.count_customers}</Td>
                        <Td>
                            <Flex alignItems={'center'} gap={3}>
                                <Icon cursor={'pointer'} 
                                title='Export Custom Audience'
                                onClick={()=>{
                                    setSelectedSegmentId(lt.id)
                                    onOpen()}} as={CiExport}/>
                                
                                <Icon as={FaTrash} onClick={() => deleteSegment(lt.id)}></Icon>
                                <Switch size={'md'} isChecked={lt.pre_cache_status === 'ENABLED'} onChange={() => toggleSegment(lt)}></Switch>
                                {
                                    !lt.aggregates.hasOwnProperty("count_customers") && (lt.pre_cache_status === 'ENABLED') && <Flex alignItems={'start'} gap={2}>
                                        <Text>Preparing Segment</Text>
                                        <OvalSpinner height={'20px'} width={'20px'}></OvalSpinner>
                                    </Flex>
                                }
                            </Flex>
                        </Td>
                    </Tr>
                ))
            }
            <ExportCustomAudienceModal segmentId={selectedSegmentId} onClose={onClose} isOpen={isOpen} />
        </Fragment>
    )
}
