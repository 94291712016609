import React, { useEffect, useState } from 'react'
import { useFormik, Form, Field } from 'formik';
import {
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
  VStack,
  HStack,
  Box,
  Text,
  Select,
  useDisclosure
} from '@chakra-ui/react';
import MobilePreview from './MobilePreview';
import ShortenUrlModal from '../../../components/ShortenUrlModal';
import { getSmsGatways } from '../../../Services/api';
import { useQuery } from 'react-query'
// import Select from '../../../components/themeComponents/react-chakra-select';
import ImageGallery from '../../../components/ImageGallery';
const initialValues = {
  campaignName: '',
  message: '',
};

const SmsCampaignForm = ({ formik, selectedChannel }) => {

  const { isOpen, onOpen, onClose } = useDisclosure()

  // console.log(gatwayQuery,"gatwayQuery")
  const [isImageGalleryOpen, setIsImageGalleryOpen] = useState(false);

  const gatwayQuery = useQuery(['gatways'], {
    queryFn: async () => {
      let res = await getSmsGatways();
      // console.log(res.data,"response")
      return res.data
    }
  })
//  console.log(gatwayQuery,"gatwayQuery")  
  let setAttachment = (image) => {
      formik.setFieldValue("attachments", image?.mediaFileUrl)
      console.log(formik.values,"image values")
      setIsImageGalleryOpen(false)
  }
  let removeAttachment = () => {
      formik.setFieldValue("attachments", "")
  }
  
  
  useEffect(()=>{
    gatwayQuery.refetch()
    // fetchGateway()
  },[])
  return (
    <>
      <HStack width={'70%'} gap={'1vw'} mt={'1rem'} bg={'#fff'} borderRadius={'4px'} p={'3rem'} height={'70vh'} alignItems={'self-start'} >

        <form style={{ width: '80%' }} onSubmit={formik.handleSubmit}>
          <VStack spacing={4} align="stretch">
            <FormControl id="message" isRequired>
              <FormLabel>Approved Template ID</FormLabel>
              <Input
              bg={"#EEEEEE"}
              borderRadius={'16px'}
                resize={'none'}
                id="message"
                name="approvedTemplateId"
                isRequired
                placeholder="Enter Approved Template ID"
                onChange={formik.handleChange}
                value={formik.values.approvedTemplateId}
              />
            </FormControl>
            <FormControl id="gateway" isRequired>
              <FormLabel>Select Gateway</FormLabel>
              <Select
                bg={"#EEEEEE"}
                borderRadius={'16px'}
                name="gateway"
                placeholder="Select gateway"
                {...formik.getFieldProps('selectedGateway')}
              >
                
                {gatwayQuery?.data?.gatewayList?.map((gateway,i) => (
                
                  <option key={gateway.gatewayUuid} value={gateway.gatewayUuid}>
                     {gateway.gatewayName}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl id="message" isRequired>
              <FormLabel>Campaign Message</FormLabel>
              <Textarea
              bg={"#EEEEEE"}
              borderRadius={'16px'}
                resize={'none'}
                id="message"
                name="message"
                isRequired
                placeholder="Enter your message"
                onChange={formik.handleChange}
                value={formik.values.message}
              />
            </FormControl>
            {/* <Button
          type="submit"
          isLoading={formik.isSubmitting}
          loadingText="Submitting"
          colorScheme="blue"
          >
          Submit
        </Button> */}
          </VStack>
          <HStack gap={'1vw'} mt={'1rem'} >
            {/* <Text textDecoration={'underline'}  cursor={'pointer'} >Add Image + </Text> */}
            <ImageGallery setImageData={setAttachment} renderComponent={ <Text textDecoration={'underline'}  cursor={'pointer'} >Add Image + </Text>}></ImageGallery>
            <Text onClick={() => onOpen()} textDecoration={'underline'} cursor={'pointer'} >Shorten Url</Text>
          </HStack>
        </form>
        <Box  position='absolute' right={'8vw'} top={'10vw'} bottom={''}>

          <MobilePreview channel={'sms'} message={formik.values.message} />
        </Box>
      </HStack>
      <ShortenUrlModal isOpen={isOpen} mainFormik={formik} onClose={onClose}></ShortenUrlModal>
    </>

  );
};

export default SmsCampaignForm;