import React, { useState, useEffect } from 'react'
import { Icon, Tbody, Td, Tr, Box, Flex, Text, Badge, Menu, MenuButton, MenuList, MenuItem ,useToken} from '@chakra-ui/react'

import { BsThreeDotsVertical } from "react-icons/bs";

import moment from 'moment';

import { useNavigate } from 'react-router-dom';
import { getOrders } from '../../Services/api';
import { Pagination, PaginationContainer, PaginationNext, PaginationPage, PaginationPageGroup, PaginationPrevious, usePagination } from '@ajna/pagination'
import { OvalSpinner } from '../../components/Loaders';
import hexToRgba from 'hex-to-rgba'
function OrderTable({  tableRef,orderData,loading}) {
    console.log(orderData,"fetchOrderData in table")
    const [openMenuIndex, setOpenMenuIndex] = useState(null);
    const [mainColor] = useToken('colors', ['primary.main'])
    const [totalCount, setTotalCount] = useState(0)
    const { currentPage, setCurrentPage, pagesCount, pages } = usePagination({ total: totalCount, initialState: { currentPage: 1, pageSize: 5, isDisabled: false }, limits: { outer: 1, inner: 1 } })
    const [stripTable, primary, heading] = useToken('colors', ['stripTable', 'primary.main', 'heading']);
    useEffect(() => {
        const handleScroll = () => {
            if (openMenuIndex !== null) {
                setOpenMenuIndex(null);
            }
        };
        const tableElement = document.querySelector('.css-1km9ei0')
        if (tableElement) {
            tableElement.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (tableElement) {
                tableElement.removeEventListener('scroll', handleScroll);
            }
        };
    }, [openMenuIndex]);

   
    const navigate = useNavigate()
 
    const badgeColor = {
        ERROR: 'red',
        COMPLETED: 'green',
        DRAFT: 'yellow',
    }

    return (
        <>
        {
            loading &&
            <>
              <Flex
                    justifyContent="center"
                    alignItems="center"
                    height="200px"
                    width="100%"
                    marginLeft="500px"
                    position="relative"
                >
                    <OvalSpinner width={50} height={50} />
                </Flex>
            </>
        }
            <Tbody overflowY={'auto'} ref={tableRef} >
            {
                !!orderData.data?.order_aggregate?.length && orderData.data?.order_aggregate?.map((dt, i) => (
                    <Tr>
                        <Td w={'25%'} fontSize={'0.8vw'} verticalAlign={'middle'}>
                            <Flex gap={3}>
                                {/* {<Icon as={channels[dt.channel]?.icon} boxSize={5}></Icon>} */}
                                <Box>
                                    <Text>{dt.name}</Text>
                                    <Text color={'light'}>{dt.order_id}</Text>
                                

                                </Box>
                            </Flex>
                        </Td>
                        <Td w={'6%'} fontSize={'0.8vw'}>
                            <Badge colorScheme={badgeColor[dt.email]} fontWeight={'400'}>
                                {dt.email}
                            </Badge>
                        </Td>
                        <Td w="7%" fontSize={'0.8vw'}>
                            <Box>
                                <Text>{moment(dt.created_at).format('LL')} {moment(dt.created_at).format('LT')} </Text>
                                {/* <Text>{moment(dt.created_at).format('LT')}</Text> */}
                            </Box>
                        </Td>
                        <Td w="7%" fontSize={'0.8vw'}>
                            <Box>
                                <Text>{dt.gross_revenue} </Text>
                                {/* <Text>{moment(dt.created_at).format('LT')}</Text> */}
                            </Box>
                        </Td>
                        <Td w="7%" fontSize={'0.8vw'}>
                            <Box>
                                <Text>{dt.shipping_cost} </Text>
                                
                            </Box>
                        </Td>
                        <Td w="7%" fontSize={'0.8vw'}>
                            <Box>
                                <Text>{dt.total_discounts} </Text>
                                
                            </Box>
                        </Td>
                        <Td w="7%" fontSize={'0.8vw'}>
                            <Box>
                                <Text>{dt.gross_profit} </Text>
                                
                            </Box>
                        </Td>
                        <Td w="7%" fontSize={'0.8vw'}>
                            <Box>
                                <Text>{dt.gross_margin} </Text>
                                
                            </Box>
                        </Td>
                        <Td w="7%" fontSize={'0.8vw'}>
                            <Box>
                                <Text>{dt.state} </Text>
                                
                            </Box>
                        </Td>
                        <Td w="7%" fontSize={'0.8vw'}>
                            <Box>
                                <Text>{dt.data_source} </Text>
                                
                            </Box>
                        </Td>
                        
                    </Tr>
                ))
            }

            
        </Tbody>
       
            
        
        </>
    )
}

export default OrderTable
