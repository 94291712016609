import React, { useState, useEffect, useRef } from 'react';
import { Box, FormControl, FormLabel, Select, Flex, FormErrorMessage, Text } from '@chakra-ui/react';
import { Formik, Form, Field, FieldArray } from 'formik';
import { smsChannelData } from '../../_helpers/data_helper';
import PrimaryButton from '../../components/themeComponents/PrimaryButton';
import SecondaryButton from '../../components/themeComponents/SecondaryButton';
import { sendSmsChannelData, getSmsChannelData } from '../../Services/api';
import SmsProviderMapping from './SmsProviderMapping';
import { useToast } from '@chakra-ui/react';
import { smsChannelValidationSchema } from '../../_helpers/validationSchema';

const SMS = () => {
  const initialData = {
    TrustSignal: {
      credentials: [{ apiKey: "", senderId: "", gatewayType: "", smsGateway: smsChannelData[3].value, route: "", isNew: true }],
    },
    RouteMobile: {
      credentials: [{ userName: '', password: "", senderId: '', smsGateway: smsChannelData[2].value, gatewayType: "", dltEntityId: "", isNew: true }],
    },
    Kaleyra: {
      credentials: [{ name: "", apiKey: "", senderId: "", smsGateway: smsChannelData[1].value, gatewayType: "", isNew: true }],
    },
    Telnyx: {
      credentials: [{ userName: "", profileid: "", smsGateway: smsChannelData[0].value, phone: "", isNew: true }],
    },
    Sinch: {
      credentials: [{ appid: "", pass: "", smsGateway: smsChannelData[4].value, name: "", senderId: "", isNew: true }],
    }
  };

  const [selectedGateway, setSelectedGateway] = useState(smsChannelData[0].label);
  const [initialValues, setInitialValues] = useState(initialData['Telnyx']);
  const toast = useToast();
  const formikRef = useRef(null);
  const SmsChannelComponent = SmsProviderMapping[selectedGateway];
  const [gatewayData, setGatewayData] = useState({});
  const [validationSchema, setValidationSchema] = useState(smsChannelValidationSchema.Telnyx);

  useEffect(() => {
    if (selectedGateway) {
      fetchSmsChannelData(selectedGateway);
    }
  }, [selectedGateway]);

  useEffect(() => {
    setValidationSchema(smsChannelValidationSchema[selectedGateway]);
    setInitialValues(initialData[selectedGateway]);
  }, [selectedGateway]);

  const fetchSmsChannelData = async (gateway) => {
    try {
      const response = await getSmsChannelData(gateway);
      const fetchedData = response.data[gateway.toLowerCase()];
  
      if (fetchedData && Object.keys(fetchedData).length > 0) {
        // Convert the data to an array if it's in object format
        const updatedCredentials = Object.values(fetchedData).map((item) => ({
          ...item,
          isNew: false, // Marking fetched credentials as not new
        }));
        setInitialValues({ credentials: updatedCredentials });
      } else {
        setInitialValues(initialData[gateway]);
      }
    } catch (error) {
      console.log('Error while fetching SMS data', error);
    }
  };

  const handleSubmit = async (values, actions) => {
    console.log('Submitting values:', values);
    try {
      values = { ...values, ...gatewayData };
      const gateway = selectedGateway.toLowerCase();
      const newCredentials = values.credentials
        .filter((credential) => credential.isNew)
        .map(({ isNew, ...rest }) => rest);

      for (let credential of newCredentials) {
        await registerSmsChannelData(credential, gateway);
      }
      toast({ title: 'Success', description: 'SMS Credentials Saved Successfully.', status: 'success' });
    } catch (error) {
      toast({ title: 'Oops!', description: 'Error in saving credentials.', status: 'error' });
    } finally {
      actions.setSubmitting(false);
    }
  };

  const registerSmsChannelData = async (data, gateway) => {
    try {
      const response = await sendSmsChannelData(data, gateway);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const handleGatewayChange = (event) => {
    const selectedGateway = event.target.value;
    console.log(selectedGateway,"selectedGateway")
    setSelectedGateway(selectedGateway);
    setInitialValues(initialData[selectedGateway]);
  };

  const addCredentialSet = (arrayHelpers) => {
    arrayHelpers.push({ ...initialData[selectedGateway].credentials[0], isNew: true });
  };

  const removeCredentialSet = (arrayHelpers, index) => {
    arrayHelpers.remove(index);
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      // validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {formikProps => (
        <Box backgroundColor="#fff" p={4} mr={6} ml={10}>
          <Form>
            <Flex direction="row" justify="space-evenly">
              <Box backgroundColor="#fff" mt="1rem" maxW="35vw" height="fit-content" p="2rem" width="100%" borderRadius="2px">
                <Text fontSize="1.2vw" mb="1rem">SMS Gateway</Text>

                <FormControl mb={4} isInvalid={formikProps.errors.provider && formikProps.touched.provider}>
                  <FormLabel htmlFor="provider">Select SMS gateway</FormLabel>
                  <Field as={Select} id="provider" name="provider" mt={4} onChange={handleGatewayChange}>
                    {smsChannelData.map((option) => (
                      <option key={option.key} value={option.key}>
                        {option.label}
                      </option>
                    ))}
                  </Field>
                  <FormErrorMessage>{formikProps.errors.provider}</FormErrorMessage>
                </FormControl>

                <FieldArray name="credentials">
                  {arrayHelpers => (
                    <div>
                      {formikProps.values.credentials.map((credential, index) => (
                        <React.Fragment key={index}>
                          {SmsChannelComponent && (
                            <SmsChannelComponent
                              setFieldValue={formikProps.setFieldValue}
                              values={formikProps.values.credentials[index]}
                              setGatewayData={setGatewayData}
                              index={index}
                            />
                          )}
                          <Flex direction="row" align="center" justify="space-between" gap={4} mt={4}>
                            {formikProps.values.credentials.length > 1 && (
                              <SecondaryButton label="Remove" type="button" onClick={() => removeCredentialSet(arrayHelpers, index)} />
                            )}
                          </Flex>
                        </React.Fragment>
                      ))}
                      <Flex direction="row" align="center" justify="space-between" gap={4} mt={4}>
                        <PrimaryButton label="Save" type="submit" isLoading={formikProps.isSubmitting} />
                        <Text cursor="pointer" onClick={() => addCredentialSet(arrayHelpers)}>
                          Add Credentials
                        </Text>
                      </Flex>
                    </div>
                  )}
                </FieldArray>
              </Box>
            </Flex>
          </Form>
        </Box>
      )}
    </Formik>
  );
};

export default SMS;
