import React from 'react'
import { Box,Image , Icon} from '@chakra-ui/react'
import { TbPlugConnected } from "react-icons/tb";
import { FaShopify } from "react-icons/fa";
import { FaRegCircle } from "react-icons/fa";
import { useOutletContext } from 'react-router-dom';
import { PiFlowArrowThin } from "react-icons/pi";
export default function IntegrationWithEntity({icon}) {
  return (
    <Box height={'auto'} borderRadius={'8px'} border={'1px solid #AD88F1'} width={'25vh'} bg={'#EEEEEE'} display={'flex'} padding={'4vw'} alignItems={'center'} justifyContent={'space-around'} flexDirection={'column'} >
        <Image   src={icon} objectFit={'cover'} mixBlendMode={'darken'} />
        {/* <Icon alignSelf={'center'} transform={'rotate(70deg)'}  width={'8em'}
                    height={'4em'} as={PiFlowArrowThin} />
        <Icon  width={'3em'}
                    alignSelf={'end'}
                    height={'3em'}as={FaRegCircle}/> */}
        
    </Box>
  )
}
