import React, { useEffect, useRef, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { Box, FormControl, FormLabel, Input, Select, Textarea, Flex, FormErrorMessage, Text, useToast } from '@chakra-ui/react';
import * as Yup from 'yup';
import { selectProvidersValue } from '../../_helpers/data_helper';
import componentMapping from './ComponentMapping';
import { sendWhatsAppChannel, getChanneldata } from '../../Services/api';
import { useSelector } from 'react-redux';
import PrimaryButton from '../../components/themeComponents/PrimaryButton';
import { whatsappchannelSchema } from '../../_helpers/validationSchema';

const Whatsapp = () => {

  const initialData = {
    Kaleyra: {
      wabaId: "",
      auth: "",
      account: "",
      businessPhone: '', 
      provider: selectProvidersValue[0].value,
      remarks: ''
    },
    Routemobile: {
      businessPhone: '',
      provider: selectProvidersValue[1].value,
      remarks: '',
      account: "",
      auth: "",
      remarks:""
    },
    Gupshup: {
      businessPhone: '',
      provider: selectProvidersValue[2].value,
      hsmAccount: '',
      hsmPassword: '',
      twoWayAccount: '',
      twoWayPassword: '',
      remarks: ''
    },
    Sinch: {
      businessPhone: '',
      provider: selectProvidersValue[3].value,
      account: '',
      auth: '',
      remarks: ''

    },
    Trustsignal:{
      businessPhone: '',
      provider: selectProvidersValue[4].value,
      account: '',
      auth: '',
      org_id: useSelector((state) => state.org.orgList[0]?.id),
      org_token:useSelector((state) => state.org.orgList[0]?.token),
    }
  };

  const [selected, setSelected] = useState(selectProvidersValue[0].value);
  const [providerData, setProviderData] = useState({});
  const [validationError, setValidationError] = useState("");
  const [loading, setLoading] = useState(false);
  const [validationSchema, setValidationSchema] = useState(whatsappchannelSchema.Kaleyra);
  const [initialValues, setInitialValues] = useState(initialData['Kaleyra']);

  const getTokenFromLocalStorage = () => {
    const storedData = localStorage.getItem("selectedOrg");
    return storedData ? JSON.parse(storedData) : null;
  };

  const orgToken = useSelector((state) => state.org.orgList[0]?.token) || getTokenFromLocalStorage().token;
  console.log(orgToken,"ORG");

  const toast=useToast()

  const SelectedChannelComponent = componentMapping[selected];
  const formikRef = useRef(null)
  const fetchChannelsData = async () => {
    try {
      // debugger
      const data = await getChanneldata(orgToken);
      // console.log(data,"data")
      setSelected(data.data.data.response.provider);
      // console.log(selected,"Updaed selected")
      formikRef.current.setValues(data.data.data.response);
      return data;
    } catch (error) {
      console.log("Error while fetching Channel Data");
    }
  };

  const registerWhatsappChannel = async (data) => {
    try {

      setLoading(true);
      data.provider = data.provider.toUpperCase();
      console.log(data,"Datatta")
      const response = await sendWhatsAppChannel(data);
      // console.log(response);
      toast({ title: 'Success', description: 'whatsapp Credentials Saved Successfully.', status: 'success' });
      return response;
    } catch (error) {
      console.log("Error while saving Whatsapp Channel", error);
      toast({ title: 'Oops!', description: 'Error in saving credentials.', status: 'error' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchChannelsData();
  }, []);

  useEffect(() => {
    setValidationSchema(whatsappchannelSchema[selected]);
    // setInitialValues(initialData[selected]);
  }, [selected]);

  return (
    <Formik
    innerRef={formikRef}
    initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        values = { ...values, ...providerData };
        console.log(values,"Val")
        registerWhatsappChannel(values);
        actions.setSubmitting(false);
      }}
      enableReinitialize={true}
    >
      {formikProps => (
        <Box backgroundColor={"#fff"} p={4} mr={6} ml={10}>
          <Form>
            <Flex direction="row" justify={'space-evenly'}>
              <Box backgroundColor={"#fff"} mt={'1rem'} maxW={"35vw"} height={'fit-content'} p={"2rem"} width={'100%'} borderRadius={"2px"}>
                <Text fontSize={'1.2vw'} mb={'1rem'}>Whatsapp</Text>
                <FormControl mb={4} isInvalid={formikProps.errors.businessPhone && formikProps.touched.businessPhone}>
                  <FormLabel htmlFor="businessPhone">Business Phone</FormLabel>
                  <Field as={Input} id="businessPhone" name="businessPhone" mt={4} />
                  <FormErrorMessage>{formikProps.errors.businessPhone}</FormErrorMessage>
                </FormControl>

                <FormControl mb={4} isInvalid={formikProps.errors.provider && formikProps.touched.provider}>
                  <FormLabel htmlFor="provider">Select Provider</FormLabel>
                  <Field as={Select} id="provider" name="provider" mt={4}
                    onChange={(e) => {
                      formikProps.handleChange(e);
                      setSelected(e.target.value);
                    }}
                  >
                    {selectProvidersValue?.map((provider) => (
                      <option key={provider.key} value={provider.value}>
                        {provider.value}
                      </option>
                    ))}
                  </Field>
                  <FormErrorMessage>{formikProps.errors.provider}</FormErrorMessage>
                </FormControl>
                {SelectedChannelComponent && <SelectedChannelComponent setProviderData={setProviderData} />}
              </Box>

              <Box mt={8}>
                <FormControl mb={4} isInvalid={formikProps.errors.remarks && formikProps.touched.remarks}>
                  <FormLabel htmlFor="remarks">Remarks</FormLabel>
                  <Textarea
                    id="remarks"
                    name="remarks"
                    // value={formikProps.values.remarks}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    placeholder="Enter your remarks"
                    height="35vh"
                    width="30vw"
                    borderRadius="md"
                    resize="none"
                    border="1px solid #CBD5E0"
                    _focus={{
                      border: "1px solid #3182CE",
                    }}
                  />
                  <FormErrorMessage>{formikProps.errors.remarks}</FormErrorMessage>
                </FormControl>
                <PrimaryButton label={"Save"} type="submit" isLoading={loading} size="sm" />
              </Box>
            </Flex>
          </Form>
        </Box>
      )}
    </Formik>
  );
};

export default Whatsapp;
