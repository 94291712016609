import { Box, FormControl, FormLabel, Grid, Input, Select, Text } from '@chakra-ui/react'
import { useFormik } from 'formik'
import React, { Fragment, forwardRef, useImperativeHandle } from 'react'
import { durations } from '../NudgeHelper'


const NudgeFinalTouch = forwardRef(({ data, updateFinalSettings }, ref)  => {
    const formik = useFormik({
        initialValues: { ...data },
        onSubmit: (values) => {
            updateFinalSettings(values)
        }
    })
    useImperativeHandle(ref, () => ({
        submitToParent: () => {
            formik.submitForm()
        }
    }))
    return (
        <Fragment>
            <Box height={'100%'} bg={'white'} p={2} overflow={'auto'}>
                <Text as={'b'} fontSize={'xl'} display={'flex'} alignItems={'center'}>Final Settings</Text>
                <Grid templateColumns={'1fr 1fr'} gap={5} mt={3}>
                    <FormControl>
                        <FormLabel color={'inputLabel'}>Name</FormLabel>
                        <Input value={formik.values.name} onChange={formik.handleChange} name="name"></Input>
                    </FormControl>
                    <FormControl>
                        <FormLabel color={'inputLabel'}>Description</FormLabel>
                        <Input value={formik.values.description} onChange={formik.handleChange} name="description"></Input>
                    </FormControl>
                    <FormControl>
                        <FormLabel color={'inputLabel'}>Expire After</FormLabel>
                        <Input value={formik.values.ttl.value} onChange={formik.handleChange} name="ttl.value" placeholder='Enter Template Name'></Input>
                    </FormControl>
                    <FormControl>
                        <FormLabel color={'inputLabel'}>Time Unit</FormLabel>
                        <Select value={formik.values.ttl.timeunit} onChange={(e) => formik.setFieldValue("ttl.timeunit", e.target.value)}>
                            {
                                durations.map(duration => (
                                    <option key={duration.key} value={duration.key}>{duration.label}</option>
                                ))
                            }
                        </Select>
                    </FormControl>
                    
                </Grid>
            </Box>
        </Fragment>
    )
})

export default NudgeFinalTouch;