import { Text, Alert, AlertIcon, FormLabel, Select } from '@chakra-ui/react'
import React, { Fragment, useEffect, useState } from 'react'
import { getSegmentProps } from '../../../Services/api';
import { eventValueOperators } from '../../../_helpers/data_helper';
import { Input } from '@chakra-ui/react';
export default function InspectorForCondition({ formData, selectedElement, updateFormData, validateFormData }) {
    let getPreviousElementActionData = (currentElementId) => {
        if (!!formData[currentElementId] && formData[currentElementId].previousElementIds.length > 0
            && !!formData[formData[currentElementId].previousElementIds[0]]
            && !!formData[formData[currentElementId].previousElementIds[0]].actionData) {

            let ret = formData[formData[currentElementId].previousElementIds[0]].actionData.availableActionEvents
            console.log(ret);

            //updateFormData(tempFormData)
            return formData[formData[currentElementId].previousElementIds[0]].actionData.availableActionEvents;
        }
    };
    const [segmentProps, setSegmentProps] = useState([])
    const [isCustomerAttribute, setIsCustomerAttribute] = useState(false);
    // const [customerAttributes, setCustomerAttributes] = useState([{ key: '', operator: '', value: '' }]);
    // console.log(customerAttributes,"customerAttributes")
    const fetchSegmentProps = async () => {
        const segmentationProps = await getSegmentProps().catch(err => err);
        //   console.log(events.data.targets[0],"API called")
        if (segmentationProps.status == 200) {
            setSegmentProps(Object.keys(segmentationProps.data.segmentationProps.customers).map((prop) => {
                return segmentationProps.data.segmentationProps.customers[prop].name
            })
            )
        }
        console.log(segmentProps)
        //   setSegmentProps(events.data.targets[0]);

        //   console.error("Error fetching segment Props events:", error);


    }



    let selectCondition = (e) => {
        let tempFormData = { ...formData };
        if (!tempFormData[selectedElement.id].nextActionCondition.criteria[0].value) {
            tempFormData[selectedElement.id].nextActionCondition.criteria[0].value = [];
        }
        

        tempFormData[selectedElement.id].nextActionCondition.criteria[0].value[0] = e.target.value;
        tempFormData[selectedElement.id].nextActionCondition.criteria[0]["key"] = "channel"
        tempFormData[selectedElement.id].nextActionCondition.criteria[1] = { property: '', operator: '', value: '' }
        setIsCustomerAttribute(true);
        updateFormData(tempFormData);
        validateFormData("beginsWith");
    }
    useEffect(() => {
        fetchSegmentProps()

    }, [])
    const handleAttributeChange = (index, field, value) => {

        if (isCustomerAttribute) {
            const updatedAttributes = [...formData[selectedElement.id].nextActionCondition.criteria];
            
            if(index>0){
                updatedAttributes[index].key = "customer"
            }
            else{
                updatedAttributes[index].key = "channel"
            }
            
            updatedAttributes[index][field] = value;
            const updatedFormData = { ...formData, [selectedElement.id]: { ...formData[selectedElement.id], nextActionCondition: { ...formData[selectedElement.id].nextActionCondition, criteria: updatedAttributes } } };
            updateFormData(updatedFormData);
        }
    };
    const handleRemoveAttribute = (index, e) => {
        const updatedAttributes = [...formData[selectedElement.id].nextActionCondition.criteria];
        updatedAttributes.splice(index, 1);
        const updatedFormData = { ...formData, [selectedElement.id]: { ...formData[selectedElement.id], nextActionCondition: { ...formData[selectedElement.id].nextActionCondition, criteria: updatedAttributes } } };
        updateFormData(updatedFormData);
        // console.log("Form Data after Remove:", updatedFormData);
    };


    const handleAddAttribute = () => {
        const updatedAttributes = [...formData[selectedElement.id].nextActionCondition.criteria, { key: '', operator: '', value: '' }];
        const updatedFormData = { ...formData, [selectedElement.id]: { ...formData[selectedElement.id], nextActionCondition: { ...formData[selectedElement.id].nextActionCondition, criteria: updatedAttributes } } };
        updateFormData(updatedFormData);
    };
    return (
        <Fragment>
            {
                !!(formData[selectedElement.id].previousElementIds.length < 1) && <Alert status='error'><AlertIcon />You need to connect condition with an action first.</Alert>
            }
            {
                !!(formData[selectedElement.id].previousElementIds.length > 0) && <Fragment>
                    <FormLabel color={'inputLabel'} mt={4}>{formData[selectedElement.id].nextActionCondition.criteria[0].property.toUpperCase()} is </FormLabel>
                    <Select value={formData[selectedElement.id].nextActionCondition.criteria[0].value[0]} placeholder='Select' onChange={selectCondition}>
                        {
                            getPreviousElementActionData(selectedElement.id).map(dt => (
                                <option value={dt.code} key={dt.code}>
                                    {dt.label}
                                </option>
                            ))
                        }
                    </Select>
                    {isCustomerAttribute && formData[selectedElement.id].nextActionCondition.criteria.map((attribute, index) => (

                        index != 0 &&
                        <Fragment key={index}>

                            <Text onClick={() => handleRemoveAttribute(index)} cursor="pointer" fontSize="sm" textDecoration="underline">Remove-</Text>

                            <Select
                                mt={4}
                                value={attribute.property}
                                placeholder='Select'
                                onChange={(e) => handleAttributeChange(index, 'property', e.target.value)}
                            >
                                {segmentProps.map(key => (
                                    <option key={key} value={key}>
                                        {key}
                                    </option>
                                ))}
                            </Select>
                            <Select
                                mt={4}
                                value={attribute.operator}
                                placeholder='Select'
                                onChange={(e) => handleAttributeChange(index, 'operator', e.target.value)}
                            >
                                {eventValueOperators.map(operator => (
                                    <option key={operator.key} value={operator.key}>
                                        {operator.label}
                                    </option>
                                ))}
                            </Select>
                            <Input
                                type='text'
                                placeholder='Enter value'
                                mt={4}
                                value={attribute.value}
                                onChange={(e) =>{ handleAttributeChange(index, 'value', e.target.value); console.log(formData,">>>fdd")}}
                            />

                        </Fragment>
                    ))}

                    {isCustomerAttribute && (

                        <Text onClick={handleAddAttribute} cursor="pointer" fontSize="sm" textDecoration="underline">Add+</Text>

                    )}

                </Fragment>

            }
        </Fragment>
    )
}
