import { Box, Icon, Text, useToken } from '@chakra-ui/react'
import React, { Fragment } from 'react'
import { OvalSpinner } from '../Loaders'

function Widget({ value, title, icon, isLoading }) {
    const [primary] = useToken('colors', ['primary.main']);
    return (
        <Box bg={'white'} minH={'3vw'} borderRadius={6} boxShadow={'sm'} p={'0.6vw'} display={'flex'} flexDirection={'column'} alignItems={!!isLoading ? 'center' : 'start'} justifyContent={'center'}>
            {
                !!isLoading && <OvalSpinner height={20} width={20} color={primary}></OvalSpinner>
            }
            {
                !isLoading && <Fragment>
                    <Icon as={icon} color={'primary.main'} boxSize={'1.4vw'} mb={'0.7vw'}></Icon>
                    <Text fontSize={'0.9vw'} mb={'0.7vw'}>{title}</Text>
                    <Text fontSize={'1vw'} as={'b'}>{value}</Text>
                </Fragment>
            }


        </Box>
    )
}

export default Widget
