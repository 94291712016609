import React, { useState } from 'react';
import { Tabs, TabList, Tab, } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
const SwitchableTabs = ({ tabContent,activeTabIndex }) => {
    const [selectedTab, setSelectedTab] = useState(0);
    const navigate = useNavigate();

    const handleTabChange = (index) => {
        setSelectedTab(index);
    };

    return (
        <>
            {!!tabContent.length &&
                <Tabs isFitted  colorScheme='#723fa3'index={activeTabIndex} >
                    <TabList gap={'1rem'} mb="1em">
                        {
                            tabContent.map((content, index) => (
                                <Link to={content?.navigateTo} >
                                    <Tab paddingLeft={'0px'} color={'#723fa3'} isSelected={selectedTab === 0}>
                                        {content?.label}
                                    </Tab>
                                </Link>
                            ))
                        }
                    </TabList>

                </Tabs>
            }
        </>
    );
};

export default SwitchableTabs;
