import { Box, Text, Flex, Image } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
function HeaderOnly() {
    const whiteLabelData = useSelector(state => state.auth?.white_label)
    useEffect(()=>{
        console.log(whiteLabelData)

    },[whiteLabelData])
    return (
        <Box height={20} bg={'white'} borderBottom={'1px solid'} borderColor={'gray.300'} pos={'fixed'} top={0} zIndex={9} right={0} left={0}>
            <Box w={60}>
                <Flex h="20" alignItems="center" justifyContent="space-between" borderRight={'1px solid'} borderColor={'gray.300'}>
                    <Flex h="20" alignItems="center" justifyContent="space-between" w={'100%'} mx={8}>
                        <Image src={whiteLabelData.data.lightBgLogo} w={'100%'} onClick={()=>{ window.open('/',"_self") }} objectFit={'cover'}/>
                        
                        {/* <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} /> */}
                    </Flex>
                    {/* <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} /> */}
                </Flex>
            </Box>
        </Box>
    )
}

export default HeaderOnly
