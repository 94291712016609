import React, { Fragment, useState, useEffect } from 'react'
import { Tr, Td, Box, Text, Flex, Icon, FormControl, FormLabel, Switch, useToast, Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react'
import { BsThreeDotsVertical } from "react-icons/bs";
import CampaignWisePerformance from '../../Campaigns/components/CampaignWisePerformance'
import { MdModeEditOutline, MdDelete } from "react-icons/md";
import { QueryClient, useMutation } from "react-query"
import { toggleFlow, deleteRule } from '../../../Services/api';
import { useNavigate } from "react-router-dom"
import { ConfimrationAlerts } from '../../../components/themeComponents/ConfirmationAlert';
import { HiOutlineDuplicate } from "react-icons/hi";
let queryClient = new QueryClient()
export default function FlowListTable({ list, refetch }) {
    const toast = useToast();
    const navigate = useNavigate()
    const toggleStatus = async (uuid, status) => {
        let toggleResponse;
        const examplePromise = new Promise(async (resolve, reject) => {
            toggleResponse = await toggleFlow(uuid);
            let updatedData = flowList.map(item => {
                if (item.uuid === uuid) {
                    item.status = !!status ? 'true' : 'false'
                    return item
                }
                return item
            })
            setFlowList(updatedData)
            resolve(toggleResponse)
        })

        toast.promise(examplePromise, {
            success: { title: `Rule ${status ? 'Enabled' : 'Disabled'}` },
            error: { title: 'Opps!', description: `Something went wrong while ${status ? 'enabling' : 'disabling'} the rule` },
            loading: { title: 'Toggling the rule', description: 'Please wait' },
        })

    }
    const mutation = useMutation(toggleStatus);
    const [flowList, setFlowList] = useState(list)
    const handleToggle = async (status, uuid) => {
        try {
            await mutation.mutateAsync(status, uuid);
            // After updating the name, refetch the data to get the updated list
            //refetch();
        } catch (error) {
            console.error('Error updating item name:', error);
        }
    }
    const deleteFlow = (uuid) => {
        ConfimrationAlerts({
            title: "Are you sure?",
            message: `Do you want to delete the flow?`,
            okText: 'Okay',
            // confirmColor: 'black',
            async onOk() {
                const examplePromise = new Promise(async (resolve, reject) => {
                    let deleteResponse = await deleteRule(uuid)

                    resolve(deleteResponse)
                    navigate(0)

                })
                toast.promise(examplePromise, {
                    success: { title: `Rule deleted` },
                    error: { title: 'Opps!', description: `Something went wrong while deleting the rule` },
                    loading: { title: 'Deleting the rule', description: 'Please wait' },
                })


            },
            onCancel() {
                //formikIntance.setSubmitting(false)
            },
        })
    }
    useState(() => {

        setFlowList(list)
    }, [list])
    return (
        <Fragment>
            {
                !!flowList.length && flowList.map(item => (
                    <Tr key={item.uuid}>
                        <Td w={'20%'}>
                            <Box>
                                <Text fontSize={'0.8vw'} >{item.name}</Text>
                                <Text fontSize={'0.8vw'} color={'light'}>{item.description}</Text>
                            </Box>
                        </Td>
                        <Td w={'10%'}>
                            <Text fontSize={'0.8vw'}>{item.timer}</Text>
                        </Td>
                        {/* <Td w={'10%'}>
                            <Text fontSize={'0.8vw'}>{!!JSON.parse(item.status) ? "ENABLED" : "DISABLED"}</Text>
                        </Td> */}
                        <Td w={'10%'}>
                            <Text fontSize={'0.8vw'}>{item.lastUpdatedAt}</Text>
                        </Td>
                        <Td w={'35%'}>
                            <CampaignWisePerformance data={item.performance}></CampaignWisePerformance>
                        </Td>
                        <Td w={'10%'}>
                            <Text fontSize={'0.8vw'} >{item.revenue}</Text>
                        </Td>
                        <Td w={'20%'} >
                        <Icon as={HiOutlineDuplicate} title='Copy'  boxSize={5} color={"light"} cursor={'pointer'} onClick={() => navigate("/flows/create/" + item.uuid + "/copycampaign")}></Icon>
                            <Icon as={MdModeEditOutline} title='Edit' ml={2} boxSize={5} color={"light"} cursor={'pointer'} onClick={() => navigate("/flows/create/" + item.uuid)}></Icon>
                            <Icon as={MdDelete} boxSize={5} title='Delete' ml={2} color={"light"} cursor={'pointer'} onClick={() => deleteFlow(item.uuid)}></Icon>
                            {/* <FormControl display='flex' alignItems='center'> */}
                                <Switch fontSize={'0.8vw'} ml={2} title={item.status === 'true' ? 'Disable' : 'Enable'} isChecked={!!(item.status === 'true')} onChange={(e) => toggleStatus(item.uuid, e.target.checked)}></Switch>
                                {/* <FormLabel fontSize={'0.8vw'} color={'light'} m={0} ml={2}>{item.status === 'true' ? 'Enabled' : 'Disabled'}</FormLabel> */}
                            {/* </FormControl> */}
                        </Td>
                    </Tr>
                ))
            }
        </Fragment>
    )
}
