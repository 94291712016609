import { Text } from '@chakra-ui/react'
import React, { Fragment } from 'react'
import { Outlet } from 'react-router-dom'

function Campaigns() {
    return (
        <Fragment>
            <Outlet/>
        </Fragment>
    )
}

export default Campaigns
