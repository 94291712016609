import React, { useState } from 'react'
import { Box, Text, Alert, AlertIcon, AlertDescription, FormControl, FormLabel, Input, FormErrorMessage, VStack, Link, Button, Stack } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom';
function ForgotPassword() {
    const [error,  setError] = useState("");

    return (
        <Box maxW={'md'} minW={'xl'} boxShadow={'base'} borderRadius={'xl'} bg={'white'} px={5} py={10}>
        <Text fontSize={'2xl'} fontWeight={'bold'} color={'primary.main'}>Welcome Back</Text>
        <Text fontSize={'md'} color={'light'} mb={4}>Enter your email and password to sign in.</Text>
        {!!error && <Alert status='error' mb={4}>
            <AlertIcon />
            <AlertDescription fontSize={14}>{error}</AlertDescription>
        </Alert>}
        <form>
            <FormControl isRequired mb={4} id='email'>
                <FormLabel color={'inputLabel'}>Email</FormLabel>
                <Input type='email' />
                <FormErrorMessage>
                    Email is required
                </FormErrorMessage>
            </FormControl>
            <Button w={'full'} bg={'primary.main'} color={'white'} _hover={{ bg: 'primary.main', opacity: '.8' }} size={'lg'}  type='submit'>
                Send Email
            </Button>
        </form>
        <Stack pt={6}>
            <Text align={'center'}>
               <Link as={RouterLink} color={'blue.400'} to="/auth/login">
                   Login
                </Link>
            </Text>
        </Stack>
    </Box>
    )
}

export default ForgotPassword
