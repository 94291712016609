import React, { Fragment, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { logout } from '../../Services/AuthService';

function Logout() {
    const dispatch = useDispatch();
    useEffect(() => {
        logout(dispatch)
    }, [])
    return (
        <Fragment></Fragment>
    )
}

export default Logout
