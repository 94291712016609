import GupShup from "./Providers/GupShup"
import Kaleyra from "./Providers/Kaleyra"
import RouteMobile from "./Providers/RouteMobile"
import Sinch from "./Providers/Sinch"
import TrustSignal from "./Providers/TrustSignal"

const componentMapping = {
    Kaleyra: Kaleyra,
    Routemobile:RouteMobile,
    Sinch:Sinch,
    Gupshup:GupShup,
    Trustsignal:TrustSignal
}
export default componentMapping