import React from 'react';
import { Formik, Form, Field, ErrorMessage, useFormikContext } from 'formik'; 
import { FormControl, FormLabel, Input, VStack, FormErrorMessage } from '@chakra-ui/react';
import * as Yup from 'yup';

const Telnyx = ({values, index, setGatewayData}) => {
  const { errors, touched, setFieldValue } = useFormikContext();  



  const handleChange = (field, event) => {
    setFieldValue(`credentials[${index}].${field}`, event.target.value);
    setGatewayData(`credentials[${index}]`, { ...values, [field]: event.target.value }); 
  };

  

  return (
    <Formik
      
    >
      {() => (
        <Form>
          <VStack spacing={4} alignItems="flex-start">
          <FormControl id="userName" mb={4} isInvalid={errors.userName && touched.userName}>
              <FormLabel>User Name</FormLabel>
              <Field
          name={`credentials[${index}].userName`}
          as={Input} 
        placeholder="userName"
          value={values.userName}
          onChange={(e) => handleChange('userName', e)}
        />
              <FormErrorMessage>{errors.appid}</FormErrorMessage>
            </FormControl>

            <FormControl id="profileid" mb={4} isInvalid={errors.profileid && touched.profileid}>
              <FormLabel>Profile Id</FormLabel>
              <Field
               as={Input} 
        placeholder="profileid"
              
          name={`credentials[${index}].profileid`}
          value={values.profileid}
          onChange={(e) => handleChange('profileid', e)}
        />
          <FormErrorMessage>{errors.profileid}</FormErrorMessage>
          </FormControl>
          <FormControl id="phone" mb={4} isInvalid={errors.phone && touched.phone}>
        <FormLabel>Password</FormLabel>
        <Input
          type='password'
          placeholder="password"
          name={`credentials[${index}].password`}
          value={values.password}
          onChange={(e) => handleChange('password', e)}
        />
        <FormErrorMessage>{errors.password}</FormErrorMessage>
      </FormControl>

            <FormControl id="phone" mb={4} isInvalid={errors.phone && touched.phone}>
              <FormLabel>Phone</FormLabel>
              <Field
              type={Input}
          placeholder="phone"
          name={`credentials[${index}].phone`}
          value={values.phone}
          onChange={(e) => handleChange('phone', e)}
        />
              <FormErrorMessage>{errors.phone}</FormErrorMessage>
            </FormControl>
          </VStack>
        </Form>
      )}
    </Formik>
  );
};

export default Telnyx;
