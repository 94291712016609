import axiosInstance from './axiosInstance'

import store from '../store/store'

export const getUserDetails = async () => {
    const orgToken = store.getState().auth?.selectedOrg?.token;
    let url = `${process.env.REACT_APP_API_ENDPOINT}/getuserdata?orgToken=${orgToken}`
    return await axiosInstance.get(url)
}


export const setProfilePhoto = async (data) => {
    const orgToken = store.getState().auth?.selectedOrg?.token;
    let url = `${process.env.REACT_APP_API_ENDPOINT}/updateprofilephoto?orgToken=${orgToken}`
    let axiosConfig = {
        headers: {
            'Content-Type': 'multipart/form-data;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }
      };
    return await axiosInstance.post(url, data, axiosConfig)
}


export const updateUserData = async ({phoneNumber,userName,orgName}) => {
    const orgToken = store.getState().auth?.selectedOrg?.token;
    let url = `${process.env.REACT_APP_API_ENDPOINT}/updateuserdata?orgToken=${orgToken}`;
    let data = {
        phoneNumber: phoneNumber,
        userName:userName,
        orgName:orgName
    }
    return await axiosInstance.put(url, data)
}


export const updatePassword = async ({oldPassword,newPassword}) => {
    const orgToken = store.getState().auth?.selectedOrg?.token;
    let url = `${process.env.REACT_APP_API_ENDPOINT}/updatepassword?orgToken=${orgToken}`;
    let data = {
        oldPassword: oldPassword,
        newPassword:newPassword,
    }
    return await axiosInstance.post(url, data)
}


export const addOrganization = async (data) => {
    
    const orgToken = store.getState().auth?.selectedOrg?.token;
    let url = `${process.env.REACT_APP_API_ENDPOINT}/rest/v1/admin/addorg?orgToken=${orgToken}`;
    return await axiosInstance.post(url, data)
}


export const editOrganization = async (data) => {   
    const orgToken = store.getState().auth?.selectedOrg?.token;
    let url = `${process.env.REACT_APP_API_ENDPOINT}/account/updatecompany?orgToken=${orgToken}`;
    return await axiosInstance.post(url, data)
}

export const getOrgDetails = async () => {   
    const orgToken = store.getState().auth?.selectedOrg?.token;
    let url = `${process.env.REACT_APP_API_ENDPOINT}/getorgdetails?orgToken=${orgToken}`;
    return await axiosInstance.get(url)
}
export const setOrgLogo = async (data) => {
    const orgToken = store.getState().auth?.selectedOrg?.token;
    let url = `${process.env.REACT_APP_API_ENDPOINT}/rest/v1/admin/badgeiconupload?orgToken=${orgToken}`
    let axiosConfig = {
        headers: {
            'Content-Type': 'multipart/form-data;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }
      };
    return await axiosInstance.post(url, data, axiosConfig)
}
export const setMobileOrgLogo=async(data)=>{
    const orgToken = store.getState().auth?.selectedOrg?.token;
    let url = `${process.env.REACT_APP_API_ENDPOINT}/admin/iconupload?orgToken=${orgToken}`
    let axiosConfig = {
        headers: {
            'Content-Type': 'multipart/form-data;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }
      };
    return await axiosInstance.post(url, data, axiosConfig)

}