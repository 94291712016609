import React, { useEffect, useState } from 'react'
import FacebookLoginButton from './FacebookLoginButton';
import axiosInstance from '../../Services/axiosInstance';
import FacebookPages from "./FacebookPages"
export default function FacebookAuth() {
    const [isfbLoggedIn, setIsFbLoggedIn] = useState(false);
    let saveFbCode = () => {
        let url = process.env.REACT_APP_API_ENDPOINT + window.location.pathname + window.location.search;
        axiosInstance.get(url).then((res) => {
            console.log(res.data);
        }).catch((e) => {
            console.log(e)
        })
    }
    let checkFbCredentials = () => {
        let url = process.env.REACT_APP_API_ENDPOINT + "/fb/fbmessenger/credentials?restful=true&channel=facebook";
        axiosInstance.get(url).then((res) => {
            console.log(res.data);
            setIsFbLoggedIn(res.data.loggedIn);
        })
    }


    useEffect(() => {
        checkFbCredentials();
        if (!isfbLoggedIn) {
            if (window.location.search.includes("code")) {
                saveFbCode();
            }
        }
    }, [])
    return (
        <>
            {
                !isfbLoggedIn &&
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <FacebookLoginButton />
                </div>
            }
            {
                !!isfbLoggedIn &&
                <>
                    <FacebookPages />
                </>
            }

        </>
    )
}
