import React from 'react'
import { AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter, Button } from "@chakra-ui/react";
import PrimaryButton from "./PrimaryButton"
import OutlinedButton from './OutlinedButton';
function Confirmation({isOpen, confirmButtonText, cancelButtonText, title, text, onConfirm, onCancel,children}) {
    return (
        <AlertDialog isOpen={isOpen}>
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader>
                        {title}
                    </AlertDialogHeader>
                    <AlertDialogBody>
                        {text}
                    </AlertDialogBody>
                    {children && <div className="confirmation-content">{children}</div>}
                    <AlertDialogFooter>
                        <OutlinedButton label={cancelButtonText} isLoading={false} colorScheme={'danger'} size="sm" mr="2" onClick={onCancel}></OutlinedButton>
                        <PrimaryButton label={confirmButtonText} onClick={onConfirm} size="sm"></PrimaryButton>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}

export default Confirmation
