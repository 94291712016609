import { Tr, Text, Td, Flex, Icon, useToast } from '@chakra-ui/react'
import moment from 'moment'
import React, { Fragment, useState } from 'react'
import { FaTrash } from 'react-icons/fa'
import { FiEdit } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import { showSwal } from '../../../_helpers/helpers'
import Swal from "sweetalert2"
import axiosInstance from '../../../Services/axiosInstance'
import { deleteNudgeTemplate } from '../../../Services/api'
export default function NudgeTable({ list, onPreview, onDelete, nudgeTemplateListQuery }) {
    const [selectedPreview, setSelectedPreview] = useState(null);
    const navigate = useNavigate();
    const toast = useToast()
    const showPreview = (uuid, body) => {
        setSelectedPreview(uuid);
        onPreview(body)
    }
    
    const deletePost = (uuid) => {
        showSwal(Swal, "Are you sure?", "Do you want to delete the template?", true, true, 'Yes, Delete', 'No', async () => {
            let deleteRes = await deleteNudgeTemplate(uuid).catch(error => {
                toast({ title: 'Oops', description:  "Something went wrong while deleting the template.", status: 'error'});
                Swal.close();
            });
            if(deleteRes.data?.status === 'error') {
                toast({ title: 'Oops', description: deleteRes.data?.message || "something went wrong while deleting the template.", status: 'error'})
                Swal.close();
            } 
            toast({ title: 'Success', description: deleteRes.data?.message , status: 'success'})
        }, (res) => {
            !!res.isConfirmed && (() => {
                Swal.close()
                nudgeTemplateListQuery.refetch();
            })()
        })
       // onDelete(uuid)
    }
    return (
        <Fragment>
            {
                list.map(lt => (
                    <Tr key={lt.uuid} bg={selectedPreview === lt.uuid && 'gray.200'}>
                        <Td onClick={() => showPreview(lt.uuid, lt.markup)} cursor={'pointer'}>
                            {lt.name}
                            <Text>{moment(lt.lastupdated).format('lll')}</Text>
                        </Td>
                        <Td>
                            <Flex justifyContent={'start'} gap={3}>
                                <Icon as={FiEdit} cursor={'pointer'} onClick={() => navigate("/nudge/layout/edit?uuid="+lt.uuid)}></Icon>
                                <Icon as={FaTrash} cursor={'pointer'} onClick={() => deletePost(lt.uuid)}></Icon>
                            </Flex>
                        </Td>
                    </Tr>
                    
                ))
            }
        </Fragment>
    )
}
