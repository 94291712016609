export const onsiteFonts = [
    {"name": "Arial", "code": "Arial, Helvetica, sans-serif"},
    {"name": "Arial Black", "code": "'Arial Black', Gadget, sans-serif"},
    {"name": "Verdana", "code": "Verdana, Geneva, sans-serif"},
    {"name": "Georgia", "code": "Georgia, 'Times New Roman', Times, serif"},
    {"name": "Courier New", "code": "'Courier New', Courier, monospace"},
    {"name": "Tahoma", "code": "Tahoma, Geneva, sans-serif"},
    {"name": "Trebuchet MS", "code": "'Trebuchet MS', Arial, Helvetica, sans-serif"},
    {"name": "Verdana", "code": "Verdana, Geneva, sans-serif"},
    {"name": "Times New Roman", "code": "'Times New Roman', Times, serif"},
    {"name": "MS Serif", "code": "'MS Serif', 'New York', serif"},
    {"name": "Comic Sans MS", "code": "'Comic Sans MS', cursive"},
    {"name":  "Helvetica", "code": "helvetica, 'helvetica neue', arial, verdana, sans-serif;"},
    {"name": "Montserrat Font","code": "montserrat"},
    {"name": "Gill Sans Nova", "code": "'Gill Sans Nova', Courier","fontUrl": "https://fonts.cdnfonts.com/css/gill-sans-nova" },
    {"name": "Lora", "code": "'Lora', sans-serif","fontUrl": "https://fonts.googleapis.com/css?family=Lora" },
    {"name": "Josefin Sans", "code": "'Josefin Sans', sans-serif","fontUrl": "https://fonts.cdnfonts.com/css/josefin-sans"},
    {"name": "Poppins", "code": "'Poppins', Courier","fontUrl": "https://fonts.cdnfonts.com/css/poppins"},
    {"name": "Inter", "code": "'Inter'","fontUrl": "https://fonts.cdnfonts.com/css/inter"},
    {"name": "Work Sans","code": "'Work Sans'","fontUrl": "https://fonts.googleapis.com/css?family=Work+Sans"}
]