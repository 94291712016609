import React, { useRef, useState, useEffect } from 'react'
import { Box, Text, Flex, FormControl, FormLabel, AspectRatio, Image, FormHelperText, Icon, useDisclosure, Tooltip, Input, HStack } from '@chakra-ui/react'
import Select from '../../../components/themeComponents/react-chakra-select'
import { DeviceFrameset } from 'react-device-frameset'
import { useQuery } from 'react-query'
import { fetchTemplate,getSmsGatways } from '../../../Services/api'
import { prepareGatewayList, prepareTemplateList, smtpTypes, winningCriteria } from '../../../_helpers/data_helper'
import { BsBox2Fill } from 'react-icons/bs'
import * as htmltoImage from "html-to-image"
import html2canvas from 'html2canvas'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import TemplatePreviewModal from './TemplatePreviewModal'
import _, { delay } from "lodash";
import MobilePreview from './MobilePreview'
import Reach from './Reach'
import Flatpickr from 'react-flatpickr';
import moment from 'moment';
import 'flatpickr/dist/themes/material_green.css';
import {
    Slider,
    SliderTrack,
    SliderFilledTrack,
    SliderThumb,
    SliderMark,
} from '@chakra-ui/react'
import ABTestMobilePreview from './ABTestMobilePreview.js'
// import * as _ from 'lodash'
function TemplateSelectionAB({ selectedChannel, orgToken, formik, reach }) {

    const durationTypes = [
        { label: 'Minutes', key: 'minutes' },
        { label: 'Hours', key: 'hours' },
        { label: 'Days', key: 'days' },
        { label: 'Weeks', key: 'weeks' }
    ];

    const { isOpen, onClose, onOpen } = useDisclosure()
    const [allTemplateData, setAllTemplateData] = useState([]);
    const [selectedTemp, setSelectedTemp] = useState("")
    const [selectedGatewayUuid, setSelectedGatewayUuid] = useState("");
    
    const [isPerviewGenerating, setIsPreviewGenerating] = useState(false)
    const [template, setTemplate] = useState({
        "A": {},
        "B": {}
    })

    const gatewayQuery = useQuery(['gatways'], {
        queryFn: async () => {
            let gatewayData = await getSmsGatways();
            console.log(gatewayData)
            return gatewayData.data;
        },
        enabled: true
    });
    
    const [audienceRange, setAudienceRange] = useState(20);
    const tempBodyRef = useRef(null)
    const [tooltipVisibility, setTooltipVisibility] = useState(false)
    const templateQuery = useQuery(['fetchTemplate'], {
        queryFn: async () => {
            
            let res = await fetchTemplate(selectedChannel,orgToken,selectedGatewayUuid);
            return prepareTemplateList(selectedChannel, res.data)
        }
    })
    
    let setTargetRatio = () => {
        const updatedCSV = formik.values.targetedAudience.includes.CSV.map((e, i) => {
            return {
                ...e,
                targetRatio: audienceRange

            };
        });

        const updatedSegment = formik.values.targetedAudience.includes.segment.map((e, i) => {
            return {
                ...e,
                targetRatio: audienceRange
            };
        });
        formik.setFieldValue('targetedAudience.includes.CSV', updatedCSV);
        formik.setFieldValue('targetedAudience.includes.segment', updatedSegment);
        console.log(formik.values.targetedAudience.includes)
    }

    let handleAudienceRange = (v) => {
        setAudienceRange(v)
        setTargetRatio()

    }

    async function generateEmailPreview() {
        setIsPreviewGenerating(true);
        try {
            const tempBody = tempBodyRef.current;
            const canvas = await htmltoImage.toPng(tempBody);
            // const dataUrl = canvas.toDataURL('image/png');
            formik.setFieldValue('tempImg', canvas)
            setIsPreviewGenerating(false);
        } catch (error) {
            console.log('Error in generating preview', error)
        }
    }
    const selectTempData = (e, temp) => {
        console.log('on temp select', e)
        formik.setFieldValue(`selectedTemp${temp}`, e.uuid);
        formik.setFieldValue(`selectedTempName${temp}`, e.label);
        if (selectedChannel === 'email') {
            formik.setFieldValue(`temp${temp}Body`, e.body)
            // generateEmailPreview(e.body)
        }
        if (selectedChannel === 'whatsapp') {
            selectTemplate(e, temp)
        }
        // formik.setFieldValue("tempBody", e.body)
    }
    const selectTemplate = (e, temp) => {
        const selectedTemplates = templateQuery.data.filter((template) => {
            return template.uuid === e.uuid;
        });

        setTemplate((prevState) => {
            const newState = {
                ...prevState,
                [temp]: selectedTemplates[0]
            };
            return newState;
        });
    }


    useEffect(() => {
        gatewayQuery.refetch()
        console.log("Updated template state:", template);
    }, [template]);

    return (
        <Box w={'100%'} m={'0 auto'} mt={5} p={3} id='screenshot' bg={'white'} maxW={'930px'}>
            <Text color={'heading'} as={'b'}>Template Information</Text>
            <Flex gap={3} direction={'column'} mt={2}>
                <Box p={3} bg={'white'} w={'100%'}>
                    {
                        <FormControl>
                            {

                                selectedChannel == 'sms' &&
                                <><FormLabel fontWeight={'600'} color={'inputLabel'}>Select Provider</FormLabel>
                                   {!!gatewayQuery?.data && gatewayQuery?.data && <Select onChange={(e)=>{
                                    formik.setFieldValue("selectedGateway",e.value)
                                    setSelectedGatewayUuid(e.value)
                                    templateQuery.refetch()
                                }}
                                    options = {prepareGatewayList(gatewayQuery?.data?.gatewayList)} />}
                                    </>
                            }

                        </FormControl>
                    }
                        {
                            selectedChannel === 'sms' && !!selectedGatewayUuid && 
                    <FormControl>
                        
                        
                        <FormLabel fontWeight={'600'} color={'inputLabel'}>Template A </FormLabel>
                        <Select options={templateQuery?.data} onChange={(e) => selectTempData(e, "A")}></Select>
                        {
                            !!formik.values.selectedTempA && <Box float={'right'} title='Preview' onClick={() => {
                                setSelectedTemp("A")
                                onOpen()
                            }
                        } cursor={'pointer'}>
                        <Icon as={!!formik.values.selectedTempA ? FaEye : FaEyeSlash} cursor={'pointer'} mr={1}></Icon>
                        </Box>
                    }

                    </FormControl>
                }
                {
                            selectedChannel !== 'sms' && 
                    <FormControl>
                        
                        
                        <FormLabel fontWeight={'600'} color={'inputLabel'}>Template A </FormLabel>
                        <Select options={templateQuery?.data} onChange={(e) => selectTempData(e, "A")}></Select>
                        {
                            !!formik.values.selectedTempA && <Box float={'right'} title='Preview' onClick={() => {
                                setSelectedTemp("A")
                                onOpen()
                            }
                        } cursor={'pointer'}>
                        <Icon as={!!formik.values.selectedTempA ? FaEye : FaEyeSlash} cursor={'pointer'} mr={1}></Icon>
                        </Box>
                    }

                    </FormControl>
                }
                {
                    selectedChannel== 'sms' && !!selectedGatewayUuid &&

                    <FormControl mt={'2rem'} >
                        <FormLabel fontWeight={'600'} color={'inputLabel'}>Template B</FormLabel>
                        <Select options={templateQuery?.data} onChange={(e) => selectTempData(e, "B")}></Select>
                        {
                            !!formik.values.selectedTempB && <Box float={'right'} title='Preview' onClick={() => {
                                
                                setSelectedTemp("B")
                                onOpen()
                            }
                            } cursor={'pointer'}>
                                <Icon as={!!formik.values.selectedTempB ? FaEye : FaEyeSlash} cursor={'pointer'} mr={1}></Icon>
                            </Box>
                        }
                        {/* <Text>
                            Reach for Template B is {audienceRange}% of {JSON.stringify(reach)}
                            </Text> */}
                        {/* <Reach audience={formik.values.targetedAudience.includes} query={subscribersCountQuery}></Reach> */}
                    </FormControl>
                    }
                    {
                        (selectedChannel === 'email') && <FormControl mt={'2rem'}>
                            <FormLabel color={'inputLabel'}>SMTP Settings</FormLabel>
                            <Select options={smtpTypes} value={_.filter(smtpTypes, { 'key': formik.values.selectedSmtpType })} onChange={(e) => {
                                formik.setFieldValue("selectedSmtpType", e.key)


                            }}></Select>
                        </FormControl>
                    }
                    <Text mt={'2rem'} >

                        Reach for both template is {audienceRange}% of {JSON.stringify(reach)}
                    </Text>
                    <Slider aria-label='slider-ex- 1' value={audienceRange}
                        onMouseEnter={() => setTooltipVisibility(true)} onMouseLeave={() => setTooltipVisibility(false)}
                        onChange={(v) => handleAudienceRange(v)} >
                        <SliderTrack>
                            <SliderFilledTrack />
                            <Tooltip
                                hasArrow
                                bg='teal.500'
                                color='white'
                                placement='top'
                                isOpen={tooltipVisibility}
                                label={audienceRange + "%"}
                            >
                                <SliderThumb />
                            </Tooltip>
                        </SliderTrack>
                        <SliderThumb />
                    </Slider>
                    <Box>

                        {
                            (selectedChannel === 'email' || selectedChannel === 'sms') && <FormControl mt={'2rem'}>
                                <FormLabel color={'inputLabel'}>Winning Criteria</FormLabel>
                                <Select options={winningCriteria[selectedChannel]} onChange={(e) => formik.setFieldValue("winningMetrics", e.key)}></Select>
                            </FormControl>
                        }
                        {
                            <Box mt={'2rem'} >
                                <Text>
                                    Duration in hours:
                                </Text>
                                <HStack>
                                    <Input onChange={(e) => { formik.setFieldValue("durationInHours", e.target.value) }} width={'20%'} type='number' name='' />
                                    {/* <Select defaultValue={durationTypes[1].label}  options={durationTypes} /> */}
                                </HStack>
                            </Box>
                        }
                    </Box>
                </Box>
                <Box p={3} bg={'white'} w={'100%'}>

                    {/* {
                        (selectedChannel === 'email') && <FormControl mt={3}>
                            <FormLabel color={'inputLabel'}>SMTP Settings</FormLabel>
                            <Select options={smtpTypes} value={_.filter(smtpTypes, { 'key': formik.values.selectedSmtpType })} onChange={(e) => formik.setFieldValue("selectedSmtpType", e.key)}></Select>
                        </FormControl>
                    }
                    <Slider aria-label='slider-ex- 1' 
                    onMouseEnter={()=>setTooltipVisibility(true)} onMouseLeave={()=>setTooltipVisibility(false)}
                    onBlur={setTargetRatio} value={audienceRange} onChange={(v) => handleAudienceRange(v)} defaultValue={20}>
                        <SliderTrack>
                            <SliderFilledTrack />
                            <Tooltip
                                hasArrow
                                bg='teal.500'
                                color='white'
                                
                                placement='top'
                                isOpen={tooltipVisibility}
                                label={audienceRange+"%"}
                            >
                                <SliderThumb />
                            </Tooltip>
                        </SliderTrack>
                        <SliderThumb />
                    </Slider> */}
                </Box>



                {/* <Box ratio={3 / 4} w={'40vw'} maxW={'530px'} bg="white" overflow={'scroll'} maxH={'450px'} >
                    <Box transform={'scale(0.8)'} dangerouslySetInnerHTML={{ __html: formik.values.tempBody }}></Box>
                </Box> */}
                {
                    (selectedChannel == 'whatsapp'|| selectedChannel=='sms') ? (!_.isEmpty(template[selectedTemp]?.dt) && <ABTestMobilePreview attachment={template[selectedTemp]?.dt.attachment} channel={selectedChannel} buttons={template[selectedTemp]?.dt.buttons} sample_body={template[selectedTemp]?.dt.sample_body} onClick={() => { onOpen() }} isOpen={isOpen} onClose={onClose} selectedChannel={selectedChannel} message={template[selectedTemp]?.dt.message || template[selectedTemp]?.message} />) :
                        <TemplatePreviewModal isOpen={isOpen} onClose={onClose} tempBody={formik.values.tempBody}></TemplatePreviewModal>
                }

            </Flex>
        </Box>
    )
}

export default TemplateSelectionAB
