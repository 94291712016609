
import firstTime from "../../../images/First Time Logo.png"
import abandonedCart from "../../../images/Abandoned Carts Logo.png"
import intentToPurchase from "../../../images/Increase Intent Logo.png"
import repeatPurchase from "../../../images/Repeat Purchase Logo.png"
import notifyCustomers from "../../../images/Notify Customers Logo.png"
import customerLoyalty from "../../../images/Customer Loyalty Logo.png"

export const recipes = [
    {
        "title": "Convert Prospects to first-time Customers",
        "description": `Improve your subscriber list and convert first-time visitors to
        first-time customers`,
        "list": [
            `    Grow subscriber base through opt-ins`,
            `    Retarget these users for higher conversions`
        ],
        "btnLabel": 'See All',
        navigateTo: { pathname: '/flows/predefined/welcome-message', state: { "Welcome Message": [], } },
        icon: firstTime
    },
    {
        title: "Recover abandoned carts",
        description: `Turn abandoned carts into purchases with high-converting
        journeys`,
        list: [
            `   Personalise messages and bring customers back`,
            `    Convert more with less efforts`
        ],
        btnLabel: 'See All',

        navigateTo: { pathname: '/flows/predefined/recover-abandoned-carts', state: { "Decrease Cart Abandonment": [], } },
        icon: abandonedCart

    },
    {
        title: "Increase Intent to Purchase",
        description: `Reach out to customers who have shown an interest in your
        products and categories & increase their intent `,
        list: [
            `        Convert potential customers who browse`,
            `    improve customer loyalty through personalisation`
        ],
        btnLabel: 'See All',

        navigateTo: { pathname: '/flows/predefined/increase-conversion', state: { 'Increase Conversions': [], } },
        icon : intentToPurchase

    },
    {
        title: "Improve Repeat Purchases",
        description: `Stay in touch customers who place orders and keep them
        informed about offers & updates`,
        list: [
            `    Increase Customer Lifetime Value`,
            `    Retain customers to increase repeat purchases`
        ],
        btnLabel: 'See All',
        navigateTo: { pathname: "/flows/predefined/review-request", state: {'Review Request': []} },
        icon: repeatPurchase
    },
    {
        title: "Notify customers about Orders",
        description: `Keep customers informed about the status of their orders to
        build trust and maintain transparency`,
        list: [
            `    Improve customer satisfaction through Journeys`,
            `    Increase an overall positive experience`
        ],
        btnLabel: 'See All',
        navigateTo: { pathname: "/flows/predefined/rto-reduction", state: {'RTO Reduction':[]} },
        icon: notifyCustomers
    },
    {
        title: "Increase Customer Loyalty",
        description: `Reactivate customers who have placed orders with your brand
        a while back and increase loyalty`,
        list: [
            `    Remind customers to replenish your products`,
            `    Provide targeted discounts & offers on recommendations`
        ],
        btnLabel: 'See All',
        navigateTo: { pathname: "", state: {} },
        icon: customerLoyalty
    },

];