import React from 'react';
import { Box, Text, Stack, HStack, Divider } from "@chakra-ui/react";
import PrimaryButton from '../../components/themeComponents/PrimaryButton';
import PlatformWidget from '../../components/themeComponents/PlatformWidget';
import MagentoIcon from "../../images/Magento.png";
import { MdDownloadForOffline } from "react-icons/md";
import { useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function Magento() {
  const { integration } = useOutletContext();
  const { t } = useTranslation();

  const WidgetContent = () => (
    <Stack justifyItems={'space-around'}  >
      <Text fontSize={'xl'} width={'60%'} my={'5px'} >{t('magento.description')}</Text>
      <PrimaryButton width={'8vw'} marginTop={'5px'} type={'submit'} borderRadius={'8px'} height={'1.8vh'} padding={'1vw 1vw'} fontSize={'0.8vw'} label={t('magento.connect')} />
    </Stack>
  )

  return (
    <>
      <Box bg={'#fff'} borderRadius={'1vw'} display={'flex'} flexDirection={'column'} p={'1rem'} justifyContent={'center'} alignItems={'center'} >
        <Box alignItems={'baseline'} display={'flex'} flexDirection={'column'} gap={'1vh'} width={'100%'} >
          <PlatformWidget platform={
            {
              title: t('magento.title', { entityName: integration.entityName }),
            }
          }
            icon={MagentoIcon}
            WidgetContent={WidgetContent}

          />

          <Text fontSize={'xl'} pl={'3%'} color={'#AD88F1'} >{t('magento.stepsTitle', { entityName: integration.entityName })}</Text>
          <HStack paddingLeft={'6%'} gap={'1vw'} width={'100%'}  >
            <Divider border={'1px solid #dadada'} orientation='vertical' height={'20vh'} />
            <Stack>
              <Stack p={'2rem'} >

                <Text color={'#7746A6'} >{t('magento.steps.step1')}</Text>
                <Text color={'#7746A6'} >{t('magento.steps.step2')}</Text>
                <Text color={'#7746A6'} >{t('magento.steps.step3')}</Text>
                <Text color={'#7746A6'} >{t('magento.steps.step4')}</Text>
                <Text color={'#7746A6'} >{t('magento.steps.step5')}</Text>
                <Text color={'#7746A6'} >{t('magento.steps.step6')}</Text>

              </Stack>
            </Stack>
          </HStack>

        </Box>
        <PrimaryButton alignSelf={'flex-end'} height={'4vh'} borderRadius={'8px'} label={'Download Guide'} rightIcon={<MdDownloadForOffline />} />
      </Box>
    </>
  );
}
