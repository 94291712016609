import { Button } from '@chakra-ui/react'
import React, { Fragment } from 'react'

function SecondaryButton({label, isLoading, type, ...rest}) {
    return (
        <Fragment>
            <Button type={type} bg={'secondary.main'} borderRadius={'8px'} color={'white'} cursor={'pointer'} _hover={{ bg: 'secondary.main', opacity: '.8' }} size={'md'} isLoading={isLoading} {...rest}>
                {label}
            </Button>
        </Fragment>
    )
}

export default SecondaryButton
