import { Text } from '@chakra-ui/react'
import axios from 'axios'
import _ from 'lodash'
import React, { Fragment, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { default as MultiSelect } from './themeComponents/react-chakra-select';
export default function StatesDropdown({ selectedCountry, isMulti, onStateSelect, ...rest }) {
    const [selectedStates, setSelectedStates] = useState();
    const getStates = () => {
        return axios.get('/json/states.json')
    }
    const prepareList = (selectedCountry, data) => {
        return _.map(_.filter(data, {"country_code": selectedCountry}), (e) => {
            return {...e, ...{label: e.name, value: e.state_code}}
        })
    }
    const stateQuery = useQuery({
        queryFn: async () => {
            let res = await getStates();
            setSelectedStates(prepareList(selectedCountry, res.data));
        },
        enabled: false
    })
    useEffect(() => {
        stateQuery.refetch()
    }, [selectedCountry])
    return (
        <Fragment>
            {
                !!isMulti && <MultiSelect isMulti options={selectedStates} onChange={(selectedValues) => onStateSelect(selectedValues)} isClearable={false}></MultiSelect>
            }
        </Fragment>
    )
}
