import React, { Fragment, useEffect, useState } from 'react';
import { Box, Flex, Text, Input, Grid, Divider, InputLeftAddon, InputGroup, Icon, HStack } from '@chakra-ui/react';
import PrimaryButton from '../../components/themeComponents/PrimaryButton';
import OutlinedButton from '../../components/themeComponents/OutlinedButton';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getSmsTemplateList } from '../../Services/api';
import {
    Pagination,
    PaginationContainer,
    PaginationNext,
    PaginationPage,
    PaginationPageGroup,
    PaginationPrevious,
} from '@ajna/pagination';
import { OvalSpinner } from '../../components/Loaders';
import { Table, TableContainer, } from '@chakra-ui/react';
import hexToRgba from 'hex-to-rgba';
import TableHead from '../Campaigns/components/TableHead';
import SmsListTable from './SmsListTable';
import MobilePreview from '../Campaigns/components/MobilePreview';
import { CiSearch } from "react-icons/ci";
import { useToken } from '@chakra-ui/react';
import { usePagination } from '@ajna/pagination';
import * as _ from 'lodash';

export default function SmsTemplateList() {
    const [mainColor] = useToken('colors', ['primary.main'])
    const [totalCount, setTotalCount] = useState(0)
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [selectedTempMessage, setSelectedTempMessage] = useState("")
    const { currentPage, setCurrentPage, pagesCount, pages, pageSize } = usePagination({ total: totalCount, initialState: { currentPage: 1, pageSize: 10, isDisabled: false }, limits: { outer: 1, inner: 1 } })

    const templateListQuery = useQuery(['templates', currentPage, pageSize], {
        queryFn: async () => {
            let templateList = await getSmsTemplateList(currentPage, pageSize);
            setTotalCount(templateList?.data?.count)
            console.log("toral count", totalCount)
            return templateList;
        },
        cacheTime:0
    });
    console.log(templateListQuery,"templateListQuery sms")

    const searchTemplate = (e) => {

    }
    useEffect(() => {
        templateListQuery.refetch()

    }, [])
    return (
        <Fragment>
            <Box w={'100%'} overflow={'hidden'} height={'100%'}>
                <Flex justifyContent={'space-between'}>
                    <Text as={'b'} fontSize={'2xl'}>SMS Templates</Text>
                    <OutlinedButton width={'10vw'} borderRadius={'8px'} label={t('flows.build.own')} onClick={() => navigate("/sms/create")} />
                </Flex>
                <Grid templateColumns={'1fr 1fr'} h={'calc(100% - 50px)'}>
                    <Box h={'100%'} overflow={'hidden'} bg={'white'} p={3} borderRight={'1px solid'} borderColor={'gray.200'}>
                        <InputGroup mb={2}>
                            {/* <InputLeftElement> */}
                            {/* <Icon as={FaSearch} color={'gray.400'}></Icon> */}
                            {/* </InputLeftElement> */}
                            {/* <Input placeholder='Search email template by name' onChange={searchCampaign}></Input> */}
                        </InputGroup>
                        <TableContainer mt={4} overflowY={'auto'} overflow={'auto'} height={'calc(100% - 50px)'}>
                            {
                                templateListQuery.data?.data?.items.length>0?(<>
                                 <Table>
                                {
                                    !!templateListQuery?.data && !templateListQuery?.isLoading && (
                                        <Fragment>
                                            <TableHead headersProps={['Template Name', 'Actions']}></TableHead>
                                            {
                                                <SmsListTable previewData={selectedTempMessage} setPreviewMsg={setSelectedTempMessage} list={templateListQuery?.data?.data?.items} ></SmsListTable>
                                            }
                                        </Fragment>
                                    )
                                }

                            </Table>
                                </>):(<><Text>No sms template created</Text></>)
                            }
                           
                            {
                                !templateListQuery.isLoading && !!templateListQuery?.data?.data?.templates?.length && <Pagination pagesCount={pagesCount} currentPage={currentPage} onPageChange={setCurrentPage} >
                                    <PaginationContainer alignItems={'flex-start'} justifyContent="end" mt={2} position={'sticky'} bottom={'0'} zIndex={'999'} bg={'white'}>
                                        <PaginationPrevious mr={2} w={8} bg={hexToRgba(mainColor, .4)} p={1} fontSize={'xs'} height={8} minW={8}>&lt;</PaginationPrevious>
                                        <PaginationPageGroup>
                                            {
                                                pages.map(page => (
                                                    <PaginationPage key={`pagination_page_${page}`} page={page} w={8} bg={hexToRgba(mainColor, .4)} p={1} fontSize={'xs'} height={8} minW={8} isDisabled={currentPage === page}></PaginationPage>
                                                ))
                                            }
                                        </PaginationPageGroup>
                                        <PaginationNext ml={2} w={8} bg={hexToRgba(mainColor, .4)} p={1} fontSize={'xs'} height={8} minW={8}>&gt;</PaginationNext>
                                    </PaginationContainer>
                                </Pagination>
                            }
                        </TableContainer>

                    </Box>
                    <Box p={3}>
                        {_.isEmpty(selectedTempMessage) &&
                            <Box mb={3} display={'flex'} alignItems={'center'} justifyContent={'center'} w={'100%'} height={'100%'}>
                                <Text>No Template is selected for preview.</Text>
                            </Box>
                        }
                        {
                            <Box mb={3} display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
                                <Box width={'90%'}>
                                    {
                                    !_.isEmpty(selectedTempMessage) &&
                                        <Box p={0} ml={3} >
                                        <Text  >Template Name: {selectedTempMessage.templateName} </Text>
                                        <Text overflow={'hidden'} whiteSpace={'nowrap'} textOverflow={'ellipsis'} width={'400px'} maxW={'400'} >Status: {!_.isEmpty(selectedTempMessage) && JSON.parse(selectedTempMessage.templateStatus) ? 'ACTIVE' : 'ARCHIVED'}</Text>
                                    </Box>
                                    }
                                    {
                                        // <Box display={'flex'}>
                                        //     <Text as={'b'}>Subject Line: </Text>
                                        //     <Text ml={3}>Subject line here</Text>
                                        // </Box>
                                        <Box mt={5} >
                                            {
                                                !_.isEmpty(selectedTempMessage) &&
                                                <MobilePreview channel={'sms'} message={selectedTempMessage?.message} />


                                            }
                                        </Box>
                                    }
                                </Box>
                                {/* <Icon as={MdOutlineZoomOutMap} boxSize={5} cursor={'pointer'} color={'primary.main'} visibility={'hidden'}></Icon> */}
                            </Box>

                        }



                    </Box>
                </Grid>

            </Box>
        </Fragment>
    );
}
