import { Box, Flex, Text, Grid, TableContainer, Table, TabList, Input, InputGroup, InputRightAddon, InputLeftElement, Icon, useToken } from '@chakra-ui/react'
import React, { Fragment, useEffect, useState, useMemo } from 'react'
import PrimaryButton from '../../components/themeComponents/PrimaryButton'
import OutlinedButton from '../../components/themeComponents/OutlinedButton'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'
import { deleteEmailTemplate, getEmailTemplateList } from '../../Services/api'
import TableHead from '../Campaigns/components/TableHead'
import TemplateTable from './TemplateTable'
import { FaSearch } from "react-icons/fa"
import { MdOutlineZoomOutMap } from "react-icons/md";
import { usePagination, Pagination, PaginationContainer, PaginationPrevious, PaginationPageGroup, PaginationNext, PaginationPage } from '@ajna/pagination'
import hexToRgba from 'hex-to-rgba'
import { showSwal } from '../../_helpers/helpers'
import Swal from 'sweetalert2'
import _ from "lodash";

export default function EmailTemplateList() {
    const navigate = useNavigate()
    const { t } = useTranslation();
    const [templatePreviewBody, setTemplatePreviewBody] = useState("")
    const [mainColor] = useToken('colors', ['primary.main'])
    const [totalCount, setTotalCount] = useState(0);
    const [preheaderText, setPreheaderText] = useState('');
    const [subjectLine, setSubjectLine] = useState("");
    const [searchKeyword, setSearchKeyword] = useState("");
    const [deletedUuid, setDeletedUUid] = useState("")
    const { currentPage, setCurrentPage, pagesCount, pages } = usePagination({ total: totalCount, initialState: { currentPage: 1, pageSize: 10, isDisabled: false }, limits: { outer: 1, inner: 1 } })
    const templateListQuery = useQuery(['emailTemplateList', currentPage, searchKeyword, deletedUuid], {
        queryFn: async () => {
            let res = await getEmailTemplateList(currentPage, searchKeyword);
            setTotalCount(res?.data?.count)
            return res.data?.items
        },
        cacheTime: 0,
        //enabled: false,
        retry: 1
    })
    const onPreview = (body, subjectLine, preHeader) => {
        setTemplatePreviewBody(body);
        setSubjectLine(subjectLine)
        var iframeDesktop = document.querySelector('#iframeDesktop');
        iframeDesktop.contentWindow.document.open('text/html', 'replace');
        iframeDesktop.contentWindow.document.write(body);
        iframeDesktop.contentWindow.document.close();
    }
    const onDelete = (templateUuid) => {
        showSwal(Swal, 'Are you sure?', 'Do you want to delete the template?', true, true, 'Yes, Delete', 'No', (async () => {
            let deleteResponse = await deleteEmailTemplate(templateUuid);
            console.log(deleteResponse);
        }), (res) => {
            !!res.isConfirmed && setDeletedUUid(templateListQuery) && Swal.close();
            !res.isConfirmed && Swal.close();
        })
    }

    const searchCampaign = _.debounce(async (e) => {

        setSearchKeyword(e.target.value)

    })


    useEffect(() => {
        // templateListQuery.refetch()
    }, [])
    return (
        <Fragment>
            <Box w={'100%'} overflow={'hidden'} height={'100%'}>
                <Flex justifyContent={'space-between'}>
                    <Text as={'b'} fontSize={'2xl'}>Email Templates</Text>
                    <OutlinedButton width={'10vw'} borderRadius={'8px'} label={t('flows.build.own')} onClick={() => navigate("/email/edit")} />
                </Flex>
                <Grid templateColumns={'1fr 1fr'} h={'calc(100% - 50px)'}>
                    <Box h={'100%'} overflow={'hidden'} bg={'white'} p={3} borderRight={'1px solid'} borderColor={'gray.200'}>
                        <InputGroup mb={2}>
                            <InputLeftElement>
                                <Icon as={FaSearch} color={'gray.400'}></Icon>
                            </InputLeftElement>
                            <Input placeholder='Search email template by name' onChange={searchCampaign}></Input>
                        </InputGroup>
                        <TableContainer mt={4} overflowY={'auto'} overflow={'auto'} height={'calc(100% - 50px)'}>
                            <Table>
                                {
                                    !!templateListQuery?.data?.length>0?(<>
                                    <TableHead headersProps={['Template Name', 'Actions']}></TableHead>
                                            {
                                                <TemplateTable list={templateListQuery.data} onPreview={onPreview} onDelete={onDelete}></TemplateTable>
                                                
                                            }
                                    
                                    </>):(<>No Templates selected</>)  
                                }

                            </Table>
                            {
                                !templateListQuery.isLoading && !!templateListQuery?.data?.length && <Pagination pagesCount={pagesCount} currentPage={currentPage} onPageChange={setCurrentPage} >
                                    <PaginationContainer alignItems={'flex-start'} justifyContent="end" mt={2} position={'sticky'} bottom={'0'} zIndex={'999'} bg={'white'}>
                                        <PaginationPrevious mr={2} w={8} bg={hexToRgba(mainColor, .4)} p={1} fontSize={'xs'} height={8} minW={8}>&lt;</PaginationPrevious>
                                        <PaginationPageGroup>
                                            {
                                                pages.map(page => (
                                                    <PaginationPage key={`pagination_page_${page}`} page={page} w={8} bg={hexToRgba(mainColor, .4)} p={1} fontSize={'xs'} height={8} minW={8} isDisabled={currentPage === page}></PaginationPage>
                                                ))
                                            }
                                        </PaginationPageGroup>
                                        <PaginationNext ml={2} w={8} bg={hexToRgba(mainColor, .4)} p={1} fontSize={'xs'} height={8} minW={8}>&gt;</PaginationNext>
                                    </PaginationContainer>
                                </Pagination>
                            }
                        </TableContainer>

                    </Box>
                    <Box p={3}>
                        {
                            !templatePreviewBody && <Box mb={3} display={'flex'} alignItems={'center'} justifyContent={'center'} w={'100%'} height={'100%'}>
                                <Text>No Template is selected for preview.</Text>
                            </Box>
                        }
                        {
                            !!templatePreviewBody && <Box mb={3} display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
                                <Box width={'90%'}>
                                    <Box display={'flex'}>
                                        <Text as={'b'} width={'100px'}>Subject Line: </Text>
                                        <Text ml={3} overflow={'hidden'} whiteSpace={'nowrap'} textOverflow={'ellipsis'} width={'400px'} maxW={'400%'} title={subjectLine}>{subjectLine}</Text>
                                    </Box>
                                    {
                                        !!preheaderText && <Box display={'flex'}>
                                            <Text as={'b'}>Subject Line: </Text>
                                            <Text ml={3}>Subject line here</Text>
                                        </Box>

                                    }
                                </Box>
                                <Icon as={MdOutlineZoomOutMap} boxSize={5} cursor={'pointer'} color={'primary.main'} visibility={'hidden'}></Icon>
                            </Box>

                        }
                        <iframe id="iframeDesktop" frameborder="0" width="100%" height="100%"></iframe>


                    </Box>
                </Grid>

            </Box>
        </Fragment>
    )
}
