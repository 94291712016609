import { Box, FormLabel, HStack, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import CampaignDraftDrawer from '../Campaigns/CampaignDraftDrawer'
import { useState } from 'react'
import PrimaryButton from '../../components/themeComponents/PrimaryButton'
import store from '../../store/store'
import Select from '../../components/themeComponents/react-chakra-select'
import { prepareOrgList } from '../../_helpers/data_helper'
import { useSteps } from '@chakra-ui/react'
import { targetAudienceSteps } from '../../_helpers/data_helper'
import CampaignStepper from '../Campaigns/components/CampaignStepper'
import OrgAndConfig from './OrgAndConfig'
import { useFormik } from 'formik'
import AudieceSelection from './AudieceSelection'
import { targetGroupCampaign } from '../../_helpers/formValues'
export default function TargetGroup() {
  const [isOpen, setIsOpen] = useState(false)
  const formik = useFormik({
    initialValues: targetGroupCampaign,
    enableReinitialize: true,
    onSubmit: ()=>{}
})
  const dummyConfiguration = [
    {
      name: "CBOI",
      from: "rewards@cboi.in",
      type: "Promotional",
      isIntegrated: true,
    },
    {
      name: "SIB",
      from: "rewards@sbi.in",
      type: "Promotional",
      isIntegrated: true,
    },
    {
      name: "IDBI",
      from: "rewards@idbi.in",
      type: "Promotional",
      isIntegrated: false,
    }
  ]
  const { activeStep, setActiveStep } = useSteps({
    index: 0, count: targetAudienceSteps.length
  })
  const [orgList, setOrgList] = useState(store.getState().org.orgList)
  console.log(orgList)
  return (
    <Box marginLeft={'1rem'} padding={'2rem'} borderRadius={'1vw'} h={"100%"} bg={'#EEEEEE'} alignItems={'baseline'} border={'1px solid #bebcbc'} >
    <Box  >
      <CampaignStepper steps={targetAudienceSteps} activeStep={activeStep}></CampaignStepper>
      <Box mt={'2rem'} >
      {
        activeStep === 0 &&
        <OrgAndConfig formik = {formik} />
      }
      </Box>
      {
        activeStep === 1 &&
        <AudieceSelection />
      }
      <HStack pos={"fixed"} bottom={'2rem'} right={'2rem'} >

      {activeStep > 0 && <PrimaryButton label={'Back'} onClick={() => { setActiveStep(activeStep - 1) }} />}
      <PrimaryButton label={'Save & Continue'} onClick={() => { setActiveStep(activeStep + 1) }} />
      </HStack>
    </Box>
    </Box>
        
  )
}
