import { Box, HStack, Grid, GridItem,Text } from '@chakra-ui/react'
import React, { Fragment } from 'react'
import { Outlet } from 'react-router-dom'

function AuthLayout() {
    return (
        <Fragment>
           <Grid gridTemplateColumns={'1fr 1fr'} height={'100vh'}>
                <GridItem>
                    <Box w={'100%'} h={'100%'} p={10} display={'flex'} alignItems={'center'} justifyContent={'center'} boxSizing='border-box'>
                        <Outlet/>
                    </Box>
                </GridItem>
                <GridItem bg={'primary.main'}>
                    
                </GridItem>
           </Grid>
        </Fragment>
    )
}

export default AuthLayout



