import React from 'react'
import { Outlet, useOutletContext,Link } from 'react-router-dom'
import SwitchableTabs from '../../components/themeComponents/SwitchableTabs'
import { getIntegrationDetails } from '../../Services/IntegrationService';
import { useState, useEffect } from 'react';
import { isProduction } from '../../_helpers/helpers';
import { Text, Box, Heading } from '@chakra-ui/react';
import store from '../../store/store';
import { FaArrowLeftLong } from "react-icons/fa6";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
function Integration() {
    const { t } = useTranslation()
    const mainIntegration= useSelector((state) => state.auth.white_label) || sessionStorage.getItem('white_label');
    const entityName = mainIntegration.entityName || mainIntegration.data?.entityName
    // console.log(mainIntegration,">>>mainIntegration")
    const integrationData=mainIntegration.data||mainIntegration
    // console.log(integrationData,">>>integrationData")
    const tabContent = [
        {
            label: 'Plugins & Integrations',
            navigateTo: '/integrations/platforms'
        },
        // {
        //     label: 'Application',
        //     navigateTo: '/integration/application'
        // },
        // {
        //     label: 'Analytics Integration',
        //     navigateTo: '/integration/integration'
        // },

    ]
    // let mainIntegrationData = ()=>{
    //     try{
    //        return mainIntegration.data
    //     }catch(e){
    //         return mainIntegration
    //     } 
    // }
    // const [integration, setIntegration] = useState(store.getState().auth?.white_label.data);
    // console.log(integration,"Inintegration")
   
    return (
        <>
            <Box marginLeft={'1rem'} padding={'2rem'} borderRadius={'1vw'} bg={'#EEEEEE'} alignItems={'baseline'} border={'1px solid #bebcbc'} >
                <Text mt={'0.5rem'} fontSize={'1.5vw'}>{t("integration.integration")}</Text>
                <Text fontSize={'0.8vw'} mb={'1rem'}>{t("integration.subheadig" )} {entityName}</Text>
                <SwitchableTabs tabContent={tabContent} />
                {/* <Link to={'/integrations/platforms'}>
                    <FaArrowLeftLong /> Back
                </Link> */}
                <Outlet context={{
                    integration: integrationData,
                    isProduction: isProduction,
                }} />
            </Box>
        </>
    )
}

export default Integration