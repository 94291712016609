import React from 'react';
import { Box, Text, Stack, HStack, Divider } from "@chakra-ui/react";
import PrimaryButton from '../../components/themeComponents/PrimaryButton';
import { useOutletContext } from 'react-router-dom';
import PlatformWidget from '../../components/themeComponents/PlatformWidget';
import GTMIcon from "../../images/GTM.png";
import { MdDownloadForOffline } from "react-icons/md";
import { useTranslation } from 'react-i18next';

export default function GTM() {
  const { integration } = useOutletContext();
  const { t } = useTranslation();

  const WidgetContent = () => (
    <Stack justifyItems={'space-around'} alignItems={'baseline'} >
      <Text fontSize={'xl'} width={'60%'} my={'5px'} >{t('gtm.description')}</Text>
      <PrimaryButton width={'8vw'} marginTop={'5px'} type={'submit'} borderRadius={'8px'} height={'1.8vh'}
        onClick={() => { window.open("https://media.orbisdata.ai/mkt_plugins/magento2/Mkt_Magento2_Integration_Guide(1).pdf", '_blank') }}
        padding={'1vw 1vw'} fontSize={'0.8vw'} label={t('gtm.downloadGuide')} rightIcon={<MdDownloadForOffline />} />
    </Stack>
  )

  return (
    <>
      <Box bg={'#fff'} borderRadius={'1vw'} display={'flex'} flexDirection={'column'} p={'1rem'} justifyContent={'center'} alignItems={'center'} width={'100%'} >
        <Box alignItems={'baseline'} display={'flex'} flexDirection={'column'} gap={'1vh'} width={'100%'}>
          <PlatformWidget platform={
            {
              title: t('gtm.title', { entityName: integration.entityName }),
            }
          }
            icon={GTMIcon}
            WidgetContent={WidgetContent}

          />

          <Text fontSize={'xl'} pl={'3%'} color={'#AD88F1'} >{t('gtm.stepsTitle')}</Text>
          <HStack paddingLeft={'6%'} gap={'1vw'} width={'100%'}  >
            <Divider border={'1px solid #dadada'} orientation='vertical' height={'20vh'} />
            <Stack alignItems={'baseline'} >
              <Stack p={'2rem'} >

                <Text color={'#7746A6'} >{t('gtm.steps.step1')}</Text>
                <Text color={'#7746A6'} >{t('gtm.steps.step2')}</Text>
                <Text color={'#7746A6'} >{t('gtm.steps.step3')}</Text>
                <Text color={'#7746A6'} >{t('gtm.steps.step4')}</Text>
                <Text color={'#7746A6'} >{t('gtm.steps.step5')}</Text>

              </Stack>
              <Text color={'#7746A6'} >{t('gtm.queries', { entityName: integration.entityName })}</Text>
            </Stack>
          </HStack>
        </Box>
      </Box>
    </>
  );
}
