import i18n  from "i18next";
import { initReactI18next } from "react-i18next";
import * as en from './i18n/en.json'
import * as hi from './i18n/hi.json'
import * as ar from './i18n/ar.json'
import * as fr from "./i18n/fr.json"
import Backend from 'i18next-xhr-backend'
import LanguageDetector from "i18next-browser-languagedetector"

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        lng: "en",
        fallbackLng: "en",
        interpolation: {
            escapeValue: false
        },
        resources: {
            en: {
                translation: en,
            },
            hi: {
                translation: hi
            },
            ar: {
                translation: ar
            },
            fr: {
                translation: fr
            }
        }
    });

export default i18n;