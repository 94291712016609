import { Flex, Icon, Td, Text, Tr, useDisclosure } from '@chakra-ui/react'
import React, { Fragment, useState } from 'react'
import { FiEdit } from "react-icons/fi";
import {FaTrash, FaEye} from "react-icons/fa"
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { BsSend } from "react-icons/bs";
import SendTestModal from '../../components/SendTestModal';
export default function TemplateTable({list, onPreview, onDelete}) {
    const [selectedPreview, setSelectedPreview] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [templateUuid, setTemplateUuid] = useState("");
    const showPreview = (uuid, body, subject) => {
        setSelectedPreview(uuid);
        onPreview(body, subject)
    }
    let openTestModal = (uuid)=>{
        setTemplateUuid(uuid)
        onOpen();
    }
    const navigate = useNavigate()
    return (
        <Fragment>
            {
                !!list.length && list.map(lt => (
                    <Tr key={lt.uuid} bg={selectedPreview === lt.uuid && 'gray.200'}>
                        <Td onClick={() => showPreview(lt.uuid, lt.body, lt.subject)} cursor={'pointer'}>
                            {lt.name}
                            <Text>{moment(lt.lastupdated).format('lll')}</Text>
                        </Td>
                        <Td>
                            <Flex justifyContent={'start'} gap={3}>
                                <Icon as={FiEdit} cursor={'pointer'} onClick={() => navigate("/email/edit/"+lt.uuid)}></Icon>
                                <Icon as = {BsSend}  cursor={'pointer'} title='Send Test Email' onClick={()=>openTestModal(lt.uuid)} />
                                <Icon as={FaTrash} onClick={() => onDelete(lt.uuid)} cursor={'pointer'}></Icon>
                                
                            </Flex>
                        </Td>
                    </Tr>
                ))
            }
           <SendTestModal uuid = {templateUuid} channel={'Email'} smptpType={'promotional'} isOpen={isOpen} onClose={onClose}></SendTestModal>
        </Fragment>
    )
}
