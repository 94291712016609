import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Card, CardHeader, CardBody, CardFooter, HStack, Image, Box, Heading, Text, Button, SimpleGrid, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Icon } from '@chakra-ui/react'
import * as _ from 'lodash'
import PrimaryButton from '../../components/themeComponents/PrimaryButton'
import OutlinedButton from '../../components/themeComponents/OutlinedButton'
import RecipeActvationModal from './RecipeActvationModal'
import { useDisclosure } from '@chakra-ui/react';
import { FaWhatsapp } from "react-icons/fa";
import { MdOutlineMailOutline } from "react-icons/md";
import { CgWebsite } from "react-icons/cg";
import { MdOutlineSms } from "react-icons/md";
import whatsappIcon from "../../images/Recipe - WhatsApp Icon.svg"
import pushIcon from "../../images/Recipe - Push Icon.svg"
import allIcon from "../../images/Recipe - All Icon.svg"
import smsIcon from "../../images/Recipe - SMS Icon.svg"
import onsiteIcon from "../../images/Recipe - Onsite Icon.svg"
import emailIcon from "../../images/Recipe - Email Icon.svg"
import { useOutletContext } from 'react-router-dom';

export default function Recipes() {
  const location = useLocation()
  console.log(location)
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { headerText, setHeaderText } = useOutletContext()
  const [activeChannel,setActiveChannel] = useState('all')
  const [recipesArray, setRecipesArray] = useState([])
  const [filteredRecipesArray, setFilteredRecipeArray] = useState(recipesArray)
  const [selectedRecipe, setSelectedRecipe] = useState({})
  const channelIcons = {
    all: allIcon,
    whatsapp: whatsappIcon,
    email: emailIcon,
    onsite: onsiteIcon,
    sms: smsIcon,
    web_push: pushIcon,
  }

  const filterRecipes = (channel) => {
    
    if (channel === 'all') {
      setActiveChannel(channel)
      setFilteredRecipeArray(recipesArray)
    } else {
      setFilteredRecipeArray(recipesArray.filter((recipes) => {
         setActiveChannel(channel)
        return (
          recipes?.templateRecipe[0]?.channel.toLowerCase() == channel
        )
      }))
    }

  }

  useEffect(() => {
    setHeaderText({ title: location.state.title, description: location.state.description })
    for (const key in location.state) {
      if (Array.isArray(location.state[key])) {
        setRecipesArray(location.state[key])
        setFilteredRecipeArray(location.state[key])
      }

    }
    console.log("state", location.state)

  }, [location.state])



  return (
    <>
    <Box px={'2rem'} >

      <HStack border={"1px solid #723FA3"} borderRadius={'8px'} my={'1rem'}  gap={'10px'} width={'fit-content'} p={'0.5rem'} >
        {
          
          Object.keys(channelIcons).map((channel,i) => (
            <Box p={'5px'} bg={channel==activeChannel && 'gray.200'} borderRadius={'8px'} >
            <Image height={30} width={30} cursor={'pointer'} onClick={() => filterRecipes(channel)} src={channelIcons[channel]} />
            </Box>
          ))
        }
      </HStack>
      <SimpleGrid spacing={4} templateColumns='repeat(auto-fill, minmax(20vw, 1fr))'>
        {
          !_.isEmpty(filteredRecipesArray) && filteredRecipesArray?.map((recipe, i) => (
            <Card key={i} border={'1px solid #723FA3'} >
              <CardHeader display={'flex'} justifyContent={'space-between'} >
                <Heading size='md' fontWeight={'500 '} >{recipe.recipeName}</Heading>
                
                  {<Image height={"40px"} src={channelIcons[recipe.templateRecipe[0].channel.toLowerCase()]} />}
                
              </CardHeader>
              <CardBody> 
                <Text>{recipe.whatsNewText}</Text>
              </CardBody>
              <CardFooter justifyContent={'end'} display={'flex'} w={'100%'} >
                <OutlinedButton label={'Preview & Edit'} borderRadius={'8px'} onClick={() => { setSelectedRecipe(recipe); onOpen() }} ></OutlinedButton>
              </CardFooter>
            </Card>
          )
        )
      }
      </SimpleGrid>
      <RecipeActvationModal selectedRecipe={selectedRecipe} isOpen={isOpen} onClose={onClose}></RecipeActvationModal>
      </Box>
    </>
  )
}
