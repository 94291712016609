import React, {Fragment} from "react";
import { useQuery, useQueryClient } from "react-query";
import { useToast, Text, Select } from "@chakra-ui/react";
import { fetchCsvHeaders } from "../../../Services/api";
import _ from "lodash";
//import Select from "../../../components/themeComponents/react-chakra-select";
import { updateCsvList } from "../../../_helpers/data_helper";
function CsvHeaders({channel, csvUrl, orgToken, onHeaderSelect, csvId, selectedHeader}){
    const toast = useToast();
    const queryClient = useQueryClient()
    const csvHeaderQuery = useQuery('csvHeader', {
        queryFn: async() => {
            let res = await fetchCsvHeaders(channel, csvUrl, orgToken);
            //onHeaderFetched(res.data, csvId )
            return updateCsvList(res.data)
        },
        onError: (error) =>  toast({title: 'Problem in loading headers'})
    })
    return (
        <Fragment>
       
        {
            !!csvHeaderQuery.isLoading && ( <Text>Loading....</Text> )
        }
        {
            !_.isEmpty(csvHeaderQuery.data?.parsedHeaders) && <Select size={'xs'} value={selectedHeader} onChange={(e) => onHeaderSelect(e.target.value, csvId, csvHeaderQuery.data)}>
                <option value={''}>Select header</option>
                {
                    !!csvHeaderQuery?.data && csvHeaderQuery.data.parsedHeaders.map(e => (
                        <option value={e.index}>{e.key}</option>
                    ))
                }
            </Select>
        }
        </Fragment>
    )
   
    
}

export default CsvHeaders;