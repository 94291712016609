import moment from "moment";
import * as Yup from "yup";
import Kaleyra from "../Pages/Settings/Providers/Kaleyra";
import TrustSignal from "../Pages/Settings/SmsFroms/TrustSignal";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const urlRegex = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm
const specialCharReqex = /^[aA-zZ\s0-9\-:,]+$/
export const register = Yup.object().shape({
  email: Yup.string().required('Email is required').email('Invalid email address'),
  password: Yup.string().required('Password is required'),
  confirmPassword: Yup.string().oneOf([Yup.ref('password')], 'Password must match'),
  full_name: Yup.string().required("Full name is required"),
  phone: Yup.string().matches(phoneRegExp, 'Please Enter valid phone number'),
  org_domain: Yup.string().required('Please Enter website url').matches(urlRegex, 'Please enter valid url.')

})

export const PasswordUpdateSchema = Yup.object().shape({
  oldPassword: Yup.string().required('Old password is required'),
  newPassword: Yup.string()
    .required('New password is required')
    .min(4, 'Password must be at least 4 characters long'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    .required('Please confirm your new password'),
});

export const UpdateProfileSchema = Yup.object().shape({
  userName: Yup.string().required('Name is required'),
  orgName: Yup.string().required('Company name is required'),
  phoneNumber: Yup.string()
    .matches(phoneRegExp, 'Phone number must be 10 digits')
    .required('Phone number is required'),
});

export const editOrganizationSchema = Yup.object().shape({
  companyName: Yup.string().required('Company name is required'),
  addressLine1: Yup.string().required('Address Line 1 is required'),
  city: Yup.string().required('City is required'),
  country: Yup.string().required('Country is required'),
  postalCode: Yup.string().required('Postal Code is required'),
  state: Yup.string().required('State is required'),
});

export const addOrganizationSchema = Yup.object().shape({
  orgRealName: Yup.string()
    .required('Organization name is required'),
  domain: Yup.string()
    .url('Invalid URL')
    .required('Website URL is required'),
});

export const scheduleCampaign = Yup.object().shape({
  scheduleStatus: Yup.string(),
  scheduleAt: Yup.string().when('scheduleStatus', {
    is: 'NOW',
    then: () => Yup.string().nullable(),
    otherwise: () => Yup.date().required("Please Select schedule time").min(moment().add(5, "minutes"), `Schedule time must be greater than ${moment().add(5, "minutes").format('lll')}.`),

  }),

  repeatStopDateTime: Yup.string().when('scheduleStatus', {
    is: 'RECURRING',
    then: () => Yup.date().required("Please Select stop time").min(Yup.ref("scheduleAt"), "Stop time must be greater then schedule time"),
    otherwise: () => Yup.string().nullable()
  }), //after 4 days
  repeatAfterDays: Yup.string().when('scheduleStatus', {
    is: 'RECURRING',
    then: () => Yup.number().required("Please enter Repeat time").min(1, "Repeat stop time must be minimum 1 day"),
    otherwise: () => Yup.number().nullable()
  })
})

export const delayValidation = Yup.object().shape({
  formInputs: Yup.object().shape({
    delayValue: Yup.number().required("Delay duration can't be empty").positive("Delay duration must be greater than 0")
  })
})

export const saveFlowValidation = Yup.object().shape({
  ruleName: Yup.string().required("Please enter flow name")
})

export const saveEmailTemplateValidation = Yup.object().shape({
  description: Yup.string().required("Please enter template name").matches(specialCharReqex, 'Template name should not include special characters.'),
  subject: Yup.string().required("Please enter subject line")

})

export const whatsappTemplateValidation = Yup.object().shape({
  // mediaType: Yup.string().when("templateType",{
  //   is: !"text",
  //   then: Yup.string().required('Media Type is required')
  // }
  
  // ),
  templateName: Yup.string().required('Template Name is required'),
  message: Yup.string().required('Message is required'),
  language: Yup.string().required('Language is required'),
  // templateType: Yup.string().required('Template Type is required'),
  attachments: Yup.string(),
  // templateDisplayName: Yup.string().required('Template Display Name is required'),
  // sampleBody: Yup.string().required('Sample Body is required'),
  // footer: Yup.string().required('Footer is required'),
  category: Yup.string().required('Category is required'),
  // header_content: Yup.string().required('Header Content is required'),
  // sample_header_content: Yup.string().required('Sample Header Content is required'),
  // buttons: Yup.array().of(
  //   Yup.object().shape({
  //     type: Yup.string().required(),
  //     url_type: Yup.string().when('type', {

  //       is: (type) => type == 'URL',
  //       then: Yup.string().required('Field is required'),
  //       otherwise: Yup.string(),
  //     }),
  //     button_name: Yup.string().when('type', {
  //       is: (type) => type == 'URL',
  //       then: Yup.string().required('Field is required'),
  //       otherwise: Yup.string(),
  //     }),
  //     url: Yup.string().when('templateType', {
  //       is: (type) => type == 'URL',
  //       then: Yup.string().required('Field is required').matches(/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi),
  //       otherwise: Yup.string(),
  //     }),
  //     quick_reply: Yup.string().when('type', {
  //       is: (type) => type !== 'URL',
  //       then: Yup.string().required('Field is required'),
  //       otherwise: Yup.string(),
  //     }),
  //   })),
});

export const segmentationSchema = Yup.object().shape({
  criteria_type: Yup.string().required('Criteria type is required'),
  criteria_operation: Yup.string().required('Criteria operation is required'),
  criteria: Yup.array().of(
      Yup.object().shape({
          criteria_type: Yup.string().required('Criteria type is required'),
          property: Yup.string().required('Property is required'),
          operator: Yup.string().required('Operator is required'),
          // data_type: Yup.string().required('Data type is required'),
          values: Yup.array().of(
              Yup.object().shape({
                  raw: Yup.number().required('Raw value is required')
              })
          ).required('Values are required')
      })
  ).required('Criteria are required')
});

export const saveSegmentModalSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  description: Yup.string().required('Description is required')
})


export const whatsappchannelSchema = {
  // WhatsApp: Yup.object().shape({
  //   businessPhone: Yup.string().required("Business Phone is required"),
  //   provider: Yup.string().required("Provider is required"),
    
  // }),
  Sinch: Yup.object().shape({
    account: Yup.string().required("Account is required"),
    auth: Yup.string().required("Auth is required"),
    businessPhone: Yup.string().required("Business Phone is required"),
    provider: Yup.string().required("Provider is required"),
  }),
  Routemobile: Yup.object().shape({
    account: Yup.string().required("Account is required"),
    auth: Yup.string().required("Password is required"),
    businessPhone: Yup.string().required("Business Phone is required"),
    provider: Yup.string().required("Provider is required"),
  }),
  Kaleyra: Yup.object().shape({
    account: Yup.string().required("account is required"),
    wabaId: Yup.string().required("WABA ID is required"),
    auth: Yup.string().required("API Key is required"),
    businessPhone: Yup.string().required("Business Phone is required"),
    provider: Yup.string().required("Provider is required"),
    
  }),
  Gupshup: Yup.object().shape({
    hsmAccount: Yup.string().required("HSM Account Id is required"),
    hsmPassword: Yup.string().required("HSM Account Password is required"),
    twoWayAccount: Yup.string().required("Two Way Account Id is required"),
    twoWayPassword: Yup.string().required("Two Way Account Password is required"),
    businessPhone: Yup.string().required("Business Phone is required"),
    provider: Yup.string().required("Provider is required"),
  }),
  Trustsignal: Yup.object().shape({
    account: Yup.string().required('Account is required'),
    auth: Yup.string().required('Auth is required'),
    businessPhone: Yup.string().required("Business Phone is required"),
    provider: Yup.string().required("Provider is required"),
  }),
};  




// Define individual validation schemas for each SMS provider
export const smsChannelValidationSchema = {
  RouteMobile: Yup.object().shape({
    userName: Yup.string().required("User Name is required"),
    password: Yup.string().required("Password is required"),
    senderId: Yup.string().required("Business Phone is required"),
    gatewayType: Yup.string().required("Gateway Type is required"),
    dltEntityId: Yup.string().required("DLT Entity ID is required"),
  }),
  Kaleyra: Yup.object().shape({
    name: Yup.string().required("Name is required"),
    apiKey: Yup.string().required("API Key is required"),
    senderId: Yup.string().required("Sender ID is required"),
    gatewayType: Yup.string().required("Gateway Type is required"),
  }),
  TrustSignal: Yup.object().shape({
    apiKey: Yup.string().required("API Key is required"),
    senderId: Yup.string().required("Sender ID is required"),
    route: Yup.string().required("Route is required"),
    gatewayType: Yup.string().required("Gateway Type is required"),
  }),
  Telnyx: Yup.object().shape({
    userName: Yup.string().required("User Name is required"),
    profileid: Yup.string().required("Profile ID is required"),
    password: Yup.string().required("Password is required"),
    phone: Yup.string().required("Phone number is required"),
  }),
  Sinch:Yup.object().shape({
    appid: Yup.string().required('appid is required'),
    pass: Yup.string().required('pass  is required'),
    name: Yup.string().required('name is required'),
    senderID:Yup.string().required('senderID is required'),
  })
};

