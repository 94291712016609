import React from "react";
import { ChakraProvider, Box, Input, Button, FormControl, FormErrorMessage, FormLabel, Text, Stack, HStack, Divider, InputGroup, InputRightAddon } from "@chakra-ui/react";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import PrimaryButton from '../../components/themeComponents/PrimaryButton';
import { Outlet, useOutletContext } from 'react-router-dom';
import PlatformWidget from '../../components/themeComponents/PlatformWidget';
import { integrateShopify } from '../../Services/IntegrationService';
import ShopifyIcon from "../../images/Shopify.png"
import { useTranslation } from 'react-i18next';

export default function Shopify() {
  const { integration } = useOutletContext();
  const { t } = useTranslation();

  const initialValues = {
    shopName: '',
  };
  const validationSchema = Yup.object({
    shopName: Yup.string().required(t('shopify.store.name.error.required')),
  });

  const onSubmit = (values) => {
    integrateShopify(values).then((res) => {
      window.open(res.data, "_self");
      console.log(res.data);
    }).catch((err) => {
      console.log(err)
    })
  };

  const WidgetContent = () => (
    <Box>
      <Text fontSize={'0.8vw'} width={'60%'} my={'10px'} >{t('integrate.shopify.flow')}</Text>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <HStack>
          <Form>
            <Field name="shopName">
              {({ field, form }) => (
                <FormControl isInvalid={form.errors.shopName && form.touched.shopName}>
                  <Box width={'100%'} gap={'0.5vw'} fontSize={'0.8vw'} display={'flex'} alignItems={'baseline'} color={'#AD88F1'} >
                    <InputGroup>
                      <Input
                        border={'1px solid #AD88F1'}
                        height={'4vh'}
                        color={'#AD88F1'}
                        {...field}
                        id="shopName"
                        placeholder={t('shopify.store.name.placeholder')}
                      />
                      <InputRightAddon height={'4vh'}  >.myshopify.com</InputRightAddon>
                    </InputGroup>
                  </Box>
                  <FormErrorMessage>{form.errors.shopName}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <PrimaryButton width={'8vw'} marginTop={'10px'} type={'submit'} borderRadius={'8px'} height={'1.5vh'} padding={'1vw 1vw'} fontSize={'0.8vw'} label={t('connect')} />
          </Form>
        </HStack>
      </Formik>
    </Box>
  )

  return (
    <>
      <Box bg={'#fff'} borderRadius={'1vw'} display={'flex'} flexDirection={'column'} p={'1rem'} justifyContent={'center'} alignItems={'center'} >
        <Box alignItems={'baseline'} display={'flex'} flexDirection={'column'} gap={'1vh'} width={'100%'} >
          <PlatformWidget platform={
            {
              title: t('connect.shopify.store', { integrationName: integration.entityName }),
            }

          }
            icon={ShopifyIcon}
            WidgetContent={WidgetContent}
          />

          <Text fontSize={'xl'} pl={'3%'} color={'#AD88F1'}>{t('steps.integrate.shopify', { integrationName: integration.entityName })}</Text>
          <HStack paddingLeft={'6%'} gap={'1vw'} width={'100%'}  >
            <Divider border={'1px solid #dadada'} orientation='vertical' height={'20vh'} />
            <Stack my={'2vh'} >
              <Stack p={'2rem'} >
                <Text color={'#7746A6'} >{t('step1.enter.store.name')}</Text>
                <Text color={'#7746A6'} >{t('step2.click.connect')}</Text>
                <Text color={'#7746A6'} >{t('step3.install.app')}</Text>
                <Text color={'#7746A6'} >{t('step4.click.enable')}</Text>
                <Text as={'i'} mt={'2vh'}>{t('note.data.delay')}</Text>
              </Stack>
            </Stack>
          </HStack>

        </Box>
      </Box>
      <Outlet />
    </>
  );
}
