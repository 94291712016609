import React from 'react';
import { useFormikContext, ErrorMessage, Field } from 'formik'; 
import { FormControl, FormLabel, Input, Select, VStack, FormErrorMessage } from '@chakra-ui/react';
import { smsChannelGatwayType } from '../../../_helpers/data_helper';

const RouteMobile = ({ values, index, setGatewayData }) => {
  const { errors, touched, setFieldValue } = useFormikContext();
  const handleChange = (field, event) => {
    setFieldValue(`credentials[${index}].${field}`, event.target.value);
    setGatewayData(`credentials[${index}]`, { ...values, [field]: event.target.value }); 
  };
  return (
    <VStack spacing={4} alignItems="flex-start">
     
      <FormControl id='userName' isInvalid={errors.userName && touched.userName}>
      
        <FormLabel>User Name</FormLabel>
        <Field
        as={Input} 
        placeholder="userName"
          name={`credentials[${index}].userName`}
          value={values.userName}
          onChange={(e) => handleChange('userName', e)}
        />
        
        <FormErrorMessage>{errors.userName}</FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={errors.password && touched.password}>
        <FormLabel>Password</FormLabel>
        <Field
        placeholder="password"
        type='password'
          name={`credentials[${index}].password`}
          value={values.password}
          onChange={(e) => handleChange('password', e)}
        />
        <ErrorMessage name="password" component={FormErrorMessage} />
      </FormControl>

      <FormControl isInvalid={errors.senderId && touched.senderId}>
        <FormLabel>Source/Sender ID</FormLabel>
        <Field
        as={Input}
        placeholder="senderId"
        
          name={`credentials[${index}].senderId`}
          value={values.senderId}
          onChange={(e) => handleChange('senderId', e)}
        />
        <ErrorMessage name="senderId" component={FormErrorMessage} />
      </FormControl>

      <FormControl isInvalid={errors.gatewayType && touched.gatewayType}>
        <FormLabel>Gateway Type</FormLabel>
        <Select
          name={`credentials[${index}].gatewayType`}
          value={values.gatewayType}
          onChange={(e) => handleChange('gatewayType', e)}
        >
          <option value="">Select Gateway Type</option>
          {smsChannelGatwayType.map((option) => (
            <option key={option.key} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
        <ErrorMessage name="gatewayType" component={FormErrorMessage} />
      </FormControl>

      <FormControl isInvalid={errors.dltEntityId && touched.dltEntityId}>
        <FormLabel>DLT Entity Id</FormLabel>
        <Field
        as={Input}
        placeholder="DLT Entity Id"
          name={`credentials[${index}].dltEntityId`}
          value={values.dltEntityId}
          onChange={(e) => handleChange('dltEntityId', e)}
        />
        <ErrorMessage name="dltEntityId" component={FormErrorMessage} />
      </FormControl>
    </VStack>
  );
};

export default RouteMobile;

