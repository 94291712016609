import { Box, FormControl, FormLabel, InputGroup, InputLeftElement, Icon, Select, Input } from '@chakra-ui/react'
import React, { useMemo } from 'react'
import { BiSearch } from "react-icons/bi"
import { useFormik } from "formik"
import _ from "lodash";
export default function FlowListFilter({onFilter}) {
    const formik = useFormik({
        initialValues: {
            keyword: '',
            status: ''
        },
        onSubmit: function(values, action){
            onFilter(values)
        }
    })
    const debounceFn = useMemo(() => _.debounce(formik.handleSubmit, 1000), []);
    function searchCampaign(e) {
        if (e.target.value.length > 2 || e.target.value.length === 0) {
            formik.setFieldValue("keyword", e.target.value)
            debounceFn(e.target.value)
        }
    }
    return (
        <Box display={'flex'} p={'1rem'} width={'100%'} bg={'white'} gap={3}>
            <FormControl maxW={'350px'}>
                <FormLabel color={'inputLabel'} fontSize={'1vw'} fontWeight={'600'}>Search By Name</FormLabel>
                <InputGroup>
                    <InputLeftElement>
                        <Icon as={BiSearch} color={'gray.500'}></Icon>
                    </InputLeftElement>
                    <Input onChange={searchCampaign}></Input>
                </InputGroup>
            </FormControl>
            <FormControl maxW={'350px'}>
                <FormLabel color={'inputLabel'} fontSize={'1vw'} fontWeight={'600'}>Status</FormLabel>
                <Select value={formik.values.status} onChange={(e) => {
                    formik.setFieldValue("status", e.target.value);
                    formik.handleSubmit()
                }}>
                    {
                        [{ key: '', label: 'All' }, { key: 'enabled', label: 'Enabled' }, { key: 'disabled', label: 'Disabled' }].map(status => (
                            <option value={status.key}>{status.label}</option>
                        ))
                    }
                </Select>
            </FormControl>
        </Box>
    )
}
