import React, { useCallback, useState } from 'react'
import { Dropzone, FileMosaic } from '@files-ui/react';
import { Flex, useToken, Text } from '@chakra-ui/react';
import hexToRgba from 'hex-to-rgba';
import axiosInstance from "../Services/axiosInstance";
import 'react-dropzone-uploader/dist/styles.css'
import { ENDPOINTS, uploadCsvFile } from '../Services/api';
function CsvUploader({ channel, token, setUploadingStatus, onUpload }) {
    const [isUploading, setIsUploading] = useState(false)
    const getUploadedParams = async ({ file, meta }) => {
        const uploadUrl = `${process.env.REACT_APP_API_ENDPOINT}${ENDPOINTS.uploadFile}${channel}?orgToken=${token}`
        const fields = new FormData();
        fields.append("file", fields);
        return { url: uploadUrl, method: 'post' }
    };
    const handleChangeStatus = ({ meta, file }, status) => { console.log('handleChangeStatus', status, meta, file) }
    const handleSubmit = (files, allFiles) => {

        console.log(files.map(f => f.meta))
        allFiles.forEach(f => f.remove())
    }
    const [mainColor] = useToken('colors', ['primary.main'])
    const [files, setFiles] = React.useState([]);
    const updateFiles = async (incommingFiles) => {
        //do something with the files
        setUploadingStatus(true)
        const formData = new FormData()
        incommingFiles.map(e => {
            formData.append('file', e.file)
        })
        let res = await uploadCsvFile(token, channel, formData);
        console.log('after upload',res)
        console.log("incomming files", incommingFiles);
        setFiles(incommingFiles);
        setUploadingStatus(false);
        onUpload(res.data)
        //even your own upload implementation
    };
    const removeFile = (id) => {
        setFiles(files.filter((x) => x.id !== id));
    };
    return (
        <Dropzone
            onChange={updateFiles}
            //value={files}
            accept="text/csv"
            footer={true}
        >
            {files.map((file) => (
                <FileMosaic key={file.id} {...file} onDelete={removeFile} info />
            ))}
        </Dropzone>
    )
}

export default CsvUploader
