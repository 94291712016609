import axios from "axios"
import axiosInstance from "../../Services/axiosInstance"
import Mustache from "mustache"
import moment from "moment"
export const readTemplateAndGetMarkup = (layoutId) => {
    return axios.get(process.env.PUBLIC_URL + "/nudgeTemplates/" + layoutId + ".html")
    // console.log('layoutid', layoutId)
}
export const getPreviewMarkup = () => {
    return axios.get(process.env.PUBLIC_URL + "/nudgeTemplates/nudge_preview.html");
}
export const getNudgeEditSteps = () => {
    return [
        { title: 'First Step', description: 'Edit Design' },
        { title: 'Second Step', description: 'Targeting' },
        { title: 'Third Step', description: 'Final Touch' },
    ]
}

export const durations = [
    {
        key: 'SECONDS',
        label: 'SECONDS'
    },
    {
        key: 'MINUTES',
        label: 'MINUTES'
    },
    {
        key: 'HOURS',
        label: 'HOURS'
    },
    {
        key: 'DAYS',
        label: 'DAYS'
    }
];
var MAX_TTL_IN_SEC = 60 * 60 * 24 * 7;
const validateAndGetExpiry = function (formData) {
    if (formData.settings.hasOwnProperty('ttl')) {
        var momentDuration = moment.duration(formData.settings.ttl.value, formData.settings.ttl.timeunit.toLowerCase());
        var expInSec = momentDuration.asSeconds();
        if (expInSec > MAX_TTL_IN_SEC) {
            return { status: false, ttl: expInSec };
        }
    }
    return { status: true, ttl: expInSec };
}
export const prepareNudgeTemplateFinalData = (data) => {
    let formData = { ...data };
    formData.markup.rendered = Mustache.render(formData.markup.raw, { formData: formData });
    let expiryDetails = validateAndGetExpiry(formData);
    if (expiryDetails.hasOwnProperty('status')) {
        formData.settings.ttl.status = expiryDetails.status;
        if (!expiryDetails.status) return;
    }
    if (formData.settings.locationAndDeviceTargeting.status && formData.settings.locationAndDeviceTargeting.criteria.length) {
        let criteria = [...formData.settings.locationAndDeviceTargeting.criteria];
        criteria.length && criteria.map(el => {
            if (!!el.value && el.value.length) {
                el.value = el.value.length && el.value.map(e => {
                    return e.value
                });
            }

        })
        criteria.map(function (el) {
            if (el.criterion === 'country') {
                el["value2"] = formData.settings.locationAndDeviceTargeting.selectedStates;
            }
        });
        formData.settings.locationAndDeviceTargeting.criteria = criteria;

    }
    let retVal = {
        name: formData.name, description: formData.description, layoutId: formData.layoutId, settings: formData.settings,
        ttl: expiryDetails.ttl || 30 * 60, markup: formData.markup, templateProps: formData.template.props
    }
    return retVal;
}