import {
    Box, Button, HStack, Image, ListItem, Modal, ModalBody, Option, ModalCloseButton, ModalContent, AlertDialogContent, AlertDialogHeader, AlertDialogBody,
    ModalFooter, ModalHeader, ModalOverlay, Select, Text, UnorderedList, Checkbox, Input, Divider, Icon, useToast, Flex, Spinner
} from '@chakra-ui/react'
import React, { Fragment, useEffect, useState } from 'react'
import ReactDOMServer from "react-dom/server"
import { connect, useDispatch } from 'react-redux'
import OrganizationList from '../Organization'
import Confirmation from '../../components/themeComponents/Confirmation'
import { Link, json, useNavigate } from 'react-router-dom'
import { activateRecipe, displayRecipePreview } from '../../Services/DoubletapService'
import PrimaryButton from '../../components/themeComponents/PrimaryButton'
import { CiEdit } from "react-icons/ci";
import { FaEye } from "react-icons/fa";
import TemplatePreviewModal from '../Campaigns/components/TemplatePreviewModal'
import { useDisclosure } from '@chakra-ui/react'
import { ConfimrationAlerts } from '../../components/themeComponents/ConfirmationAlert';
import Swal from "sweetalert2"
import SecondaryButton from '../../components/themeComponents/SecondaryButton'
import { editRecipeDetails } from '../../Services/api'
import { showSwal } from '../../_helpers/helpers'
export default function RecipeActvationModal({ selectedRecipe, isOpen, onClose }) {
    const toast = useToast()
    const [showAlert, setShowAlert] = useState(false);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    // const [isPreviewClose,setIsPreviewClose] = useState(false);
    const [isLoading, setIsLoading] = useState(true)
    const [discountData, setDiscountData] = useState(false);
    const navigate = useNavigate()
    const [tempBody, setTempBody] = useState("");
    const showRecipePreview = () => {

        displayRecipePreview(selectedRecipe?.templateRecipe[0].channel, selectedRecipe?.templateRecipe[0].type, selectedRecipe.uuid, selectedRecipe?.templateRecipe[0].name, btoa(JSON.stringify(selectedRecipe.discountData))).then((res) => {
            setTempBody(res.data)
            setIsPreviewOpen(true);
        }).catch((err) => {
            toast({
                title: 'Something went wrong.',
                description: "Preview is not available at the moment",
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        })
    }

    const onRecipeActivate = () => {
        activateRecipe(selectedRecipe).then((res) => {
            console.log("res", res)
        })
        onClose()
    }

    const editRecipe = (recipe) => {
        showSwal(Swal, 'Are you sure?', 'Do you want to edit the recipe?', true, true, 'Yes, Edit', 'No', ( async () => {
            try {
                let editRecipeRes = await editRecipeDetails(selectedRecipe);
                if (!!editRecipeRes.data?.uuid) {
                    return editRecipeRes.data
                }
                toast({ title: 'Oops!', status: 'error', description: 'Something went wrong while editing the recipe. Please try again' })
                Swal.close()

            } catch (error) {
                toast({ title: 'Oops!', status: 'error', description: 'Something went wrong while editing the recipe. Please try again' })
                Swal.close()
            }
        }), (result) => {
            result.isConfirmed && (() => {
                localStorage.setItem("recipeData", JSON.stringify(result.value));
                navigate('/flows/create/' + result.value.uuid + '?edit-recipe')
            })()
            Swal.close()
        })
    }
    return (
        <>
            <Modal isOpen={isOpen} size={'xl'} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader display={'flex'} >
                        <Text w={'100%'} >{selectedRecipe.title}</Text>
                        <HStack cursor={'pointer'} mr={'3vw'} width={'100%'} justifyContent={'end'} >


                            {/* <PrimaryButton  label={'Edit'} />
                    <PrimaryButton  label={'Preview'} /> */}
                            <HStack gap={'10px'}  >

                                <Text fontSize={'0.8vw'} as={'u'} cursor={'pointer'} onClick={() => editRecipe(selectedRecipe)}>
                                    Edit
                                </Text>
                                <Icon as={CiEdit} />
                            </HStack>
                            <Divider orientation='vertical' height={'70%'} />

                            <HStack onClick={showRecipePreview} >
                                {/* <Select>
                                    {
                                        !!selectedRecipe?.templateRecipe.length && selectedRecipe?.templateRecipe.map((e, i) => (
                                            <option onClick={() => showRecipePreview(i)} > Preview for {e.channel + " " + parseInt(i + 1)} </option>
                                        ))
                                    }
                                </Select> */}
                                <Text fontSize={'0.8vw'} as={'u'} >
                                    Preview
                                </Text>
                                <Icon as={FaEye} />

                            </HStack>
                        </HStack>

                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pt={0}>
                        <UnorderedList>
                            <ListItem mt={'1rem'}>
                                What is {selectedRecipe.title} ?
                            </ListItem>
                            <Text>{selectedRecipe.whatsNewText}</Text>
                            <ListItem mt={'1rem'}>
                                How many nudges ?
                            </ListItem>
                            <Text>{selectedRecipe.nudgeText}</Text>

                            {/* 
                        <ListItem mt={'1rem'}>
                            Coupon code
                        </ListItem> */}
                            <Text>
                                {/* {


                                selectedRecipe?.discountData?.length > 0 && selectedRecipe?.discountData.map((disc, i) => (
                                    <HStack>
                                        <Checkbox onChange={() => enableDiscount(i)} >
                                            {selectedRecipe.templateRecipe[0].channel.toLowerCase() + " " + (i + 1)}  Discount :
                                        </Checkbox>
                                        {
                                            !!discountData &&
                                            <HStack>
                                            <Select>
                                            <option>
                                            Percentage
                                            </option>
                                            <option>
                                            Fixed Amount
                                            </option>
                                            </Select>
                                            <Input value={""} />
                                            </HStack>
                                        }
                                        </HStack>
                                        )
                                        )
                                        
                                    } */}

                            </Text>

                            <ListItem mt={'1rem'}>
                                What can you expect:
                            </ListItem>
                            <Text>{selectedRecipe.expectationText}</Text>
                            <ListItem mt={'1rem'}>
                                How to create:
                            </ListItem>
                            <Text>{selectedRecipe.howText}</Text>
                        </UnorderedList>
                    </ModalBody>
                    <ModalFooter>
                        <PrimaryButton onClick={() => setShowAlert(true)} label={'Activate'} />
                    </ModalFooter>

                </ModalContent>
                <Confirmation isOpen={showAlert} title={'Activate Flow'} text="Are you sure?" confirmButtonText={'Yes'} cancelButtonText={'Cancel'} onConfirm={() => onRecipeActivate()} onCancel={() => { setShowAlert(false) }}></Confirmation>
            </Modal>
            <TemplatePreviewModal isOpen={isPreviewOpen} onClose={() => setIsPreviewOpen(false)} tempBody={tempBody}></TemplatePreviewModal>

        </>
    )
}


