import React, { useEffect } from 'react'
import { Card, Image, Stack, CardBody, Heading, CardFooter, Button, Text, Box, Icon, UnorderedList, ListItem } from '@chakra-ui/react'
import Platforms from '../../Pages/Integration/Platforms'
import PrimaryButton from './PrimaryButton'
import OutlinedButton from '../themeComponents/OutlinedButton'
import { Link } from 'react-router-dom'
import { IoIosCheckmarkCircle } from "react-icons/io";
export default function CustomCard({ platform }) {
    return (
        <Card
            direction={{ base: 'column', sm: 'row' }}
            overflow='hidden'
            borderRadius={'5px'}
            variant='outline'
            width={'24vw'}
            height={"20vh"}
        
        >
            <Box width={'25%'} display={'flex'} bg={'#d9d9d9'} justifyContent={'center'} alignItems={'center'} >
                <Image
                    objectFit={'cover'}
                    width={'3em'}
                    height={'auto'}
                    src={platform.icon}
                    mixBlendMode={'darken'}
                />
            </Box>
            <Stack width={'75%'} >
                <CardBody width={'100%'} display={'flex'}
                    padding={'1rem'}
                    flexDirection={'column'} >
                    <Text display={'flex'} alignItems={'center'} fontSize={'0.9vw'}>{platform.title}
                        {
                            platform.isIntegrated &&
                            <Box ml={'5px'} bg={'#ECFAD1'} display={"flex"} alignItems={'center'} w={'30%'} border={'1px solid #CDCDCD'} color={'#518B21'} padding={'0.1vw 1vw'} borderRadius={'20px'} fontSize={'0.5vw'} as='span' >
                                <IoIosCheckmarkCircle size={12} fill='green' />
                                Enabled
                            </Box>

                        }
                    </Text>
                    <Text fontSize={'0.6vw'}  marginBottom={2}>
                        {platform.description}
                    </Text>


                </CardBody>
                <CardFooter display={'flex'} p={0} width={'inherit'} justifyContent={'end'}>
                    {
                        platform?.isIntegrated ?
                            <Box>

                                <Link style={{ alignSelf: 'end', display: 'flex', gap: '5px', marginBottom: '1vh', padding: '0.5rem' }} to={platform.navigateTo} >
                                    <PrimaryButton right={'8vw'} borderRadius={'1vw'} height={'1.8vh'} padding={'1vw 1vw'} position={'absolute'} bottom={'0.8vw'} fontSize={'0.8vw'} label={'Manage'} />
                                </Link>

                                <Link style={{ alignSelf: 'end', display: 'flex', gap: '5px', marginBottom: '1vh', padding: '0.5rem' }} to={platform.navigateTo} >
                                    <PrimaryButton right={'1vw'} borderRadius={'1vw'} height={'1.8vh'} padding={'1vw 1vw'} position={'absolute'} bottom={'0.8vw'} fontSize={'0.8vw'} label={'Remove'} />
                                </Link>
                            </Box>

                            :
                            <Link style={{ alignSelf: 'end', display: 'flex', gap: '5px', marginBottom: '1vh', padding: '0.5rem' }} to={platform.navigateTo} >
                                <PrimaryButton right={'1vw'} borderRadius={'1vw'} height={'1.8vh'} padding={'1vw 1vw'} position={'absolute'} bottom={'0.8vw'} fontSize={'0.8vw'} label={'Connect'}  />
                            </Link>
                    }

                </CardFooter>
            </Stack>
        </Card>
    )
}
