import React, { useEffect, useState } from 'react';
import { Box, Icon, Text, VStack, HStack, Button, Avatar, Input, Tooltip } from '@chakra-ui/react';
import { FaAndroid, FaApple, FaChrome, FaFirefox, FaSafari, FaLinux, FaWindows } from 'react-icons/fa';
import axiosInstance from '../../Services/axiosInstance';
import { getLiveActivitiesFeed } from '../../Services/api';
import { setupMoment } from './liveActivitiesHelpers';
import { CiMail } from "react-icons/ci";
import { MdLocalPhone } from 'react-icons/md';
import * as _ from 'lodash';
export default function LiveActivities() {
  const [details, setDetails] = useState(null);
  const [activityFeed, setActivityFeed] = useState([]);
  const [currentActivity, setCurrentActivity] = useState({});
  const [searchQuery, setSearchQuery] = useState(""); // New state for search query
  const browserList = {
    "chrome": FaChrome,
    "mozilla": FaFirefox,
    "safari": FaSafari
  };
  const os = {
    "linux": FaLinux,
    "windows": FaWindows,
    "macos": FaApple,
    "android": FaAndroid,
    "ios": FaApple
  };

  const momentUtils = setupMoment();

  const fetchDetails = async (cookieId) => {
    try {
      const response = await axiosInstance.get(`/rest/v1/liveactivities/detailactivities/${cookieId}`);
      setDetails(response.data);
    } catch (error) {
      console.error('Error fetching details:', error);
    }
  };

  const fetchLiveActivities = async () => {
    let resp = await getLiveActivitiesFeed();
    setActivityFeed(resp?.data);
  };

  useEffect(() => {
    fetchLiveActivities();
  }, []);

  // Filter activities based on search query
  const filteredActivities = activityFeed.filter(item => {
    const query = searchQuery.toLowerCase();
    return (
      item?.cookieid?.toLowerCase().includes(query) ||
      item?.useremail?.toLowerCase().includes(query) ||
      item?.userphone?.toLowerCase().includes(query)
    );
  });

  return (
    <Box w={'100%'}>
      <Text as={'heading'}>
        Live Activities
      </Text>

      {/* Search Input */}
      
      <Box display={'flex'}>

        <VStack background={'#fff'} w={'50%'} justifyContent={"start"} alignItems={'start'} overflowY={'auto'} minH={'50vh'} maxH={'70vh'}>
      <Input
        placeholder="Search by cookie ID, email, or phone"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)} // Update search query
        mb={4}
      />
          {!!filteredActivities?.length && filteredActivities.map((item, index) => (
            <Box
              key={index}
              width={'100%'}
              cursor={'pointer'}
              display={'flex'}
              boxShadow={"0 3px 10px rgb(0 0 0 / 0.2)"}
              justifyContent={'space-between'}
              gap={"10px"}
              alignItems={'center'}
              padding={"1rem"}
              onClick={() => {
                fetchDetails(item?.cookieid);
                setCurrentActivity(item);
              }}
              bg={item?.cookieid === currentActivity?.cookieid ? "gray.200" : "#fff"}
            >
              <VStack>
                <HStack>
                  <Avatar />
                  <Text>{item.useremail || item.userphone || item.cookieid}</Text>
                </HStack>
                <Text fontSize={'12px'} alignSelf={'start'}>{item.city}, {item.state}</Text>
              </VStack>
              <VStack>
                <HStack>
                  <Text fontSize={'10px'}>{momentUtils.getTimeAgo(item.createdtime)}</Text>
                </HStack>
                <HStack>
                  <Icon width={'15px'} fontSize={'10px'} as={browserList[item?.browser?.toLowerCase()]} boxSize={6} color="gray.500" />
                  <Icon width={'15px'} as={os[item.os.toLowerCase()]} boxSize={6} color="gray.500" />
                </HStack>
              </VStack>
            </Box>
          ))}
        </VStack>

        {!_.isEmpty(currentActivity)  && (
          <Box w={'50%'} display={'flex'} flexDirection={'column'} alignItems={'start'}>
            <Box mt={8} p={4} w={'90%'} ml={'20px'} h={'fit-content'} bg={'#fff'} boxShadow={'0 3px 10px rgb(0 0 0 / 0.2)'} borderRadius="md">
              <HStack>
                <Avatar />
                <Text>{currentActivity.useremail || currentActivity.userphone || currentActivity.cookieid} | {currentActivity.city} ({currentActivity.state})</Text>
              </HStack>
              <HStack gap={'3vw'}>
                <VStack alignItems={'start'}>
                  <HStack alignItems={'start'} w={'100%'} mt={'15px'} gap={'10px'}>
                    <Icon as={CiMail} />
                    <Text>{currentActivity.useremail || "Not available"}</Text>
                  </HStack>
                  <HStack mt={'15px'} alignItems={'start'} gap={'10px'}>
                    <Icon as={MdLocalPhone} />
                    <Text>{currentActivity.userphone || "Not available"}</Text>
                  </HStack>
                </VStack>
                <VStack alignItems={'start'}>
                  <Text> <Text as={'b'} >First Seen:</Text> {momentUtils.getTimeAgo(currentActivity.firstSeen)}</Text>
                  <Text> <Text as={'b'} >Last Seen:</Text>  {momentUtils.getTimeAgo(currentActivity.createdtime)}</Text>
                </VStack>
              </HStack>
            </Box>

            <VStack alignItems={'start'} justifyContent={'space-between'} ml={'20px'} mt={"1vw"} w={'90%'} display={'flex'}>
              {!!details && details?.data.map((detail, index) => (
                <VStack key={index} p={'1rem'} boxShadow={"0 3px 10px rgb(0 0 0 / 0.2)"} w={'100%'} justifyContent={'space-between'} alignItems={'flex-start'}>
                  <HStack w={'100%'} justifyContent={'space-between'}>
                    <Text>{momentUtils.formatDate(detail?.time, "MMM Do YY")}</Text>
                    <HStack>
                      <Icon width={'15px'} as={browserList[currentActivity.browser.toLowerCase()]} boxSize={6} color="gray.500" />
                      <Icon width={'15px'} as={os[currentActivity.os.toLowerCase()]} boxSize={6} color="gray.500" />
                    </HStack>
                  </HStack>
                  <VStack w={'100%'} alignItems={'start'}>
                    <HStack justifyContent={'space-between'} w={'100%'}>
                      <Text>
                        {detail?.description} {detail?.eventVal.startsWith("http") ? <a target='_blank' style={{
                          textDecoration:"underline"
                        }}  href={detail.eventVal}>Go to page</a> : detail.eventVal}
                      </Text>
                      <Text fontSize={"10px"}>{momentUtils.getTimeAgo(detail?.time)}</Text>
                    </HStack>
                  </VStack>
                </VStack>
              ))}
            </VStack>
          </Box>
        )}
      </Box>
    </Box>
  );
}
