import React from 'react';
import { Box, Text, Stack, HStack, Divider } from "@chakra-ui/react";
import PrimaryButton from '../../components/themeComponents/PrimaryButton';
import PlatformWidget from '../../components/themeComponents/PlatformWidget';
import { integrateGA4 } from '../../Services/IntegrationService';
import GA4Icon from "../../images/GA4.png";
import { useOutletContext } from 'react-router-dom';
import { HiArrowDownTray } from "react-icons/hi2";
import { useTranslation } from 'react-i18next';

export default function GA4() {
  const { integration } = useOutletContext();
  const { t } = useTranslation();

  const WidgetContent = () => (
    <Stack justifyItems={'space-around'}  >
      <Text fontSize={'xl'} width={'60%'} my={'5px'} >{t('ga4.description')}</Text>
      <PrimaryButton height={'4vh'} fontSize={'0.8vw'} width={'12vw'} label={t('ga4.signInWithGoogle')} onClick={ga4Integration} borderRadius={'1vw'} ></PrimaryButton>
    </Stack>
  )

  const ga4Integration = () => {
    integrateGA4().then((res) => {
      console.log(res);
      window.open(res.data, '_blank')

    }).catch((err) => {
      console.error(err)
    })
  }

  return (
    <>
      <Box bg={'#fff'} borderRadius={'1vw'} display={'flex'} flexDirection={'column'} p={'1rem'} justifyContent={'center'} alignItems={'center'} >
        <Box alignItems={'center'} display={'flex'} flexDirection={'column'} gap={'1vh'} >
          <PlatformWidget platform={
            {
              title: t('ga4.title', { entityName: integration.entityName }),
            }
          }
            icon={GA4Icon}
            WidgetContent={WidgetContent}
            styles={{ height: 'fit-content', width: '25vw' }}
          />
          <Text fontSize={'xl'} alignSelf={'start'} pl={'3%'} >{t('ga4.stepsTitle')}</Text>
          <HStack paddingLeft={'6%'} gap={'1vw'} width={'100%'}  >
            <Divider border={'1px solid #dadada'} orientation='vertical' height={'20vh'} />

            <Stack>
              <Text>{t('ga4.steps.step1')}</Text>
              <Text>{t('ga4.steps.step2')}</Text>
              <Text>{t('ga4.steps.step3')}</Text>
            </Stack>
          </HStack>
        </Box>

      </Box>
    </>
  );
}
