import {  Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react'
import React, {  useState } from 'react'
import PrimaryButton from '../../../components/themeComponents/PrimaryButton'
import {OvalSpinner} from '../../../components/Loaders'
import hexToRgba from 'hex-to-rgba'
import CsvUploader from '../../../components/CsvUploader'
import _ from 'lodash'


function UploadCsvModal({isOpen, onClose, channel, orgToken, onSelect}) {
    const [isUploading, setIsUploading] = useState(false)
    const [uploadedFile, setUploadedFile] = useState({})
    const onUpload = (file) => {
        console.log('file in modal controller', file);
        setUploadedFile(file)
        //onSelect(fille)
    }
    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'xl'}>
            <ModalOverlay></ModalOverlay>
            <ModalContent>
                <ModalHeader>Add CSV</ModalHeader>
                <ModalCloseButton></ModalCloseButton>
                <ModalBody>
                    {isUploading && <OvalSpinner height={30} width={20}></OvalSpinner>}
                    <CsvUploader channel={channel} token={orgToken} setUploadingStatus={(status) => setIsUploading(status)} onUpload={onUpload}></CsvUploader>
                </ModalBody>
                <ModalFooter>
                    <PrimaryButton label={'Select'} size={'sm'} isDisabled={_.isEmpty(uploadedFile)} onClick={() => onSelect(uploadedFile)}></PrimaryButton>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default UploadCsvModal;
