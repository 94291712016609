import { Icon, InputGroup, InputRightAddon, Input, Popover, PopoverTrigger, PopoverContent, useDisclosure } from '@chakra-ui/react'
import EmojiPicker from 'emoji-picker-react'
import React, { Fragment, useState, useRef } from 'react'
import { MdOutlineEmojiEmotions } from "react-icons/md"
export default function InputWithEmoji({ onChange, value, ...rest }) {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const inputRef = useRef(null);
  const onEmojiClick = (e) => {
    const { selectionStart, selectionEnd } = inputRef.current;
    const newInputValue = value.substring(0, selectionStart) + e.emoji + value.substring(selectionEnd);
    onChange(newInputValue);
    inputRef.current.focus();
    inputRef.current.setSelectionRange(selectionStart + e.emoji.length, selectionStart + e.emoji.length);
    onClose()
  }
  return (
    <Fragment>
      <Popover isOpen={isOpen}>
        <InputGroup>
          <Input onChange={(e) => onChange(e.target.value)} {...rest} value={value} ref={inputRef}></Input>
          <PopoverTrigger>
            <InputRightAddon onClick={() => !!isOpen ? onClose() : onOpen()}><Icon as={MdOutlineEmojiEmotions}></Icon></InputRightAddon>
          </PopoverTrigger>
        </InputGroup>
        <PopoverContent>
          <EmojiPicker onEmojiClick={onEmojiClick}></EmojiPicker>
        </PopoverContent>

      </Popover>
    </Fragment>
  )
}
