import React from 'react';
import { Box, Text, Stack, HStack, Divider } from '@chakra-ui/react';
import PrimaryButton from '../../components/themeComponents/PrimaryButton';
import { useOutletContext } from 'react-router-dom';
import PlatformWidget from '../../components/themeComponents/PlatformWidget';
import WordPressIcon from "../../images/Wordpress.png";
import { MdDownloadForOffline } from "react-icons/md";
import { useTranslation } from 'react-i18next';

export default function WordPress() {
    const { integration } = useOutletContext();
    const { t } = useTranslation();

    const WidgetContent = () => (
        <Stack gap={'3vh'} >
            <Text fontSize={'xl'} width={'60%'} my={'5px'} ></Text>
            <Text>
                {t('wordpress.description')}
            </Text>
            <PrimaryButton width={'8vw'}
                onClick={() => window.open('https://wordpress.org/plugins/mkt', '_blank')}
                marginTop={'5px'} type={'submit'} borderRadius={'8px'} height={'1.8vh'} padding={'1vw 1vw'} fontSize={'0.8vw'} label={t('wordpress.connect')} />
        </Stack>
    )

    return (
        <>
            <Box bg={'#fff'} borderRadius={'1vw'} display={'flex'} flexDirection={'column'} p={'1rem'} justifyContent={'center'} alignItems={'center'} >
                <Box alignItems={'baseline'} width={'100%'} display={'flex'} flexDirection={'column'} gap={'1vh'} >
                    <PlatformWidget platform={
                        {
                            title: t('wordpress.title', { entityName: integration.entityName }),
                        }
                    }
                        icon={WordPressIcon}
                        WidgetContent={WidgetContent}

                    />

                    <Text fontSize={'xl'} pl={'3%'} color={'#AD88F1'} >{t('wordpress.stepsTitle', { entityName: integration.entityName })}</Text>
                    <HStack paddingLeft={'6%'} my={'2vh'} gap={'1vw'} width={'100%'}  >
                        <Divider border={'1px solid #dadada'} orientation='vertical' height={'20vh'} />
                        <Stack>
                            <Stack p={'2rem'} >

                                <Text color={'#7746A6'} >{t('wordpress.steps.step1')}</Text>
                                <Text color={'#7746A6'} >{t('wordpress.steps.step2')}</Text>
                                <Text color={'#7746A6'} >{t('wordpress.steps.step3')}</Text>
                                <Text color={'#7746A6'} >{t('wordpress.steps.step4')}</Text>
                                <Text color={'#7746A6'} >{t('wordpress.steps.step5')}</Text>
                                <Text color={'#7746A6'} >{t('wordpress.steps.step6')}</Text>

                            </Stack>
                        </Stack>
                    </HStack>

                </Box>
                <PrimaryButton alignSelf={'flex-end'} height={'4vh'} borderRadius={'8px'} label={'Download Guide'} rightIcon={<MdDownloadForOffline />} />
            </Box>
        </>
    );
}
