import React from 'react'
import { Card, Image, Stack, CardBody, Heading, CardFooter, Button, Text, Box, Icon, UnorderedList, ListItem } from '@chakra-ui/react';
import IntegrationWithEntity from '../IntegrationWithEntity';

export default function PlatformWidget({ platform, WidgetContent, icon }) {
    return (
        <Card
            direction={{ base: 'column', sm: 'row' }}
            overflow='hidden'
            width={'100%'}
            flexDirection={'row-reverse'}
            boxShadow={'none'}
            gap={'1vw'}
        >
            <IntegrationWithEntity icon={icon} />
            <Stack border={'1px solid #AD88F1'} width={'100%'} borderRadius={'8px'} >
                <CardBody width={'100%'} justifyContent={'space-around'} display={'flex'}
                    padding={'1rem'}
                    flexDirection={'column'} >
                    <Text fontSize={'1.2vw'}>{platform.title}</Text>
                    {/* <Text fontSize={'0.6vw'} >
                        {platform.description}
                    </Text> */}
                    <WidgetContent />

                </CardBody>
                {/* <UnorderedList mb={'1vh'} >
                    {
                        !!platform?.list.length && platform?.list.map((item) => (
                            <ListItem fontSize={'0.6vw'} ><Text>{item}</Text></ListItem>
                        ))
                    }
                </UnorderedList> */}
            </Stack>
        </Card>
    )
}
