import React, { Fragment, useEffect, useState } from 'react'
import { Box, Flex, Grid, Icon, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Table, Tbody, Td, Text, Th, Thead, Tr, useToast, Select, ModalFooter } from '@chakra-ui/react'
import TableHead from './TableHead'
import { useQuery } from 'react-query'
import { fetchCsvHeaders, saveValidationData, validateCsv } from '../../../Services/api'
import _ from "lodash";
import CsvHeaders from './Csvheaders'
import { BsFiletypeCsv } from "react-icons/bs";
import OutlinedButton from '../../../components/themeComponents/OutlinedButton'
import PrimaryButton from '../../../components/themeComponents/PrimaryButton'

function CsvValidatorModal({ onClose, isOpen, csvList, channel, orgToken, invalidCsv, onConfirm }) {
    const [list, setList] = useState(csvList);
    const [isValidating, setIsValidating] = useState({});
    const toast = useToast();
    const onHeaderSelect = async (selectedHeader, csvId, extraData, isNewHeader) => {
        !!selectedHeader && _.isString(selectedHeader) && (selectedHeader = parseInt(selectedHeader));
        let tempList = [...csvList];
        let selecctCsvIndex = _.findIndex(tempList, { value: csvId });
        if (selecctCsvIndex > -1) {
            !!_.isEmpty(tempList[selecctCsvIndex].data.extraData) ? (tempList[selecctCsvIndex].data.extraData = extraData) : (tempList[selecctCsvIndex].data.extraData = extraData)
            //!!isNewHeader && (tempList[selecctCsvIndex].data.extraData['mappedHeadres'])

            !_.isEmpty(extraData.parsedHeaders) && (tempList[selecctCsvIndex].data.extraData["mapped_headers"] = !!selectedHeader ? _.filter(extraData.parsedHeaders, { index: parseInt(selectedHeader) }) : []);
            !!_.isEmpty(extraData.parsedHeaders) && (tempList[selecctCsvIndex].data.extraData["mapped_headers"] = [{ index: selectedHeader, key: extraData.headers[selectedHeader - 1] }])
            tempList[selecctCsvIndex].data.extraData.hasOwnProperty("validationData") && tempList[selecctCsvIndex].data.extraData.validationData && (tempList[selecctCsvIndex].data['reValidate'] = true)
            
        } else {
            tempList[selecctCsvIndex].data.extraData["mapped_headers"] = []
        }
        setList(tempList)

    }
    async function updateList(file, isValidating, fileId, validationData) {
        let tempList = [...list];
        let selecctCsvIndex = _.findIndex(tempList, { value: fileId });
        tempList[selecctCsvIndex].data['isLoading'] = isValidating;
        tempList[selecctCsvIndex].data['reValidate'] = false
        if (validationData) {
            tempList[selecctCsvIndex].data.extraData['validationData'] = validationData;
            let res = await saveValidationData(file, channel, orgToken);
            console.log('validation data save', res.data)
        }
        setList(tempList);
    }
    const onValidate = async (file, fileId) => {
        console.log("file in onValidate", file)
        if (!file?.extraData?.mapped_headers.length) return toast({ title: 'Please select header first' })
        updateList(file, true, fileId);
        let res = await validateCsv(file.extraData.mapped_headers[0] || file.extraData.mapped_headers, orgToken, file.url, channel);
        if (res.data.hasOwnProperty('is_valid')) {
            updateList(file, false, fileId, res.data)
        }
        //console.log(res.data, 'validationData')

        //setList(headers)

    }

    const confirmCsvValidation = () => {
        onConfirm(list)
    }
    useEffect(() => {
        setList(csvList)
    }, [csvList])

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'xl'} closeOnOverlayClick={false}>
            <ModalOverlay></ModalOverlay>
            <ModalContent maxW={'1050px'} w={'100%'}>
                <ModalHeader>
                    CSV Validator
                </ModalHeader>
                <ModalCloseButton></ModalCloseButton>
                <ModalBody>
                    {/* <Grid gridTemplateColumns={'repeat(4, 1fr)'} gap={4}>
                        {
                            list.length && list.map(csv => (
                                <Flex direction={'column'} p={3} justifyContent={'center'} alignItems={'center'} gap={2} border={'1px solid'} borderColor={'danger'} rounded={5}>
                                    <Icon as={BsFiletypeCsv} boxSize={10} color={'danger'}></Icon>
                                    <Text color={'danger'}>{csv.label}</Text>
                                    <CsvHeaders channel={channel} csvUrl={csv.data.url} orgToken={orgToken} onHeaderSelect={onHeaderSelect} csvId={csv.value}></CsvHeaders>
                                </Flex>
                            ))
                        }
                    </Grid> */}
                    <Table>
                        <TableHead headersProps={['CSV Name', 'CSV Headers', 'Total Rows', 'Valid Rows', 'Invalid Rows', 'Actions']} fontSize={'xs'}></TableHead>
                        <Tbody>
                            {
                                list.length && list.map(csv => (
                                    <Tr>
                                        <Td>{csv.label}</Td>
                                        <Td>
                                            {
                                                !csv.data?.extraData?.headers && <CsvHeaders channel={channel} csvUrl={csv.data.url} orgToken={orgToken} onHeaderSelect={onHeaderSelect} csvId={csv.value} selectedHeader={csv?.data?.extraData?.mapped_headers[0]?.index || ''}></CsvHeaders>
                                            }
                                            {
                                                !_.isEmpty(csv.data?.extraData?.headers) && <Select size={'xs'} value={csv?.data?.extraData?.mapped_headers[0].index || ''} onChange={(e) => onHeaderSelect(e.target.value, csv.value, csv?.data?.extraData, true)}>
                                                    <option value={''}>Select header</option>
                                                    {
                                                        !!csv?.data?.extraData && csv?.data?.extraData?.headers.map((e, index) => (
                                                            <option value={index + 1}>{e}</option>
                                                        ))
                                                    }
                                                </Select>
                                            }
                                        </Td>
                                        <Td>{!!csv.data?.extraData?.validationData ? csv.data.extraData.validationData.counts.total_rows : '-'}</Td>
                                        <Td>{!!csv.data?.extraData?.validationData ? csv.data.extraData.validationData.counts.estimated_reach : '-'}</Td>
                                        <Td>{!!csv.data?.extraData?.validationData ? csv.data.extraData.validationData.counts.dirty_rows : '-'}</Td>
                                        <Td>
                                            {
                                                !csv.data?.reValidate && !!csv.data?.extraData?.validationData && <OutlinedButton label={'Validated'} colorScheme={'success'} size={'xs'}></OutlinedButton>
                                            }
                                            {
                                                !csv.data?.reValidate && !csv.data?.extraData?.validationData && <OutlinedButton label={'Validate'} size={'xs'} isLoading={csv?.data?.isLoading} onClick={() => onValidate(csv.data, csv.value)}></OutlinedButton>
                                            }
                                            {
                                                !!csv.data.reValidate && !!csv.data?.extraData?.validationData && <OutlinedButton label={'Revalidate'} size='xs' onClick={() => onValidate(csv.data, csv.value)} isLoading={csv?.data?.isLoading}></OutlinedButton>
                                            }

                                        </Td>
                                    </Tr>
                                ))
                            }

                        </Tbody>

                    </Table>


                </ModalBody>
                <ModalFooter>
                    <PrimaryButton label={'Update List'} onClick={confirmCsvValidation}></PrimaryButton>
                </ModalFooter>
            </ModalContent>

        </Modal>

    )
}

export default CsvValidatorModal
