import { createSlice } from "@reduxjs/toolkit";

export const orgReducer = createSlice({
    name: 'org',
    initialState: {
        orgList: [],
        isLoading: true
    },
    reducers: {
        SET_ORG_LIST: (state, action) => {
            state.orgList = action.payload.orgList;
            state.isLoading = false
        }
    }
})

export const {SET_ORG_LIST} = orgReducer.actions