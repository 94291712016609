import React from 'react';
import { Box, Text, Stack, HStack, Divider } from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';
import PrimaryButton from '../../components/themeComponents/PrimaryButton';
import PlatformWidget from '../../components/themeComponents/PlatformWidget';
import BigCommerceIcon from '../../images/BigCommerce.png';
import { useOutletContext  } from 'react-router-dom';

export default function BigCommerce() {
  const { t } = useTranslation();
  const { integration } = useOutletContext()
  const WidgetContent = () => (
    <Stack justifyItems={'space-around'}>
      <Text>{t('integrate.bigcommerce.flow')}</Text>
      <PrimaryButton height={'4vh'} fontSize={'0.8vw'} width={'12vw'} label={t('download.guide')} borderRadius={'1vw'} />
    </Stack>
  );

  let listArr = [
    t('automate.omnichannel.flows'),
    t('improve.personalisation'),
    t('access.segmentation')
  ];

  return (
    <>
      <Box bg={'#fff'} borderRadius={'1vw'} display={'flex'} flexDirection={'column'} p={'4rem'} justifyContent={'center'} alignItems={'center'}>
        <Box maxW={'100%'} alignItems={'start'} display={'flex'} flexDirection={'column'} gap={'1vh'} width={'100%'}>
          <PlatformWidget
            platform={{
              title: t('connect.store.account', { integrationName: integration.entityName }),
              list: listArr,
            }}
            icon={BigCommerceIcon}
            WidgetContent={WidgetContent}
            styles={{ height: 'fit-content', width: '25vw' }}
          />

          <Text fontSize={'xl'} pl={'3%'} color={'#AD88F1'}>{t('steps.integrate.bigcommerce')}</Text>
          <HStack paddingLeft={'6%'} gap={'1vw'} width={'100%'}>
            <Divider border={'1px solid #dadada'} orientation='vertical' height={'20vh'} />
            <Stack>
              <Text>{t('step1.download.guide')}</Text>
              <Text>{t('step2.follow.steps')}</Text>
              <Text>{t('step3.api.calls')}</Text>
              <Text>{t('queries.contact.orbis')}</Text>
            </Stack>
          </HStack>
        </Box>
      </Box>
    </>
  );
}
