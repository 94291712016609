import React, { useState, useRef, useEffect } from 'react'
import hljs from "highlight.js/lib/core";
import javascript from "highlight.js/lib/languages/javascript";
import "highlight.js/styles/monokai.css";
import { Box } from '@chakra-ui/react';
import { HiOutlineClipboardCopy, HiOutlineClipboardCheck } from 'react-icons/hi';
import store from '../../store/store';
import { useOutletContext } from 'react-router-dom';
hljs.registerLanguage("javascript", javascript);

function JSMapper() {
    const { integration, isProduction } = useOutletContext()
    const codeRef = useRef(null);
    console.log(integration)
    const [copyIcon, setCopyIcon] = useState(true);
    let url = `${process.env.REACT_APP_API_ENDPOINT}`
    console.log(url, "urlll")

    let copyToClipBoard = () => {
        navigator?.clipboard?.writeText(codeRef.current.innerText);
        setCopyIcon(false);
        setTimeout(() => {
            setCopyIcon(true);
        }, 1000)
    }

    useEffect(() => {
        console.log(integration)
        console.log()
        !!integration?.miscProperties.trackerDomain && hljs.highlightBlock(codeRef.current);
    }, [integration?.miscProperties.trackerDomain]);

    return (
        <>
            <Box maxW={'40vw'} boxShadow={' 0 3px 10px rgb(0 0 0 / 0.2)'} >
                <pre style={{ background: "#fff", display: 'flex', flexWrap: 'wrap', justifyContent: 'end', padding: '1rem', borderRadius: '2px' }}>
                    <Box
                        title='Tap to copy'
                        onClick={copyToClipBoard}
                        size={'1vw'}
                        position={'absolute'}

                        cursor={'pointer'}
                        as={copyIcon ? HiOutlineClipboardCopy : HiOutlineClipboardCheck}
                    />
                    {!!integration?.miscProperties?.trackerDomain &&
                        <code
                            className="javascript"
                            style={{
                                fontSize: '0.7vw',
                                backgroundColor: '#fff',

                                padding: '0.5rem',
                                borderRadius: '2px'
                            }}
                            ref={codeRef}
                        >
                            {`<script>
    (function(f,t,r,c,k){
    var a,m;f['BaseFtObject']=k;f[k]=f[k]||function(){
        (f[k].q=f[k].q||[]).push(arguments)},f[k].l=1*new Date();
        f[k].h=c;a=t.createElement(r),m=t.getElementsByTagName(r)[0];
        a.async=1;a.src=c;m.parentNode.insertBefore(a,m)})
    (window,document,'script','//${url}/${integration?.entityName}.wl${isProduction() ? '.compressed.js' : '.js'}','${integration?.entityName}');
    ${integration?.entityName} ('configure', '${store.getState().auth?.selectedOrg?.token}');
    </script>
    `}
                        </code>
                    }
                </pre>
            </Box>
        </>
    )
}

export default JSMapper;