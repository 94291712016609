import React from 'react';
import { FormControl, FormLabel, Input, VStack, FormErrorMessage, Select } from '@chakra-ui/react';
import { useFormikContext,Field } from 'formik'; // Import useFormikContext from Formik
import { smsChannelGatwayType } from '../../../_helpers/data_helper';
const TrustSignal = ({ values, index, setGatewayData }) => {
  const { errors, touched, setFieldValue } = useFormikContext(); // Access setFieldValue from Formik context

  const handleChange = (field, event) => {
    setFieldValue(`credentials[${index}].${field}`, event.target.value);
    setGatewayData(`credentials[${index}]`, { ...values, [field]: event.target.value }); // Send data back to parent
  };

  return (
    <VStack spacing={4} alignItems="flex-start">
     <FormControl id="apiKey" mb={4} isInvalid={errors.apiKey && touched.apiKey}>
      
        <FormLabel>API Key</FormLabel>
        <Field
        as={Input} 
        placeholder="apiKey"
          name={`credentials[${index}].apiKey`}
          value={values.apiKey}
          onChange={(e) => handleChange('apiKey', e)}
        />
        <FormErrorMessage>{errors.apiKey}</FormErrorMessage>
      </FormControl>

      <FormControl id="apiKey" isInvalid={errors.senderId && touched.senderId}>
        <FormLabel>Source/Sender ID</FormLabel>
        <Field
        as={Input} 
        placeholder="senderId"
          name={`credentials[${index}].senderId`}
          value={values.senderId}
          onChange={(e) => handleChange('senderId', e)}
        />
        <FormErrorMessage>{errors.senderId}</FormErrorMessage>
      </FormControl>

      <FormControl id='gatewayType' isInvalid={errors.gatewayType && touched.gatewayType}>
        <FormLabel>Gateway Type</FormLabel>
        <Select
          name={`credentials[${index}].gatewayType`}
          value={values.gatewayType}
          onChange={(e) => handleChange('gatewayType', e)}
        >
          <option value="">Select Gateway Type</option>
          {smsChannelGatwayType.map((option) => (
            <option key={option.key} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
        <FormErrorMessage>{errors.gatewayType}</FormErrorMessage>
      </FormControl>

      <FormControl id='route' isInvalid={errors.route && touched.route}>
        <FormLabel>Route</FormLabel>
        <Field
        as={Input} 
        placeholder="Route"
          name={`credentials[${index}].route`}
          value={values.route}
          onChange={(e) => handleChange('route', e)}
        />
        <FormErrorMessage>{errors.route}</FormErrorMessage>
      </FormControl>
    </VStack>
  );
};

export default TrustSignal;
