import React, { useState } from 'react'
import { Formik, Form, Field } from 'formik';
import { Box, FormControl, FormLabel, Text, Input, Button, FormErrorMessage } from '@chakra-ui/react';
import PrimaryButton from '../../components/themeComponents/PrimaryButton';
import { addOrganizationSchema } from '../../_helpers/validationSchema';
import { addOrganization } from '../../Services/AccountService';
import { useToast } from '@chakra-ui/react';
const initialValues = {
    orgRealName: '',
    domain: '',
};
export default function AddOrganization() {
    const[loading,setLoading]=useState(false)
    const toast = useToast();
    const onSubmit = (values, { setSubmitting }) => {
        setLoading(true);

        addOrganization(values)
            .then((res) => {
                console.log(res.data);
                toast({
                    title: "Organization added.",
                    description: "The new organization has been added successfully.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
            })
            .catch((err) => {
                console.log(err);
                toast({
                    title: "Error adding organization.",
                    description: "There was an error adding the new organization. Please try again.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            })
            .finally(() => {
                setLoading(false);
                setSubmitting(false);
            });
        }
    return (

        <Box p={4}>
            <Text fontSize={'xl'} >Add a new domain to your account.</Text>
            <Text fontSize={'sm'} >Please note that the new domain will require new settings and new billing.</Text>
            <Formik
                initialValues={initialValues}
                validationSchema={addOrganizationSchema}
                onSubmit={onSubmit}
            >
                {(formikProps) => (
                    <Form>
                        <Box backgroundColor={"#fff"} mt={'1rem'} maxW={"35vw"} height={'fit-content'} boxShadow={'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'} p={"2rem"} width={'100%'} borderRadius={"2px"} >
                            
                            <Field name="orgRealName">
                                {({ field, form }) => (
                                    <FormControl isInvalid={form.errors.orgRealName && form.touched.orgRealName}>
                                        <FormLabel htmlFor="orgRealName">Organization Name</FormLabel>
                                        <Input {...field} id="orgRealName" placeholder="Enter organization name" />
                                        <FormErrorMessage>{form.errors.orgRealName}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>

                            <Field name="domain">
                                {({ field, form }) => (
                                    <FormControl isInvalid={form.errors.domain && form.touched.domain} mt={4}>
                                        <FormLabel htmlFor="domain">Website URL</FormLabel>
                                        <Input {...field} id="domain" placeholder="Enter website URL" />
                                        <FormErrorMessage>{form.errors.domain}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>

                            <PrimaryButton
                                label={'Add Organization'}
                                mt={'1rem'}
                                // isLoading={formikProps.isSubmitting},
                                isLoading={loading}
                                type="submit"
                            />
                        </Box>
                    </Form>
                )}
            </Formik>
        </Box>

    )
}
