import React, { useState, useEffect } from 'react'
import PrimaryButton from '../../components/themeComponents/PrimaryButton'
import { Button, Modal, ModalOverlay, ModalContent, ModalHeader, Grid, ModalFooter, VStack, Input, FormLabel, FormErrorMessage, FormControl, Box, ModalBody, ModalCloseButton, Card, HStack, Text, Heading, CardBody, Image, Stack, CardFooter, Textarea, Divider } from "@chakra-ui/react";
import ImageGallery from '../../components/ImageGallery';
import MobilePreview from '../Campaigns/components/MobilePreview';
export default function AddSampleModal({ isOpen, onClose, formik }) {
    const [isImageGalleryOpen, setIsImageGalleryOpen] = useState(false);
    let setAttachment = (image) => {
        formik.setFieldValue("attachments", image?.mediaFileUrl)
        console.log(formik.values)
        setIsImageGalleryOpen(false)
    }
    let removeAttachment = () => {
        formik.setFieldValue("attachments", "")
    }
    let handleSubmit = () => {
        formik.handleSubmit()
    }
    let replaceVariablesInMessage = (e,i) =>{
        
        formik.setFieldValue("message", formik.values.message.replace(formik.values.variables[i], e.target.value))
    }
    useEffect(() => {
        console.log(formik.values.variables)
    }, [])
    return (
        <>
            <Modal size={'4xl'} isOpen={isOpen} onClose={onClose} >
                <ModalOverlay />
                <ModalContent padding={'1rem'} >
                    <ModalHeader>
                        <Heading fontSize={'xl'} >
                            Add Sample
                        </Heading>

                    </ModalHeader>
                    <ModalCloseButton onClick={onClose} />
                    <ModalBody border={"1px solid #ebebeb"} borderRadius={'8px'} maxH={'700px'} overflowY={'auto'} >
                        <HStack>
                            <VStack alignItems={'baseline'} >
                                <VStack alignItems={'baseline'} justifyContent={'space-around'} h={"7vw"} >

                                    <Text>When submitting a template, please provide examples of the specific content you'd
                                        like included.</Text>
                                    <Text>Avoid including any real user or customer information, and focus solely on sample
                                        content within your examples</Text>
                                </VStack>
                                <FormControl isInvalid={formik.errors.attachments && formik.touched.attachments}>
                                    {<FormLabel>Header
                                        {!!formik.values.attachments && <Text position={'absolute'} onClick={() => removeAttachment()} cursor={'pointer'} left={50} >X</Text>}
                                    </FormLabel>}
                                    {
                                        !!formik.values.attachments ? <Image src={formik.values.attachments} height={"50px"} width={"50px"} /> :
                                            <Input
                                                bg={"#EEEEEE"}
                                                borderRadius={'16px'}
                                                name="attachments"
                                                cursor={'pointer'}
                                                hidden={true}
                                                placeholder={formik.values.attachments || "Choose file JPG or PNG"}
                                                {...formik.getFieldProps('attachments')}
                                            /> &&
                                            // <Text bg={'#ebebeb'} p={'0.6vw'} borderRadius={'16px'} textAlign={'center'} w={'33%'} onClick={() => { setIsImageGalleryOpen(true) }}>Choose JPG or PNG file</Text>
                                            <ImageGallery setImageData={setAttachment} renderComponent={<Text bg={'#ebebeb'} p={'0.6vw'} borderRadius={'16px'} textAlign={'center'} w={'33%'} cursor={'pointer'}>Choose JPG or PNG file</Text>}></ImageGallery>
                                    }
                                    <FormErrorMessage>{formik.errors.attachments}</FormErrorMessage>
                                </FormControl>
                                {/* <ImageGallery setImageData={setAttachment} renderComponent={<Text bg={'#ebebeb'} p={'0.6vw'} borderRadius={'16px'} textAlign={'center'} w={'33%'}>Choose JPG or PNG file</Text>}></ImageGallery> */}
                                <FormControl isInvalid={formik.errors.attachments && formik.touched.attachments}>
                                    <FormLabel>Sample Text</FormLabel>
                                    <Textarea
                                        bg={"#EEEEEE"}
                                        borderRadius={'16px'}
                                        name="sample_body"

                                        placeholder="Enter Sample Text Here"
                                        {...formik.getFieldProps('sample_body')}
                                    />
                                    <FormErrorMessage>{formik.errors.sample_body}</FormErrorMessage>
                                </FormControl>
                                <FormControl isInvalid={formik.errors.attachments && formik.touched.attachments}>
                                    {!!formik.values.variables.length > 0 &&
                                        <FormLabel>Body</FormLabel>
                                    }
                                    {
                                        !!formik?.values?.variables?.length > 0 && formik?.values?.variables?.map((variable, index) => (
                                            <FormControl isInvalid={formik.errors.attachments && formik.touched.attachments}>
                                                <FormLabel>Variable {index + 1}</FormLabel>
                                                <Input
                                                    bg={"#EEEEEE"}
                                                    borderRadius={'16px'}
                                                    name={`variables[${index}]`}
                                                    defaultValue={variable}
                                                    onKeyUp={(e)=>replaceVariablesInMessage(e,index)}

                                                />
                                                <FormErrorMessage>{formik.errors.variables}</FormErrorMessage>
                                            </FormControl>
                                        ))
                                    }
                                    <FormErrorMessage>{formik.errors.sample_body}</FormErrorMessage>
                                </FormControl>
                                <FormControl isInvalid={formik.errors.buttons && formik.touched.buttons}>
                                    {!!formik.values.buttons.length > 0 &&
                                        <FormLabel>Buttons</FormLabel>
                                    }
                                    {
                                        !!formik?.values?.buttons?.length > 0 && formik?.values?.buttons?.map((button, index) => (
                                            <FormControl>
                                                <FormLabel>URL {index + 1} </FormLabel>
                                                <Input
                                                    bg={"#EEEEEE"}
                                                    borderRadius={'16px'}
                                                    name={`buttons[${index}].url`}
                                                    defaultValue={button.url}
                                                    placeholder='Enter Full URL '
                                                />
                                                {/* <FormErrorMessage>{formik.errors.variables}</FormErrorMessage> */}
                                            </FormControl>
                                        ))
                                    }

                                    <FormErrorMessage>{formik.errors.sample_body}</FormErrorMessage>
                                </FormControl>
                            </VStack>
                            <Divider orientation='vertical' position={'relative'} height={'100%'} border={'1px solid #ebebeb'} />
                            {/* <Box position={'relative'} bottom={''} right={'2vw'}  >
                                <MobilePreview sample_body={formik.values.sample_body} buttons={formik.values.buttons} attachment={formik.values.attachments} channel={'whatsapp'} message={formik.values.message} />
                            </Box> */}
                        </HStack>
                    </ModalBody>
                    <ModalFooter>
                        <PrimaryButton label={'Submit'} type={'button'} onClick={() => { handleSubmit() }} />
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}
