import { Formik, Form, Field } from 'formik';
import {
  Text,
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  FormErrorMessage,
} from '@chakra-ui/react';
import PrimaryButton from '../../components/themeComponents/PrimaryButton';
import { PasswordUpdateSchema } from '../../_helpers/validationSchema'
import { updatePassword } from '../../Services/AccountService';

const PasswordUpdateForm = () => {
  const handleSubmit = (values) => {

    updatePassword(values).then((res) => {
      console.log("userInfo: " + values);
    }).catch((err) => {
      console.log(err);
    })

  };

  return (
    <Box backgroundColor={"#fff"} mt={'1rem'} maxW={"35vw"} height={'fit-content'} boxShadow={'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'} p={"2rem"} width={'100%'} borderRadius={"2px"} >
      <Text fontSize={'lg'} >Update Password</Text>
      <Text fontSize={'sm'} > You can update your password here. </Text>
      <Formik
        initialValues={{
          oldPassword: '',
          newPassword: '',
          confirmPassword: '',
        }}
        validationSchema={PasswordUpdateSchema}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <Form>
            <FormControl
              id="oldPassword"
              mt={4}
              isInvalid={
                formikProps.touched.oldPassword &&
                formikProps.errors.oldPassword
              }
            >
              <FormLabel htmlFor="oldPassword">Old Password</FormLabel>
              <Field name="oldPassword" as={Input} type="password" />
              <FormErrorMessage>
                {formikProps.errors.oldPassword}
              </FormErrorMessage>
            </FormControl>

            <FormControl
              id="newPassword"
              isInvalid={
                formikProps.touched.newPassword &&
                formikProps.errors.newPassword
              }
              mt={4}
            >
              <FormLabel htmlFor="newPassword">New Password</FormLabel>
              <Field name="newPassword" as={Input} type="password" />
              <FormErrorMessage>
                {formikProps.errors.newPassword}
              </FormErrorMessage>
            </FormControl>

            <FormControl
              id="confirmPassword"
              isInvalid={
                formikProps.touched.confirmPassword &&
                formikProps.errors.confirmPassword
              }
              mt={4}
            >
              <FormLabel htmlFor="confirmPassword">
                Retype New Password
              </FormLabel>
              <Field name="confirmPassword" as={Input} type="password" />
              <FormErrorMessage>
                {formikProps.errors.confirmPassword}
              </FormErrorMessage>
            </FormControl>

            <PrimaryButton
              label={'Update Password'}
              mt={4}
              isLoading={false}
              type="submit"
            />
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default PasswordUpdateForm;
