import React from 'react';
import { useFormikContext, Field, ErrorMessage } from 'formik';
import { FormControl, FormLabel, Input, VStack, FormErrorMessage } from '@chakra-ui/react';

const Sinch = ({ setGatewayData, index }) => {
  const { errors, touched, values, setFieldValue } = useFormikContext();

  // Handle field changes
  const handleChange = (field, event) => {
    setFieldValue(`credentials[${index}].${field}`, event.target.value);
    setGatewayData(`credentials[${index}]`, { ...values.credentials[index], [field]: event.target.value });
  };

  return (
    <VStack spacing={4} alignItems="flex-start">
      <FormControl id={`appid-${index}`} isInvalid={errors.credentials?.[index]?.appid && touched.credentials?.[index]?.appid}>
        <FormLabel>APPID</FormLabel>
        <Field
          as={Input}
          name={`credentials[${index}].appid`}
          value={values.credentials[index]?.appid || ''}
          onChange={(e) => handleChange('appid', e)}
          placeholder="APPID"
        />
        <FormErrorMessage>{errors.credentials?.[index]?.appid}</FormErrorMessage>
      </FormControl>

      <FormControl id={`pass-${index}`} isInvalid={errors.credentials?.[index]?.pass && touched.credentials?.[index]?.pass}>
        <FormLabel>Pass</FormLabel>
        <Field
          as={Input}
          name={`credentials[${index}].pass`}
          value={values.credentials[index]?.pass || ''}
          onChange={(e) => handleChange('pass', e)}
          placeholder="Pass"
        />
        <FormErrorMessage>{errors.credentials?.[index]?.pass}</FormErrorMessage>
      </FormControl>

      <FormControl id={`name-${index}`} isInvalid={errors.credentials?.[index]?.name && touched.credentials?.[index]?.name}>
        <FormLabel>Name</FormLabel>
        <Field
          as={Input}
          name={`credentials[${index}].name`}
          value={values.credentials[index]?.name || ''}
          onChange={(e) => handleChange('name', e)}
          placeholder="Name"
        />
        <FormErrorMessage>{errors.credentials?.[index]?.name}</FormErrorMessage>
      </FormControl>

      <FormControl id={`senderId-${index}`} isInvalid={errors.credentials?.[index]?.senderId && touched.credentials?.[index]?.senderId}>
        <FormLabel>Sender ID</FormLabel>
        <Field
          as={Input}
          name={`credentials[${index}].senderId`}
          value={values.credentials[index]?.senderId || ''}
          onChange={(e) => handleChange('senderId', e)}
          placeholder="Sender ID"
        />
        <FormErrorMessage>{errors.credentials?.[index]?.senderId}</FormErrorMessage>
      </FormControl>
    </VStack>
  );
};

export default Sinch;
