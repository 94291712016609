import { useQuery } from "react-query";
import { fetchEventNameJson } from "../../../Services/api";
import ShowToastr from "../../../components/themeComponents/ShowToastr";
import FlowEditService from "./FlowEditService";

export const prepareEventNameJson = (res) => {
    let retVal = [];
    !!res && (typeof res !== 'string') && (!!res.length > 0)
        && (() => {
            res.forEach(obj => {
                (obj.key !== 'join') && retVal.splice(retVal, 0, { code: obj.key, name: obj.label });
                (obj.key === 'join') && retVal.splice(retVal, 0, { code: obj.key, name: 'New User' });
            });
        })();
    return retVal;
}

export const getInitialPropsQueries = (query) => {
    let retVal = {
        "EventName": query({
            queryFn: async () => {
                let res = await fetchEventNameJson();
                return prepareEventNameJson(res.data);
            },
            onError: (error) => <ShowToastr title={error.message}></ShowToastr>,
            cacheTime: 0,
            retry: 1,
            enabled: false
        })
    }
    return retVal
}

export const getTemplatesQuery = (query, actionType, path) => {
    let retVal = {
        email: query({
            queryFn: async () => {
                let res = await FlowEditService.getTemplatesForAction(path);
                return res.data
            },
            onError: (error) => <ShowToastr title={error.message}></ShowToastr>,
            cacheTime: 0,
            retry: 1,
            enabled: false
        })
    }
    return retVal['email'];
}