import { Box, Flex, Text } from '@chakra-ui/react'
import React from 'react'

function Engagement({data}) {
    return (
        <Flex direction={'column'} gap={3}>
            {data.map(dt => {
                return (
                    <Box>
                        <Text as={'b'} color={dt?.color} fontSize={'md'}>{dt?.value}%</Text>
                        <Text color={'light'}>{dt?.label}</Text>
                    </Box>
                )
            })}
        </Flex>
    )
}

export default Engagement
