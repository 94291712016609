import React, { useRef, useEffect, Fragment, useState } from 'react'

import { Text, Box, useToken, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, useToast } from '@chakra-ui/react'
import { Helmet } from 'react-helmet';
import RappidService from './helpers/RappidService';
import FlowEditService from './helpers/FlowEditService';
import hexToRgba from 'hex-to-rgba';
import _ from 'lodash';
import useStateRef from 'react-usestateref';
import FlowInspector from './helpers/FlowInspector';
import PrimaryButton from '../../components/themeComponents/PrimaryButton';
import SecondaryButton from '../../components/themeComponents/SecondaryButton';
import SaveFlowModal from './components/SaveFlowModal';
import { ConfimrationAlerts } from '../../components/themeComponents/ConfirmationAlert';
import { saveFlow, fetchRule } from '../../Services/api';
import ShowToastr from '../../components/themeComponents/ShowToastr';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import ReactDOMServer from "react-dom/server"
import { showSwal } from '../../_helpers/helpers';

function FlowEditor({ joint }) {
    let { uuid,actiontype } = useParams();
    console.log(actiontype)
    let searchParams = useSearchParams()
    const rappidPaperContainer = useRef(null);
    const rappidStencilContainer = useRef(null);
    const rappidScrollerContainer = useRef(null);
    const toast = useToast()
    const navigate = useNavigate()
    // const saveButtonRef = useRef(null)
    // const graph = useRef(null);
    const [mainColor, heading] = useToken('colors', ['primary.main', 'heading'])
    const [flowValidation, setFlowValidation, flowValidationRef] = useStateRef(FlowEditService.initialFormValidationData())
    const [formData, setFormData, formDataRef] = useStateRef(Object)
    const [areAllElementsConnected, setAreAllElementsConnected] = useState(true);
    const [selectedElementActionProps, setSelectedElementActionProps, selectedElementActionPropsRef] = useStateRef(null);
    const [formAcionProps, setFormAcionProps, formAcionPropsRef] = useStateRef(FlowEditService.getFormActionProps());
    const [timer, setTimer] = useState(FlowEditService.getTimer())
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [ruleDetails, setRuleDetails] = useState({ ruleName: '', ruleDescription: '', ruleStatus: '' })
    const [actionProps, setActionProps] = useState({
        initialShape: FlowEditService.getInitialActionProps(),
        conditionShape: FlowEditService.getConditionShapeProps(),
        delayShape: FlowEditService.getDelayShapeProps(),
        emailShape: FlowEditService.getEmailShape(),
        smsShape: FlowEditService.getSmsShape(),
        whatsappShape: FlowEditService.getWhatsappShape()

    })
    let getElementShapeForPaper = (element) => {
        element.attr('body/refWidth', '100%');
        element.attr('body/refHeight', '100%');
        if (element.attributes.type === 'condition') {
            element.attr('label/transform', 'matrix(1,0,0,1,8,75)')
        } else {
            element.attr('label/transform', 'matrix(1,0,0,1,70,30)');
        }
        element.resize(70, 70);
        element.removeAttr('./data-tooltip');

        /*SETTING SHAPE LABEL ON PAPER AND AFTER FETCHING TEMPLATE*/
        window.jQuery(`[model-id=${element.id}] [joint-selector='label'] tspan`).text(element.get('actionData').labelText);

        /*REMOVING TOOLTIP ON PAPER*/
        window.jQuery(`[model-id=${element.id}]`).removeAttr('data-tooltip');
        return element;
    };
    let dragStartClone = el => {
        let newElement = el.clone();
        newElement.removeAttr('./data-tooltip');
        return newElement;
    };
    let dragEndClone = el => {
        let newElement = el.clone();
        newElement = getElementShapeForPaper(newElement)
        return newElement;
    };
    let initResetVars = () => {
        setSelectedElementActionProps(undefined)
    }
    let validateConnection = (cellViewS, magnetS, cellViewT, magnetT, end, linkView) => {
        console.log("in validate connection")
        // Incompatible type check
        let isIncompatible = (!!cellViewS.model.attributes.actionData && !!cellViewT.model.attributes.actionData
            && !!cellViewS.model.attributes.actionData.elementType && !!cellViewT.model.attributes.actionData.elementType
            && cellViewS.model.attributes.actionData.connectableShapeTypes.indexOf(cellViewT.model.attributes.actionData.elementType) < 0)

        // Prevent linking from input ports.
        if (magnetS && magnetS.getAttribute('port-group') === 'in') return false;

        // Prevent linking from output ports to input ports within one element.
        if (cellViewS === cellViewT) return false;

        if (!cellViewT.model.isElement()) return false;
        // Prevent linking with incompatible type
        if (isIncompatible) {
            setAreAllElementsConnected(!isIncompatible)
            //areAllElementsConnected = !isIncompatible
            return false
        }
        // Prevent linking with already linked ports
        if (!!cellViewT && !!cellViewT.model && cellViewT.model.id && !!formData[cellViewT.model.id]
            && formData[cellViewT.model.id].previousElementIds && formData[cellViewT.model.id].previousElementIds.length > 0) return false;

        else {
            setAreAllElementsConnected(true)
        }
        // Prevent linking to input ports.
        return magnetT && magnetT.getAttribute('port-group') === 'in';
    };
    let validateMagnet = (cellView, magnet) => {
        // Note that this is the default behaviour. Just showing it here for reference.
        // Disable linking interaction for magnets marked as passive (see below `.inPorts circle`).

        return magnet.getAttribute('magnet') !== 'passive';
    };

    let canDrag = (elementView, evt, groupName) => {
        if (elementView.model.get('actionData').elementType === FlowEditService.elementTypes.initial
            && formDataRef.current.hasOwnProperty('beginsWith')) {
            return false;
        }
        return elementView.model.get('actionData').actionType !== 'comingsoon';
        //return true
    };
    let setNextActionCondition = (linkView) => {
        let tempFormData = { ...formDataRef.current };
        tempFormData[linkView.targetView.model.id].actionData.elementType === FlowEditService.elementTypes.condition && (() => {
            tempFormData[linkView.targetView.model.id].nextActionCondition.criteria[0] = { property: linkView.sourceView.model.get('actionData').actionType, operator: 'Equals', value: [] }
            setFormData(tempFormData);
            // setFormData(Object.assign(formData, {}))
        })()

    };
    let pointerUpFunc = (cellView) => {
        initResetVars();

        // We don't want a Halo for links.
        if (cellView.model instanceof joint.dia.Link) return;

        var halo = RappidService.getHalo(cellView, joint)
        halo.render();
        let tempSelectedActionProps = { ...selectedElementActionPropsRef.current };
        tempSelectedActionProps = cellView.model.get('actionData');
        tempSelectedActionProps['id'] = cellView.model.id;
        setSelectedElementActionProps(tempSelectedActionProps);
        // setSelectedElementActionProps(cellView.model.get('actionData'));
        // setSelectedElementActionProps(Object.assign(selectedElementActionPropsRef.current, { id: cellView.model.id }));

        !!selectedElementActionPropsRef.current.templatesFetchUrl && console.log("fetch templates");
        highlightElements();
    };
    const resetInvalidFormReasons = () => {
        console.log("at reset", FlowEditService.initialFormValidationData())
        setFlowValidation(FlowEditService.initialFormValidationData());
        return true;
    }
    const setInvalidFormDataReason = (id, reason) => {
        let tempFlowValidation = { ...flowValidationRef.current };
        let elementType = !!id ? formDataRef.current[id].actionData.elementType : '';
        !id && (tempFlowValidation.reasons.genericReasons = _.union(tempFlowValidation.reasons.genericReasons, [reason]));
        !!id
            && (() => {
                tempFlowValidation.reasons.elementReasons[id] = { elementId: id, elementType: elementType, reason: reason }
            })();
        tempFlowValidation.isFormValid = !areAllElementsConnected;
        setFlowValidation(tempFlowValidation)
    }
    const validateInitial = (id) => {
        console.log("validate initial", id)
        for (var i = 0; i < formDataRef.current[id].nextActionCondition.criteria.length; i++) {
            !(!!formDataRef.current[id].nextActionCondition.criteria[i].property
                && !!formDataRef.current[id].nextActionCondition.criteria[i].operator
                && !!formDataRef.current[id].nextActionCondition.criteria[i].value.length > 0
                && !!formDataRef.current[id].nextActionCondition.criteria[i].value[0])
                && setInvalidFormDataReason(id, 'Start should have a valid condition.')
        }
    }
    const validateFormData = (id, criterion) => {
        console.log("idid", id)
        if (!!criterion) {
            criterion.value = [];
        }
        !id && (id = 'beginsWith');
        !formDataRef.current['beginsWith'] && (resetInvalidFormReasons()) && setInvalidFormDataReason('', 'Flow should begin with a start condition');
        if (!id) return
        if (!formDataRef.current[id]) return;
        if (!formDataRef.current[id].nextElementIds) return;
        console.log(formDataRef.current)
        !!(formDataRef.current[id].actionData.elementType === FlowEditService.elementTypes.initial) && (resetInvalidFormReasons());


        !!(formDataRef.current[id].actionData.elementType === FlowEditService.elementTypes.initial) && validateInitial(id);

        //Validating Delay
        !!(formDataRef.current[id].actionData.elementType === FlowEditService.elementTypes.delay) && (() => {
            formDataRef.current[id].wait.duration < 0 && setInvalidFormDataReason(id, 'Delay should be greater than or equal to 0.');
        })();

        //Validating condition
        !!(formDataRef.current[id].actionData.elementType === FlowEditService.elementTypes.condition) && (() => {
            !(!!formDataRef.current[id].nextActionCondition.criteria[0].property
                && !!formDataRef.current[id].nextActionCondition.criteria[0].operator
                && !!formDataRef.current[id].nextActionCondition.criteria[0].value.length > 0
                && !!formDataRef.current[id].nextActionCondition.criteria[0].value[0])
                && setInvalidFormDataReason(id, 'Enter a valid condition.');
        })();

        //Validating Action
        !!(formDataRef.current[id].actionData.elementType === FlowEditService.elementTypes.action) && (() => {
            !formDataRef.current[id].action.templateUUID && setInvalidFormDataReason(id,
                `${formDataRef.current[id].actionData.labelText} action should contain ${formDataRef.current[id].actionData.actionType} template`);
        })()

        formDataRef.current[id].nextElementIds.length > 0 && formDataRef.current[id].nextElementIds.forEach(elementId => {
            validateFormData(elementId);
        });

        formDataRef.current[id].nextElementIds.length < 1 && formDataRef.current[id].actionData.elementType !== 'action' && setInvalidFormDataReason('', 'Flow should end with an action');
        !(formDataRef.current['beginsWith'] && !!formDataRef.current['beginsWith']['nextElementIds'] && formDataRef.current['beginsWith']['nextElementIds'].length > 0)
            && setInvalidFormDataReason('', 'Flow should begin with a start condition');

        highlightElements();
    }
    const highlightElements = () => {
        //console.log("in highlights")

        highlightInvalidElements();
        highlightActiveElement();
        highlightWarningElements();


    }
    let highlightInvalidElements = () => {

        let invalidElementIds = [];
        Object.keys(flowValidationRef.current.reasons.elementReasons).forEach(key => {
            if (!!flowValidationRef.current.reasons.elementReasons[key].elementId) {
                invalidElementIds.push(flowValidationRef.current.reasons.elementReasons[key].elementId);
            }
        });

        Object.keys(formDataRef.current).forEach(key => {

            (typeof undefined !== key) && !!key && !!formDataRef.current[key]
                && (() => {
                    let imgPath = FlowEditService.elementTypeDefaultImages[formDataRef.current[key]?.actionData?.actionType];
                    if (invalidElementIds.indexOf(key) > -1) {
                        imgPath = FlowEditService.elementTypeErrorImages[formDataRef.current[key]?.actionData?.actionType];
                    }
                    window.$(`g[model-id=${formDataRef.current[key].elementId}] image`).attr('xlink:href', imgPath);
                })();
        });
    };
    const highlightActiveElement = () => {
        !!selectedElementActionPropsRef.current && !!selectedElementActionPropsRef.current.id
            && window.$(`g[model-id=${selectedElementActionPropsRef.current.id}] image`).attr('xlink:href', FlowEditService.elementTypeActiveImages[selectedElementActionPropsRef.current.actionType]);
    }
    const highlightWarningElements = () => {
        Object.keys(formDataRef.current).forEach(key => {
            !!formDataRef.current[key]?.previousElementIds.length < 1 && !!formDataRef.current[key]?.nextElementIds.length < 1
                && window.$(`g[model-id=${formDataRef.current[key].elementId}] image`).attr('xlink:href', FlowEditService.elementTypeWarningImages[formDataRef.current[key]?.actionData?.actionType]);
        });
    };
    let setDelayInAction = (delayId, actionId) => {
        let tempFormData = { ...formDataRef.current };
        !!actionId
            && (() => {
                tempFormData[delayId] && !!tempFormData[actionId] && (tempFormData[actionId].wait = { ...tempFormData[delayId].wait });
                !tempFormData[delayId] && (tempFormData[actionId].wait = {
                    duration: 0,
                    formInputs: {
                        delayDuration: 'minutes',
                        delayValue: 0
                    },
                    type: 'delay'
                });
                setFormData(tempFormData)
            })();
    };
    let handleLinkRemoval = (link) => {
        let sourceId = link.source().id;
        let targetId = link.target().id;

        !!formDataRef.current[sourceId] && !!formDataRef.current[targetId]
            && formDataRef.current[sourceId].actionData.elementType === FlowEditService.elementTypes.delay
            && formDataRef.current[targetId].actionData.elementType === FlowEditService.elementTypes.action
            && !!targetId && setDelayInAction('', targetId);

        window.$(`[port=${link.source().port}]`).attr('magnet', true);
    };
    let setNextAndPreviousIds = (sourceViewId, targetViewId) => {
        let tempFormData = { ...formDataRef.current };
        tempFormData[sourceViewId].nextElementIds = _.union(tempFormData[sourceViewId].nextElementIds, [targetViewId]);
        tempFormData[targetViewId].previousElementIds = _.union(tempFormData[targetViewId].previousElementIds, [sourceViewId]);
        setFormData(tempFormData)
    };
    let setFormDataFromGraph = (graph) => {
        /*EMPTY ALL THE PREVIOUS AND NEXT ELEMENT IDs BEFORE PROCEEDING*/
        let tempFormData = { ...formDataRef.current }
        Object.keys(tempFormData).forEach((key, index) => {
            tempFormData[key].nextElementIds = [];
            tempFormData[key].previousElementIds = [];
            if (index === Object.keys(tempFormData).length - 1) {
                setFormData(tempFormData);
            }
        })

        graph.getLinks().forEach(link => {
            let sourceCell = link.getSourceCell();
            let targetCell = link.getTargetCell();
            !!sourceCell && !!targetCell && !!targetCell.id
                && (() => {
                    let sourceCellId = sourceCell.get('actionData').elementType === FlowEditService.elementTypes.initial ? 'beginsWith' : sourceCell.id;
                    setNextAndPreviousIds(sourceCellId, targetCell.id);
                })();

            /*IF SOURCE CELL IS DELAY AND TARGET CELL IS AN ACTION. SETTING DELAY IN THE ACTION FOR BACKEND*/
            sourceCell.get('actionData').elementType === FlowEditService.elementTypes.delay
                && !!targetCell && !!targetCell.get('actionData') && targetCell.get('actionData').elementType === FlowEditService.elementTypes.action
                && setDelayInAction(sourceCell.id, targetCell.id);
        });
    };
    const initializeListeners = (paper, graph, stencil) => {

        paper.on('cell:pointerup', function (cellView) {
            console.log("cell:pointerup");
            pointerUpFunc(cellView)
        });
        paper.on("link:pointerup", function (linkView, evn, x, y) {
            console.log("linkView, evn, x, y", linkView, evn, x, y)
            initResetVars();
            !(!!linkView.targetView && !!linkView.sourceView) && (() => {
                linkView.remove();
                graph.removeCells(graph.getCell(linkView.model.id))
            })();
            (!!linkView.targetView && !!linkView.sourceView) && (() => {
                //console.log(formData)
                /*ON CREATING LINK WE NEED TO CHECK IF THE TARGET VIEW IS A CONDITION. IF SO THEN WE NEED TO TELL CONDITION ABOUT ITS PREVIOUS ELEMENT*/
                setNextActionCondition(linkView);

                /*LINK ALL THE PREVIOUS AND NEXT ELEMENTS*/
                setFormDataFromGraph(graph);

                /*SET MAGNETS TO PASSIVE IF THE CONNECTION IS MADE*/
                linkView.sourceMagnet.setAttribute('magnet', 'passive');

                /*IF THE PORT-GROUP OF SOURCE MAGNET IS NOT OUT, THEN IT HAS TO BE EITHER THE YES PORT OR THE NO PORT. SO WE NEED TO ASSOCIATE WHICH ACTION IS TO BE TAKEN AND WHEN*/
                (linkView.sourceMagnet.getAttribute('port-group') !== 'out')
                    && (() => {
                        let tempFormData = { ...formDataRef.current };
                        tempFormData[linkView.targetView.model.id].triggerAt = linkView.sourceMagnet.getAttribute('port-group');
                        tempFormData[linkView.sourceView.model.id]['nextTriggeredElements'][linkView.sourceMagnet.getAttribute('port-group')] = linkView.targetView.model.id;
                        setFormData(tempFormData);
                    })();
                validateFormData('beginsWith')

            })();

        });
        paper.on("blank:pointerup", function (elementView) {
            initResetVars();
            validateFormData('beginsWith');

        });
        graph.on("add", function (cell, collection, opt) {
            setAreAllElementsConnected(cell.isLink());
            if (!cell.isLink()) {
                setFlowValidation({ ...flowValidationRef.current, ...{ isFormValid: false } });
                !!cell && cell.id && !!cell.attributes && !!cell.attributes.actionData && !!cell.attributes.actionData.actionType
                    && (() => {
                        let formDataCellId = cell.attributes.actionData.actionType === FlowEditService.elementTypes.initial
                            ? 'beginsWith'
                            : cell.id;
                        let tempFormData = { ...formDataRef.current };
                        tempFormData[formDataCellId] = FlowEditService.getFormDataForElement(cell.attributes.actionData, cell.id);
                        setFormData(tempFormData);
                        //setFormData(Object.assign(formDataRef.current, { [formDataCellId]: FlowEditService.getFormDataForElement(cell.attributes.actionData, cell.id) }))
                    })()
            }
            FlowEditService.addCss();
            /*VALIDATE THE FORM AND HIGHLIGHT THE ELEMETS*/
            validateFormData('beginsWith')
        });
        graph.on("remove", function (cell, opt) {
            console.log("on remove");
            let removedCellId = cell.id;
            !!cell.isLink() && handleLinkRemoval(cell);
            formDataRef.current.hasOwnProperty("beginsWith") && formDataRef.current.beginsWith.elementId === cell.id && (removedCellId = 'beginsWith');
            setAreAllElementsConnected(!cell.isLink());
            setFormData(function (prevState) {
                return _.omit(formDataRef.current, removedCellId)
            })
            validateFormData('beginsWith')
            setFormDataFromGraph(graph);
            initResetVars()
            validateFormData('beginsWith')


        })
        paper.on('blank:pointerup', (linkView, evn, x, y) => {
            console.log('blank:pointerup')
            initResetVars();
            validateFormData("beginsWith");

        });
        let removeUnwantedLinks = () => {
            let linksArray = graph.getLinks();
            if (!!linksArray && linksArray.length > 0) {
                linksArray.forEach(link => {
                    if (!(!!link.target() && !!link.target().id)) {
                        graph.removeCells(graph.getCell(link.id));
                    }
                });
            }
        };
        const saveButton = document.getElementById("saveButtonRef");
        saveButton.addEventListener("click", function (e) {
            console.log("after button click", paper);
            removeUnwantedLinks();
            localStorage.setItem("graphData", JSON.stringify(graph));
            onOpen()
        })
    }
    const onSave = (formikIntance) => {
        const { ruleName, ruleDescription, timer } = formikIntance.values;
        const data = FlowEditService.prepareFlowData(ruleName, ruleDescription, formDataRef.current, formAcionPropsRef.current, timer);
        
        // const dateTime = new Date(currentDateTime).getTime() / 1000;
        
    Object.keys(data.formData).forEach((key,i) => {
      if(data.formData[key].wait.hasOwnProperty('segmentProperty')){
        data.formData[key].wait.triggerTime = Date.parse(data.formData[key].wait.triggerTime);
        
      }
      
    })
    
        showSwal(Swal, "Are you sure?", `Do you want to ${!!uuid && !actiontype  ? 'update' : 'create'} the flow?`, true, true, `${!!uuid && !actiontype ? 'Update' : 'Create'}`, 'No', (async () => {
            const saveResponse = await saveFlow(data, uuid, searchParams[0].has("edit-recipe") , actiontype ).catch(error => {
                console.log(error)
                !!error && toast({ title: 'Oops', description: `Something went wrog while ${!!uuid && !actiontype ? 'updating' : 'creating'} the flow. Please try again.`, status: 'error' });
                formikIntance.setSubmitting(false)
                Swal.close()
            })
            if (!_.isEmpty(saveResponse)){
                toast({ title: `Flow ${!!uuid && !actiontype ? 'Updated' : 'Created'}.`, description: `We've ${!!uuid && !actiontype? 'updated' : 'created'} the flow for you.`, status: 'success' });
                navigate("/flows/list");
            } 
        }), (res) => {
            !!res.isConfirmed && (() => {
                formikIntance.setSubmitting(false)
                Swal.close()
            })()
        })
    }
    const updateFormData = (data) => {
        setFormData(data)
    }
    const updateFormActionProps = (data) => {
        setFormAcionProps(data);
    }
    let setGraphPaperElements = (graph) => {
        graph.getElements().forEach(el => {
            el = getElementShapeForPaper(el);
        });
    };
    let setResponseToFormData = (res) => {
        setFormData(res.formData);
        setRuleDetails({ ruleName: res.ruleName, ruleDescription: res.ruleDescription, ruleStatus: res.ruleStatus });
        !!res.timer && setTimer(res.timer);
        !!res.actionProps?.goal?.hasGoal && (() => {
            res.actionProps.goal.eventValue = res.actionProps.goal.eventValue.map(ev => {
                return { id: ev, text: ev }
            })
        })()
        !!res.actionProps && setFormAcionProps(res.actionProps);
        console.log("formActionPropsRef", formAcionPropsRef.current)
        !!res.actionProps.isScheduled && (() => {
            let tempActionProps = { ...formAcionPropsRef.current };
            //tempActionProps.scheduler['']
        })()


    }
    let setRecipeResponseToFormData = (res) => {
        setFormData(JSON.parse(res.formData));
        setRuleDetails({ ruleName: res.ruleName, ruleDescription: res.ruleDescription, ruleStatus: res.ruleStatus });
        !!res.timer && setTimer(res.timer);
        !!res.actionProps && (res.actionProps = JSON.parse(res.actionProps));
        !!res.actionProps?.goal?.hasGoal && (() => {
            res.actionProps.goal.eventValue = res.actionProps.goal.eventValue.map(ev => {
                return { id: ev, text: ev }
            })
        })()
        !!res.actionProps && setFormAcionProps(res.actionProps);
    }
    const notValidRule = () => {
        toast({ title: 'Not Found', description: 'Not a valid rule', status: 'error' });
        navigate(-1)
    }
    useEffect(() => {
        let rappidParams = {
            joint,
            stencilContainer: rappidStencilContainer.current,
            paperContainer: rappidPaperContainer.current,
            validateConnection, validateMagnet, canDrag, dragStartClone, dragEndClone, color: hexToRgba(mainColor, .3)
        }
        const { paper, graph, paperScroller, snaplines, tooltip, stencil } = RappidService.getRappidDeps(rappidParams);
        joint.setTheme('modern');
        let initialize = () => {
            if (!!rappidPaperContainer.current && !!rappidStencilContainer.current && !!rappidScrollerContainer.current) {
                // Initialize Rappid.js

                let shapes = FlowEditService.prepareShapes(actionProps, joint);
                stencil.load({
                    initial: [shapes.initialShape],
                    condition: [shapes.conditionShape],
                    delay: [shapes.delayShape],
                    actions: [shapes.emailShape, shapes.smsShape, shapes.whatsappShape]

                })
                window.jQuery(`#rappid-stencil-container .joint-port circle, #rappid-stencil-container .joint-port text, .joint-port-label`).css('display', 'none')
                initializeListeners(paper, graph, stencil)
            }
        };
        const fetchFlow = async () => {
            let flowResponse;
            if (searchParams[0].has("edit-recipe")) {
                if (_.has(localStorage, 'recipeData')) {
                    flowResponse = localStorage.getItem('recipeData');
                    flowResponse = { data: JSON.parse(flowResponse) }
                } else return notValidRule()
            } else {
                flowResponse = await fetchRule(uuid);
                if (_.isEmpty(flowResponse.data)) return notValidRule()
            }

            !_.isEmpty(flowResponse.data) && !!flowResponse.data.formData && !!flowResponse.data.graphData && !!flowResponse.data.uuid && (() => {
                initialize();
                !!searchParams[0].has("edit-recipe") ? graph.fromJSON(JSON.parse(flowResponse.data.graphData)) : graph.fromJSON(flowResponse.data.graphData);
                setGraphPaperElements(graph);
                FlowEditService.addCss();
                searchParams[0].has("edit-recipe") ? setRecipeResponseToFormData(flowResponse.data) : setResponseToFormData(flowResponse.data);
                validateFormData("beginsWith")
            })()
        }
        !uuid ? initialize() : fetchFlow()

    }, []);
    return (
        <Fragment>
            <Box w={'100%'} h={'100%'} mt={20} pos={'relative'}>


                <Box ref={rappidStencilContainer} pos={'absolute'} w={'15vw'} left={0} h={'calc(100vh - 5rem)'} top={0} bottom={'0px'}></Box>
                <Box ref={rappidScrollerContainer} pos={'absolute'} left={'15vw'} overflow={'auto'} right={'20vw'} top={'1px'} h={'calc(100vh - 5rem)'} w={'calc(100% - 35vw)'} bg={hexToRgba(mainColor, .3)}>
                    <Box ref={rappidPaperContainer} id='rappid-paper-container'></Box>
                </Box>
                <Box pos={'absolute'} w={'20vw'} right={0} h={'calc(100vh - 5rem)'} overflow={'auto'} p={3} pb={'5rem'}>
                    {
                        <FlowInspector formData={formDataRef.current} formValidation={flowValidationRef.current} selectedElement={selectedElementActionPropsRef.current} updateFormData={updateFormData} formAcionProps={formAcionPropsRef.current} validateFormData={validateFormData} updateFormActionProps={updateFormActionProps}></FlowInspector>
                    }
                    <Box position={'absolute'} bottom="10px" right={"20px"}>
                        <PrimaryButton label={'Next'} isDisabled={!flowValidationRef.current.isFormValid} id={"saveButtonRef"}>

                        </PrimaryButton>
                    </Box>
                </Box>

                <Modal isOpen={isOpen} onClose={onClose} size={'2xl'} closeOnEsc={false} closeOnOverlayClick={false}>
                    <SaveFlowModal formActionProps={formAcionPropsRef.current} onSave={onSave} scheduler={formAcionPropsRef.current.scheduler} updateFormActionProps={updateFormActionProps} timer={timer} ruleName={ruleDetails.ruleName} ruleDescription={ruleDetails.ruleDescription} uuid={uuid} actiontype={actiontype} ></SaveFlowModal>
                </Modal>
            </Box>
        </Fragment>
    )
}

export default FlowEditor
