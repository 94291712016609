import React, { useEffect } from 'react';
import { Button, Image } from '@chakra-ui/react';
import { loginWithFacebook } from '../../Services/api';
const FacebookLoginButton = () => {


  const handleFBLogin = async () => {
        let res = await loginWithFacebook();
        console.log(res)
        if(!!res.data.authUrl){
            window.open(res.data.authUrl,"_self");
        }
  };

  return (
    <Button 
      onClick={handleFBLogin} 
      colorScheme="facebook" 
      leftIcon={<Image src="https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_(2019).svg" boxSize="20px" alt="FB Logo" />}
    >
      Login with Facebook
    </Button>
  );
};

export default FacebookLoginButton;
