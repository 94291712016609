import { Modal, Box, ModalOverlay, ModalContent, ModalCloseButton, ModalHeader, ModalBody } from '@chakra-ui/react'
import React from 'react'

function TemplatePreviewModal({ isOpen, onClose, tempBody }) {
    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'xl'} scrollBehavior='inside'>
            <ModalOverlay></ModalOverlay>
            <ModalContent w={'auto'} maxW={'700px'}>
                <ModalHeader fontSize={'sm'}>Template Preview</ModalHeader>
                <ModalCloseButton onClick={onClose} ></ModalCloseButton>
                <ModalBody>
                    <Box dangerouslySetInnerHTML={{ __html: !!tempBody ? tempBody : '<b>No Preview</b' }} w={'100%'}></Box>
                </ModalBody>
            </ModalContent>

        </Modal>
    )
}

export default TemplatePreviewModal
