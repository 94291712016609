    import { Grid, GridItem, Text, Box, Container, useToken, useToast, Flex, Tooltip } from '@chakra-ui/react'
    import React, { Fragment, useEffect, useState } from 'react';
    import { connect } from "react-redux"
    import DateRangeSelection from '../components/DateRangeSelection';
    import Widget from '../components/themeComponents/Widget';
    import { CiMoneyBill } from "react-icons/ci";
    import { BiMoneyWithdraw } from "react-icons/bi";
    import { MdOutlineCampaign } from "react-icons/md";
    import { FiShoppingCart } from "react-icons/fi";
    import { BarChart, DougnutCanvas, PieCanvas } from '../components/Charts';
    import { useQuery, useQueryClient } from 'react-query';
    import { getWidgetsData, getCampaignRevenue, getEvents, getSubscribersCount, getRevenueBreakdown, getRevenueStats } from '../Services/api';
    import { OvalSpinner } from '../components/Loaders';
    import { prepareRevenueBreakdownData, prepareEventsChartData, prepareSubscribersCountData, prepareAllEvents, prepareDefaultsEvents, prepareRevenueStats } from '../_helpers/data_helper';
    import hexToRgba from 'hex-to-rgba';
    import _ from 'lodash';
    import moment from 'moment';
    import { useLocation } from 'react-router-dom';
    import store from '../store/store';
    import Select from '../components/themeComponents/react-chakra-select';
    import { useSelector } from 'react-redux';

    function Dashboard({ org }) {
        
        const white_label = useSelector((state) => state.auth.white_label)||sessionStorage.getItem('white_label');
        // console.log(white_label,">>label black")
        // console.log(white_label.data?.entityName,"entityName")
        // console.log(white_label.entityName,">>>????")
        // const entityName=white_label.entityName?.toUpperCase()||white_label.data?.entityName?.toUpperCase();
   
        const entityName=white_label?.entityName||white_label.data?.entityName




        const [stripTable, primary, heading] = useToken('colors', ['stripTable', 'primary.main', 'heading']);
        const [dateRange, setDateRange] = useState({ startDate: moment().subtract(15, "days"), endDate: moment() })
        const queryClient = useQueryClient();
        const [allEvents, setAllEvents] = useState([]);
        const [selectedEvents, setSelectedEvents] = useState([]);
        const [subscribersData, setSubscribersData] = useState([
            { label: 'Email', value: 321, color: '#65a6fa' },
            { label: 'Phone', value: 345, color: '#00cadc' },
            { label: 'Push', value: 211, color: '#1cbbf3' }
        ])
        const toast = useToast();
        const widgetQuery = useQuery(['revenueData', dateRange], {
            queryFn: async () => {
                return getWidgetsData(org.token, dateRange);

            },
            onError: (error) => toast({ title: error?.message || 'Something went wrong' }),
            cacheTime: 0,
        })
        const campaignRevenueQuery = useQuery(['campaignRevenue', dateRange], {
            queryFn: async () => {
                let res = await getCampaignRevenue(org.token, dateRange);
                return prepareRevenueBreakdownData(res?.data)
            },

            onError: (error) => toast({ title: error?.message || 'Something went wrong' }),
            onSuccess: (fetchedData) => {
                console.log('in success funtion', fetchedData)
                const modifiedData = prepareRevenueBreakdownData(fetchedData.data);
                return modifiedData;
            },
            cacheTime: 0
        })
        const eventsQuery = useQuery(['event', dateRange], {
            queryFn: async () => {
                let res = await getEvents(org.token, dateRange);
                setAllEvents(prepareAllEvents(res?.data));
                setSelectedEvents(prepareDefaultsEvents(res?.data));
                return prepareEventsChartData(res?.data)
            },
            onError: (error) => toast({ title: error?.message || 'Something went wrong while fetching the events.' }),
            // cacheTime: 0,
            retry: 0,
            enabled: false
        })

        const subscribersQuery = useQuery(['subscribersCount', dateRange], {
            queryFn: async () => {
                let res = await getSubscribersCount(org.token, dateRange);
                console.log(prepareSubscribersCountData(res?.data))
                return prepareSubscribersCountData(res?.data)
            },
            cacheTime: 0,
            retry: 0
        })

        const revenueBreakdownQuery = useQuery(['revenueBreakdown', dateRange], {
            queryFn: async () => {
                let res = await getRevenueBreakdown(org.token, dateRange);
                return res.data
            },
            cacheTime: 0,
            retry: 0
        })

        const revenueStatsQuery = useQuery(['revenueStats', dateRange], {
            queryFn: async () => {
                let res = await getRevenueStats(org.token, dateRange);
                return prepareRevenueStats(res?.data)
            },
            cacheTime: 0,
            retry: 0
        })
        function onDateChange(date) {
            setDateRange({ startDate: date.startDate, endDate: date.endDate })
        }
        const handleEventChange = (selectedValues, e) => {
            setSelectedEvents(selectedValues);
            queryClient.setQueryData(['event'], (data) => {
                console.log(data);
                return data
            })

        }
        useEffect(() => {
            eventsQuery.refetch();
        }, [])
        return (

            <Fragment>
                <DateRangeSelection dateRange={dateRange} onDateChange={onDateChange} isLoading={subscribersData.isLoading || eventsQuery.isLoading || widgetQuery.isLoading || widgetQuery.campaignRevenueQuery}></DateRangeSelection>
                <Container marginInlineEnd={'unset'} marginInlineStart={'unset'} maxW={'unset'} m={'0'} w={'100%'} >
                    <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} my={4}>
                        <Text as={'b'} fontSize={'xl'}>Dashboard</Text>
                    </Box>
                    <Grid templateColumns={'repeat(4, 1fr)'} gap={'0.8vw'} w={'100%'} mb={'1vw'}>
                        <GridItem w={'100%'}>
                            <Widget value={widgetQuery?.data?.data?.total_Revenue} title={'Revenue by ' + entityName} icon={CiMoneyBill} isLoading={widgetQuery.isFetching || widgetQuery.isLoading}></Widget>
                        </GridItem>
                        <GridItem w={'100%'}>
                            <Widget value={widgetQuery?.data?.data?.average_orders_value} title={'Order value per Customer'} icon={BiMoneyWithdraw} isLoading={widgetQuery.isFetching || widgetQuery.isLoading}></Widget>
                        </GridItem>
                        <GridItem w={'100%'}>
                            <Widget value={widgetQuery?.data?.data?.orders} title={'Orders by ' + entityName} icon={FiShoppingCart} isLoading={widgetQuery.isFetching || widgetQuery.isLoading}></Widget>
                        </GridItem>
                        <GridItem w={'100%'}>
                            <Widget value={widgetQuery?.data?.data?.campaign_sent} title={'Campaign Sent'} icon={MdOutlineCampaign} isLoading={widgetQuery.isFetching || widgetQuery.isLoading}></Widget>
                        </GridItem>
                    </Grid>
                    <Grid gridTemplateColumns={'1fr 1fr 1fr'} mt={'0.6vw'} gap={"1vw"}>
                        <GridItem>
                            <Box bg={'white'} p={'0.8vw'} maxH={'300px'} minH={'15vw'} height={'100%'} pos={'relative'}>
                                <Text as={'b'} color={'heading'} fontSize={'0.9vw'}  >Revenue Breakdown</Text>
                                {
                                    revenueBreakdownQuery.isLoading && <Box pos={'absolute'} right={'1vw'} top={'1vw'}>
                                        <OvalSpinner height={20} width={20} color={primary}></OvalSpinner>
                                    </Box>
                                }
                                <DougnutCanvas 
                                dataPoints={campaignRevenueQuery?.data?.chartData}
            ></DougnutCanvas>
                            </Box>
                        </GridItem>

                        <GridItem>
                            <Box bg={'white'} p={'0.8vw'} maxH={'300px'} minH={'15vw'} height={'100%'} pos={'relative'}>
                                {
                                    campaignRevenueQuery.isLoading && <Box pos={'absolute'} right={'1vw'} top={'1vw'}>
                                        <OvalSpinner height={20} width={20} color={primary}></OvalSpinner>
                                    </Box>
                                }
                                <Text as={'b'} color={'heading'} fontSize={'0.9vw'}>Top Perfoming Campaigns</Text>
                                <PieCanvas dataPoints={campaignRevenueQuery?.data?.bestPerformingCampaigns} >
                                </PieCanvas>
                            </Box>
                        </GridItem>
                        <GridItem>
                            <Box bg={'white'} p={3} maxH={'300px'} minH={'15vw'} height={'100%'} pos={'relative'}>
                                {
                                    subscribersQuery.isLoading && <Box pos={'absolute'} right={'1vw'} top={'1vw'}>
                                        <OvalSpinner height={20} width={20} color={primary}></OvalSpinner>
                                    </Box>
                                }
                                <Text as={'b'} fontSize={'0.9vw'} color={'heading'}>Subscribers Count</Text>
                                {
                                    !(subscribersQuery?.data && subscribersQuery?.data?.length && subscribersQuery?.data[0]?.totalSubscribers) &&
                                    <Box h={'100%'} w={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'}><Text>No Data to display</Text></Box>
                                }
                                {
                                    !!(subscribersQuery?.data && subscribersQuery?.data?.length && subscribersQuery?.data[0]?.totalSubscribers) &&
                                    <Flex h={'11vw'} w={'100%'} gap={'1vw'} px={'1vw'} mt={'1vw'}>

                                        {
                                            subscribersQuery?.data.map(sub => {
                                                return <Box w={sub.width ? sub.width + '%' : 0} h={'100%'} bg={sub.color} rounded={3} p={'1vw'} display={'flex'} flexDir={'column'} transition={'all .3s ease-in-out'}>
                                                    <Text as={'b'} color={hexToRgba(heading, .5)} fontSize={'xs'}>{sub.label}</Text>
                                                    <Tooltip hasArrow label={sub.label + ': ' + sub.value} placement='top' p={'1vw'}>
                                                        <Box w={'100%'} h={'100%'} bg={hexToRgba('#fff', .15)} cursor={'pointer'} display={'grid'} placeItems={'center'}>
                                                            <Text pos={'absolute'} color={hexToRgba(heading, .6)}>{sub.value}</Text>
                                                        </Box>
                                                    </Tooltip>
                                                </Box>
                                            })
                                        }

                                    </Flex>
                                }
                            </Box>
                        </GridItem>
                    </Grid>
                    <Grid gridTemplateColumns={'1fr 1fr'} gap={'1vw'} mt={'1vw'}>
                        <GridItem>
                            <Box bg={'white'} height={'100%'} p={'1vw'} maxH={'400px'} minH={'15vw'}>
                                <Text as={'b'} color={'heading'} fontSize={'0.9vw'} >Revenue</Text>
                                <BarChart series={[
                                    {
                                        name: "",
                                        data: revenueStatsQuery.data
                                    }
                                ]} ></BarChart>
                            </Box>
                        </GridItem>
                        <GridItem >
                            <Box bg={'white'} height={'100%'} p={'1vw'} maxH={'400px'} minH={'15vw'}>
                                <Text as={'b'} color={'heading'} fontSize={'0.9vw'} >Events</Text>
                                {/* <Box>
                                    <Text as={'b'} fontSize={'0.8vw'} color={'heading'}>Events</Text>
                                    
                                    {
                                        allEvents.length && <Select isMulti options={allEvents} isClearable={false} closeMenuOnSelect={true}
                                            value={selectedEvents} onChange={handleEventChange}></Select>
                                    }

                                </Box> */}
                                <BarChart series={[{ name: "", data: eventsQuery?.data }]} ></BarChart>
                            </Box>
                        </GridItem>
                    </Grid>
                </Container>

            </Fragment >

        )
    }

    const mapStateToProps = (state) => {
        return {
            ...state,
            org: state.auth.selectedOrg
        }
    }
    export default connect(mapStateToProps)(Dashboard)
