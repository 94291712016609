import React, { useState } from 'react'
import { Box, Icon, Input, InputGroup, InputLeftElement, Table, TableContainer, Text, useDisclosure, Select, FormControl, FormLabel, useToken, Flex, HStack, border } from '@chakra-ui/react'
import TableHead from '../Campaigns/components/TableHead'
import { Fragment } from 'react'
import OrderTable from './OrderTable'
import OrderStats from './OrderStats'
import { exportCSV, getOrders } from '../../Services/api';
import { useEffect } from 'react'
import * as _ from 'lodash'
import { useQuery } from 'react-query'
import { abbreviateNumber } from 'js-abbreviation-number'
import PrimaryButton from '../../components/themeComponents/PrimaryButton'
import Swal from 'sweetalert2'
import { formik, useFormik } from 'formik'
import { TagsInput } from "react-tag-input-component";
import Confirmation from '../../components/themeComponents/Confirmation'
import { Formik, Field, Form, ErrorMessage } from 'formik';
import hexToRgba from 'hex-to-rgba'
import { Pagination, PaginationContainer, PaginationNext, PaginationPage, PaginationPageGroup, PaginationPrevious, usePagination } from '@ajna/pagination'
import { useSelector } from 'react-redux'
import ExportCSV from './ExportCSV'
const Orders = () => {


    const [orderData,setOrderData]=useState([])
    console.log(orderData,"OD")
    const [showAlert, setShowAlert] = useState(false);
    const [email, setEmail] = useState('');
    const [openMenuIndex, setOpenMenuIndex] = useState(null);
    const [mainColor] = useToken('colors', ['primary.main'])
    const [totalCount, setTotalCount] = useState(0)
    
    const { currentPage, setCurrentPage, pagesCount, pages } = usePagination({ total: totalCount, initialState: { currentPage: 1, pageSize: 5, isDisabled: false }, limits: { outer: 1, inner: 1 } })
    
    const selectedOrgData = localStorage.getItem("selectedOrg");
    const selectedOrg = JSON.parse(selectedOrgData);
    const orgId = selectedOrg.id;

    const fetchOrderData = useQuery(
      ['orders', currentPage], 
      async () => {
        const res = await getOrders(orgId, currentPage);
        setTotalCount(res.data.order_aggregate[0]._total_count); 
        return res.data;
      },
      {
       
        enabled: true 
      }
    );
 
    let totalGrossRevenue;
    if (fetchOrderData && Array.isArray(fetchOrderData?.data?.order_sum)) {
      totalGrossRevenue = abbreviateNumber(_.sum(fetchOrderData.data.order_sum.map(e => e.total_gross_revenue)));
      console.log(abbreviateNumber(totalGrossRevenue), "totalGrossRevenue");
    }
    let totalDiscounts;
    if (fetchOrderData && Array.isArray(fetchOrderData?.data?.order_sum)) {
      totalDiscounts = abbreviateNumber(_.sum(fetchOrderData.data.order_sum.map(e => e.total_discounts)));
      console.log(abbreviateNumber(totalDiscounts), "totalGrossRevenue");
    }
    let totalReturns;
    if (fetchOrderData && Array.isArray(fetchOrderData?.data?.order_sum)) {
      totalReturns = abbreviateNumber(_.sum(fetchOrderData.data.order_sum.map(e => e.total_refunded)));
      console.log(abbreviateNumber(totalReturns), "totalGrossRevenue");
    }

    const orderStatsData={
        "Orders": !fetchOrderData.isLoading && fetchOrderData?.data?.order_aggregate[0]._total_count,
        "Gross Revenue":totalGrossRevenue,
        "Total Discounts":totalDiscounts,
        "Returns":totalReturns
    }
    const handleClick=()=>{
      console.log("Email ",email)
      console.log("clicked")
    }
    const downloadcsv=async(orgId,email)=>{
      try {
        const data=await exportCSV(orgId,email)
        console.log("data in email",data)
        
      } catch (error) {
        console.log("Error in exporting CSV",error)
        
      }
    }
  return (
    <>
    <Box>
      <Flex justifyContent={"end"}>

      <PrimaryButton onClick={() => setShowAlert(true)} label={"Export CSV"}/>
      {/* <Confirmation isOpen={showAlert} title={'Confirm Email'} text="
            Confirm email address to receive the CSV file" confirmButtonText={'Yes'} cancelButtonText={'Cancel'}  onCancel={() => { setShowAlert(false) }}
            onConfirm={async () => {
              await downloadcsv(orgId, email)
              setShowAlert(false)
          }}
            
            >
          <input
           style={{
            width: "70%",
            padding: "10px", 
            margin:"20px",
            border: "1px solid #ccc", 
            borderRadius: "4px", 
            fontSize: "16px", 
            boxShadow: "inset 0 1px 3px rgba(0,0,0,0.1)",
            transition: "border-color 0.2s ease, box-shadow 0.2s ease", 
            outline: "none", 
          }}
          type="email"
          name='email'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
          />
        </Confirmation> */}
        <ExportCSV isOpen={showAlert} onConfirm={handleClick} title={'Export CSV'} text="
            Export orders to CSV" confirmButtonText={'Export'} cancelButtonText={'Cancel'}  onCancel={() => { setShowAlert(false) }}
        downloadcsv={downloadcsv}
            />
     
      </Flex>
        
    </Box>
    <OrderStats  orderData={orderStatsData} loading={fetchOrderData.isLoading}>

    </OrderStats>
    <TableContainer bg={'white'} maxH={'60vh'} w={'99%'} overflowY={'auto'} mt={6} >
        {/* {
          campaignQuery.isLoading && <Flex justifyContent={'center'} alignItems={'center'} h={'200px'}><OvalSpinner width={50} height={50}></OvalSpinner></Flex>
        } */}
        <Table >
          {
             <Fragment>
              <TableHead headersProps={['Order Id', 'Email', 'Order Date', 'Gross Revenue', 'Shipping Cost', 'Discount', 'Gross Profit','Gross Margin','State','Data Source','Channel','Campaign','Source','Device']}></TableHead>
              <OrderTable orderData={fetchOrderData} loading={fetchOrderData.isLoading} ></OrderTable>
            </Fragment>
          }
        </Table>

      </TableContainer>
      {
          !fetchOrderData.isLoading && <Pagination pagesCount={pagesCount} currentPage={currentPage} onPageChange={setCurrentPage} >
            <PaginationContainer alignItems={'center'} justifyContent="end" mt={2}>
              <PaginationPrevious  mr={2} w={8} bg={hexToRgba(mainColor, .4)} p={1} fontSize={'xs'} height={8} minW={8}>&lt;</PaginationPrevious>
              <PaginationPageGroup height={8}  >
                {
                  pages.map(page => (
                    <PaginationPage key={`pagination_page_${page}`} page={page} w={10} bg={hexToRgba(mainColor, .4)} p={1} fontSize={'xs'} height={8} minW={8} isDisabled={currentPage === page}></PaginationPage>
                  ))
                }
              </PaginationPageGroup>
              <PaginationNext ml={2} w={8} bg={hexToRgba(mainColor, .4)} p={1} fontSize={'xs'} height={8} minW={8}>&gt;</PaginationNext>
            </PaginationContainer>
          </Pagination>
        }
    
    </>
  )
}

export default Orders
