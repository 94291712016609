import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import {
    FormControl,
    FormLabel,
    Input,
    Button,
    Textarea,
    Text,
    Heading,
    Select,
    VStack,
    Box,
    HStack,
    Divider
} from '@chakra-ui/react';
import { mediaType, buttonData, templateTypes, whatsappCategories } from '../../_helpers/data_helper';
import MobilePreview from '../Campaigns/components/MobilePreview';
import PrimaryButton from '../../components/themeComponents/PrimaryButton';
import OutlinedButton from '../../components/themeComponents/OutlinedButton';
import { useToast } from '@chakra-ui/react';
import { applyWhatsappTemplate, postWhatsappTemplate, updateWhatsappTemplate } from '../../Services/api';
import Swal from 'sweetalert2';
import { useLocation, useNavigate } from 'react-router-dom';
import Confirmation from '../../components/themeComponents/Confirmation';
import { whatsappTemplateValidation } from '../../_helpers/validationSchema';
import { FormErrorMessage } from '@chakra-ui/react';
import { HiOutlineSpeakerphone } from "react-icons/hi";
import { Helmet } from 'react-helmet';
import ImageGallery from '../../components/ImageGallery';
import { useDisclosure } from '@chakra-ui/react';
import AddSampleModal from './AddSampleModal';
import { whatsappChannelAccess } from '../../Services/api';
import { Link } from 'react-router-dom';
const initialValues = {
    mediaType: '',
    templateName: '',
    message: '',
    language: 'en',
    templateType: '',
    templateId: '',
    templateDescription: '',
    attachments: '',
    variables: [],
    variablesCount: 0,
    buttons: [],
    templateDisplayName: '',
    sample_url: '',
    sampleBody: '',
    footer: '',
    category: '',
    header_content: '',
    sample_header_content: '',
    uuid: ''
};

const WhatsappTemplate = () => {
    const [imageData, setImageData] = useState({})
    const location = useLocation()
    const toast = useToast()
    const [showAlert, setShowAlert] = useState(false);
    const navigate = useNavigate();
    const {isOpen, onOpen, onClose} = useDisclosure();
    const[checkAccess,setCheckAccess]=useState(null);
    console.log(checkAccess,">>check access");
    const handleSubmit = async (values, type) => {
        console.log(formik.errors)
        let saveResponse = null;
        if (location?.state?.uuid) {
            try {

                values.variables = JSON.parse(values.variables)
            } catch (e) {

            }
            saveResponse = await updateWhatsappTemplate(values, location.state.uuid).catch(error => {
                console.log(error)
                !!error && toast({ title: 'Oops', description: `Something went wrong while updating the template. Please try again.`, status: 'error' });
                formik.setSubmitting(false)
                Swal.close()
            });
        } else {
            if (type == 'apply') {
                saveResponse = await applyWhatsappTemplate(values).catch(error => {
                    console.log(error)
                    !!error && toast({ title: 'Oops', description: `Something went wrong while updating the template. Please try again.`, status: 'error' });
                    formik.setSubmitting(false)
                    Swal.close()
                });
            } else {
                saveResponse = await postWhatsappTemplate(values).catch(error => {
                    console.log(error)
                    !!error && toast({ title: 'Oops', description: `Something went wrong while updating the template. Please try again.`, status: 'error' });
                    formik.setSubmitting(false)
                    Swal.close()
                });
            }
        }
        if (saveResponse) {
            setShowAlert(true)
        }
    };
    const removeButton = (idx) => {
        formik.setFieldValue("buttons", formik.values.buttons.filter((x, i) => i != idx))
    }
    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        onSubmit: handleSubmit,
        validationSchema: whatsappTemplateValidation
    });
    const ctaBtnObject = { "type": "url", "url": "", "url_type": "", "button_name": "", "sample_url": "", "quick_reply": "" }
    const quickReplyBtnObject = { "type": "quick_reply", "url": "", "url_type": "", "button_name": "", "sample_url": "", "quick_reply": "" }
    // const addVariables = () => {
        

    //     let regexForVars = /{{([^{}]*)}}/g
    //     console.log(formik.values.message,"message before test")
    //     if (regexForVars.test(formik.values.message)) {
    //         console.log(formik.values.message,"message after test")
    //         let variables = formik.values.message.match(regexForVars)
    //         formik.setFieldValue("message", formik.values.message + `{{$${variables.length + 1}}}`)
    //         formik.setFieldValue("variableCount", variables.length)
    //         formik.setFieldValue("variables",variables)
    //         console.log(variables,"final")
    //     }
    //     else {
    //         formik.setFieldValue("variables",["{{$1}}"])
    //         formik.setFieldValue("message", formik.values.message + "{{$1}}")
    //         formik.setFieldValue("variableCount", 1)
            
    //     }
    // }
    const addVariables = () => {
        let regexForVars = /{{([^{}]*)}}/g;
        console.log(formik.values.message, "message before test");
    
        if (regexForVars.test(formik.values.message)) {
            let variables = formik.values.message.match(regexForVars);
    
            let nextVariableIndex = variables.length + 1;
            let newVariable = `{{$${nextVariableIndex}}}`;
    
            let updatedMessage = formik.values.message + newVariable;
    
            formik.setFieldValue("message", updatedMessage);
            formik.setFieldValue("variableCount", nextVariableIndex);
            formik.setFieldValue("variables", [...variables, newVariable]); 
            console.log([...variables, newVariable], "final");
        } else {
            let newVariable = "{{$1}}";
            formik.setFieldValue("message", formik.values.message + newVariable);
            formik.setFieldValue("variables", [newVariable]);
            formik.setFieldValue("variableCount", 1);
        }
    };
    
   
    const addButtons = (type) => {
        if (type.toLowerCase() == 'cta') {
            formik.setFieldValue('buttons', [
                ...formik.values.buttons,
                ctaBtnObject,
            ]);
        } else {
            formik.setFieldValue('buttons', [
                ...formik.values.buttons,
                quickReplyBtnObject,
            ]);
        }
    }
    const checkWhatsappAccess=async ()=>{
        try {
          const response=  await whatsappChannelAccess();
          console.log(response,"response");
          setCheckAccess(response.data.hasWhatsAppAccess);

            
        } catch (error) {
            console.log("Error while checking Whatsapp Access", error);
            
            
        }
    }
    const openImageGallery = () =>{
        
    }
    useEffect(() => {

        console.log(imageData.mediaFileUrl)
        if (!!location?.state?.uuid) {

            try {
                location.state.buttons = JSON.parse(location.state.buttons)
                location.state.variables = JSON.parse(location.state.variables)
            } catch (e) {
                return
            }
            formik.setValues(location?.state)
        }
        
    }, [])
    useEffect(()=>{
        checkWhatsappAccess()

    },[])
    return (
        <HStack p={4} >
            {checkAccess==true? (<>
                <Box width={'60%'}>
                <Heading as="h3" size="lg" mb={4}>
                    Create Whatsapp Template
                </Heading>
                
                <Text>Template Information</Text>
                <Divider />
                <form onSubmit={formik.handleSubmit}>
                    <VStack
                        // boxShadow={'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'}
                        w={'100%'}
                        mt={'2vw '}
                        maxW={'55vw'}

                        p={'1.5vw'}
                        spacing={4}
                    >
                        <HStack w={'100%'}>
                            <FormControl isInvalid={formik.touched.templateName && formik.errors.templateName}>
                                <FormLabel>Template Name*</FormLabel>
                                <Input
                                    bg={"#EEEEEE"}
                                    borderRadius={'16px'}
                                    name="templateName"
                                    placeholder="Enter Template Name"
                                    {...formik.getFieldProps('templateName')}
                                />
                                <FormErrorMessage>{formik.errors.templateName}</FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={formik.touched.language && formik.errors.language}>
                                <FormLabel>Language*</FormLabel>
                                <Select
                                    bg={"#EEEEEE"}
                                    borderRadius={'16px'}
                                    name="language" defaultValue="en" {...formik.getFieldProps('language')}>
                                    <option value="en">English</option>
                                    <option value="fr">French</option>
                                    <option value="es">Spanish</option>
                                    <option value="de">German</option>
                                </Select>
                                <FormErrorMessage>{formik.errors.language}</FormErrorMessage>
                            </FormControl>
                        </HStack>

                        <HStack w={'50%'} alignSelf={'baseline'}>
                            <FormControl isInvalid={formik.errors.category && formik.touched.category}>
                                <FormLabel>Category*</FormLabel>
                                <Select
                                    bg={"#EEEEEE"}
                                    borderRadius={'16px'}
                                    name="category"
                                    placeholder="Select Category"
                                    {...formik.getFieldProps('category')}
                                >
                                    {whatsappCategories.map((category, i) => (
                                        <option key={i} value={category.key}>
                                            {category.label}
                                        </option>
                                    ))}
                                </Select>
                                <FormErrorMessage>{formik.errors.category}</FormErrorMessage>
                            </FormControl>
                        </HStack>
                        <HStack w={'100%'} >
                            <HiOutlineSpeakerphone />
                            <Text alignSelf={'start'} > Sample Content is mandatory while creating a template</Text>
                        </HStack>
                        <HStack alignSelf={'start'} w={'50%'}>
                            <FormControl isInvalid={formik.touched.templateType && formik.errors.templateType}>
                                <FormLabel display={'flex'} >Header <Text fontWeight={'400'} >(optional)</Text> </FormLabel>
                                <Select
                                    bg={"#EEEEEE"}
                                    borderRadius={'16px'}
                                    name="templateType"
                                    placeholder="Select Template Type"
                                    {...formik.getFieldProps('templateType')}
                                >
                                    {mediaType.map((mediaType, i) => (
                                        <option key={i} value={mediaType.key}>
                                            {mediaType.label}
                                        </option>
                                    ))}
                                </Select>
                                <FormErrorMessage>{formik.errors.templateType}</FormErrorMessage>
                            </FormControl>
                            {/* {formik.values.templateType !== 'text' && (
                                <FormControl isInvalid={formik.touched.mediaType && formik.errors.mediaType}>
                                    <FormLabel>Media Type</FormLabel>
                                    <Select
                                        bg={"#EEEEEE"}
                                        borderRadius={'16px'}
                                        name="mediaType"
                                        placeholder="Select Media Type"
                                        {...formik.getFieldProps('mediaType')}
                                    >
                                        {mediaType.map((type) => (
                                            <option key={type.key} value={type.key}>
                                                {type.label}
                                            </option>
                                        ))}
                                    </Select>
                                    <FormErrorMessage>{formik.errors.mediaType}</FormErrorMessage>
                                </FormControl>
                            )} */}
                        </HStack>
                        {/* <HStack w={'100%'}> */}
                        {/* <FormControl isInvalid={formik.touched.header_content && formik.errors.header_content}>
                                <FormLabel>Template Header</FormLabel>
                                <Input
                                    bg={"#EEEEEE"}
                                    borderRadius={'16px'}
                                    name="header_content"
                                    placeholder="Enter Template header"
                                    {...formik.getFieldProps('header_content')}
                                />
                                <FormErrorMessage>{formik.errors.header_content}</FormErrorMessage>
                            </FormControl> */}
                        {/* <FormControl isInvalid={formik.errors.header_content && formik.touched.header_content}>
                                <FormLabel>Sample Header</FormLabel>
                                <Input
                                    bg={"#EEEEEE"}
                                    borderRadius={'16px'}
                                    name="sample_header_content"
                                    placeholder="Enter Sample Header Content"
                                    {...formik.getFieldProps('sample_header_content')}
                                />
                                <FormErrorMessage>{formik.errors.header_content}</FormErrorMessage>
                            </FormControl> */}
                        {/* </HStack> */}
                        {/* {
                            formik.values.templateType != 'text' &&
                            <HStack w={'100%'} >
                                <FormControl isInvalid={formik.errors.attachments && formik.touched.attachments}>
                                    <FormLabel>Attachments</FormLabel>
                                    <Input
                                        bg={"#EEEEEE"}
                                        borderRadius={'16px'}
                                        name="attachments"
                                        placeholder="attachment"
                                        {...formik.getFieldProps('attachments')}
                                    />
                                    <FormErrorMessage>{formik.errors.attachments}</FormErrorMessage>
                                </FormControl>
                            </HStack>
                        } */}
                        {/* <HStack alignSelf={'baseline'} w={'50%'} > */}
                        {/* <FormControl isInvalid={formik.errors.sampleBody && formik.touched.sampleBody}>
                                <FormLabel>Sample Body</FormLabel>
                                <Input
                                    bg={"#EEEEEE"}
                                    borderRadius={'16px'}
                                    name="sampleBody"
                                    placeholder="Enter sample body"
                                    {...formik.getFieldProps('sampleBody')}
                                />
                                <FormErrorMessage>{formik.errors.sampleBody}</FormErrorMessage>
                            </FormControl> */}

                        {/* </HStack> */}

                        {/* <HStack w={'100%'}>
                            <FormControl isInvalid={formik.errors.templateDisplayName && formik.touched.templateDisplayName}>
                                <FormLabel>Template Display Name</FormLabel>
                                <Input
                                    bg={"#EEEEEE"}
                                    borderRadius={'16px'}
                                    name="templateDisplayName"
                                    placeholder="Enter Template Name"
                                    {...formik.getFieldProps('templateDisplayName')}
                                />
                                <FormErrorMessage>{formik.errors.templateDisplayName}</FormErrorMessage>
                            </FormControl>
                           
                        </HStack> */}

                        <FormControl isInvalid={formik.errors.message && formik.touched.message}>
                            <FormLabel>Body*</FormLabel>
                            <Text display={'flex'} justifyContent={'end'} textDecoration={'underline'} my={'5px'} onClick={addVariables} cursor={'pointer'} > Add variables+</Text>
                            <Textarea
                            resize={false}
                                bg={"#EEEEEE"}
                                borderRadius={'16px'}
                                name="message"
                                placeholder="Enter Message"
                                {...formik.getFieldProps('message')}
                            />
                            <FormErrorMessage>{formik.errors.message}</FormErrorMessage>
                        </FormControl>
                        <HStack w={'50%'} alignSelf={'baseline'} >
                            <FormControl>
                                <FormLabel display={'flex'} >Footer <Text fontWeight={'400'} >(optional)</Text></FormLabel>
                                <Input
                                    bg={"#EEEEEE"}
                                    borderRadius={'16px'}
                                    name="footer"
                                    placeholder="Enter footer"
                                    {...formik.getFieldProps('footer')}
                                />
                                {/* <FormErrorMessage>{formik.errors.footer}</FormErrorMessage> */}
                            </FormControl>
                        </HStack>
                        {
                            formik.values.templateType != 'text' &&
                            <HStack justifyContent={'start'} w={'100%'} >
                                <Text textDecoration={'underline'} onClick={() => addButtons('cta')} >Add Button+</Text>
                            </HStack>
                        }
                        <FormControl isInvalid={formik.errors.buttons && formik.touched.buttons}>

                            {formik.errors.buttons && <FormErrorMessage>{formik.errors.buttons}</FormErrorMessage>}
                            {!!formik.values.buttons.length && formik.values.buttons.map((button, index) => (
                                <VStack key={index} border="1px solid #E2E8F0" borderRadius="md" p={2} mb={2}>
                                    <Text cursor={'pointer'} onClick={() => removeButton(index)} alignSelf={'end'} >X</Text>
                                    <FormControl>
                                        <FormLabel>Button Type</FormLabel>
                                        <Select
                                            bg={"#EEEEEE"}
                                            borderRadius={'16px'}
                                            value={formik.values.buttons[index]?.type || ''}
                                            onChange={(e) => {
                                                formik.values.buttons[index].type = e.target.value;
                                                formik.setValues({ ...formik.values });
                                            }}
                                        >
                                            <option value="">Select Button Type</option>
                                            <option value="url">URL</option>
                                            <option value="quick_reply">Quick Reply</option>
                                        </Select>
                                    </FormControl>
                                    {formik.values.buttons[index]?.type === 'url' && (
                                        <>
                                            <HStack w={'100%'} >

                                                <FormControl>
                                                    <FormLabel>URL</FormLabel>
                                                    <Input
                                                        bg={"#EEEEEE"}
                                                        borderRadius={'16px'}
                                                        value={formik.values.buttons[index]?.url || ''}
                                                        onChange={(e) => {
                                                            formik.values.buttons[index].url = e.target.value;
                                                            formik.setValues({ ...formik.values });
                                                        }}
                                                    />
                                                </FormControl>
                                                <FormControl>
                                                    <FormLabel>Button Name</FormLabel>
                                                    <Input
                                                        bg={"#EEEEEE"}
                                                        borderRadius={'16px'}
                                                        value={formik.values.buttons[index]?.button_name || ''}
                                                        onChange={(e) => {
                                                            formik.values.buttons[index].button_name = e.target.value;
                                                            formik.setValues({ ...formik.values });
                                                        }}
                                                    />
                                                </FormControl>
                                            </HStack>
                                        </>
                                    )}
                                    {formik.values.buttons[index]?.type === 'quick_reply' && (
                                        <>
                                            <FormControl>
                                                <FormLabel>Quick Reply</FormLabel>
                                                <Input
                                                    bg={"#EEEEEE"}
                                                    borderRadius={'16px'}
                                                    value={formik.values.buttons[index]?.quick_reply || ''}
                                                    onChange={(e) => {
                                                        formik.values.buttons[index].quick_reply = e.target.value;
                                                        formik.setValues({ ...formik.values });
                                                    }}
                                                />
                                            </FormControl>
                                        </>
                                    )}
                                </VStack>
                            ))}
                        </FormControl>

                        <HStack w={'100%'} gap={'5px'} alignSelf={'baseline'} justifyContent={'start'} >
                            <PrimaryButton borderRadius='8px' width='7vw' padding={'2px 10px'} onClick={()=>handleSubmit(formik.values)} label={location?.state?.uuid ? 'Update' : 'Apply'} type={'button'} />
                            <OutlinedButton borderRadius='8px' width='8vw' padding={'2px 10px'} label={'Add Sample'} onClick={()=>{onOpen()}} />
                            {/* <OutlinedButton label={'Save Draft'} type={'submit'} /> */}
                        </HStack>
                    </VStack>
                </form>
            </Box>
            <Divider orientation='vertical' height={'70vh'} border={'1px solid #ebebeb'} position={'absolute'} right={'27vw'} />
            <Box position={'absolute'} bottom={''} right={'6vw'}  >
                <MobilePreview  buttons={formik.values.buttons} attachment={formik.values.attachments} channel={'whatsapp'} message={formik.values.message} />
            </Box></>)
            :
            (
            <>
            <Link to={"/settings/whatsapp"}>
                <Text display="inline">
                    No <Text as="span" fontStyle="italic">Whatsapp Provider</Text> Integrated
                </Text>
            </Link>
            </>)}
            
            {/* <ImageGallery isOpen = {isOpen} onClose={onClose} setImageData={setAttachment} /> */}
            <AddSampleModal isOpen={isOpen} onClose={onClose} formik={formik} />

            <Confirmation isOpen={showAlert} title={'Template Created'} text="Want to create another template?" confirmButtonText={'Yes'} cancelButtonText={'Go to overview'} onConfirm={() => setShowAlert(false)} onCancel={() => { navigate('/whatsapp/list'); setShowAlert(false) }}></Confirmation>
        </HStack>
    );
};

export default WhatsappTemplate;
