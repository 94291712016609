import { Box, Text, Flex, Grid, TableContainer, Table } from '@chakra-ui/react'
import React, { Fragment, useState } from 'react'
import OutlinedButton from '../../components/themeComponents/OutlinedButton'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { getOnsiteNudgeTemplateList } from '../../Services/api';
import TableHead from '../Campaigns/components/TableHead';
import { OvalSpinner } from '../../components/Loaders';
import NudgeTable from './NudgeComponents/NudgeTable';
import axiosInstance from '../../Services/axiosInstance';
export default function NudgeTemplateList() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [totalCount, setTotalCount] = useState(0);
    const [templatePreviewBody, setTemplatePreviewBody] = useState('')
    const nudgeTemplateListQuery = useQuery(['emailTemplateList'], {
        queryFn: async () => {
            let res = await getOnsiteNudgeTemplateList();
            setTotalCount(res?.data?.count);
            return res.data?.items
        },
        cacheTime: 0,
        retry: 1
    })
    const onPreview = (body) => {
        setTemplatePreviewBody(body);
        var iframeDesktop = document.querySelector('#iframeDesktop');
        iframeDesktop.contentWindow.document.open('text/html', 'replace');
        iframeDesktop.contentWindow.document.write(body);
        iframeDesktop.contentWindow.document.close();
    }
    const deleteTemplate = useMutation((uuid) => {
        return axiosInstance.delete(`/rest/v1/nudge/template/${uuid}`)
    }, {
        onSuccess: () => {
            nudgeTemplateListQuery.refetch()
        }
    })
    return (
        <Fragment>
            <Box w={'100%'} h={'100%'} overflow={'hidden'}>
                <Flex justifyContent={'space-between'}>
                    <Text as={'b'} fontSize={'2xl'}>Nudge Templates</Text>
                    <OutlinedButton width={'10vw'} borderRadius={'8px'} label={t('flows.build.own')} onClick={() => navigate("/nudge/layout")} />
                </Flex>
                <Grid templateColumns={'1fr 1fr'} h={'calc(100% - 50px)'}>
                    <Box h={'100%'} overflow={'hidden'} bg={'white'} p={3} borderRight={'1px solid'} borderColor={'gray.200'}>
                        <TableContainer mt={4} overflowY={'auto'} overflow={'auto'} height={'calc(100% - 50px)'}>
                            <Table>
                                {
                                    !!nudgeTemplateListQuery?.data && !nudgeTemplateListQuery?.isLoading && (
                                        <Fragment>
                                            <TableHead headersProps={['Template Name', 'Actions']}></TableHead>
                                            <NudgeTable list={nudgeTemplateListQuery?.data || []} onPreview={onPreview} onDelete={(uuid) => deleteTemplate.mutate(uuid)} nudgeTemplateListQuery={nudgeTemplateListQuery}></NudgeTable>
                                        </Fragment>
                                    )
                                }
                                {
                                    !!nudgeTemplateListQuery?.isLoading && <Box display={'flex'} alignItems={'center'} justifyContent={'center'} minHeight={'200px'}>
                                        <OvalSpinner height={'100px'} width={'100px'}></OvalSpinner>
                                    </Box>

                                }
                            </Table>
                        </TableContainer>
                    </Box>
                    <Box p={3}>
                        {
                            !templatePreviewBody && <Box mb={3} display={'flex'} alignItems={'center'} justifyContent={'center'} w={'100%'} height={'100%'}>
                                <Text>No Template is selected for preview.</Text>
                            </Box>
                        }

                        <iframe id="iframeDesktop" frameborder="0" width="100%" height="100%"></iframe>
                    </Box>
                </Grid>
            </Box>
        </Fragment>
    )
}
