import React, { Fragment, useState, useEffect } from 'react';
import { Tr, Td, Box, Text, Flex, Icon, useToast } from '@chakra-ui/react';
import { FiEdit } from "react-icons/fi";
import { FaTrash } from "react-icons/fa";
import { BsSend } from "react-icons/bs";
import moment from 'moment';
import SendTestModal from '../../components/SendTestModal';
import { useDisclosure } from '@chakra-ui/react';
import Swal from 'sweetalert2';
import { deleteWhatsapptemplate } from '../../Services/api';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';

export default function WhatsappListTable({ list, refetch, setPreviewMsg, previewData }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();
    const navigate = useNavigate();
    const queryClient = useQueryClient(); 
    const [smsList, setSmsList] = useState(list);
    const [templateUuid, setTemplateUuid] = useState("");


    const mutation = useMutation(
        (uuid) => deleteWhatsapptemplate(uuid),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('templates');
                toast({ title: 'Template deleted successfully', status: 'success' });
            },
            onError: (error) => {
                toast({ title: 'Oops!', description: 'Something went wrong: ' + error.message, status: 'error' });
            }
        }
    );

    const deleteTemplate = async (uuid) => {
        try {
            const result = await Swal.fire({
                title: "Are you sure?",
                text: "Do you want to delete the template?",
                showCancelButton: true,
                confirmButtonText: 'Okay',
                cancelButtonText: 'Cancel',
                reverseButtons: true,
            });

            if (result.isConfirmed) {
                console.log('Attempting to delete template with UUID:', uuid);
                
                mutation.mutate(uuid); // Perform deletion

                console.log('Template successfully deleted');
            } else {
                console.log('Cancel button clicked');
            }
        } catch (err) {
            console.error('Error deleting template:', err);
            toast({ title: 'Oops!', description: 'Something went wrong: ' + err.message, status: 'error' });
        } finally {
            Swal.close();
        }
    };

    const setPreviewData = (item) => {
        try {
            item.buttons = JSON.parse(item.buttons);
        } catch (err) {
            console.error('Error parsing buttons JSON:', err);
        }
        setPreviewMsg(item);
    };

    const openTestModal = (uuid) => {
        setTemplateUuid(uuid);
        onOpen();
    };

    useEffect(() => {
        setSmsList(list);
    }, [list]);

    return (
        <Fragment>
            {smsList.length > 0 && smsList.map(item => (
                <Tr key={item.uuid} bg={item?.uuid === previewData?.uuid ? 'gray.200' : ''}>
                    <Td borderRight={'1px solid #ebebeb'} w={'80%'} onClick={() => setPreviewData(item)} cursor={'pointer'}>
                        <Box>
                            <Text cursor={'pointer'} title='Preview'>{item.templateName}</Text>
                            <Text fontSize={'0.6vw'} color={'light'}>{moment(item.createdDate).format("MMM D, YYYY h:mm A")}</Text>
                        </Box>
                    </Td>
                    <Td>
                        <Flex gap={3}>
                            <Icon as={FiEdit} cursor={'pointer'} title='Edit' onClick={() => navigate("/whatsapp/create/", { state: item })} />
                            <Icon as={BsSend} cursor={'pointer'} title='Send Test Whatsapp' onClick={() => openTestModal(item.uuid)} />
                            <Icon as={FaTrash} cursor={'pointer'} title='Delete' onClick={() => deleteTemplate(item.uuid)} />
                        </Flex>
                    </Td>
                </Tr>
            ))}
            <SendTestModal uuid={templateUuid} channel={'Whatsapp'} isOpen={isOpen} onClose={onClose} />
        </Fragment>
    );
}
