import { Box, Button, HStack, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import {FaArrowRight, FaCheck} from "react-icons/fa6"
import { SET_ORG } from '../store/Reducers/AuthReducer'
import { setSelectedOrganization } from '../Services/AuthService'
import OutlinedButton from './themeComponents/OutlinedButton'
import OrganizationList from './OrganizationList'
import Confirmation from './themeComponents/Confirmation'
import { SET_ORG_LIST } from '../store/Reducers/OrgReducer'
import _ from "lodash"
import { getOrgList } from '../Services/api'
import { useNavigate } from 'react-router-dom'
function OrganizationSelectionModal({ orgList, selectedOrg, isOpen, onClose, token }) {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [showAlert, setShowAlert] = useState(false);
    const [organization, setOrganization] = useState({});
    const toast = useToast()
    const onOrgSelect = () => {
        if(organization.token !== selectedOrg.token){
            setShowAlert(false);
            setSelectedOrganization(dispatch, organization);
            onClose();
            navigate("/");
            navigate(0)
        }
    }
    const fetchOrgList = async () => {
        let orgResp = await getOrgList(token).catch(error => {
            toast({title: 'Oops!', description: 'Something went wrong while fetching the org list', status: 'error'});
        })
        
        _.has(orgResp, 'data') && dispatch(SET_ORG_LIST({orgList: orgResp?.data || []}))
        
    }
    useEffect(() => {
       !! _.isEmpty(orgList) &&  fetchOrgList()
    }, [])
    return (
        <Modal isOpen={isOpen} size={'xl'} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <Text>Select Organization</Text>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody pt={0}>
                  <OrganizationList orgList={orgList} selectedOrg={selectedOrg} onOrgSelect={(org) => {setOrganization(org); setShowAlert(true)}}></OrganizationList>
                </ModalBody>
                {/* <ModalFooter>
                    <Button bg={'secondary.main'} color={'white'} mr={2}>
                        Close
                    </Button>
                    <Button bg={'primary.main'} color={'white'}>
                        Select
                    </Button>
                </ModalFooter> */}

            </ModalContent>
            <Confirmation isOpen={showAlert} title={'Select Organization'} text="Are you sure?" confirmButtonText={'Select'} cancelButtonText={'Cancel'} onConfirm={() => onOrgSelect()} onCancel={() => {setOrganization({}); setShowAlert(false)}}></Confirmation>
        </Modal>
    )
}

const mapStateToProps = (state) => {
    console.log("state", state)
    return {
        ...state,
        orgList: state.org.orgList,
        selectedOrg: state.auth.selectedOrg,
        token: state.auth.token
    }
}
export default connect(mapStateToProps)(OrganizationSelectionModal)
