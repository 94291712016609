import React, { Fragment } from 'react'
import { default as MultiSelect } from './themeComponents/react-chakra-select';
import { browserList } from '../_helpers/data_helper';
export default function BrowserDropdown({isMulti, value, onBrowserSelect, ...rest}) {
    const getOptions = () => {
        return browserList.map(list => {
            return {label: list.name, value: list.code}
        })
    }
  return (
   <Fragment>
    {
        !!isMulti && <MultiSelect isMulti options={getOptions()} value={value} onChange={(selectedValues) => onBrowserSelect(selectedValues)} colorScheme='brand' isClearable={false} {...rest}></MultiSelect>
    }
   </Fragment>
  )
}
