import React from 'react'
import { Box, FormControl, FormLabel, Input, Divider, FormErrorMessage, Text, VStack, HStack, Stack } from '@chakra-ui/react';
import { useFormik } from 'formik';
import PrimaryButton from '../../components/themeComponents/PrimaryButton';
import { useOutletContext } from 'react-router-dom';
import PlatformWidget from '../../components/themeComponents/PlatformWidget';
import gorgiasIcon from "../../images/GorgiasLogo.png";
import { MdDownloadForOffline } from "react-icons/md";
import { integrateGorgias } from '../../Services/IntegrationService';
import { useTranslation } from 'react-i18next';

export default function Gorgias() {
  const { integration } = useOutletContext();
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      "mkt-gorgias-api": '',
      "mkt-gorgias-url": '',
      "mkt-gorgias-username": '',
      "mkt-gorgias-support-email": '',
    },
    onSubmit: values => {
      integrateGorgias(values).then(res => {
        console.log(res);
      }).catch(err => {
        console.error(err);
      });
    },
  });

  const WidgetContent = () => (
    <Stack justifyItems={'space-around'}  >
      <Text fontSize={'xl'} width={'60%'} my={'5px'} >{t('gorgias.description')}</Text>
      <PrimaryButton width={'8vw'} marginTop={'5px'} type={'submit'} borderRadius={'8px'} height={'1.8vh'} padding={'1vw 1vw'} fontSize={'0.8vw'} label={'Know More'} rightIcon={<MdDownloadForOffline />} />
    </Stack>
  );

  return (
    <>
      <Box bg={'#fff'} borderRadius={'1vw'} display={'flex'} flexDirection={'column'} width={'100%'} p={'1rem'} justifyContent={'center'} alignItems={'center'} >
        <Box alignItems={'baseline'} display={'flex'} flexDirection={'column'} gap={'1vh'} width={'100%'} >
          <PlatformWidget platform={
            {
              title: t('gorgias.title', { entityName: integration.entityName }),
            }
          }
            icon={gorgiasIcon}
            WidgetContent={WidgetContent}
          />
          <Text fontSize={'1.2vw'} paddingLeft={'3%'} >{t('gorgias.connectGorgias')}</Text>
          <HStack paddingLeft={'6%'} gap={'1vw'} width={'100%'}  >
            <Divider border={'1px solid #dadada'} orientation='vertical' height={'20vh'} />
            <form onSubmit={formik.handleSubmit} style={{width:'50%'}} >
              <VStack spacing={4} align="stretch">
                <FormControl alignItems={'center'} display={'flex'} isInvalid={formik.touched['mkt-gorgias-api'] && formik.errors['mkt-gorgias-api']}>
                  <FormLabel w={'50%'}>{t('gorgias.apiInputLabel')}</FormLabel>
                  <Input
                    name="mkt-gorgias-api"
                    type="text"
                    placeholder={t('gorgias.apiInputLabel')}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values['mkt-gorgias-api']}
                  />
                  <FormErrorMessage>{formik.errors['mkt-gorgias-api']}</FormErrorMessage>
                </FormControl>

                <FormControl alignItems={'center'} display={'flex'} isInvalid={formik.touched['mkt-gorgias-url'] && formik.errors['mkt-gorgias-url']}>
                  <FormLabel w={'50%'}>{t('gorgias.urlInputLabel')}</FormLabel>
                  <Input
                    name="mkt-gorgias-url"
                    type="text"
                    placeholder={t('gorgias.urlInputLabel')}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values['mkt-gorgias-url']}
                  />
                  <FormErrorMessage>{formik.errors['mkt-gorgias-url']}</FormErrorMessage>
                </FormControl>

                <FormControl alignItems={'center'} display={'flex'} isInvalid={formik.touched['mkt-gorgias-username'] && formik.errors['mkt-gorgias-username']}>
                  <FormLabel w={'50%'}>{t('gorgias.usernameInputLabel')}</FormLabel>
                  <Input
                    name="mkt-gorgias-username"
                    placeholder={t('gorgias.usernameInputLabel')}
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values['mkt-gorgias-username']}
                  />
                  <FormErrorMessage>{formik.errors['mkt-gorgias-username']}</FormErrorMessage>
                </FormControl>

                <FormControl alignItems={'center'} display={'flex'} width={'100%'} isInvalid={formik.touched['mkt-gorgias-support-email'] && formik.errors['mkt-gorgias-support-email']}>
                  <FormLabel w={'50%'}>{t('gorgias.supportEmailInputLabel')}</FormLabel>
                  <Input
                    name="mkt-gorgias-support-email"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values['mkt-gorgias-support-email']}
                  />
                  <FormErrorMessage>{formik.errors['mkt-gorgias-support-email']}</FormErrorMessage>
                </FormControl>
                <PrimaryButton label={t('gorgias.integrateButtonLabel')} type={'submit'} borderRadius={'8px'} height={'4vh'} width={'8vw'} />
              </VStack>
            </form>
          </HStack>
        </Box>
      </Box>
    </>
  );
}
