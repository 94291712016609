import React, { useState, useEffect, useRef } from 'react';
import { Box, Textarea, VStack,Text } from '@chakra-ui/react';
import hljs from 'highlight.js/lib/core';
import sql from 'highlight.js/lib/languages/sql';
import 'highlight.js/styles/github.css'; 

hljs.registerLanguage('sql', sql);

const CodeEditor = ({sendQuery}) => {
  const [query, setQuery] = useState('');
  const textareaRef = useRef(null);
  const highlightedRef = useRef(null);

  const handleQueryChange = (e) => {
    
    setQuery(e.target.value);
    sendQuery(e.target.value)
  };

  // Synchronize scrolling between the textarea and highlighted div
  const handleScroll = () => {
    if (highlightedRef.current && textareaRef.current) {
      // Scroll the highlighted div to match the textarea scroll
      highlightedRef.current.scrollTop = textareaRef.current.scrollTop;
      highlightedRef.current.scrollLeft = textareaRef.current.scrollLeft;
    }
  };

  useEffect(() => {
    if (highlightedRef.current) {
      const highlighted = hljs.highlight(query, { language: 'sql' }).value;
      highlightedRef.current.innerHTML = highlighted;
    }
  }, [query]);

  return (
    <VStack spacing={4} align="stretch" width="100%">
      <Text>
        Enter a raw query
      </Text>
      <Box
        position="relative"
        width="100%"
        height="150px"
        borderWidth="1px"
        borderRadius="md"
        overflow="hidden"
        backgroundColor="white"
      >
        {/* This div will display the highlighted SQL syntax */}
        <Box
          ref={highlightedRef}
          as="pre"
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          padding="12px"
          pointerEvents="none"
          whiteSpace="pre-wrap"
          wordWrap="break-word"
          overflow="auto" // Ensure only the highlighted div is scrollable
          style={{
            fontFamily: 'inherit',
            fontSize: 'inherit',
            lineHeight: 'inherit',
            color: 'black', // Ensure the highlighted text is visible
            zIndex: 0,
            margin: 0, // Remove margin for alignment
          }}
        />

        {/* This textarea is used for input, overlaying the highlighted syntax */}
        <Textarea
          ref={textareaRef}
          value={query}
          onChange={handleQueryChange}
          onScroll={handleScroll} // Attach the scroll handler
          placeholder="Type your query here..."
          size="md"
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          padding="12px"
          borderWidth="0"
          backgroundColor="transparent"
          zIndex="1"
          resize="none"
          style={{
            caretColor: 'black', // Show the caret in the textarea
            color: 'transparent', // Hide the text in the textarea
            whiteSpace: 'pre-wrap',
            overflow: 'hidden', // Disable scrolling on textarea
            outline: 'none', // Remove default textarea outline
            fontFamily: 'inherit',
            fontSize: 'inherit',
            lineHeight: 'inherit',
          }}
        />
      </Box>
    </VStack>
  );
};

export default CodeEditor;
