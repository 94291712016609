import { Text, Box } from "@chakra-ui/react";
import { Fragment } from "react";
import { Oval } from "react-loader-spinner";

export const OvalSpinner = ({ height, width, color, label, labelSize }) => {
    return (
        <Fragment>
            <Box display={'flex'} flexDir={'column'} alignItems={'center'} justifyContent={'center'}>
                <Oval height={height} width={width} color={color}></Oval>
                <Text fontSize={labelSize ?? 'xl'} mt={2}>{label}</Text>
            </Box>
        </Fragment>
    )
} 