import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogCloseButton,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
    
    ThemeProvider,
    useDisclosure
} from "@chakra-ui/react";
import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom/client";
import PrimaryButton from "./PrimaryButton";
import {theme} from '../../index'
import SecondaryButton from "./SecondaryButton";
let returnResponse;

const AlertRoot = (props) => {
    const { title, message, cancelText, okText, onOk, onCancel, confirmButtonBg, confirmButtonColor, isLoading } = props;
    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = useRef(null);

    useEffect(() => {
        onOpen();
    }, [onOpen]);

    const confirm = () => {
        onOk();
        // onClose();
    };

    const cancel = () => {
        onCancel();
        onClose();
    };

    return (
        <>
            <ThemeProvider theme={theme} cssVarsRoot="body">
                <AlertDialog
                    motionPreset="slideInBottom"
                    leastDestructiveRef={cancelRef}
                    onClose={onClose}
                    isOpen={isOpen}
                    isCentered
                >
                    <AlertDialogOverlay />

                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">{title}</AlertDialogHeader>
                        <AlertDialogCloseButton />
                        <AlertDialogBody>{message}</AlertDialogBody>
                        <AlertDialogFooter>
                            <SecondaryButton label={cancelText ?? 'Close'} variant="ghost" ref={cancelRef} onClick={cancel}>
                                
                            </SecondaryButton>
                            <PrimaryButton label={okText ?? 'Continue'} onClick={confirm} ml={3} isLoading={isLoading}>

                            </PrimaryButton>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialog>
            </ThemeProvider>
        </>
    );
};

// pass in alert type
function Create(props) {
    const rootID = "temp";
    let div = document.getElementById(rootID);

    if (!div) {
        div = document.createElement("div");
        div.id = rootID;
        document.body.appendChild(div);
    }

    const root = ReactDOM.createRoot(div);
    root.render(<AlertRoot {...props} />);

    if (div) {
        div.remove();
    }
}

export function ConfimrationAlerts(props) {
    Create(props);

    return new Promise(resolve => {
        returnResponse = resolve;
    });
}