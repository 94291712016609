import React, { Fragment, useEffect, useState } from 'react'
import { Text, Box, useToken } from '@chakra-ui/react'
import HeaderOnly from '../../components/HeaderOnly'
import useScript from '../../_helpers/useScript';
import { Helmet, HelmetProvider } from 'react-helmet-async'
import FlowEditor from './FlowEditor';
import { OvalSpinner } from "./../../components/Loaders"
function CreateFlow() {
    const [mainColor] = useToken('colors', ['primary.main'])
    const [isRappidLoaded, setIsRappidLoaded] = useState(false)
    function checkForRapid() {
        console.log('joint', window.joint, 'jquery', window.jQuery, 'Backbone', window.Backbone, 'lodash', window._)
        if (!!window.joint && !!window.jQuery && !!window.Backbone && !!window._) {
            setTimeout(() => {
                setIsRappidLoaded(true)
            }, 1000)

        } else {
            setTimeout(() => {
                checkForRapid()
            }, 100)
        }
    }
    useEffect(() => {
        checkForRapid()
    }, [])
    return (
        <Fragment>
            <HeaderOnly></HeaderOnly>
            {
                !isRappidLoaded && <Box display={'grid'} placeItems={'center'} h={'100vh'} width={'100%'}>
                    <OvalSpinner height={'100px'} width={'100px'} color={mainColor} label="Initializing Editor...">
                    </OvalSpinner>
                </Box>
            }
            {
                !!isRappidLoaded && <FlowEditor joint={window.joint}></FlowEditor>
            }
            <HelmetProvider>
                <Helmet>
                    <link rel='stylesheet' type="text/css" href={process.env.PUBLIC_URL + '/css/rappid.min.css'}></link>
                  
                </Helmet>
            </HelmetProvider>
        </Fragment>
    )
}

export default CreateFlow
