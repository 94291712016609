import { Select } from '@chakra-ui/react';
import axios from 'axios'
import React, { Fragment, useEffect } from 'react'
import { useQuery } from 'react-query'
import _ from "lodash"
import { default as MultiSelect } from './themeComponents/react-chakra-select';
export default function CountryDropdown({ isMulti, onCountrySelect, ...rest }) {
    const getCountries = () => {
        return axios.get('/json/countries.json');
    }
    const countryQuery = useQuery(['countryQuery'], {
        queryFn: async () => {
            let resp = await getCountries()

            return _.map(resp.data, (datum) => {
                return { ...datum, ...{ label: datum.name, value: datum.code } }
            })
        }
    })
    return (
        <Fragment>
            {
                !!isMulti ? <MultiSelect isMulti options={!!countryQuery.data ? countryQuery.data : []} colorScheme='brand' isClearable={false} value={rest.value} onChange={(selectedValues) => onCountrySelect(selectedValues)} {...rest}></MultiSelect> : <Select {...rest} placeholder='Select Country'>
                    {
                        !!countryQuery.data && countryQuery?.data.map(country => {
                            return <option key={country.value} value={country.value}>{country.label}</option>
                        })
                    }
                </Select>
            }

        </Fragment>
    )
}
