import React, { useEffect, useState } from 'react';
import { Box, SimpleGrid, Text, VStack } from '@chakra-ui/react';
import CustomCard from '../../components/themeComponents/CustomCard';
import { useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isPlatformIntegrated } from '../../Services/IntegrationService';

import ShopifyIcon from '../../images/Shopify.png';
import WordPressIcon from '../../images/Wordpress.png';
import GA4Icon from '../../images/GA4.png';
import CustomIcon from '../../images/Custom Website.png';
import MagentoIcon from '../../images/Magento.png';
import BigCommerceIcon from '../../images/BigCommerce.png';
import LightSpeedIcon from '../../images/Lightspeed-R.png';
import GTMIcon from '../../images/GTM.png';
import GorgiasIcon from '../../images/GorgiasLogo.png';



export default function Platforms() {
  const { integration } = useOutletContext();
  const { t } = useTranslation();

  const [platforms, setPlatforms] = useState([
    {
      title: t('platforms.shopify.title'),
      icon: ShopifyIcon,
      description: t('platforms.shopify.description'),
      navigateTo: '/integrations/shopify',
      btnLabel: t('platforms.shopify.btnLabel'),
      isIntegrated: false,
    },
    {
      title: t('platforms.wordpress.title'),
      icon: WordPressIcon,
      description: t('platforms.wordpress.description'),
      navigateTo: '/integrations/wordpress',
      btnLabel: t('platforms.wordpress.btnLabel'),
      isIntegrated: false,
    },
    {
      title: t('platforms.ga4.title'),
      icon: GA4Icon,
      description: t('platforms.ga4.description'),
      navigateTo: '/integrations/ga4',
      btnLabel: t('platforms.ga4.btnLabel'),
      isIntegrated: false,
    },
    {
      title: t('platforms.custom.title'),
      icon: CustomIcon,
      description: t('platforms.custom.description'),
      navigateTo: '/integrations/custom',
      btnLabel: t('platforms.custom.btnLabel'),
      isIntegrated: false,
    },
    {
      title: t('platforms.magento.title'),
      icon: MagentoIcon,
      description: t('platforms.magento.description'),
      navigateTo: '/integrations/magento',
      btnLabel: t('platforms.magento.btnLabel'),
      isIntegrated: false,
    },
    {
      title: t('platforms.bigCommerce.title'),
      icon: BigCommerceIcon,
      description: t('platforms.bigCommerce.description'),
      navigateTo: '/integrations/big-commerce',
      btnLabel: t('platforms.bigCommerce.btnLabel'),
      isIntegrated: false,
    },
    {
      title: t('platforms.lightspeed.title'),
      icon: LightSpeedIcon,
      description: t('platforms.lightspeed.description'),
      navigateTo: '/integrations/lightspeed',
      btnLabel: t('platforms.lightspeed.btnLabel'),
      isIntegrated: false,
    },
    {
      title: t('platforms.gtm.title'),
      icon: GTMIcon,
      description: t('platforms.gtm.description'),
      navigateTo: '/integrations/gtm',
      btnLabel: t('platforms.gtm.btnLabel'),
      isIntegrated: false,
    },
    {
      title: t('platforms.gorgias.title'),
      icon: GorgiasIcon,
      description: t('platforms.gorgias.description'),
      navigateTo: '/integrations/gorgias',
      btnLabel: t('platforms.gorgias.btnLabel'),
      isIntegrated: false,
    },
  ]);

  const updateIsIntegrated = (title, value) => {
    setPlatforms((prevPlatforms) =>
      prevPlatforms.map((platform) =>
        platform.title.toLowerCase() === title ? { ...platform, isIntegrated: value } : platform
      )
    );
  };

  useEffect(() => {
    isPlatformIntegrated().then((res) => {
      updateIsIntegrated(res.data.platform.toLowerCase(), res.data.isIntegrated);
    });
  }, []);

  return (
    <>
      <Box display="flex" flexDirection="column"  alignItems="start" >
        <VStack alignItems="baseline" my="1vh">
          <Text fontSize="1vw">{t('platforms.select')}</Text>
          <Text fontSize="0.8vw">{t('platforms.contact.tech.team')}</Text>
        </VStack>

        <SimpleGrid spacing="8vw" columns={[2, null, 3]} gridGap={"2vw"}>
          {platforms.map((platform, i) => (
            <CustomCard key={i} platform={platform} />
          ))}
        </SimpleGrid>
      </Box>  
    </>
  );
}
