import React, { Fragment, useEffect, useState } from 'react';
import { Form, Field, useFormikContext } from 'formik';
import {
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    Box,
    Image,
    Icon,
    Text,
    Avatar,
} from '@chakra-ui/react';
import { HiOutlineCloudUpload } from 'react-icons/hi';
import PrimaryButton from '../../components/themeComponents/PrimaryButton';
import { UpdateProfileSchema } from '../../_helpers/validationSchema'
import { editUserDetails, setProfilePhoto } from '../../Services/AccountService';
import SecondaryButton from '../../components/themeComponents/SecondaryButton';
import { getUserDetails, updateUserData } from '../../Services/AccountService';
import { useFormik } from 'formik';
const UpdateProfileForm = () => {
    const [profileDetails, setProfileDetails] = useState({ email: "", phoneNumber: "", userName: "", orgName: "", profilePhoto: "", });
    const formik = useFormik({
        initialValues: {},
        enableReinitialize: true,
        validationSchema: UpdateProfileSchema,
        onSubmit: (values) => {
            console.log(values);
            updateUserData(values).then((res) => {
                console.log("userInfo: " + values);
            }).catch((err) => {
                console.log(err);
            })
        }
    })



    const fetchUserDetails = () => {
        getUserDetails().then((res) => {
            formik.setValues({ ...res.data }, false);
        }).catch((err) => {
            console.log(err);
        })

    }

    const uploadProfilePhoto = (file) => {
        setProfilePhoto(file).then((res) => {
            console.log(res);
            formik.setFieldValue("profilePhoto", res.data);
        }).catch((err) => {
            console.log(err);
        })
        fetchUserDetails();
    }

    useEffect(() => {
        fetchUserDetails();
    }, [])

    return (
        <Fragment>
            {
                formik.values.userName &&
                <Box backgroundColor={"#fff"} mt={'1rem'} maxW={"35vw"} height={'fit-content'} boxShadow={'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'} p={"2rem"} width={'100%'} borderRadius={"2px"}  >
                    <Text fontSize={'lg'} >Update Profile</Text>
                    <Text fontSize={'sm'} > You can update your profile information here. </Text>
                    <form onSubmit={formik.handleSubmit} >
                        <Box my={'1rem'} display={'flex'} flexDirection={'column'} alignItems={'center'} >
                            <Avatar size={'2xl'} src={formik.values.profilePhoto} name={formik.values.userName} />
                            <FormControl
                                id="profilePhoto"
                                isInvalid={
                                    formik.touched.profilePhoto &&
                                    formik.errors.profilePhoto
                                }
                            >
                                <FormLabel my={'1rem'} textAlign={'center'} textDecoration={'underline'} htmlFor="profilePhoto">
                                    <SecondaryButton label={'Upload Photo'} rightIcon={<HiOutlineCloudUpload />} />
                                </FormLabel>
                                <Input
                                    name="profilePhoto"
                                    type="file"
                                    height="100%"
                                    width="100%"
                                    position="absolute"
                                    top="0"
                                    left="0"
                                    opacity="0"
                                    aria-hidden="true"
                                    cursor={'pointer'}
                                    accept="image/*"
                                    onChange={(event) => {
                                        formik.setFieldValue('profilePhoto', event.currentTarget.files[0])
                                        uploadProfilePhoto({ profile: event.currentTarget.files[0] })
                                        setProfileDetails({ profilePhoto: URL.createObjectURL(event.currentTarget.files[0]) })
                                    }}
                                />
                                <FormErrorMessage>
                                    {formik.errors.profilePhoto}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                        <FormControl
                            isInvalid={formik.errors.userName && formik.touched.userName}
                            mt={4}
                        >
                            <FormLabel htmlFor="userName">Name</FormLabel>
                            <Input onChange={formik.handleChange} value={formik.values.userName} name="userName" id="userName" placeholder="Your Name" />
                            <FormErrorMessage>{formik.errors.userName}</FormErrorMessage>
                        </FormControl>
                        <FormControl
                            mt={4}
                        >
                            <FormLabel htmlFor="userName">Email</FormLabel>
                            <Input value={formik.values.email} name="email" id="email" disabled />
                            <FormErrorMessage>{formik.errors.userName}</FormErrorMessage>
                        </FormControl>
                        <FormControl
                            isInvalid={formik.errors.phoneNumber && formik.touched.phoneNumber}
                            mt={4}
                        >
                            <FormLabel htmlFor="phoneNumber">Phone</FormLabel>
                            <Input value={formik.values.phoneNumber} onChange={formik.handleChange} name="phoneNumber" id="phoneNumber" placeholder="Your Phone" />
                            <FormErrorMessage>{formik.errors.phoneNumber}</FormErrorMessage>
                        </FormControl>
                        <FormControl
                            isInvalid={formik.errors.orgName && formik.touched.orgName}
                            mt={4}
                        >
                            <FormLabel htmlFor="orgName">Organization Name</FormLabel>
                            <Input onChange={formik.handleChange} value={formik.values.orgName} name="orgName" id="orgName" placeholder="Your Organization" />
                            <FormErrorMessage>{formik.errors.orgName}</FormErrorMessage>
                        </FormControl>
                        <PrimaryButton
                            label={'Update Profile'}
                            mt={4}
                            isLoading={false}
                            type="submit"
                        />
                    </form>
                </Box>
            }

        </Fragment>
    );
};

export default UpdateProfileForm;