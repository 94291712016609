import { Box, Button, FormControl, FormErrorMessage,Select, FormLabel, Input, Text, VStack, Stack, Link, useToast, Alert, AlertDescription, AlertIcon, InputRightElement, Icon, InputGroup } from '@chakra-ui/react'
import { useFormik } from 'formik'
import React, { Fragment, useState } from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { login as values } from '../../_helpers/formValues'
import { login, loginConfirm } from '../../Services/AuthService'
import { extractErrorMessageFromResponse } from '../../_helpers/helpers'
import { useDispatch } from 'react-redux';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import PrimaryButton from '../../components/themeComponents/PrimaryButton'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import store from '../../store/store'
import { useSelector } from 'react-redux'
function Login() {
    const white_label = useSelector((state) => state.auth.white_label)||sessionStorage.getItem('white_label');
    // console.log(white_label,">>label black")
    // console.log(white_label.data?.entityName,"entityName")
    // console.log(white_label.entityName,">>>????")
    // const entityName=white_label.entityName?.toUpperCase()||white_label.data?.entityName?.toUpperCase();
    const capitalizeFirstLetter = (str) => {
        if (!str) return '';
        return str.charAt(0).toUpperCase() + str.slice(1);
      };
    const entityName=capitalizeFirstLetter(white_label?.entityName)||capitalizeFirstLetter(white_label.data?.entityName)
    const { t } = useTranslation()
    const [error, setError] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const toast = useToast();
    const dispatch = useDispatch();
    const navigate = useNavigate();
// white_label?.entityName ||white_label.data?.entityName

    const handleSubmit = async (values, action) => {
        action.setSubmitting(true);
        setError('');
        const response = await login(values).catch(error => {
            action.setSubmitting(false);
            setError(extractErrorMessageFromResponse(error));
        })
        !!response && loginConfirm(action, toast, response, dispatch, navigate);
    }

    const formik = useFormik({
        initialValues: values,
        onSubmit: handleSubmit
    })
    return (
        <Fragment>
           
            <Box maxW={'md'} minW={'xl'} boxShadow={'base'} borderRadius={'xl'} bg={'white'} px={5} py={10}>
            {/* <Select placeholder='Select option'>
                <option value='en' onClick={() => i18next.changeLanguage('en')}>en</option>
                <option value='ar' onClick={() => i18next.changeLanguage('ar')}>ar</option>
                <option value='fr' onClick={() => i18next.changeLanguage('fr')}>fr</option>
                <option value='hi' onClick={() => i18next.changeLanguage('hi')}>hi</option>
            </Select> */}
                <Text fontSize={'2xl'} fontWeight={'bold'} color={'primary.main'}>{t("page.login.heading")} {entityName}</Text>
                <Text fontSize={'md'} color={'light'} mb={4}>{t("page.login.subheading")}</Text>
                {!!error && <Alert status='error' mb={4}>
                    <AlertIcon />
                    <AlertDescription fontSize={14}>{error}</AlertDescription>
                </Alert>}
                <form onSubmit={formik.handleSubmit}>
                    <FormControl isRequired mb={4} id='email'>
                        <FormLabel color={'inputLabel'}>Email</FormLabel>
                        <Input type='email' {...formik.getFieldProps('email')} />
                        <FormErrorMessage>
                            Email is required
                        </FormErrorMessage>
                    </FormControl>
                    <FormControl isRequired mb={2} id='password'>
                        <FormLabel color={'inputLabel'}>Password</FormLabel>
                        <InputGroup>
                            <Input type={!!showPassword ? 'text' : 'password'} {...formik.getFieldProps("password")} />
                            <InputRightElement cursor={'pointer'} onClick={() => setShowPassword(!showPassword)}>
                                {!!showPassword ? <Icon as={AiFillEye} /> : <Icon as={AiFillEyeInvisible} />}
                            </InputRightElement>
                        </InputGroup>
                        <FormErrorMessage>
                            Password is required
                        </FormErrorMessage>
                    </FormControl>
                    <VStack justifyContent={'end'} alignItems={'end'} mb={4}>
                        <Link as={RouterLink} color={'blue.500'} to={'/auth/forgot-password'}>{t('page.login.frgtpswrd')}</Link>
                    </VStack>
                    <PrimaryButton label={t('page.login')} isLoading={formik.isSubmitting} type={'submit'} width={'100%'}></PrimaryButton>

                </form>
                <Stack pt={6}>
                    <Text align={'center'}>
                       {t("page.login.donthaveaccnt")} <Link as={RouterLink} color={'blue.400'} to="/auth/register">
                            {t("page.sinup")}
                        </Link>
                    </Text>
                </Stack>
            </Box>
        </Fragment>
    )
}

export default Login
