import { Box, Text, Divider, Container } from '@chakra-ui/react';
import React, { Fragment } from 'react';
import UpdateProfileForm from './UpdateProfileForm';
import PasswordUpdateForm from './PasswordUpdateForm';
function Profile() {
  return (
    <Fragment>
      <Container maxW={'unset'} width={'100%'} p={'2rem'} borderRadius={'1rem'} justifyContent={'start'}>
        <Text as={'b'} ml={'3rem'} fontSize={'xl'}>Account Details </Text>
        <Box display={'flex'} flexDirection={'column'} >
          {/* <ProfilePhotoUploadForm /> */}
          <Box display={'flex'} justifyContent={'space-around'} >
            <UpdateProfileForm />
            <Divider orientation='vertical' height={'inherit'} borderWidth={'1px'} />
            <PasswordUpdateForm />
          </Box>
        </Box>
      </Container>
    </Fragment>
  )
}

export default Profile