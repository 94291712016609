import { Text } from '@chakra-ui/react'
import _ from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

function PrivateRoutes({Component}) {
    const authData = useSelector(state => state.auth)
    console.log(authData)
    if(!!authData.isLoading){
        return <Text fontSize={'3xl'}>Loading....</Text>
    }
    if(!authData.isAuthenticated) {
        return <Navigate to={'/auth'}></Navigate>
    }
    if(!!_.isEmpty(authData.selectedOrg)){
        return <Navigate to={'/organization'}></Navigate>
    }
    return (
        <Component />
    )
}

export default PrivateRoutes
