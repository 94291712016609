import { Step, StepIcon, StepIndicator, StepNumber, StepStatus, Stepper, Box, StepTitle, StepDescription, StepSeparator, Icon } from '@chakra-ui/react'
import React from 'react'
import { getNudgeEditSteps } from '../NudgeHelper'
import { FaEdit } from 'react-icons/fa'

export default function NudgeStepper({ activeStep }) {
    return (
        <Stepper index={activeStep} maxW={'1280px'} mt={2} margin={'0 auto'}>
            {
                getNudgeEditSteps().map((step, index) => (
                    <Step key={index}>
                        <StepIndicator bg={activeStep === index ?  'teal' : 'primary.main'} color={'white'} border={'unset'}>
                            <StepStatus complete={<StepIcon />} incomplete={<StepNumber />} active={<StepNumber/>}></StepStatus>
                        </StepIndicator>
                        <Box flexShrink='0'>
                            <StepTitle>{step.description}</StepTitle>    
                        </Box>
                        <StepSeparator/>
                    </Step>
                ))
            }
        </Stepper>
    )
}
