import { Box, useRadio } from '@chakra-ui/react'
import React from 'react'

function RadioCards(props) {
    const {getInputProps, getRadioProps} = useRadio(props);
    const input = getInputProps();
    const checkbox = getRadioProps();
    const abTestChannels = ["email"]

    return (
        <Box w={'100%'} as="label">
            <input disabled={true} {...input}></input>
            <Box {...checkbox} cursor={'pointer'} border={'1px solid'} pos={'relative'} borderColor={'gray'} w={'100%'} minH={'100px'}    _checked={{
               color: 'white', borderColor:'primary.main', _before: {content: '""', bgColor: 'primary.main', pos:'absolute', top: 0, right: 0, left: 0, bottom: 0, opacity: 0.2}
            }}>
                {props.children}
            </Box>
        </Box>
    )
}

export default RadioCards
