import React, { useEffect } from 'react'
import { Card, Image, Stack, CardBody, Heading, CardFooter, Button, Text, Box, Icon, UnorderedList, ListItem, OrderedList, VStack, Divider } from '@chakra-ui/react'
import PrimaryButton from './PrimaryButton'
import { Link } from 'react-router-dom';
import * as _ from "lodash"

export default function Recipecard({ recipe }) {
    return (
        <Card
            direction={{ base: 'column', sm: 'row' }}
            overflow='hidden'
            borderRadius={'5px'}
            variant='outline'
            // // height={'10%'}
            // width={'100%'}
            height={{ base: '45vh', sm: '34vh' }}
            width={{ base: '88vw', sm: '25vw' }}
            bg={'#ebebeb'}
            border={'1px solid #DACFF0'}
            // pt={'4'}
            // m={}
           
            
        >
            <Box width={'25%'} display={'flex'} bg={'#ebebeb'} justifyContent={'center'} alignItems={'center'} >
                <Image
                        objectFit={'cover'}
                        width={'4vw'}
                        height={'auto'}
                        src={recipe.icon}
                />
            </Box>
            <Divider orientation='vertical' height={'80%'} mt={'3%'} />
            <Stack width={'75%'} >
                <CardBody width={'100%'} display={'flex'}
                    padding={'1rem'}
                    flexDirection={'column'} >
                    <Text fontSize={'0.9vw'}>{recipe.title}</Text>
                    {/* to be Edit  */}
                    <Text fontSize={'0.8vw'} pt={"4"} >
                        {recipe.description}
                    </Text>
                    <VStack mt={'1vh'} alignItems={'self-start'} >

                    <Text fontSize={'0.8vw'} >Journey Highlights:</Text>
                    {
                        !!recipe?.list &&!!recipe.list.length > 0 && recipe.list.map(
                            (list,i)=>(
                                <UnorderedList>
                                    <ListItem color={'#000000a3'} fontSize={'0.8vw'}   key={i} >
                                        {list}
                                    </ListItem>
                                </UnorderedList>
                            )
                            )
                        }
                        </VStack>
                </CardBody>
                <CardFooter display={'flex'} p={0} width={'inherit'} justifyContent={'end'}>
                    <Link style={{ alignSelf: 'end', display: 'flex', gap: '5px', marginBottom: '1vh', padding: '0.5rem',  }} to={ !_.isEmpty(recipe.navigateTo.pathname)  && recipe.navigateTo.pathname} state={recipe.navigateTo.state}  >
                        <PrimaryButton right={'1vw'} borderRadius={'1vw'} height={'1.8vh'} 
                        padding={'1vw 1vw'} position={'absolute'} bottom={'0.8vw'} fontSize={'0.8vw'} label={recipe.btnLabel} />
                    </Link>
                </CardFooter>

            </Stack>
        </Card>
    )
}

