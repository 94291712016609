
import React, { Fragment, useState, forwardRef, useRef, createRef } from 'react'
import ReactDatePicker from 'react-datepicker'
import { Box, Flex, FormLabel, Radio, RadioGroup, Stack, Text, Input, Alert, AlertIcon, AlertTitle } from '@chakra-ui/react'
// import flatpickr from 'flatpickr'

// import "flatpickr/dist/flatpickr.css";
import Flatpickr from 'react-flatpickr';
import moment from 'moment';
import 'flatpickr/dist/themes/material_green.css';
import DateTimePicker from '../../../components/themeComponents/DateTimePicker'
function ScheduleCampaign({ channel, formik, errors }) {
    const minDate = new Date();
    const DateInput = ({ value, defaultValue, inputRef, ...props }) => {
        return <Input {...props} ref={inputRef} defaultValue={defaultValue} ml={3} border={'1px solid'} borderColor={'gray.200'} borderRadius={5} p={2} fontSize={'sm'} cursor={'pointer'} w={'100%'} maxW={'300px'} />
        //<InputGroup w={'auto'}><Input onClick={onClick} ref={ref} ml={3} value={placholderPrefix + value} w={'auto'}></Input></InputGroup>
    }
    const onScheduleStatusChange = (value) => {
        formik.setFieldValue("formik.values.scheduleData.scheduleStatus", value)
    }
    const onDateSelect = (dateStr, formikProp) => {
        formik.setFieldValue(formikProp, dateStr[0])
    }
    return (
        <Fragment>
            <Box w={'100%'} m={'0 auto'} mt={5} p={3} bg={'white'} maxW={'930px'} id={'scheduleCampaign'}>
                <Text color={'heading'} as={'b'}>Schedule Campaign</Text>
                <Flex direction={'column'} mt={4}>

                    <Stack spacing={4} direction={'column'}>
                        <Radio value='NOW' name='scheduleData.scheduleStatus' onChange={formik.handleChange} isChecked={formik.values.scheduleData.scheduleStatus === 'NOW'}>
                            <FormLabel color={'inputLabel'} m={0} fontSize={'sm'}>Send Now</FormLabel>
                        </Radio>
                        <Radio value='SCHEDULED' name='scheduleData.scheduleStatus' onChange={formik.handleChange} isChecked={formik.values.scheduleData.scheduleStatus === 'SCHEDULED'}>
                            <FormLabel color={'inputLabel'} m={0} fontSize={'sm'}>Schedule for Later</FormLabel>
                        </Radio>
                        {
                            (formik.values.scheduleData.scheduleStatus === 'SCHEDULED') && <Flex alignItems={'center'} pl={10}>
                                <Text color={'inputLabel'} fontSize={'sm'}>Specify</Text>

                                <Box border={'1px solid'} height={10} fontSize={'md'} paddingInlineStart={4} paddingInlineEnd={4} display={'flex'} alignItems={'center'} maxWidth={'300px'} w={'100%'} ml={3} rounded={'md'} borderColor={'rgb(226, 232, 240)'}>
                                    <Flatpickr options={{ minDate: 'today', enableTime: true, dateFormat: 'M d Y, h:i K' }}
                                        value={new Date(formik.values.scheduleData.scheduleAt)} 
                                        defaultValue={new Date(formik.values.scheduleData.scheduleAt)}
                                        onChange={(props) => onDateSelect(props, "scheduleData.scheduleAt")}>


                                    </Flatpickr>
                                </Box>
                            </Flex>
                        }
                        <Radio value='RECURRING' name='scheduleData.scheduleStatus' onChange={formik.handleChange} isChecked={formik.values.scheduleData.scheduleStatus === 'RECURRING'}>
                            <FormLabel color={'inputLabel'} m={0} fontSize={'sm'}>Recurring</FormLabel>
                        </Radio>
                        {
                            (formik.values.scheduleData.scheduleStatus === 'RECURRING') && <Box display={'flex'} flexDirection={'column'} gap={3}>
                                <Flex alignItems={'center'} pl={10}>
                                    <Text color={'inputLabel'} fontSize={'sm'} maxWidth={'100px'} w={'100%'}>Start At</Text>
                                    <Box border={'1px solid'} height={10} fontSize={'md'} paddingInlineStart={4} paddingInlineEnd={4} display={'flex'} alignItems={'center'} maxWidth={'300px'} w={'100%'} ml={3} rounded={'md'} borderColor={'rgb(226, 232, 240)'}>
                                        <Flatpickr options={{ minDate: 'today', enableTime: true, dateFormat: 'M d Y, h:i K' }} value={new Date(formik.values.scheduleData.scheduleAt)} defaultValue={new Date(formik.values.scheduleData.scheduleAt)}>


                                        </Flatpickr></Box>
                                </Flex>
                                <Flex alignItems={'center'} pl={10}>
                                    <Text color={'inputLabel'} fontSize={'sm'} maxWidth={'100px'} w={'100%'}>Repeat Every</Text>
                                    <Input {...formik.getFieldProps("scheduleData.repeatAfterDays")} ml={3} maxW={'300px'}></Input>
                                </Flex>
                                <Flex alignItems={'center'} pl={10}>
                                    <Text color={'inputLabel'} fontSize={'sm'} maxWidth={'100px'} w={'100%'}>Stop At</Text>
                                    <input type='hidden' {...formik.getFieldProps("scheduleData.repeatStopDateTime")} required />
                                    {/* <ReactDatePicker selected={formik.values.scheduleData.repeatStopDateTime} minDate={minDate} style={{ width: '100%' }} onChange={(date) => formik.setFieldValue("scheduleData.repeatStopDateTime", date)} showTimeSelect dateFormat={"MMMM d, h:mm aa"} customInput={<DateInput></DateInput>}>

                                    </ReactDatePicker> */}
                                    {/* <DateTimePicker defaultValue={formik.values.scheduleData.repeatStopDateTime} onDateSelect={(selectedDates, dateStr, instance) => formik.setFieldValue("scheduleData.repeatStopDateTime", dateStr)} minDate={"today"}></DateTimePicker>
                                     */}
                                    <Box border={'1px solid'} height={10} fontSize={'md'} paddingInlineStart={4} paddingInlineEnd={4} display={'flex'} alignItems={'center'} maxWidth={'300px'} w={'100%'} ml={3} rounded={'md'} borderColor={'rgb(226, 232, 240)'}>
                                        <Flatpickr options={{ minDate: 'today', enableTime: true, dateFormat: 'M d Y, h:i K' }} value={new Date(formik.values.scheduleData.repeatStopDateTime)} defaultValue={new Date(formik.values.scheduleData.repeatStopDateTime)}>


                                        </Flatpickr></Box>
                                </Flex>


                            </Box>
                        }
                        <Flex direction={'column'} ml={10} w={'70%'}>
                            {
                                !!errors.length && errors.map(error => (
                                    <Alert status="error">
                                        <Fragment>
                                            <AlertIcon></AlertIcon>
                                            <AlertTitle>{error}</AlertTitle>
                                        </Fragment>
                                    </Alert>
                                ))
                            }
                        </Flex>
                    </Stack>

                </Flex>

            </Box>

        </Fragment>
    )
}

export default ScheduleCampaign
