import React from 'react'
import { Modal, Box, ModalOverlay, ModalContent, ModalCloseButton, ModalHeader, ModalBody } from '@chakra-ui/react'
import MobilePreview from './MobilePreview'
export default function ABTestMobilePreview({ message, channel, buttons, attachment, sample_body , isOpen, onClose}) {

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'4xl'} scrollBehavior='inside'>
    <ModalOverlay></ModalOverlay>
    <ModalContent w={'auto'} maxW={'700px'}>
        <ModalHeader fontSize={'sm'}>Template Preview </ModalHeader>
        <ModalCloseButton onClick={onClose} ></ModalCloseButton>
        <ModalBody>
            <MobilePreview message={message} channel={channel} attachment={attachment} sample_body={sample_body} buttons={buttons}/>
        </ModalBody>
    </ModalContent>

</Modal>
  )
}
