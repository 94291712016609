import store from '../store/store'
import axiosInstance  from './axiosInstance';

export const getIntegrationDetails = async () => {   
    const orgToken = store.getState().auth?.selectedOrg?.token;
    let url = `${process.env.REACT_APP_API_ENDPOINT}/integrationdetails`;
    return await axiosInstance.get(url)
}
export const integrateShopify = async ({shopName}) => {
    const orgToken = store.getState().auth?.selectedOrg?.token;
    let url = `${process.env.REACT_APP_API_ENDPOINT}/integrations/shopify/auth/${shopName}.myshopify.com`;
    return await axiosInstance.get(url)
}
export const integrateGA4= async () => {
    const orgToken = store.getState().auth?.selectedOrg?.token;
    let url = `${process.env.REACT_APP_API_ENDPOINT}/googleanalytics/buildurl`;
    return await axiosInstance.get(url)
}
export const integrateGorgias= async (data) => {
    const orgToken = store.getState().auth?.selectedOrg?.token;
    let url = `${process.env.REACT_APP_API_ENDPOINT}/integration/thirdparty/gorgias`;
    return await axiosInstance.post(url,data)
}
export const saveShopifyToken = async (params) => {
    let url = `${process.env.REACT_APP_API_ENDPOINT}${params}`;
    return await axiosInstance.get(url)

}
export const isPlatformIntegrated = async ()=> {
    let url = `${process.env.REACT_APP_API_ENDPOINT}/analytics/integration/isintegrated`;
    return await axiosInstance.get(url)
}