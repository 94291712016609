// smsProviderMapping.js

import TrustSignal from "./SmsFroms/TrustSignal";
import Kaleyra from "./SmsFroms/Kalyera";
import Telnyx from "./SmsFroms/Telnyx";
import RouteMobile from "./SmsFroms/RouteMobile";
import Sinch from "./SmsFroms/Sinch";
const SmsProviderMapping = {
  TrustSignal: TrustSignal,
  RouteMobile: RouteMobile, 
  Kaleyra: Kaleyra,
  Telnyx: Telnyx,
  Sinch: Sinch,
};

export default SmsProviderMapping;
