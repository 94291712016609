import { Button } from '@chakra-ui/react';
import React, { Fragment } from 'react'

function OutlinedButton({label, colorScheme, ...rest}) {
    return (
        <Fragment>
            <Button borderRadius={'8px'} variant={'outline'} color={colorScheme} border={'1px solid'} borderColor={colorScheme} {...rest}>
                {label}
            </Button>
        </Fragment>
    )
}

export default OutlinedButton;
