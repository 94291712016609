import { Box, Flex, Step, StepDescription, StepIcon, StepIndicator, StepNumber, StepSeparator, StepStatus, Stepper, useToken, Text } from '@chakra-ui/react'
import React from 'react'

function CampaignStepper({steps, activeStep}) {
    const [mainColor] = useToken('colors', ['primary.main'])
    //const [mainColor, heading] = useToken('colors', ['primary.main', 'heading'])
    return (
        <Stepper index={activeStep} display={'flex'} maxW={'930px'} m={'0 auto'} w={'100%'} justifyContent={'flex-start'}>
            {
                steps.map((step, index) => (
                    <Step key={index} borderColor={mainColor} w={'auto'} flex="unset">
                        <StepIndicator bg={'primary.main'} borderColor={'primary.main'} color={'white'}>
                            <StepStatus complete={<StepIcon/>} incomplete={<StepNumber borderColor='primary.main'/>} active={<StepNumber />}>

                            </StepStatus>
                        </StepIndicator>
                        <Box>
                            <StepDescription color={mainColor}>{step.description}</StepDescription>
                        </Box>
                        {/* <StepSeparator bg={mainColor}></StepSeparator> */}
                    </Step>
                    // <Flex key={index} alignItems={'center'} gap={2}>
                    //     <Box rounded={'50%'} w={25} h={25} border={'1px solid'} display={'flex'} alignItems={'center'} borderColor={'primary.main'} color={'primary.main'} justifyContent={'center'} fontSize={'xs'}>
                    //         {index + 1}
                            
                    //     </Box>
                    //     <Text fontSize={'xs'} color={'primary.main'}>{step.description}</Text>
                    // </Flex>
                ))
            }
        </Stepper>

    )
}

export default CampaignStepper
