    import React, { useEffect, useState } from 'react';
    import { Formik, Form, Field } from 'formik';
    import {
        FormControl,
        FormLabel,
        Input,
        Button,
        FormErrorMessage,
        Box,
        Select,
        Text,
        VStack,
    } from '@chakra-ui/react';
    import { editOrganizationSchema } from '../_helpers/validationSchema';
    import PrimaryButton from '../components/themeComponents/PrimaryButton';
    import { editOrganization, getOrgDetails, getorgdetails } from '../Services/AccountService';

    const initialValues = {
        companyName: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        postalCode: '',
        state: '',
        country: '',
    };

    const EditOrganization = () => {

        const [orgDetails, setOrgDetails] = useState({})

        const handleSubmit = (values) => {
            editOrganization(values).then((res) => {
                console.log(res);
            }).catch(() => { });
        };

        let fetchOrgDetails = () => {
            getOrgDetails().then((res) => {
                setOrgDetails(res.data)
                console.log(orgDetails)
            })
        }

        useEffect(() => {
            fetchOrgDetails()
        }, [])

        return (
            <>
                {!!orgDetails.companyName &&
                    <Formik
                        initialValues={orgDetails}
                        validationSchema={editOrganizationSchema}
                        onSubmit={handleSubmit}
                    >
                        {(formik) => (
                            <Form>
                                <Text fontSize={'xl'} >Company Info</Text>
                                <Box mt={'1rem'} backgroundColor={"#fff"} width={'100%'} boxShadow={'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'} maxW={"35vw"} p={"2rem"} borderRadius={"2px"} >
                                    <VStack alignItems={'baseline'}  spacing={4}>
                                        <Field name="companyName">
                                            {({ field, form }) => (
                                                <FormControl
                                                    isInvalid={form.errors.companyName && form.touched.companyName}
                                                >
                                                    <FormLabel htmlFor="companyName">Company Name </FormLabel>
                                                    <Input
                                                        {...field}
                                                        id="companyName"
                                                        placeholder="Enter company name"
                                                    />
                                                    <FormErrorMessage>{form.errors.companyName}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>
                                        <Field name="addressLine1">
                                            {({ field, form }) => (
                                                <FormControl
                                                    isInvalid={
                                                        form.errors.addressLine1 && form.touched.addressLine1
                                                    }
                                                >
                                                    <FormLabel htmlFor="addressLine1">Address Line 1</FormLabel>
                                                    <Input
                                                        {...field}
                                                        id="addressLine1"
                                                        placeholder="Enter address line 1"
                                                    />
                                                    <FormErrorMessage>
                                                        {form.errors.addressLine1}
                                                    </FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>
                                        <Field name="addressLine2">
                                            {({ field }) => (
                                                <FormControl>
                                                    <FormLabel htmlFor="addressLine2">Address Line 2</FormLabel>
                                                    <Input
                                                        {...field}
                                                        id="addressLine2"
                                                        placeholder="Enter address line 2"
                                                    />
                                                </FormControl>
                                            )}
                                        </Field>
                                        <Field name="city">
                                            {({ field, form }) => (
                                                <FormControl isInvalid={form.errors.city && form.touched.city}>
                                                    <FormLabel htmlFor="city">City</FormLabel>
                                                    <Input {...field} id="city" placeholder="Enter city" />
                                                    <FormErrorMessage>{form.errors.city}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>
                                        <Field name="postalCode">
                                            {({ field, form }) => (
                                                <FormControl
                                                    isInvalid={
                                                        form.errors.postalCode && form.touched.postalCode
                                                    }
                                                >
                                                    <FormLabel htmlFor="postalCode">Postal Code</FormLabel>
                                                    <Input
                                                        {...field}
                                                        id="postalCode"
                                                        placeholder="Enter postal code"
                                                    />
                                                    <FormErrorMessage>{form.errors.postalCode}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>
                                        <Field name="state">
                                            {({ field, form }) => (
                                                <FormControl isInvalid={form.errors.state && form.touched.state}>
                                                    <FormLabel htmlFor="state">State</FormLabel>
                                                    <Input {...field} id="state" placeholder="Enter state" />
                                                    <FormErrorMessage>{form.errors.state}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>
                                        <Field name="country">
                                            {({ field, form }) => (
                                                <FormControl
                                                    isInvalid={
                                                        form.errors.country && form.touched.country
                                                    }
                                                >
                                                    <FormLabel htmlFor="country">Country</FormLabel>
                                                    <Input
                                                        {...field}
                                                        id="country"
                                                        type='text'
                                                        placeholder="country"
                                                    >
                                                    </Input>
                                                    <FormErrorMessage>{form.errors.country}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>
                                        <PrimaryButton label={'Submit'} type={'submit'} />
                                    </VStack>
                                </Box>
                            </Form>

                        )}
                    </Formik>

                }
            </>

        );
    };

    export default EditOrganization;
