import React, { Fragment,useState,useEffect } from 'react'
import { FormLabel, InputGroup, Input, InputRightAddon, Select, FormControl, FormErrorMessage, Text } from "@chakra-ui/react"
import FlowEditService from '../helpers/FlowEditService'
import { useFormik } from 'formik'
import { delayValidation } from '../../../_helpers/validationSchema'
import { getSegmentProps } from '../../../Services/api'
import DateTimePicker from '../../../components/themeComponents/DateTimePicker'
import { Box } from '@chakra-ui/react'
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_green.css';
export default function InspectorForDelay({ formData, selectedElement, updateFormData, validateFormData,showDateTimePicker }) {
    
    let setDelayInFormData = (durationType, delayValue) => {
        let tempFormData = { ...formData };
        if (tempFormData.hasOwnProperty("undefined")) {
            delete tempFormData["undefined"]
        }

        !!selectedElement && (() => {
            let objectId = selectedElement.elementType === FlowEditService.elementTypes.initial ? 'beginsWith' : selectedElement.id;
            let secondsMultiplier;
            durationType === 'seconds' && (secondsMultiplier = 1);
            durationType === 'minutes' && (secondsMultiplier = 60);
            durationType === 'hours' && (secondsMultiplier = 60 * 60);
            durationType === 'days' && (secondsMultiplier = 60 * 60 * 24);
            durationType === 'weeks' && (secondsMultiplier = 60 * 60 * 24 * 7);
            tempFormData[objectId].wait = formik.values;
            tempFormData[objectId].wait.formInputs = {
                delayValue: delayValue, delayDuration: durationType
            }
            tempFormData[objectId].wait.duration = Number(tempFormData[objectId].wait.formInputs.delayValue) * secondsMultiplier;
            updateFormData(tempFormData)
            validateFormData("beginsWith")
            
        })()

    }
    const formik = useFormik({
        initialValues: { ...formData[selectedElement.id].wait },
        validationSchema: delayValidation,
    })
    const formatDateTime = (date) => {
        return new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true
        }).format(date);
    };
    const [delaySegmentProps, setDelaySegmentProps] = useState([])
    const [currentDateTime, setCurrentDateTime] = useState(new Date());
    const fetchSegmentProps = async () => {
        const segmentationProps = await getSegmentProps().catch(err => err);
        if (segmentationProps.status === 200) {
            const datetimeProps = Object.values(segmentationProps.data.segmentationProps.customers).filter(prop => prop.dataType === 'datetime');
            setDelaySegmentProps(datetimeProps);
        }
        
    };

    console.log(delaySegmentProps,">>DDDD")
    const handleChange = (fieldName, value) => {
        
        formData[selectedElement.id].nextActionCondition['wait'] = formData[selectedElement.id].wait
         formData[selectedElement.id].nextActionCondition['wait'][fieldName] = value;
        //  console.log(formData[selectedElement.id])
        // tempFormData[fieldName] = value;    
        // updateFormData(tempFormData);
      };

    useEffect(() => {
        fetchSegmentProps();
    }, []);
    return (
        <Fragment>
            <FormControl>
                <FormLabel color={'inputLabel'} mt={2}>
                    Duration
                </FormLabel>
                <InputGroup>
                    <Input name='formInputs.delayValue' onChange={(e) => { formik.setFieldValue("formInputs.delayValue", e.target.value);setTimeout(()=> {setDelayInFormData(formik.values.formInputs.delayDuration, e.target.value)}, 100) }}
                        value={formik.values.formInputs.delayValue}></Input>
                    <InputRightAddon p={0} bg={'transparent'}>
                        <Select minW={'150px'} name={`formInputs.delayDuration`} value={formik.values.formInputs.delayDuration}
                            onChange={(e) => { formik.setFieldValue("formInputs.delayDuration", e.target.value); setDelayInFormData(e.target.value, formik.values.formInputs.delayValue) }}>
                            {
                                FlowEditService.getDelayDuration().map(duration => (
                                    <option key={duration.value} value={duration.value}>{duration.key}</option>
                                ))
                            }
                        </Select>
                        
                    </InputRightAddon>

                </InputGroup>



            </FormControl>
            {
                !!formik.errors?.formInputs?.delayValue && <Text color={'danger'} fontSize={'sm'}>{formik.errors.formInputs.delayValue}</Text>
            }
        <FormControl>
        
        {
            showDateTimePicker && (
                <>
                <FormLabel color={'inputLabel'} mt={2}>
          Select Property
        </FormLabel>
                <Select
          placeholder='Select property'
        //   value={formData[selectedElement.id]?.nextActionCondition?.wait?.segmentProperty}
          onChange={(e) => handleChange('segmentProperty', e.target.value)}
        >
          {delaySegmentProps.map((prop) => (
            <option key={prop.value} value={prop.value}>
              {prop.value}
            </option>
          ))}
        </Select>
      {/* </FormControl> */}
      <FormControl>
        <FormLabel color={'inputLabel'} mt={2}>
          Current Date and Time
        </FormLabel>
        {/* <Box 
        // style={{ position: 'relative', marginTop: '-20px' }}
        >

        <DateTimePicker 
        // style={{ position: 'relative', marginTop: '-20px' }}
        // onChange={(e) => }
            onDateSelect={(selectedDates, dateStr, instance) => handleChange('triggerTime', dateStr)} minDate={"today"}></DateTimePicker>
            </Box> */}
            <Box border={'1px solid'} height={10} fontSize={'md'} paddingInlineStart={4} paddingInlineEnd={4} display={'flex'} alignItems={'center'} maxWidth={'300px'} w={'100%'} ml={3} rounded={'md'} borderColor={'rgb(226, 232, 240)'}>
                                        <Flatpickr options={{ minDate: 'today', enableTime: true, dateFormat: 'M d Y, h:i K' }} 
                                        value={currentDateTime}
                                        onChange={(e)=> handleChange('triggerTime',e)} 
                                        >


                                        </Flatpickr></Box>
         
      </FormControl>
                </>
            )
        }
        


        </FormControl>

        </Fragment>
    )
}
