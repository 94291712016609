import React from 'react'
import Recipecard from '../../../components/themeComponents/RecipeCard'
import { Box, SimpleGrid } from '@chakra-ui/react'
import { recipes } from './RecipeCardsData'
export default function RecipeTypes() {
  return (
    <Box display={'flex'} mt={'2vh'} justifyContent={'center'} pr={'4'} mr={4}>
                    <SimpleGrid spacing={'3vw'} columns={[2, null, 3]} gridGap={"4vw"} >
                        {
                            !!recipes.length &&
                            recipes.map((recipe, i) => (
                                <>
                                    {
                                        <Recipecard key={i} recipe={recipe} />
                                    }
                                </>
                            ))
                        }

                    </SimpleGrid>
                </Box>

  )
}
