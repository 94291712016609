import { Button } from '@chakra-ui/react'
import React, { Fragment } from 'react'

function PrimaryButton({label, isLoading, type, ...rest}) {
    return (
        <Fragment>
            <Button type={type} bg={'primary.main'} color={'white'} _hover={{ bg: 'primary.main', opacity: '.8' }} borderRadius={'8px'} size={'md'} isLoading={isLoading} {...rest}>
                {label}
            </Button>
        </Fragment>
    )
}

export default PrimaryButton
