import React, {Fragment} from 'react'
import { Outlet } from 'react-router-dom'
import { Grid, Text } from '@chakra-ui/react'
import { connect } from 'react-redux'

function SegmentIndex({ isAnalyticsIntegrated }) {
    if (!!isAnalyticsIntegrated) return (
        <Outlet></Outlet>
    )
    if (!isAnalyticsIntegrated) return (
        <Fragment>
            <Grid placeContent={'center'}>
                <Text>Analytics plugin is not integrated</Text>
            </Grid>
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        ...state,
        isAnalyticsIntegrated: state.auth.selectedOrg.isIntegrated
    }
}
export default connect(mapStateToProps)(SegmentIndex);