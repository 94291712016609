import React, { Fragment, useEffect, useState } from 'react'
import { Container, Text, useSteps, Box, FormControl, FormLabel, Input, Flex, Checkbox, useToast } from '@chakra-ui/react'
import CampaignStepper from './components/CampaignStepper'
import { campaignSteps, prepareDraftCampaignData, prepareTargetedAudienceData } from '../../_helpers/data_helper'
import Select from '../../components/themeComponents/react-chakra-select'
import AudienceSelection from './components/AudienceSelection'
import PrimaryButton from "./../../components/themeComponents/PrimaryButton"
import { useQuery } from 'react-query'
import { connect, useDispatch } from 'react-redux'
import { draftCampaign, getCampaign, publishABCampaign, publishCampaign } from '../../Services/api'
import { OvalSpinner } from '../../components/Loaders'
import { useNavigate, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { broadcastCampaign as initialValues } from '../../_helpers/formValues'
import _ from "lodash"
import OutlinedButton from '../../components/themeComponents/OutlinedButton'
import TemplateSelection from './components/TemplateSelection'
import ScheduleCampaign from './components/ScheduleCampaign'
import { UPDATE_REACH } from '../../store/Reducers/CampaignReducer'
import { scheduleCampaign as scheduleCampaignValidationSchema } from '../../_helpers/validationSchema'
import SmsCampaignForm from './components/SmsCampaignForm'
import TemplateSelectionAB from './components/TemplateSelectionAB'
import { sendAbTest } from '../../Services/api'

function CreateCampaign({ org, reach }) {
    const [showSliders, setShowSlider] = useState(false)
    // console.log(reach,"reach in main modal")
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { id } = useParams();
    const { activeStep, setActiveStep } = useSteps({
        index: 0, count: campaignSteps.length
    })
    const toast = useToast();
    const [errors, setErrors] = useState([]);
    const campaignQuery = useQuery('campaign', {
        queryFn: async () => {
            let res = await getCampaign(org.token, id);
            let data = prepareDraftCampaignData(res.data)
            formik.setValues(data)
            return data;
        },
        onError: (error) => toast({ title: error?.message || 'Something went wrong' }),
        cacheTime: 0,
        retry: 0
    })
    const saveAbTest = async (values, action) => {
        
        values.broadcastType = "a_b_test_v2"
        values.broadcast = "a_b_test_v2"
        let campaignData = { ...values };
        let targetedAudience = {};
        (!_.isEmpty(campaignData.targetedAudience.includes.CSV) || !_.isEmpty(campaignData.targetedAudience.includes.segment)) && (targetedAudience = prepareTargetedAudienceData(campaignData.targetedAudience))
        campaignData.targetedAudience = targetedAudience;
        const abTestResp = await sendAbTest(org.token, campaignData)
        if (abTestResp.data && abTestResp.status == '200') {
            let publishResponse = await publishABCampaign( abTestResp.data,campaignData.scheduleData, action ,abTestResp.data[0].orgId  )
            console.log('publish respone', publishResponse)
            action.setSubmitting(false);
            abTestResp.status == '200' && (() => {
                toast({
                    title: ` Navigating to login page.`,
                    status: 'success', duration: 1000, isClosable: true
                })
                setIsSubmitting(false);
                navigate("/campaign/list")
            })()
            abTestResp.status != '200'  && (() => {
                toast({
                    title: ` Navigating to login page.`,
                    status: 'error', duration: 1000, isClosable: true
                })
            })()
        }
    }
    const saveAndPublishCampaign = async (values, action) => {
        if (values.campaignType == 'a_b_test_v2') {
            saveAbTest(values, action);
            return
        } else {

            let campaignData = { ...values };
            let targetedAudience = {};
            (!_.isEmpty(campaignData.targetedAudience.includes.CSV) || !_.isEmpty(campaignData.targetedAudience.includes.segment)) && (targetedAudience = prepareTargetedAudienceData(campaignData.targetedAudience))
            campaignData.targetedAudience = targetedAudience;
            const draftResponse = await draftCampaign(org.token, campaignData)
            if (draftResponse.data && draftResponse.data.result === 'success') {
                let publishResponse = await publishCampaign(org.token, draftResponse.data.id, campaignData.scheduleData)
                console.log('publish respone', publishResponse)
                action.setSubmitting(false);
                publishResponse.data.result === 'success' && (() => {
                    toast({
                        title: `${publishResponse.data.result}. Navigating to login page.`,
                        status: 'success', duration: 1000, isClosable: true
                    })
                    setIsSubmitting(false);
                    navigate("/campaign/list")
                })()
                publishResponse.data.result !== 'success' && (() => {
                    toast({
                        title: `${publishResponse.data.result}. Navigating to login page.`,
                        status: 'error', duration: 1000, isClosable: true
                    })
                })()
            }
        }
    }
    const handleSubmit = async (values, action) => {
        setIsSubmitting(true)
        try {
            await scheduleCampaignValidationSchema.validate(values.scheduleData, { abortEarly: false })
            !!errors.length && setErrors([]);
            saveAndPublishCampaign(values, action)
        } catch (err) {
            setErrors(err.errors)
            setIsSubmitting(false)
            console.log('vaidation error', err.errors)
        }


    }
    const formik = useFormik({
        initialValues: initialValues,
        enableReinitialize: true,
        onSubmit: handleSubmit
    })
    // useEffect(() => {
    //     return () => {
    //         dispatch(UPDATE_REACH({count: 0}))
    //     }
    // })
    if (campaignQuery.isLoading) return <OvalSpinner height={20} width={20}></OvalSpinner>
    if (!!_.isEmpty(campaignQuery.data)) return toast({ title: 'Not a valid campaign' })
    if (!_.isEmpty(campaignQuery.data)) return (
        <Container maxW={'1230px'} p={4} height={'100%'} display={'flex'} flexDirection={'column'} alignItems={'flex-start'}>
            {formik.isSubmitting}
            <CampaignStepper steps={campaignSteps} activeStep={activeStep}></CampaignStepper>
            {
                activeStep === 0 &&
                <Fragment>
                    <Box maxW={'930px'} w={'100%'} bg={'white'} m={'0 auto'} mt={5} p={3}>
                        <Text color={'heading'} as={'b'}>Campaign Information</Text>
                        <Flex p={3} gap={5}>
                            <FormControl>
                                <FormLabel color={'inputLable'}>Campaign Name</FormLabel>
                                <Input type='text' {...formik.getFieldProps('campaignName')}></Input>
                            </FormControl>
                            <FormControl>
                                <FormLabel color={'inputLable'}>Tags (Coming Soon)</FormLabel>
                                <Input type='text' disabled></Input>
                            </FormControl>
                        </Flex>
                    </Box>
                    <Box maxW={'930px'} w={'100%'} bg={'white'} m={'0 auto'} mt={5} p={3}>
                        <Text color={'heading'} as={'b'}>Audience Selection</Text>
                        <AudienceSelection selectedChannel={formik.values.selectedChannel} showSliders={showSliders} setShowSlider={setShowSlider} orgToken={org.token} orgId={org.id} reach={reach} formik={formik}></AudienceSelection>
                    </Box>
                </Fragment>
            }
            {
                activeStep === 1 && formik.values.selectedChannel !== 'sms' && (formik.values.campaignType == 'single_send_v2' || !formik.values.campaignType) && <TemplateSelection selectedChannel={formik.values.selectedChannel} orgToken={org.token} formik={formik}></TemplateSelection>
            }
            {
                activeStep === 1 &&   formik.values.campaignType == 'a_b_test_v2' && <TemplateSelectionAB selectedChannel={formik.values.selectedChannel} orgToken={org.token} reach={reach} formik={formik}></TemplateSelectionAB>
            }
            {
                activeStep == 1 && formik.values.campaignType !== 'a_b_test_v2' && formik.values.selectedChannel == 'sms' && <SmsCampaignForm selectedChannel={formik.values.selectedChannel} formik={formik} />
            }
            {
                activeStep === 2 && <ScheduleCampaign selectedChannel={formik.values.selectedChannel} formik={formik} errors={errors}></ScheduleCampaign>
            }

            <Flex h={'5rem'} bg={'white'} w={'calc(100% - 15rem)'} pos={'fixed'} right={'0'} bottom={0} justifyContent={'flex-end'} alignItems={'center'} px={4}>
                {/* <Text>{JSON.stringify(formik.values.scheduleData)}</Text> */}
                <OutlinedButton label={'Back'} mr={2} onClick={() => { (activeStep === 0) ? navigate("/campaign/list") : setActiveStep(activeStep - 1) }}></OutlinedButton>
                {
                    (activeStep === 2) && <PrimaryButton label={'Publish'} isDisabled={(formik.values.scheduleData.scheduleStatus == 'SCHEDULED' && !formik.values.scheduleData.scheduleAt) || (formik.values.scheduleData.scheduleStatus == 'SEND NOW')} onClick={formik.handleSubmit} isLoading={isSubmitting} type={'submit'}></PrimaryButton>
                }
                {
                    (activeStep !== 2 && formik.values.selectedChannel !== 'email') && <PrimaryButton label={'Next'} onClick={() => setActiveStep(activeStep + 1)} isDisabled={(activeStep == 0 && reach < 1) }></PrimaryButton>
                }
                {
                    (activeStep !== 2 && formik.values.selectedChannel === 'email') && <PrimaryButton label={'Next'} isDisabled={(activeStep == 0 && reach < 1)} onClick={() => setActiveStep(activeStep + 1)} ></PrimaryButton>
                }

            </Flex>
        </Container>
    )
}

const mapStateToProps = (state) => {
    return {
        ...state,
        org: state.auth.selectedOrg,
        reach: state.campaign.reach
    }
}
export default connect(mapStateToProps)(CreateCampaign)
