export function setupMoment() {
    // Check if moment is available
    function checkMoment() {
        try {
            return require("moment"); // Node.js environment
        } catch (e) {
            throw new Error("Please install moment via npm. Please reference: https://github.com/urish/angular-moment");
        }
    }

    // Get moment instance
    const moment = typeof require === "function" ? checkMoment() : (window.moment || null);
    
    if (!moment) {
        throw new Error("Moment.js is not available. Please reference: https://github.com/urish/angular-moment");
    }

    const config = {
        preprocess: null,
        timezone: null,
        format: null,
        statefulFilters: true
    };

    const timeAgoConfig = {
        withoutSuffix: false,
        serverTime: null,
        titleFormat: null,
        fullDateThreshold: null,
        fullDateFormat: null
    };

    function getTimeAgo(inputTime) {
        const now = moment();
        const targetTime = moment(inputTime);

        if (!targetTime.isValid()) {
            return "Invalid date";
        }

        return targetTime.from(now);
    }

    function formatDate(inputTime, format) {
        const targetTime = moment(inputTime);

        if (!targetTime.isValid()) {
            return "Invalid date";
        }

        return targetTime.format(format || config.format);
    }

    function timeDifference(inputTime, unit = 'minutes') {
        const now = moment();
        const targetTime = moment(inputTime);

        if (!targetTime.isValid()) {
            return "Invalid date";
        }

        return targetTime.diff(now, unit);
    }

    function addTime(inputTime, value, unit = 'days') {
        const targetTime = moment(inputTime);

        if (!targetTime.isValid()) {
            return "Invalid date";
        }

        return targetTime.add(value, unit).toISOString();
    }

    function subtractTime(inputTime, value, unit = 'days') {
        const targetTime = moment(inputTime);

        if (!targetTime.isValid()) {
            return "Invalid date";
        }

        return targetTime.subtract(value, unit).toISOString();
    }

    return {
        getTimeAgo,
        formatDate,
        timeDifference,
        addTime,
        subtractTime
    };
}

// // Example usage:
// const momentUtils = setupMoment();

// // Get time ago
// console.log(momentUtils.getTimeAgo("2024-09-05T16:53:45.375Z"));

// // Format a date
// console.log(momentUtils.formatDate("2024-09-05T16:53:45.375Z", "YYYY-MM-DD HH:mm:ss"));

// // Get difference in minutes
// console.log(momentUtils.timeDifference("2024-09-05T16:53:45.375Z", 'minutes'));

// // Add time to a date
// console.log(momentUtils.addTime("2024-09-05T16:53:45.375Z", 1, 'days'));

// // Subtract time from a date
// console.log(momentUtils.subtractTime("2024-09-05T16:53:45.375Z", 1, 'hours'));
