import { Box, FormLabel, HStack, Text, VStack } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import CampaignDraftDrawer from '../Campaigns/CampaignDraftDrawer'
import { useState } from 'react'
import PrimaryButton from '../../components/themeComponents/PrimaryButton'
import store from '../../store/store'
import Select from '../../components/themeComponents/react-chakra-select'
import { prepareOrgList } from '../../_helpers/data_helper'
import { useSteps } from '@chakra-ui/react'
import { targetAudienceSteps } from '../../_helpers/data_helper'
import CampaignStepper from '../Campaigns/components/CampaignStepper'
import { getEmailConf } from '../../Services/api'
export default function OrgAndConfig(formik) {
  const [isOpen, setIsOpen] = useState(false)

  const dummyConfiguration = [
    {
      name: "CBOI",
      from: "rewards@cboi.in",
      type: "Promotional",
      isIntegrated: true,
    },
    {
      name: "SIB",
      from: "rewards@sbi.in",
      type: "Promotional",
      isIntegrated: true,
    },
    {
      name: "IDBI",
      from: "rewards@idbi.in",
      type: "Promotional",
      isIntegrated: false,
    }
  ]
  const [emailConfiguration, setEmailConfiguration] = useState([])
  const { activeStep, setActiveStep } = useSteps({
    index: 0, count: targetAudienceSteps.length
  })
  const emailConf = async (token) => {
    let res = await getEmailConf(token).catch(err => console.error(err));
    
    setEmailConfiguration(...emailConfiguration, [res.data])
  }

  const setOrglist = (org) => {

    !!org.length && emailConf(org[org.length - 1].data.token)
    formik.formik.setFieldValue("orgList", org.map(e => {
      return e.data.token
    }))



  }
  const [orgList, setOrgList] = useState(store.getState().org.orgList)
  console.log(orgList)
  useEffect(() => {
    console.log("formik", formik)
  }, [])
  return (
    <Box maxW={'1100px'}  >

      <Text fontSize={'1vw'} >Organisation</Text>
      {
        <>
        <Box mt={'1rem'} >

          <form>

            <Text>Bank </Text>
            <Select bg={'#fff'}  isMulti options={prepareOrgList(store.getState().org.orgList)} closeMenuOnSelect={false} name="orgList" value={formik?.values?.orgList.token} onChange={(value) => { setOrglist(value) }} background={'white'} isClearable={false}></Select>
          </form>
        </Box>
        </>
      }
      <FormLabel>Configuration</FormLabel>
      <VStack bg={'#fff'} w={'100%'}  >
        <HStack justifyContent={'space-between'} w={'100%'} px={'2rem'} >

          {
            ["Bank", "From", "Configuration Type"].map((value) => (
              <Text>{value}</Text>
            ))
          }

        </HStack>
        {
          !!emailConfiguration.length && emailConfiguration.map((value) => (
            <>
              <HStack justifyContent={'space-between'} w={'100%'} px={'2rem'} >
                <Text>{value.orgName}</Text>
                <Text>{value.from.split("<")[1].split(">")[0]}</Text>
                <Text>{value.isIntegrated ? "Promotional" : ""}</Text>

              </HStack>
            </>
          ))
        }
      </VStack>
    </Box>
  )
}
