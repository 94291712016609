import {configureStore} from "@reduxjs/toolkit" 
import { authSlice } from "./Reducers/AuthReducer"
import { orgReducer } from "./Reducers/OrgReducer"
import { CampaignsSlice } from "./Reducers/CampaignReducer"

export default configureStore({
    reducer: {
        auth: authSlice.reducer,
        org:orgReducer.reducer,
        campaign: CampaignsSlice.reducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}),
})