import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
    FormControl,
    FormLabel,
    Input,
    Textarea,
    Button,
    VStack,
    Box,
    Text,
    HStack,
    Divider,
    FormErrorMessage,
    Select
} from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react';
import PrimaryButton from '../../components/themeComponents/PrimaryButton';
import MobilePreview from '../Campaigns/components/MobilePreview';
import { postSmsTemplate, updateSmsTemplate, getSmsGatways } from '../../Services/api';
import Confirmation from '../../components/themeComponents/Confirmation';
import { showSwal } from '../../_helpers/helpers';
import Swal from 'sweetalert2';
import { useLocation } from 'react-router-dom';
import OutlinedButton from '../../components/themeComponents/OutlinedButton';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useQuery } from 'react-query';

export default function SmsTemplate() {
    const navigate = useNavigate()
    const location = useLocation()
    const [showAlert, setShowAlert] = useState(false);

    const initialValues = {
        templateName: '',
        templateDescription: '',
        message: '',
        provider: ''
    };

    const validationSchema = Yup.object().shape({
        templateName: Yup.string().required('Template Name is required'),
        templateDescription: Yup.string().required('Description is required'),
        message: Yup.string().required('Message is required'),
        provider: Yup.string().required('Provider is required'),
    });
    const gatewayQuery = useQuery(['gatways'], {
        queryFn: async () => {
            let gatewayData = await getSmsGatways();
            console.log(gatewayData)
            return gatewayData.data;
        },
        enabled: true
    });

    const toast = useToast();
    const handleSubmit = async (values) => {
        if (!location?.state?.uuid) {
            let saveResponse = await postSmsTemplate(values).catch(error => {
                console.log(error)
                !!error && toast({ title: 'Oops', description: `Something went wrong while creating the template. Please try again.`, status: 'error' });
                formik.setSubmitting(false)
                Swal.close()
            });
            if (saveResponse) {
                setShowAlert(true)
            }
        } else {
            let saveResponse = await updateSmsTemplate(values, location.state.uuid).catch(error => {
                console.log(error)
                !!error && toast({ title: 'Oops', description: `Something went wrong while updating the template. Please try again.`, status: 'error' });
                formik.setSubmitting(false)
                Swal.close()
            });
            if (saveResponse) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Template updated successfully!',

                });
            }
        }
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: handleSubmit,
    });
    useEffect(() => {
        gatewayQuery.refetch()
        setTimeout(() => {
            if (!!location?.state?.uuid) {
                formik.setFieldValue("message", location.state.message);
                formik.setFieldValue("templateName", location.state.templateName);
                formik.setFieldValue("templateDescription", location.state.templateDescription);
                formik.setFieldValue("provider", location.state?.provider);
            }
        }, 0)

    }, [])
    return (
        <Box px={'4vw'} >
            <Text fontSize={'xl'} >Create Template</Text>
            <Text>SMS template</Text>
            <form onSubmit={formik.handleSubmit}>
                <VStack mt={'5vw'} maxW={'50vw'} p={'1.5vw'} spacing={4}>
                    <FormControl isInvalid={formik.errors.templateName && formik.touched.templateName}>
                        <FormLabel htmlFor="templateName">Template Name</FormLabel>
                        <Input bg={"#EEEEEE"}
                            borderRadius={'16px'}
                            id="templateName"
                            name="templateName"
                            value={formik.values.templateName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        <FormErrorMessage>{formik.errors.templateName}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={formik.errors.templateDescription && formik.touched.templateDescription}>
                        <FormLabel htmlFor="templateDescription">Description</FormLabel>
                        <Textarea
                            bg={"#EEEEEE"}
                            borderRadius={'16px'}
                            id="templateDescription"
                            name="templateDescription"
                            value={formik.values.templateDescription}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        <FormErrorMessage>{formik.errors.templateDescription}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={formik.errors.message && formik.touched.message}>
                        <FormLabel htmlFor="message">Message</FormLabel>
                        <Textarea
                            bg={"#EEEEEE"}
                            borderRadius={'16px'}
                            id="message"
                            name="message"
                            value={formik.values.message}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        <FormErrorMessage>{formik.errors.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={formik.errors.provider && formik.touched.provider}>
                        <FormLabel htmlFor="provider">Provider</FormLabel>
                        <Select
                            bg={"#EEEEEE"}
                            borderRadius={'16px'}
                            id="provider"
                            name="provider"
                            value={formik.values.provider}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        >
                            {
                                !!gatewayQuery.data && gatewayQuery.data?.gatewayList.map((list) => (
                                    <option value={list.gatewayUuid} >
                                        {list.gatewayName}
                                    </option>
                                ))
                            }

                        </Select>
                        <FormErrorMessage>{formik.errors.provider}</FormErrorMessage>
                    </FormControl>
                    <HStack alignSelf={'baseline'}>
                        <PrimaryButton alignSelf={'baseline'} label={location?.state?.uuid ? 'Update' : 'Save'} type="submit"></PrimaryButton>
                        <OutlinedButton alignSelf={'baseline'} label={'Cancel'} type="button" onClick={() => navigate('/sms/list')}></OutlinedButton>
                    </HStack>
                </VStack>
            </form>
            <Box position={'absolute'} top={'9vw'} right={'10vw'} >
                <MobilePreview channel={'sms'} message={formik.values.message} />
            </Box>
            <Confirmation isOpen={showAlert} title={'Template Created'} text="Want to create another template?" confirmButtonText={'Yes'} cancelButtonText={'Go to overview'} onConfirm={() => setShowAlert(false)} onCancel={() => { navigate('/sms/list'); setShowAlert(false) }}></Confirmation>
        </Box>

    );
};
