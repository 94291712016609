import {createSlice} from "@reduxjs/toolkit";


export const CampaignsSlice = createSlice({
    name: 'campaigns',
    initialState: {
        reach: 0,
        invalidCsvCount: [],
        segmentCount: {
            predefined: 0, rfm: 0, custom: 0
        },
        isLoading: true
    },
    reducers:{
        UPDATE_REACH: (state, action) => {
            console.log("in reducer", action)
            state.reach = action.payload.count
            state.isLoading = !!action.payload.isLoading;
        },
        UPDATE_INVALID_CSV_COUNT: (state, action) => {
            state.invalidCsvCount = action.payload
        },
        SET_SEGMENT_COUNT: (state, action) => {
            state.segmentCount[action.type] = action.payload;
        }

    }
})

export const {UPDATE_REACH, UPDATE_INVALID_CSV_COUNT, SET_SEGMENT_COUNT} = CampaignsSlice.actions