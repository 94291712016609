import { useToken, Box } from '@chakra-ui/react';
import React, { Fragment, useEffect, useState } from 'react'
import Chart from 'react-apexcharts'

import CanvasJSReact from '@canvasjs/react-charts';
import hexToRgba from 'hex-to-rgba';
import _ from 'lodash';
var CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;
export function PieChart({ series, title, labels }) {
    const [chartOptions, setChartOptions] = useState({});
    const [chartSeries, setChartSeries] = useState([]);
    const [mainColor, heading] = useToken('colors', ['primary.main', 'heading'])
    console.log("in pie chart", series)
    function initChart() {
        const options = {
            id: "basic-pie",

            //toolbar: { show: false },
            // animations: {
            //     enabled: true,
            //     easing: 'easeinout',
            //     speed: 800,
            //     animateGradually: {
            //         enabled: true,
            //         delay: 150
            //     },
            //     dynamicAnimation: {
            //         enabled: true,
            //         speed: 350
            //     }
            // },
            title: {
                text: title, style: {
                    fontSize: '16px', fontWeight: 'bold', color: heading
                }
            },
            series: series,
            labels: labels || [],
            legend: {
                show: false,
                position: 'bottom',
                horizontalAlign: 'center',
                itemMargin: {
                    horizontal: 5,
                    vertical: 10
                },
                formatter: function (seriesName, opts) {
                    return [seriesName, ':', opts.w.globals.series[opts.seriesIndex]]
                },
                markers: {
                    width: 12,
                    height: 12,
                    strokeWidth: 0,
                    strokeColor: '#fff',
                    fillColors: undefined,
                    radius: 12,
                    customHTML: undefined,
                    onClick: undefined,
                    offsetX: -2,
                    offsetY: -2
                },
            },
            theme: {
                monochrome: {
                    enabled: true,
                    color: mainColor,
                    shadeTo: 'light',
                    shadeIntensity: .5

                }
            },
            plotOptions: {
                pie: {
                    customScale: .8,
                    size: '50%',
                    donut: {
                        size: '50%',

                    }
                },



            },
            dataLabels: {
                enabled: true,
                formatter: function (val, opts) {
                    return opts.w.globals.series[opts.seriesIndex];
                },
                offsetX: -100, // Adjust the X offset to move the labels outside the chart
                offsetY: 10,  // Adjust the Y offset to move the labels outside the chart
            },
            stroke: {
                show: false
            },
            annotations: {
                position: 'front',
                yaxis: [
                    {
                        y: 3,
                        // y2: null,
                        borderColor: "red",
                        fillColor: "red",
                        strokeDashArray: 0,
                        label: {
                            borderColor: 'transparent',
                            style: {
                                color: "red",
                                background: 'transparent',
                            },
                            text: "Annotation text",
                            textAnchor: 'start',
                            position: 'left',
                        },
                    },
                ],
            },
            noData: {
                text: "No data to display",
                align: "center",
                verticalAlign: "middle",
            },
            tooltip: {
                enabled: true,
                fillSeriesColor: false,
                followCursor: true,
                fixed: {
                    enabled: true,
                    position: 'topRight',
                    offsetX: 10
                }
            }
        }
        setChartOptions(options)
        setChartSeries(series)
    }
    useEffect(() => {
        initChart()
    }, [series])
    return (
        <Chart type="donut" series={chartSeries || []} options={chartOptions}>

        </Chart>
    )
}

export function BarChart({ series, title, height }) {
    const [chartOptions, setChartOptions] = useState({});
    const [chartSeries, setChartSeries] = useState([]);
    const [mainColor, heading] = useToken('colors', ['primary.main', 'heading'])
    function initChart() {
        const options = {
            id: "basic-pie",

            //
            // animations: {
            //     enabled: true,
            //     easing: 'easeinout',
            //     speed: 800,
            //     animateGradually: {
            //         enabled: true,
            //         delay: 150
            //     },
            //     dynamicAnimation: {
            //         enabled: true,
            //         speed: 350
            //     }
            // },
            noData: {
                text: "No data to display",
                align: 'center',
                verticalAlign: 'middle',
                style: {
                    color: '#000',
                    fontSize: '14px',
                    //  fontFamily: undefined
                }
            },
            plotOptions: {
                bar: {
                    borderRadius: 5,
                    borderRadiusApplication: 'end',
                    columnWidth: '90%'
                }
            },
            title: {
                enabled: !!title,
                text: title, style: {
                    fontSize: '16px', fontWeight: '600', color: heading
                }
            },
            chart: {
                toolbar: { show: false },
            },
            legend: {
                show: false
            },
            theme: {
                monochrome: {
                    enabled: true,
                    color: mainColor,
                    shadeTo: 'light',
                    shadeIntensity: 1,
                    opacity: .4

                }
            },
            fill: {
                opacity: 0.6,

            },
            stroke: {
                show: false
            },

        }
        setChartOptions(options)
        setChartSeries(series)
    }
    useEffect(() => {
        initChart()
    }, [series])
    return (
        <Chart type='bar' series={chartSeries} options={chartOptions} height={'100%'}>

        </Chart>
    )
}

export function DonutChart({ series, title }) {
    const [chartOptions, setChartOptions] = useState({});
    const [chartSeries, setChartSeries] = useState([]);
    const [mainColor, heading] = useToken('colors', ['primary.main', 'heading'])
    function initChart() {
        const options = {
            id: "basic-pie",

            //toolbar: { show: false },
            // animations: {
            //     enabled: true,
            //     easing: 'easeinout',
            //     speed: 800,
            //     animateGradually: {
            //         enabled: true,
            //         delay: 150
            //     },
            //     dynamicAnimation: {
            //         enabled: true,
            //         speed: 350
            //     }
            // },
            title: {
                text: title, style: {
                    fontSize: '16px', color: heading
                }
            },
            labels: ['Campaigns', 'Automation', 'gghjg'],
            legend: {
                show: false
            },
            // theme: {
            //     monochrome: {
            //         enabled: true,
            //        // color: mainColor,
            //         shadeTo: 'light',
            //         shadeIntensity:.5

            //     }
            // },
            colors: ['#3ff0b9', mainColor, '#1cbbf3'],
            fill: {
                colors: ['#3ff0b9', mainColor, '#1cbbf3'],
            },
            plotOptions: {
                pie: {
                    customScale: .8,
                    //size: '50%',
                    donut: {
                        size: '50%',
                        dataLabels: {
                            offset: 100,
                            minAngleToShowLabel: 10
                        },
                        labels: {
                            labels: {
                                show: true,
                                name: {
                                    show: true,
                                    fontSize: '22px',
                                    fontFamily: 'Helvetica, Arial, sans-serif',
                                    fontWeight: 600,
                                    color: undefined,
                                    offsetY: -10,
                                    formatter: function (val) {
                                        return val
                                    }
                                },
                                value: {
                                    show: true,
                                    fontSize: '16px',
                                    fontFamily: 'Helvetica, Arial, sans-serif',
                                    fontWeight: 400,
                                    color: undefined,
                                    offsetY: 16,
                                    formatter: function (val) {
                                        return val
                                    }
                                }
                            }
                        }
                    },

                },
                stroke: {
                    show: true
                }
            }
        }
        setChartOptions(options)
        setChartSeries(series)
    }
    useEffect(() => {
        initChart()
    }, [series])
    return (
        <Chart type='pie' series={chartSeries} options={chartOptions}></Chart>
    )
}


export function ProgressChart({ data }) {
    return (
        <Box p={2} w={'100%'} display={'flex'} alignItems={'center'}>
            {
                data.length && data.map((val, index) => {
                    return (
                        <Box w={`${val.value}%`} h={'50px'} bg={val.color} borderStartRadius={index === 0 && 5} borderEndRadius={index === data.length - 1 && 5} cursor={'pointer'}></Box>
                    )
                })
            }
        </Box>
    )
}


export function DougnutCanvas({dataPoints}) {
    const [mainColor, heading] = useToken('colors', ['primary.main', 'heading'])
    CanvasJS.addColorSet("customColorSet", [hexToRgba(mainColor), hexToRgba(mainColor, .5)])
    const options = {
        colorSet: 'customColorSet',
        height: 250,
        title: {
           // text: 'Revenue Breakdown',
            horizontalAlign:'left',
            fontColor: heading, fontSize: 16, fontWeight: 'bold'

        },
        subtitles: [{
            text: _.sumBy(dataPoints, 'y') > 0 ? '' : 'No Data to display',		
            fontColor: hexToRgba(heading, .8),
            verticalAlign: 'center'
        }],
        data: [
            {
                type: 'doughnut',
                radius: '90%',
                innerRadius: '50%',
                indexLabel: "{label}: {y}",
                dataPoints: dataPoints
            },
        ],
    };
    return (
        <Box h={'100%'} width={'100%'}>
            <CanvasJSChart options={options} series={[44, 55]} height="100%"></CanvasJSChart>
        </Box>
    )
}

export function PieCanvas({dataPoints}) {
    const [mainColor, heading, color1, color2, color3] = useToken('colors', ['primary.main', 'heading', 'chartColors.color1', 'chartColors.color2', 'chartColors.color3'])
    CanvasJS.addColorSet("customColorSet", [color1, color2, mainColor, hexToRgba(mainColor, .5), color3])
    const options = {
        colorSet: 'customColorSet',
        height: 250,
        title: {
           // text: 'Revenue Breakdown',
            horizontalAlign:'left',
            fontColor: heading, fontSize: 16, fontWeight: 'bold'

        },
        subtitles: [{
            //text: _.sumBy(dataPoints, 'y') > 0 ? '' : 'No Data to display',		
            fontColor: hexToRgba(heading, .8),
            verticalAlign: 'center'
        }],
        data: [
            {
                type: 'pie',
                radius: '90%',
                innerRadius: '50%',
                indexLabel: "{label} - {y}",
                dataPoints:  dataPoints
            },
        ],
    };
    return (
        <Box h={'100%'} w={'100%'}>
            <CanvasJSChart options={options}></CanvasJSChart>
        </Box>
    )
}