import { Spinner, Text, useToast } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import OrganizationList from '../components/OrganizationList'
import { Box } from "@chakra-ui/react"
import { setSelectedOrganization } from '../Services/AuthService'
import _ from 'lodash'
import { getOrgList } from '../Services/api'
import { SET_ORG_LIST } from '../store/Reducers/OrgReducer'
import { OvalSpinner } from '../components/Loaders'
function Organization({ isAuthenticated, orgList, selectedOrg, user_data, isLoading, token }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const toast = useToast();
    const onOrgSelect = (org) => {
        if(org.token !== selectedOrg?.token){
            setSelectedOrganization(dispatch, org);
            navigate("/")
        }
    }
    const fetchOrgList = async () => {
        let orgResp = await getOrgList(token).catch(error => {
            toast({title: 'Oops!', description: 'Something went wrong while fetching the org list', status: 'error'});
        })
        dispatch(SET_ORG_LIST({orgList: orgResp?.data}))
    }

    useEffect(() => {
        !isAuthenticated && navigate('/auth');
        !!_.isEmpty(orgList) && fetchOrgList()
    }, [])
    return (
        <Box p={6}>
            <Box maxW={'560px'}  margin={'0 auto'} mb={2}>
                <Text as={'b'} fontSize={'2xl'}>Hello, <Text as={'b'} color={'primary.main'}>{user_data?.fullName}</Text></Text>
                <Text color="light">Select a store to continue.</Text>
            </Box>
            <Box maxW={'560px'} shadow={'md'} rounded={'md'} bg={'white'} px={5} margin={'0 auto'}>
                {
                    !isLoading && !!orgList.length && <OrganizationList orgList={orgList} selectedOrg={selectedOrg} onOrgSelect={(org) => onOrgSelect(org)}></OrganizationList>
                }
                {
                    !!isLoading && <OvalSpinner height={'100px'} width={'100px'}></OvalSpinner>
                }
            </Box>
        </Box>
    )
}


const mapStateToProps = (state) => {
    console.log('state', state)
    return {
        ...state,
        isAuthenticated: state.auth.isAuthenticated,
        orgList: state.org?.orgList,
        selectedOrg: state.org.selectedOrg,
        user_data: state.auth.user_data,
        isLoading: state.org.isLoading,
        token: state.auth.token
    }
}
export default connect(mapStateToProps)(Organization)
