import React from 'react';
import { useFormikContext, Field, ErrorMessage } from 'formik'; 
import { FormControl, FormLabel, Input, Select, VStack, FormErrorMessage } from '@chakra-ui/react';
import { smsChannelGatwayType } from '../../../_helpers/data_helper';

const Kaleyra = ({ setGatewayData, index }) => {
  const { errors, touched, values,setFieldValue } = useFormikContext(); 
  const handleChange = (field, event) => {
    setFieldValue(`credentials[${index}].${field}`, event.target.value);
    setGatewayData(`credentials[${index}]`, { ...values, [field]: event.target.value }); 
  };
  return (
    <VStack spacing={4} alignItems="flex-start">
      <FormControl isInvalid={errors.name && touched.name}>
        <FormLabel>Name</FormLabel>
        <Input
          name={`credentials[${index}].name`}
          value={values.name}
          onChange={(e) => handleChange('name', e)}
        />
        <ErrorMessage name={`credentials[${index}].name`} component={FormErrorMessage} />
      </FormControl>

      <FormControl isInvalid={errors.apiKey && touched.apiKey}>
        <FormLabel>API Key</FormLabel>
        <Input
          name={`credentials[${index}].apiKey`}
          value={values.apiKey}
          onChange={(e) => handleChange('apiKey', e)}
        />
        <ErrorMessage name={`credentials[${index}].apiKey`} component={FormErrorMessage} />
      </FormControl>

      <FormControl isInvalid={errors.senderId && touched.senderId}>
        <FormLabel>Sender ID</FormLabel>
        <Input
          name={`credentials[${index}].senderId`}
          value={values.apiKey}
          onChange={(e) => handleChange('senderId', e)}
        />
        <ErrorMessage name={`credentials[${index}].senderId`} component={FormErrorMessage} />
      </FormControl>

      <FormControl isInvalid={errors.gatewayType && touched.gatewayType}>
        <FormLabel>Gateway Type</FormLabel>
        <Select
          name={`credentials[${index}].gatewayType`}
          value={values.gatewayType}
          onChange={(e) => handleChange('gatewayType', e)}
        >
          <option value="">Select Gateway Type</option>
          {smsChannelGatwayType.map((option) => (
            <option key={option.key} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
        <ErrorMessage name={`credentials[${index}].gatewayType`} component={FormErrorMessage} />
      </FormControl>
    </VStack>
  );
};

export default Kaleyra;

