import { Stack, Heading, Box, Divider, Text, SimpleGrid, HStack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Recipecard from '../../components/themeComponents/RecipeCard'
import { getRecipes } from '../../Services/DoubletapService'
import PrimaryButton from '../../components/themeComponents/PrimaryButton'
import OutlinedButton from '../../components/themeComponents/OutlinedButton'
import * as _ from 'lodash'
import { recipes } from './components/RecipeCardsData'
import { Outlet, useNavigate } from 'react-router-dom'
export default function PredefinedFlows() {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const [headerText, setHeaderText] = useState({
        title: t('flows.select.goal'),
        description: t('flows.goal.subtitle')
    })
    useEffect(() => {
        getRecipes().then((res) => {

            recipes.forEach(recipe => {
                Object.keys(recipe['navigateTo']['state']).forEach(key => {
                    if (res.data.hasOwnProperty(key)) {
                        recipe['navigateTo']['state'][key] = res.data[key];
                    }
                });
                recipe['navigateTo']['state']['icon'] = recipe.icon;
                recipe['navigateTo']['state']['title'] = recipe.title;
                recipe['navigateTo']['state']['description'] = recipe.description;
            });

        })
    }, [recipes[0].navigateTo.state['Welcome Message']])

    return (
        <>
            <Stack>
                <Box width={'100%'} p={'1vw'}  >
                    <Heading fontSize={'1.5vw'} fontWeight={'400'} >
                        {t('flows.recipe.header')}
                    </Heading>
                    <Divider />
                </Box>

                <HStack justifyContent={'space-between'} >
                    <Box p={'0 1.2vw'} >
                        <Text fontSize={'1vw'} >
                            {headerText.title}
                        </Text>
                        <Text fontSize={'0.9vw'} >
                            {headerText.description}
                        </Text>
                    </Box>
                    <OutlinedButton width={'10vw'} borderRadius={'8px'} label={t('flows.build.own')} onClick={() => navigate("/flows/create")} />
                </HStack>
                <Box>

                    <Outlet
                        context={{
                            setHeaderText: setHeaderText,
                            headerText: headerText,
                        }}
                    />
                </Box>
            </Stack>
        </>
    )
}
