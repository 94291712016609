import React, { useMemo } from 'react'
import { Box, FormControl, FormLabel, InputGroup, InputLeftElement, Icon, Input, Select } from '@chakra-ui/react';
import { campaignStatus } from './../../../_helpers/data_helper'
import { BiSearch } from 'react-icons/bi';
import { useFormik } from 'formik';
import _, { debounce } from 'lodash';
function CampaignFilter({onFilter, channels,setSelectedChannel}) {
    function handleSubmit(values, action) {
       onFilter(values)
    }
    const formik = useFormik({
        initialValues: {
            campaignName: '',
            channel: '',
            status: 'ALL'
        },
        onSubmit: handleSubmit
    })
    const debounceFn = useMemo(() => _.debounce(formik.handleSubmit, 1000),[])
    function searchCampaign(e) {
        if(e.target.value.length > 2 || e.target.value.length === 0){
            formik.setFieldValue("campaignName", e.target.value)
            debounceFn(e.target.value)
        }
    
    }
    return (
        <Box display={'flex'} p={'1rem'} width={'99%'} bg={'white'} gap={3} >
          <FormControl maxW={'350px'}>
            <FormLabel color={'inputLabel'} fontSize={'1vw'} fontWeight={'600'}>Search Campaign</FormLabel>
            <InputGroup>
              <InputLeftElement pointerEvents={'none'}>
                <Icon as={BiSearch} color={'gray.500'}></Icon>
              </InputLeftElement>
              <Input onChange={searchCampaign}></Input>
            </InputGroup>
          </FormControl>
          <FormControl maxW={'350px'}>
            <FormLabel color={'inputLabel'} fontSize={'1vw'} fontWeight={'600'}>Status</FormLabel>
            <Select onChange={(e) => {
                formik.setFieldValue("status", e.target.value);
                formik.handleSubmit()
            }}> 
              {
                campaignStatus.map(status => {
                  return <option value={status.key}>{status.label}</option>
                })
              }
            </Select>
          </FormControl>

          <FormControl maxW={'350px'}>
            <FormLabel color={'inputLabel'} fontSize={'1vw'} fontWeight={'600'}>Channel</FormLabel>
            <Select onChange={(e) => {
                formik.setFieldValue("channel", e.target.value);
                // setSelectedChannel(e.target.value)
                formik.handleSubmit()
            }}>
              
              {
                Object.keys(channels).map(channel => {
                  return <option value={channel}>{channel}</option>
                })
              }
            </Select>
          </FormControl>

        </Box>
    )
}

export default CampaignFilter
