import React,{useEffect} from 'react'
import {saveShopifyToken} from '../../Services/IntegrationService';
import { redirect, useNavigate} from 'react-router-dom';
import { Box, useToken } from '@chakra-ui/react'
import { OvalSpinner } from '../../components/Loaders';

export default function ShopifyAuth() {
  const [primary] = useToken('colors', ['primary.main']);
  const navigate = useNavigate()
  useEffect(()=>{
  let params = window.location.pathname + window.location.search
  saveShopifyToken(params).then((res)=>{
    console.log(res);
    navigate('/integrations/platforms')

  }).catch((err)=>{
    console.log(err);
    navigate('/integrations/platforms')
  })
},[])

  return (
    <Box>
      <OvalSpinner height={20} width={20} color={primary}></OvalSpinner>
    </Box>
  )
}
