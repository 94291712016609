import { Fragment, forwardRef, useImperativeHandle, useRef } from "react"
import { Text, Grid, FormControl, FormLabel, Box, Input, Select, Divider, Icon, FormHelperText, Flex, InputRightElement, InputGroup, InputRightAddon, Checkbox } from "@chakra-ui/react"
import { SketchPicker } from "react-color"
import ColorPicker from "../../../components/themeComponents/ColorPicker"
import { onsiteFonts } from "../../../_helpers/FontFamilies"
import ImageGallery from "../../../components/ImageGallery"
import { useFormik } from "formik"
import { FaInfoCircle } from "react-icons/fa"
import InputWithEmoji from "../../../components/InputWithEmoji"



export const TitleForm = forwardRef(({ data, onSubmit, formName }, ref) => {
    const formRef = useRef(null)
    const formik = useFormik({
        initialValues: data,
        innerRef: formRef,
        onSubmit: (values) => {
            onSubmit(values, formName)
        }
    })
    useImperativeHandle(ref, () => ({
        submitForm: () => {
            formik.submitForm()
        }
    }))

    return (
        <Fragment>
            <Box>
                <Text as={'b'} fontSize={'xl'}>Title</Text>
                <Text fontSize={'md'}>Visual settings for Title can be managed here. HTML tags are supported</Text>
                <form onSubmit={formik.handleSubmit}>
                    <Grid templateColumns={'1fr 1fr'} gap={5} mt={3}>

                        <FormControl>
                            <FormLabel color={'inputLabel'}>Text</FormLabel>
                            <InputWithEmoji onChange={(e) => formik.setFieldValue("text", e)} value={formik.values.text}></InputWithEmoji>
                        </FormControl>
                        <FormControl>
                            <FormLabel color={'inputLabel'}>Font Size</FormLabel>
                            <InputGroup>
                                <Input value={formik.values.fontSize} onChange={(e) => formik.setFieldValue("fontSize", e.target.value)}></Input>
                                <InputRightAddon>px</InputRightAddon>
                            </InputGroup>
                        </FormControl>
                        <FormControl>
                            <FormLabel color={'inputLabel'}>Text Color</FormLabel>
                            <ColorPicker value={formik.values.color} onColorSelect={(val) => formik.setFieldValue("color", val)}></ColorPicker>
                        </FormControl>
                        <FormControl>
                            <FormLabel color={'inputLabel'}>Font Family</FormLabel>
                            <Select value={formik.values.fontFamily} placeholder="Select Font">
                                {
                                    onsiteFonts.map(font => (
                                        <option key={font.code} value={font.code}>{font.name}</option>
                                    ))
                                }
                            </Select>
                        </FormControl>

                    </Grid>
                    <button type="submit" style={{ display: "none" }}></button>
                </form>
            </Box>
            <Divider my={4}></Divider>
        </Fragment>
    )
})

export const SubtitleForm = forwardRef(({ data, onSubmit, formName }, ref) => {
    const formRef = useRef(null)
    const formik = useFormik({
        initialValues: data,
        innerRef: formRef,
        onSubmit: (values) => {
            onSubmit(values, formName)
        }
    })
    useImperativeHandle(ref, () => ({
        submitForm: () => {
            formik.submitForm()
        }
    }))
    return (
        <Fragment>
            <Box>
                <Text as={'b'} fontSize={'xl'}>Subtitle</Text>
                <Text fontSize={'md'}>Visual settings for sub-title can be managed here. HTML tags are supported.</Text>
                <Grid templateColumns={'1fr 1fr'} gap={5} mt={3}>
                    <FormControl>
                        <FormLabel color={'inputLabel'}>Text</FormLabel>
                        {/* <EmojiPicker></EmojiPicker> */}
                        <InputWithEmoji onChange={(e) => formik.setFieldValue("text", e)} value={formik.values.text}></InputWithEmoji>

                    </FormControl>
                    <FormControl>
                        <FormLabel color={'inputLabel'}>Font Size</FormLabel>
                        <InputGroup>
                            <Input value={formik.values.fontSize} onChange={(e) => formik.setFieldValue("fontSize", e.target.value)}></Input>
                            <InputRightAddon>px</InputRightAddon>
                        </InputGroup>
                    </FormControl>
                    <FormControl>
                        <FormLabel color={'inputLabel'}>Text Color</FormLabel>
                        <ColorPicker value={formik.values.color} onColorSelect={(val) => formik.setFieldValue("color", val)}></ColorPicker>
                    </FormControl>
                    <FormControl>
                        <FormLabel color={'inputLabel'}>Font Family</FormLabel>
                        <Select value={formik.values.fontFamily} placeholder="Select Font">
                            {
                                onsiteFonts.map(font => (
                                    <option key={font.code} value={font.code}>{font.name}</option>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Grid>
            </Box>
            <Divider my={4}></Divider>
        </Fragment>
    )
})

export const DescriptionForm = forwardRef(({ data, onSubmit, formName }, ref) => {
    const formRef = useRef(null)
    const formik = useFormik({
        initialValues: data,
        innerRef: formRef,
        onSubmit: (values) => {
            onSubmit(values, formName)
        }
    })
    useImperativeHandle(ref, () => ({
        submitForm: () => {
            formik.submitForm()
        }
    }))

    return (
        <Fragment>
            <Box>
                <Text as={'b'} fontSize={'xl'}>Description</Text>
                <Text fontSize={'md'}>Visual settings for Description can be managed here. HTML tags are supported</Text>
                <Grid templateColumns={'1fr 1fr'} gap={5} mt={3}>
                    <FormControl>
                        <FormLabel color={'inputLabel'}>Text</FormLabel>
                        <Input value={formik.values.text} onChange={(e) => formik.setFieldValue("text", e.target.value)}></Input>
                    </FormControl>
                    <FormControl>
                        <FormLabel color={'inputLabel'}>Font Size</FormLabel>
                        <InputGroup>
                            <Input value={formik.values.fontSize} onChange={(e) => formik.setFieldValue("fontSize", e.target.value)}></Input>
                            <InputRightAddon>px</InputRightAddon>
                        </InputGroup>
                    </FormControl>
                    <FormControl>
                        <FormLabel color={'inputLabel'}>Text Color</FormLabel>
                        <ColorPicker value={formik.values.color} onColorSelect={(val) => formik.setFieldValue("color", val)}></ColorPicker>
                    </FormControl>
                    <FormControl>
                        <FormLabel color={'inputLabel'}>Font Family</FormLabel>
                        <Select value={formik.values.fontFamily} placeholder="Select Font">
                            {
                                onsiteFonts.map(font => (
                                    <option key={font.code} value={font.code}>{font.name}</option>
                                ))
                            }
                        </Select>
                    </FormControl>
                    <FormControl>
                        <ImageGallery></ImageGallery>
                    </FormControl>
                </Grid>
            </Box>
            <Divider my={4}></Divider>
        </Fragment>
    )
})

export const BackgroundForm = forwardRef(({ data, onSubmit, formName }, ref) => {
    const formRef = useRef(null)
    const formik = useFormik({
        initialValues: data,
        innerRef: formRef,
        onSubmit: (values) => {
            onSubmit(values, formName)
        }
    })
    useImperativeHandle(ref, () => ({
        submitForm: () => {
            formik.submitForm()
        }
    }))
    return (
        <Fragment>
            <Box>
                <Text as={'b'} fontSize={'xl'} display={'flex'} alignItems={'center'}>Background Settings <Icon as={FaInfoCircle} ml={2} cursor={'pointer'} title="Background color or background image can be applied. If both provided Background image will get applied"></Icon></Text>
                <Text fontSize={'md'}>Apply background on your template</Text>
                <Grid templateColumns={'1fr 1fr'} gap={5} mt={3}>
                    <FormControl>
                        <FormLabel color={'inputLabel'}>Color</FormLabel>
                        <ColorPicker value={formik.values.color} onColorSelect={(val) => formik.setFieldValue("color", val)}></ColorPicker>
                    </FormControl>
                    <FormControl>
                        <FormLabel color={'inputLabel'}>Background Image</FormLabel>
                        <Input value={formik.values.url} onChange={(e) => formik.setFieldValue("url", e.target.value)}></Input>
                        <Flex justifyContent={'space-between'}>
                            <FormHelperText>{formik.values.placeHolder}</FormHelperText>
                            <ImageGallery setImageData={(data) => formik.setFieldValue("url", data?.mediaFileUrl)} renderComponent={<FormHelperText textDecoration={'underline'}>Select From Gallery</FormHelperText>} ></ImageGallery>


                        </Flex>

                    </FormControl>
                </Grid>
            </Box>
            <Divider my={4}></Divider>
        </Fragment>
    )
})

export const ConfirmButton = forwardRef(({ data, onSubmit, formName }, ref) => {
    const formRef = useRef(null)
    const formik = useFormik({
        initialValues: data,
        innerRef: formRef,
        onSubmit: (values) => {
            onSubmit(values, formName)
        }
    })
    useImperativeHandle(ref, () => ({
        submitForm: () => {
            formik.submitForm()
        }
    }))
    return (
        <Fragment>
            <Box>
                <Text as={'b'} fontSize={'xl'} display={'flex'} alignItems={'center'}>Button</Text>
                <Text fontSize={'md'}>Visual settings for button can be managed here. HTML tags are supported</Text>
                <Grid templateColumns={'1fr 1fr'} gap={5} mt={3}>
                    <FormControl>
                        <FormLabel color={'inputLabel'}>Button Text</FormLabel>
                        <Input value={formik.values.text} onColorSelect={(val) => formik.setFieldValue("text", val)}></Input>
                    </FormControl>
                    <FormControl>
                        <FormLabel color={'inputLabel'}>Button Text Font Family</FormLabel>
                        <Select value={formik.values.fontFamily} placeholder="Select Font">
                            {
                                onsiteFonts.map(font => (
                                    <option key={font.code} value={font.code}>{font.name}</option>
                                ))
                            }
                        </Select>
                    </FormControl>
                    <FormControl>
                        <FormLabel color={'inputLabel'}>Button Font Size</FormLabel>
                        <InputGroup>
                            <Input value={formik.values.fontSize} onChange={(e) => formik.setFieldValue("fontSize", e.target.value)}></Input>
                            <InputRightAddon>px</InputRightAddon>
                        </InputGroup>
                    </FormControl>
                    <FormControl>
                        <FormLabel color={'inputLabel'}>Button Text Color</FormLabel>
                        <ColorPicker value={formik.values.color} onColorSelect={(val) => formik.setFieldValue("color", val)}></ColorPicker>
                    </FormControl>
                    <FormControl>
                        <FormLabel color={'inputLabel'}>Button Background Color</FormLabel>
                        <ColorPicker value={formik.values.background.color} onColorSelect={(val) => formik.setFieldValue("background.color", val)}></ColorPicker>
                    </FormControl>

                </Grid>
            </Box>
            <Divider my={4}></Divider>
        </Fragment>
    )
})

export const CancelButton = forwardRef(({ data, formName, onSubmit }, ref) => {
    const formRef = useRef(null)
    const formik = useFormik({
        initialValues: data,
        innerRef: formRef,
        onSubmit: (values) => {
            onSubmit(values, formName)
        }
    })
    useImperativeHandle(ref, () => ({
        submitForm: () => {
            formik.submitForm()
        }
    }))

    return (
        <Fragment>
            <Box>
                <Text as={'b'} fontSize={'xl'} display={'flex'} alignItems={'center'}>Cancel Text</Text>
                <Text fontSize={'md'}>Visual settings for cancel button can be managed here. HTML tags are supported</Text>
                <Grid templateColumns={'1fr 1fr'} gap={5} mt={3}>
                    <FormControl>
                        <FormLabel color={'inputLabel'}>Text</FormLabel>
                        <Input value={formik.values.text} onChange={(e) => formik.setFieldValue("text", e.target.value)}></Input>
                    </FormControl>
                    <FormControl>
                        <FormLabel color={'inputLabel'}>Font Size</FormLabel>
                        <InputGroup>
                            <Input value={formik.values.fontSize} onChange={(e) => formik.setFieldValue("fontSize", e.target.value)}></Input>
                            <InputRightAddon>px</InputRightAddon>
                        </InputGroup>
                    </FormControl>
                    <FormControl>
                        <FormLabel color={'inputLabel'}>Color</FormLabel>
                        <ColorPicker value={formik.values.color} onColorSelect={(val) => formik.setFieldValue("color", val)}></ColorPicker>
                    </FormControl>
                    <FormControl>
                        <FormLabel color={'inputLabel'}>Font Family</FormLabel>
                        <Select value={formik.values.fontFamily} placeholder="Select Font">
                            {
                                onsiteFonts.map(font => (
                                    <option key={font.code} value={font.code}>{font.name}</option>
                                ))
                            }
                        </Select>
                    </FormControl>

                </Grid>
            </Box>
            <Divider my={4}></Divider>
        </Fragment>
    )
})

export const CouponForm = forwardRef(({ data, formName, onSubmit }, ref) => {
    const formRef = useRef(null)
    const formik = useFormik({
        initialValues: data,
        innerRef: formRef,
        onSubmit: (values) => {
            onSubmit(values, formName)
        }
    })
    useImperativeHandle(ref, () => ({
        submitForm: () => {
            formik.submitForm()
        }
    }))
    return (
        <Fragment>
            <Box>
                <Text as={'b'} fontSize={'xl'} display={'flex'} alignItems={'center'}>Coupon</Text>
                <Text fontSize={'md'}>Visual settings for coupon can be managed here. HTML tags are supported</Text>
                <Checkbox isChecked={formik.values.show} my={2} onChange={(e) => formik.setFieldValue("show", e.target.checked)}>Show Coupon</Checkbox>
                {
                    !!formik.values.show && <Grid templateColumns={'1fr 1fr'} gap={5} mt={3}>
                        <FormControl>
                            <FormLabel color={'inputLabel'}>Coupon Code</FormLabel>
                            <Input value={formik.values.text} onChange={(e) => formik.setFieldValue("text", e.target.value)}></Input>
                        </FormControl>
                        <FormControl>
                            <FormLabel color={'inputLabel'}>Coupon Text Size</FormLabel>
                            <InputGroup>
                                <Input value={formik.values.fontSize} onChange={(e) => formik.setFieldValue("fontSize", e.target.value)}></Input>
                                <InputRightAddon>px</InputRightAddon>
                            </InputGroup>
                        </FormControl>
                        <FormControl>
                            <FormLabel color={'inputLabel'}>Coupon Font</FormLabel>
                            <Select value={formik.values.fontFamily} placeholder="Select Font">
                                {
                                    onsiteFonts.map(font => (
                                        <option key={font.code} value={font.code}>{font.name}</option>
                                    ))
                                }
                            </Select>
                        </FormControl>
                        <FormControl>
                            <FormLabel color={'inputLabel'}>Coupon Text Color</FormLabel>
                            <ColorPicker value={formik.values.color} onColorSelect={(val) => formik.setFieldValue("color", val)}></ColorPicker>
                        </FormControl>
                        <FormControl>
                            <FormLabel color={'inputLabel'}>Coupon Border Color</FormLabel>
                            <ColorPicker value={formik.values.border.color} onColorSelect={(val) => formik.setFieldValue("border.color", val)}></ColorPicker>
                        </FormControl>
                    </Grid>
                }

            </Box>
            <Divider my={4}></Divider>
        </Fragment>
    )
})

export const Ribbon = forwardRef(({ data, formName, onSubmit }, ref) => {
    const formRef = useRef(null)
    const formik = useFormik({
        initialValues: data,
        innerRef: formRef,
        onSubmit: (values) => {
            onSubmit(values, formName)
        }
    })
    useImperativeHandle(ref, () => ({
        submitForm: () => {
            formik.submitForm()
        }
    }))
    return (
        <Fragment>
            <Box>
                <Text as={'b'} fontSize={'xl'} display={'flex'} alignItems={'center'}>Ribbon <Icon as={FaInfoCircle} ml={2} cursor={'pointer'} title="There will be no Ribbon if Ribbon text is left blank and Onsite Push will be closed"></Icon></Text>
                <Text fontSize={'md'}>Visual settings for Ribbon can be managed here. HTML tags are supported</Text>
                <Checkbox isChecked={formik.values.show} my={2} onChange={(e) => formik.setFieldValue("show", e.target.checked)}>Show Ribbon</Checkbox>
                {
                    !!formik.values.show && <Grid templateColumns={'1fr 1fr'} gap={5} mt={3}>
                        <FormControl>
                            <FormLabel color={'inputLabel'}>Text</FormLabel>
                            <Input value={formik.values.text} onChange={(e) => formik.setFieldValue("text", e.target.value)}></Input>
                        </FormControl>
                        <FormControl>
                            <FormLabel color={'inputLabel'}>Font Size</FormLabel>
                            <InputGroup>
                                <Input value={formik.values.fontSize} onChange={(e) => formik.setFieldValue("fontSize", e.target.value)}></Input>
                                <InputRightAddon>px</InputRightAddon>
                            </InputGroup>
                        </FormControl>
                        <FormControl>
                            <FormLabel color={'inputLabel'}>Text Color</FormLabel>
                            <ColorPicker value={formik.values.color} onColorSelect={(val) => formik.setFieldValue("color", val)}></ColorPicker>
                        </FormControl>
                        <FormControl>
                            <FormLabel color={'inputLabel'}>Text Font Family</FormLabel>
                            <Select value={formik.values.fontFamily} placeholder="Select Font">
                                {
                                    onsiteFonts.map(font => (
                                        <option key={font.code} value={font.code}>{font.name}</option>
                                    ))
                                }
                            </Select>
                        </FormControl>
                        <FormControl>
                            <FormLabel color={'inputLabel'}>Background Color</FormLabel>
                            <ColorPicker value={formik.values.background.color} onColorSelect={(val) => formik.setFieldValue("background.color", val)}></ColorPicker>

                        </FormControl>

                    </Grid>
                }
            </Box>
        </Fragment>
    )
})