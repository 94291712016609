import React, { Fragment } from 'react'
import Header from './Header'
import { Box } from '@chakra-ui/react'

function Index() {
    return (
        <Fragment>
           
            <Header />
           
        </Fragment>
    )
}

export default Index
