import { Text, Box, Flex, Icon, Divider, Stack, Button, Select, Input, CloseButton, Checkbox, FormLabel } from '@chakra-ui/react'
import { FieldArray, FormikProvider, useFormik } from 'formik';
import React, { Fragment, forwardRef, useImperativeHandle } from 'react'
import { FaCheckCircle, FaPlus } from "react-icons/fa";
import SecondaryButton from '../../../components/themeComponents/SecondaryButton';
import { locationPlatformCriteriaProperties, locationPlatformCriteriaPropertiesOperator, urlTargetingOperators } from '../../../_helpers/data_helper';
import CountryDropdown from '../../../components/CountryDropdown';
import StatesDropdown from '../../../components/StatesDropdown';
import BrowserDropdown from '../../../components/BrowserDropdown';
import LanguageDropdwon from '../../../components/LanguageDropdwon';

const NudgeTargeting = forwardRef(({ data, updateTemplateSettings }, ref) => {
    const formik = useFormik({
        initialValues: data,
        onSubmit: (values) => {
            updateTemplateSettings(values)
        }
    })
    useImperativeHandle(ref, () => ({
        submitToParent: () => {
            formik.submitForm()
        }
    }))
    return (
        <Fragment>
            <Box height={'100%'} bg={'white'} p={2} overflow={'auto'}>
                <Text as={'b'} fontSize={'xl'} display={'flex'} alignItems={'center'}>Device Targeting </Text>
                <Flex gap={4} alignItems={'center'} mt={4} px={3}>
                    <Box maxW={'300px'} border={'3px solid'} borderColor={formik.values.device.screen.desktop.show ? 'primary.main' : 'gray.200'}
                        w={'100%'} rounded={5} maxH={'300px'} minH={'100px'} p={4} cursor={'pointer'} position={'relative'}
                        onClick={() => formik.setFieldValue("device.screen.desktop.show", !formik.values.device.screen.desktop.show)}>
                        <Text as={'b'} fontSize={20}>Desktop</Text>
                        <Text>The pop-up will be shown on desktops. </Text>
                        <Icon as={FaCheckCircle} position={'absolute'} right={'10px'} top={"10px"} color={formik.values.device.screen.desktop.show ? 'primary.main' : 'gray.300'}></Icon>
                    </Box>
                    <Box maxW={'300px'} w={'100%'} border={'3px solid'} borderColor={formik.values.device.screen.mobile.show ? 'primary.main' : 'gray.200'}
                        rounded={5} maxH={'300px'} minH={'100px'} p={4} cursor={'pointer'} position={'relative'}
                        onClick={() => formik.setFieldValue("device.screen.mobile.show", !formik.values.device.screen.mobile.show)}>
                        <Text as={'b'} fontSize={20}>Mobile</Text>
                        <Text>The pop-up will be shown on tablets. </Text>
                        <Icon as={FaCheckCircle} position={'absolute'} right={'10px'} top={"10px"} color={formik.values.device.screen.mobile.show ? 'primary.main' : 'gray.300'}></Icon>
                    </Box>
                    <Box maxW={'300px'} w={'100%'} border={'3px solid'} borderColor={formik.values.device.screen.tablet.show ? 'primary.main' : 'gray.200'}
                        rounded={5} maxH={'300px'} minH={'100px'} p={4} cursor={'pointer'} position={'relative'}
                        onClick={() => formik.setFieldValue("device.screen.tablet.show", !formik.values.device.screen.tablet.show)}>
                        <Text as={'b'} fontSize={20}>Tablet</Text>
                        <Text>The pop-up will be shown on mobiles.</Text>
                        <Icon as={FaCheckCircle} position={'absolute'} right={'10px'} top={"10px"} color={formik.values.device.screen.tablet.show ? 'primary.main' : 'gray.300'}></Icon>
                    </Box>
                </Flex>
                <Divider my={4}></Divider>
                <Text as={'b'} fontSize={'xl'} display={'flex'} alignItems={'center'}>
                    Show pop up to mapped users also
                    <Checkbox ml={3} isChecked={formik.values.showToMappedUsers} onChange={(e) => formik.setFieldValue("showToMappedUsers", e.target.checked)}></Checkbox>
                </Text>
                <Divider my={4}></Divider>
                {/* Url Targeting */}
                <Text as={'b'} fontSize={'xl'} display={'flex'} alignItems={'center'}>Url Targeting </Text>
                <Flex gap={4} alignItems={'center'} mt={4} px={3}>
                    <Box maxW={'350px'} border={'3px solid'} borderColor={formik.values.urlSettings.urlTargeting.choice === 'ALL' ? 'primary.main' : 'gray.200'}
                        w={'100%'} rounded={5} maxH={'300px'} minH={'100px'} p={4} cursor={'pointer'} position={'relative'}
                        onClick={() => formik.setFieldValue("urlSettings.urlTargeting.choice", 'ALL')}>
                        <Text as={'b'} fontSize={20}>Show Popup on all pages.</Text>
                        <Text>Pop-up will be shown on all the pages/urls of your website.</Text>
                        <Icon as={FaCheckCircle} position={'absolute'} right={'10px'} top={"10px"} color={formik.values.urlSettings.urlTargeting.choice === 'ALL' ? 'primary.main' : 'gray.300'}></Icon>
                    </Box>
                    <Box maxW={'350px'} w={'100%'} border={'3px solid'} borderColor={formik.values.urlSettings.urlTargeting.choice === 'SPECIFIC' ? 'primary.main' : 'gray.200'}
                        rounded={5} maxH={'300px'} minH={'100px'} p={4} cursor={'pointer'} position={'relative'}
                        onClick={() => formik.setFieldValue("urlSettings.urlTargeting.choice", 'SPECIFIC')}>
                        <Text as={'b'} fontSize={20}>Show popup on specific pages.</Text>
                        <Text>Popup can be shown/hidden on specific pages/urls.</Text>
                        <Icon as={FaCheckCircle} position={'absolute'} right={'10px'} top={"10px"} color={formik.values.urlSettings.urlTargeting ? 'primary.main' : 'gray.300'}></Icon>
                    </Box>

                </Flex>
                <Box mt={3} px={3}>
                    {
                        formik.values.urlSettings.urlTargeting.choice === 'SPECIFIC' && <Box>
                            <Text>Global Operator</Text>
                            <FormikProvider value={formik}>
                                <FieldArray name="urlSettings.urlTargeting.criteria" render={arrayHelpers => (
                                    <Fragment>
                                        <Stack direction={'row'} gap={0} mb={5}>
                                            <Button bg={formik.values.urlSettings.urlTargeting.globalOperator === 'AND' ? 'primary.main' : 'gray.100'}
                                                color={formik.values.urlSettings.urlTargeting.globalOperator === 'AND' ? 'white' : 'black'} onClick={() => formik.setFieldValue("urlSettings.urlTargeting.globalOperator", 'AND')}>AND</Button>
                                            <Button bg={formik.values.urlSettings.urlTargeting.globalOperator === 'OR' ? 'primary.main' : 'gray.100'}
                                                color={formik.values.urlSettings.urlTargeting.globalOperator === 'OR' ? 'white' : 'black'} onClick={() => formik.setFieldValue("urlSettings.urlTargeting.globalOperator", 'OR')}>OR</Button>
                                            <SecondaryButton ml={6} rightIcon={<FaPlus></FaPlus>} label={'Add Filter'} onClick={() => arrayHelpers.push({ operator: '', value: '' })}></SecondaryButton>
                                        </Stack>
                                        {
                                            !!formik.values.urlSettings.urlTargeting.criteria.length && formik.values.urlSettings.urlTargeting.criteria.map((criterion, index) => (
                                                <Fragment>
                                                    <Stack direction={'row'} gap={3} key={index} mb={3} mt={2} alignItems={'center'}>
                                                        {
                                                            <Box bg={'gray.200'} p={2} opacity={index !== 0 ? 1 : 0}>{formik.values.urlSettings.urlTargeting.globalOperator}</Box>
                                                        }

                                                        <Text as={'b'}>URL</Text>
                                                        <Select placeholder='Select operator' value={formik.values.urlSettings.urlTargeting.criteria[index].operator} onChange={(e) => formik.setFieldValue(`urlSettings.urlTargeting.criteria.${index}.operator`, e.target.value)}>
                                                            {
                                                                urlTargetingOperators.map(op => (
                                                                    <option key={op.value} value={op.value}>{op.label}</option>
                                                                ))
                                                            }
                                                        </Select>
                                                        <Input value={formik.values.urlSettings.urlTargeting.criteria[index].value} onChange={(e) => formik.setFieldValue(`urlSettings.urlTargeting.criteria.${index}.value`, e.target.value)}></Input>
                                                        {
                                                            formik.values.urlSettings.urlTargeting.criteria.length > 1 && <CloseButton onClick={() => arrayHelpers.remove()}></CloseButton>
                                                        }


                                                    </Stack>

                                                </Fragment>
                                            ))
                                        }
                                    </Fragment>

                                )}></FieldArray>

                            </FormikProvider>

                        </Box>
                    }
                </Box>
                <Divider my={4}></Divider>
                <Text as={'b'} fontSize={'xl'} display={'flex'} alignItems={'center'}>Location, Language and Platform Targeting <Checkbox ml={3} isChecked={formik.values.locationAndDeviceTargeting.status} onChange={(e) => formik.setFieldValue("locationAndDeviceTargeting.status", e.target.checked)}></Checkbox></Text>
                {
                    !!formik.values.locationAndDeviceTargeting.status && <Fragment>
                        <Text mt={2} px={3}>Global Operator</Text>
                        <FormikProvider value={formik}>
                            <FieldArray name='locationAndDeviceTargeting.criteria' render={arrayHelpers => (
                                <Fragment>
                                    <Stack direction={'row'} gap={0} mb={5} px={3}>
                                        <Button bg={formik.values.locationAndDeviceTargeting.globalOperator === 'AND' ? 'primary.main' : 'gray.100'}
                                            color={formik.values.locationAndDeviceTargeting.globalOperator === 'AND' ? 'white' : 'black'} onClick={() => formik.setFieldValue("urlSettings.urlTargeting.globalOperator", 'AND')}>AND</Button>
                                        <Button bg={formik.values.locationAndDeviceTargeting.globalOperator === 'OR' ? 'primary.main' : 'gray.100'}
                                            color={formik.values.locationAndDeviceTargeting.globalOperator === 'OR' ? 'white' : 'black'} onClick={() => formik.setFieldValue("urlSettings.urlTargeting.globalOperator", 'OR')}>OR</Button>
                                        <SecondaryButton ml={6} rightIcon={<FaPlus></FaPlus>} label={'Add Criteria'} onClick={() => arrayHelpers.push({ criterion: '', operator: '', value: '' })}></SecondaryButton>
                                    </Stack>
                                    {
                                        !!formik.values.locationAndDeviceTargeting.criteria.length && formik.values.locationAndDeviceTargeting.criteria.map((criterion, index) => (
                                            <Fragment>
                                                <Stack direction={'row'} gap={3} key={index} mb={3} mt={2} alignItems={'center'}>
                                                    {
                                                        <Box bg={'gray.200'} p={2} opacity={index !== 0 ? 1 : 0}>{formik.values.locationAndDeviceTargeting.globalOperator}</Box>
                                                    }
                                                    <Select value={formik.values.locationAndDeviceTargeting.criteria[index].criterion} placeholder='Select Property' onChange={(e) => formik.setFieldValue(`locationAndDeviceTargeting.criteria.${index}.criterion`, e.target.value)}>
                                                        {
                                                            locationPlatformCriteriaProperties.map(prop => (
                                                                <option key={prop.code} value={prop.value}>{prop.code}</option>
                                                            ))
                                                        }
                                                    </Select>
                                                    <Select value={formik.values.locationAndDeviceTargeting.criteria[index].operator} placeholder='Select Operator' onChange={(e) => formik.setFieldValue(`locationAndDeviceTargeting.criteria.${index}.operator`, e.target.value)}>
                                                        {
                                                            !!formik.values.locationAndDeviceTargeting.criteria[index].criterion && locationPlatformCriteriaPropertiesOperator[formik.values.locationAndDeviceTargeting.criteria[index].criterion].map(prop => (
                                                                <option key={prop.code} value={prop.value}>{prop.code}</option>
                                                            ))
                                                        }
                                                    </Select>
                                                    {
                                                        !formik.values.locationAndDeviceTargeting.criteria[index].criterion && <Select opacity={0}></Select>
                                                    }
                                                    {
                                                        formik.values.locationAndDeviceTargeting.criteria[index].criterion === 'country' && <Box minW={'300px'}>
                                                            <CountryDropdown isMulti={true} onCountrySelect={(value) => formik.setFieldValue(`locationAndDeviceTargeting.criteria.${index}.value`, value)} value={formik.values.locationAndDeviceTargeting.criteria[index].value} maxWidth={'300px'} width={'100%'} minWidth={'300px'}></CountryDropdown>
                                                        </Box>
                                                    }
                                                    {
                                                        formik.values.locationAndDeviceTargeting.criteria[index].criterion === 'browser' && <Box minW={'300px'}>
                                                            <BrowserDropdown isMulti={true} value={formik.values.locationAndDeviceTargeting.criteria[index].value} onBrowserSelect={(value) => formik.setFieldValue(`locationAndDeviceTargeting.criteria.${index}.value`, value)}></BrowserDropdown>
                                                        </Box>
                                                    }
                                                    {
                                                        formik.values.locationAndDeviceTargeting.criteria[index].criterion === 'language' && <Box minW={'300px'}>
                                                            <LanguageDropdwon isMulti={true} value={formik.values.locationAndDeviceTargeting.criteria[index].value} onLanguageSelect={(value) => formik.setFieldValue(`locationAndDeviceTargeting.criteria.${index}.value`, value)}></LanguageDropdwon>
                                                        </Box>
                                                    }
                                                    {
                                                        formik.values.locationAndDeviceTargeting.criteria.length > 1 && <CloseButton onClick={() => arrayHelpers.remove()}></CloseButton>
                                                    }

                                                </Stack>
                                                <Stack gap={3} direction={'row'} mb={3} mt={2} wrap={'wrap'} pl={'4rem'}>
                                                    {
                                                        formik.values.locationAndDeviceTargeting.criteria[index].criterion === 'country' && !!formik.values.locationAndDeviceTargeting.criteria[index].value.length && formik.values.locationAndDeviceTargeting.criteria[index].value.map(val => (
                                                            <Box key={val.code} maxW={'300px'} w={'100%'}>
                                                                <FormLabel color={'inputLabel'}>States for {val.label}</FormLabel>
                                                                <StatesDropdown selectedCountry={val.code} isMulti={true} onStateSelect={(value) => formik.setFieldValue(`locationAndDeviceTargeting.selectedStates[${val.code}]`, value)} value={formik.values.locationAndDeviceTargeting.criteria[index].value}></StatesDropdown>
                                                            </Box>
                                                        ))
                                                    }
                                                </Stack>

                                            </Fragment>
                                        ))
                                    }
                                </Fragment>
                            )}></FieldArray>

                        </FormikProvider>
                    </Fragment>
                }
            </Box>

        </Fragment>
    )
})

export default NudgeTargeting;