export const onsitePushTemplates = {
    mkt_onsite_temp001: {
        uuid: 'c71bf33b247444cca30cf28625221c5c',
        title: {
            text: "20% OFF",
            color: "#000000",
            colorMode: "hex",
            fontSize: "60px",
            fontFamily: "Arial, Helvetica, sans-serif",
        },
        subTitle: {
            text: "Your next order",
            color: "#000000",
            colorMode: "hex",
            fontSize: "32px",
            fontFamily: "Arial, Helvetica, sans-serif"
        },
        description: {
            text: "Get 20% discount on all products with this code",
            color: "#000000",
            colorMode: "hex",
            fontSize: "15px",
            fontFamily: "Arial, Helvetica, sans-serif"
        },
        background: {
            color: "#ffffff",
            colorMode: "hex",
            isImage: true,
            placeHolder: "Recommended Size:(560 X 450)px",
            url: "assets/ng/partials/onsitepush/templatesimg/previews/560x450.png"
        },
        confirmButton: {
            text: "Show My Coupon",
            thankYouNote: "Thank You!",
            fontSize: "20px",
            fontFamily: "Arial, Helvetica, sans-serif",
            color: "#fff",
            colorMode: "hex",
            background: {
                color: "#000000",
                colorMode: "hex"
            }
        },
        cancelButton: {
            text: "No thanks, I want to pay full price",
            fontSize: "14px",
            fontFamily: "Arial, Helvetica, sans-serif",
            color: "#ddd",
            colorMode: "hex"
        },
        closeButton: {
            show: true
        },
        coupon: {
            text: "COUPON4PREVIEW",
            show: true,
            fontSize: "20px",
            fontFamily: "Arial, Helvetica, sans-serif",
            color: "#000",
            colorMode: "hex",
            border: {
                color: "#ccc",
                colorMode: "hex"
            }
        },
        afterText: {
            text: "Continue Shopping",
            show: true
        },
        clipboardText: {
            text: "Copy to clipboard",
            show: true
        },
        afterClipboardText: {
            text: "Code copied to clipboard!",
            show: true
        },
        ribbon: {
            text: "20% Off",
            show: true,
            fontSize: "16px",
            fontFamily: "Arial, Helvetica, sans-serif",
            color: "#fff",
            colorMode: "hex",
            enabled: true,
            background: {
                color: "#000",
                colorMode: "hex"
            }
        },
        enableRedirect: {
            show: false,
        }
    },
    mkt_onsite_temp002: {
        uuid: '3bfe973dc2a1419fbb76c9d3336fab88',
        title: {
            text: "10% OFF",
            color: "#000000",
            colorMode: "hex",
            fontSize: "60px",
            fontFamily: "Arial, Helvetica, sans-serif"
        },
        subTitle: {
            text: "Your next order",
            color: "#000000",
            colorMode: "hex",
            fontSize: "32px",
            fontFamily: "Arial, Helvetica, sans-serif"
        },
        description: {
            text: "Subscribe to our newsletter and get a 10% discount.",
            color: "#000000",
            colorMode: "hex",
            fontSize: "15px",
            fontFamily: "Arial, Helvetica, sans-serif"
        },
        background: {
            color: "#ffffff",
            colorMode: "hex",
            isImage: true,
            placeHolder: "Recommended Size:(560 X 415)px",
            url: "assets/ng/partials/onsitepush/templatesimg/previews/560x415.png"
        },
        confirmButton: {
            text: "Subscribe",
            thankYouNote: "Thank You!",
            afterText: {
                text: "Continue Shopping",
                show: true
            },
            clipboardText: {
                text: "Copy to clipboard",
                show: true
            },
            afterClipboardText: {
                text: "Code copied to clipboard!",
                show: true
            },
            fontSize: "20px",
            fontFamily: "Arial, Helvetica, sans-serif",
            color: "#fff",
            colorMode: "hex",
            background: {
                color: "#000000",
                colorMode: "hex"
            }
        },
        cancelButton: {
            text: "No thanks, I want to pay full price",
            fontSize: "14px",
            fontFamily: "Arial, Helvetica, sans-serif",
            color: "#ddd",
            colorMode: "hex"
        },

        closeButton: {
            show: true
        },
        showFields: {
            email: true,
            phone: false
        },
        coupon: {
            text: "COUPON4PREVIEW",
            show: true,
            fontSize: "20px",
            fontFamily: "Arial, Helvetica, sans-serif",
            color: "#000",
            colorMode: "hex",
            border: {
                color: "#ccc",
                colorMode: "hex"
            }
        },
        ribbon: {
            text: "10% Off",
            show: true,
            fontSize: "16px",
            fontFamily: "Arial, Helvetica, sans-serif",
            color: "#fff",
            colorMode: "hex",
            enabled: true,
            background: {
                color: "#000",
                colorMode: "hex"
            }
        },
        enableRedirect: {
            show: false,
        },
        dataCollection: true,
    },
    onsite_temp003: {
        uuid: '931d0d3af7204d1fa3e7b4acef9e5876',
        title: {
            text: "Forgot something in your cart?",
            color: "#000000",
            colorMode: "hex",
            fontSize: "16px",
            fontFamily: "Arial, Helvetica, sans-serif"
        },
        description: {
            text: "Your cart is waiting for you, grab it before stocks ends.",
            color: "#000000",
            colorMode: "hex",
            fontSize: "14px",
            fontFamily: "Arial, Helvetica, sans-serif"
        },
        background: {
            color: "#fefefe",
            colorMode: "hex",
            isImage: false,
            placeHolder: "Recommended Size:(70 X 70)px",
            url: "assets/ng/partials/onsitepush/templatesimg/previews/70x70.png",
            templatePosition: {
                show: true,
                value: "mkt-bottom-left",
                offset: {
                    unitType: '%',
                    value: 0,
                    onlyForMobile: false
                }
            }
        },
        closeButton: {
            fontSize: "14px",
            color: "#ddd",
            colorMode: "hex",
            show: true
        },
        ribbon: {
            enabled: false,
        },
        enableRedirect: {
            show: true,
        },
        showYotpoReview: false,
    },
    mkt_onsite_temp004: {
        uuid: 'a23e73ed06c640118c0f8923586428f9',
        title: {
            text: "Enjoy 15% discount",
            color: "#fff",
            colorMode: "hex",
            fontSize: "15px",
            fontFamily: "Arial, Helvetica, sans-serif"
        },
        description: {
            text: "on minimum purchase of $100",
            color: "#fff",
            colorMode: "hex",
            fontSize: "12px",
            fontFamily: "Arial, Helvetica, sans-serif"
        },
        background: {
            color: "rgba(0,0,0,0.92)",
            colorMode: "rgba",
            isImage: false,
            placeHolder: "Recommended Size:(145 X 116)px",
            url: "assets/ng/partials/onsitepush/templatesimg/previews/145x116.png",
            templatePosition: {
                show: true,
                value: "mkt-bottom-left"
            }
        },
        closeButton: {
            fontSize: "20px",
            show: true,
            color: "#fff",
            colorMode: "hex"
        },
        confirmButton: {
            text: "Subscribe",
            thankYouNote: "Thank You!",
            afterText: {
                text: "Continue Shopping",
                show: true
            },
            clipboardText: {
                text: "Copy to clipboard",
                show: true
            },
            afterClipboardText: {
                text: "Code copied to clipboard!",
                show: true
            },
            fontSize: "12px",
            fontFamily: "Arial, Helvetica, sans-serif",
            color: "#fff",
            colorMode: "hex",
            background: {
                color: "#6d6e6e",
                colorMode: "hex"
            }
        },
        coupon: {
            text: "COUPON4PREVIEW",
            show: true,
            fontSize: "11px",
            fontFamily: "Arial, Helvetica, sans-serif",
            color: "#333",
            colorMode: "hex",
            background: {
                color: "#fff",
                colorMode: "hex"
            }
        },
        ribbon: {
            text: "$15 Off",
            show: true,
            fontSize: "11px",
            fontFamily: "Arial, Helvetica, sans-serif",
            color: "#fff",
            colorMode: "hex",
            enabled: true,
            background: {
                color: "#000",
                colorMode: "hex"
            }
        },
        showFields: {
            email: true,
            phone: false
        },
        enableRedirect: {
            show: true,
        },
        dataCollection: true,
        showFields: {
            email: true,
            phone: false
        },
    },
    mkt_onsite_temp005: {
        uuid: 'b764c0a9e538432f8562788ffc496541',
        title: {
            text: "Get latest Deals on Shopping, Movie tickets and much more only for you.",
            color: "#363f4e",
            colorMode: "hex",
            fontSize: "14px",
            fontFamily: "Arial, Helvetica, sans-serif"
        },
        description: {
            text: "Click 'Allow' to stay connected",
            color: "#363f4e",
            colorMode: "hex",
            fontSize: "17px",
            fontFamily: "Arial, Helvetica, sans-serif"
        },
        background: {
            color: "#fafafa",
            colorMode: "hex",
            isImage: false,
            placeHolder: "Recommended Size:(560 X 450)px",
            url: "assets/ng/partials/onsitepush/templatesimg/previews/560x450.png"
        },
        notifBell: {
            isImage: false,
            url: "assets/ng/partials/onsitepush/templatesimg/notifications-button.png"
        },
        confirmButton: {
            text: "Allow",
            fontSize: "14px",
            fontFamily: "Arial, Helvetica, sans-serif",
            color: "#fff",
            colorMode: "hex",
            background: {
                color: "#d94d3a",
                colorMode: "hex"
            },
            laterButton: {
                text: "Later",
                color: "#fff",
                colorMode: "hex",
                background: {
                    color: "#444547",
                    colorMode: "hex"
                }
            },
        },
        closeButton: {
            show: false
        },
        ribbon: {
            text: "Get Notifications",
            show: true,
            fontSize: "18px",
            fontFamily: "Arial, Helvetica, sans-serif",
            color: "#fff",
            colorMode: "hex",
            enabled: true,
            background: {
                color: "#d94d3a",
                colorMode: "hex"
            }
        },
        enableRedirect: {
            show: false,
        }
    },
    mkt_onsite_temp006: {
        uuid: '38a060bdfa9c47caa813aeb4e5f0d879',
        closeButton: {
            show: true
        },
        imageBased: {
            maxWidth: "450px",
            bgcolor: "#fafafa",
            colorMode: "hex",
            isImage: true,
            placeHolder: "Recommended Size:(450 X 450)px",
            url: "assets/ng/partials/onsitepush/templatesimg/mkt-image-based.jpg"
        },
        cancelButton: {
            text: "No thanks !!",
            fontSize: "14px",
            fontFamily: "Arial, Helvetica, sans-serif",
            color: "#ddd",
            colorMode: "hex"
        },
        ribbon: {
            text: "View Offer",
            show: true,
            fontSize: "18px",
            fontFamily: "Arial, Helvetica, sans-serif",
            color: "#fff",
            colorMode: "hex",
            enabled: true,
            background: {
                color: "#d94d3a",
                colorMode: "hex"
            }
        },
        enableRedirect: {
            show: true,
        }
    },
    mkt_onsite_temp007: {
        uuid: 'bfbc8c07b80040b6b6ef6d1ce41bb36a',
        title: {
            text: "20% Off",
            color: "#ffffff",
            colorMode: "hex",
            fontSize: "30px",
            fontFamily: "Arial, Helvetica, sans-serif"
        },
        subTitle: {
            text: "Free Shipping on Orders $75+",
            color: "#ffffff",
            colorMode: "hex",
            fontSize: "18px",
            fontFamily: "Arial, Helvetica, sans-serif"
        },
        background: {
            color: "#ffffff",
            colorMode: "hex",
            isImage: true,
            placeHolder: "Recommended Size:(290 X 400)px",
            url: "assets/ng/partials/onsitepush/templatesimg/320x400.jpg",
            templatePosition: {
                show: true,
                value: "mkt-bottom-left"
            }
        },
        confirmButton: {
            text: "Buy Now",
            fontSize: "15px",
            fontFamily: "Arial, Helvetica, sans-serif",
            color: "#000000",
            colorMode: "hex",
            background: {
                color: "#fbdb06",
                colorMode: "hex"
            }
        },
        closeButton: {
            show: true
        },
        coupon: {
            text: "Use code: <b>COUPON4PREVIEW</b>",
            show: true,
            fontSize: "12px",
            fontFamily: "Arial, Helvetica, sans-serif",
            color: "#ffffff",
            colorMode: "hex",
            border: {
                color: "#ffffff",
                colorMode: "hex"
            }
        },
        timer: {
            text: new Date(new Date().getTime()),
            show: true,
            fontSize: "25px",
            fontFamily: "Arial, Helvetica, sans-serif",
            color: "#ffffff",
            colorMode: "hex",
            background: {
                color: "rgba(0, 0, 0, 0.4)",
                colorMode: "rgba"
            }
        },
        ribbon: {
            enabled: false,
        },
        enableRedirect: {
            show: true,
        }
    },
    mkt_onsite_temp008: {
        uuid: '9627a12e70824d5aa09c187458d9638e',
        background: {
            color: "#fefefe",
            colorMode: "hex",
            isImage: true,
            placeHolder: "Required Image Size: 360*240px",
            url: "assets/ng/partials/onsitepush/templatesimg/previews/360x240.png",
            templatePosition: {
                show: true,
                value: "mkt-bottom-left"
            }
        },
        closeButton: {
            fontSize: "14px",
            color: "#ddd",
            colorMode: "hex",
            show: true
        },
        ribbon: {
            enabled: false,
        },
        enableRedirect: {
            show: true,
        }
    },
    mkt_onsite_temp009: {
        uuid: '37e74dc11a9a463c9445955685dfa130',
        //entityName : $rootScope.entityName,
        //entitySiteUrl : $rootScope.entitySiteUrl,
        iframe: {
            //link: $rootScope.iframeLink
        },
        title: {
            text: "Don't miss another update from us!",
            color: "#424242",
            colorMode: "hex",
            fontSize: "13px",
            fontFamily: "Arial, Helvetica, sans-serif"
        },
        description: {
            text: "Send your cart to Facebook messenger",
            color: "#424242",
            colorMode: "hex",
            fontSize: "13px",
            fontFamily: "Arial, Helvetica, sans-serif"
        },
        onSuccess: {
            text: "Thanks for subscribing!",
            color: "#424242",
            colorMode: "hex",
            fontSize: "13px",
            fontFamily: "Arial, Helvetica, sans-serif"
        },
        background: {
            color: "#fafafa",
            colorMode: "hex",
            isImage: true,
            placeHolder: "Recommended Size:(300 X 160)px",
            url: "assets/ng/partials/onsitepush/templatesimg/previews/300x160.png",
            templatePosition: {
                show: true,
                value: "mkt-bottom-left"
            }
        },
        closeButton: {
            fontSize: "14px",
            color: "#ddd",
            colorMode: "hex",
            show: true
        },
        enableRedirect: {
            show: true,
        }
    },
    mkt_onsite_temp010: {
        uuid: 'd57d9abe06bf413389b3aa95b33d5680',
        title: {
            text: "A LIMITED-TIME DEAL",
            color: "#152d3e",
            colorMode: "hex",
            fontSize: "20px",
            fontFamily: "Arial, Helvetica, sans-serif"
        },
        description: {
            text: "Claim a one-in-a-lifetime bonus!",
            color: "#000000",
            colorMode: "hex",
            fontSize: "16px",
            fontFamily: "Arial, Helvetica, sans-serif"
        },
        background: {
            color: "#f2f2f2",
            colorMode: "hex",
            isImage: true,
            placeHolder: "Recommended Size:(280 X 220)px",
            url: "assets/ng/partials/onsitepush/templatesimg/previews/280x220.png"
        },
        confirmButton: {
            text: "Claim 50% Off",
            thankYouNote: "Thank You!",
            afterText: {
                text: "Continue Shopping",
                show: true
            },
            clipboardText: {
                text: "Copy to clipboard",
                show: true
            },
            afterClipboardText: {
                text: "Code copied to clipboard!",
                show: true
            },
            fontSize: "16px",
            fontFamily: "Arial, Helvetica, sans-serif",
            color: "#fff",
            colorMode: "hex",
            background: {
                color: "#152d3e",
                colorMode: "hex"
            }
        },
        cancelButton: {
            text: "No thanks, I want to pay full price",
            fontSize: "14px",
            fontFamily: "Arial, Helvetica, sans-serif",
            color: "#ddd",
            colorMode: "hex"
        },

        closeButton: {
            show: true
        },
        showFields: {
            email: true,
            phone: false
        },
        coupon: {
            text: "COUPON4PREVIEW",
            show: true,
            fontSize: "16px",
            fontFamily: "Arial, Helvetica, sans-serif",
            color: "#152d3e",
            colorMode: "hex",
            border: {
                color: "#ccc",
                colorMode: "hex"
            }
        },
        ribbon: {
            text: "Big Sale 50% Off",
            show: true,
            fontSize: "16px",
            fontFamily: "Arial, Helvetica, sans-serif",
            color: "#fff",
            colorMode: "hex",
            enabled: true,
            background: {
                color: "#152d3e",
                colorMode: "hex"
            }
        },
        enableRedirect: {
            show: true,
        },
        dataCollection: true,
    },
    mkt_onsite_temp011: {
        uuid: 'f024ad6a8d664c0597942d0d3c06a03b',
        sliderSettings: {
            templatePosition: {
                show: true,
                value: "mkt-bottom-left"
            },
            slides: [
                {
                    image: "assets/ng/partials/onsitepush/templatesimg/slides/1.jpg",
                    url: "",
                    target: "_blank"
                },
                {
                    image: "assets/ng/partials/onsitepush/templatesimg/slides/2.jpg",
                    url: "",
                    target: "_blank"
                },
                {
                    image: "assets/ng/partials/onsitepush/templatesimg/slides/3.jpg",
                    url: "",
                    target: "_blank"
                }
            ]
        },
        closeButton: {
            fontSize: "20px",
            show: true,
            color: "#fff",
            colorMode: "hex"
        },
        ribbon: {
            text: "View Offer",
            show: true,
            fontSize: "11px",
            fontFamily: "Arial, Helvetica, sans-serif",
            color: "#fff",
            colorMode: "hex",
            enabled: true,
            background: {
                color: "#000",
                colorMode: "hex"
            }
        },
        enableRedirect: {
            show: false,
        }
    },
    mkt_onsite_temp012: {
        uuid: '2579cb33445144f295b657f2c2dfbf6c',
        title: {
            text: "A LIMITED-TIME DEAL",
            color: "#152d3e",
            colorMode: "hex",
            fontSize: "20px",
            fontFamily: "Arial, Helvetica, sans-serif"
        },
        description: {
            text: "Claim a one-in-a-lifetime bonus!",
            color: "#000000",
            colorMode: "hex",
            fontSize: "16px",
            fontFamily: "Arial, Helvetica, sans-serif"
        },
        background: {
            color: "#f2f2f2",
            colorMode: "hex",
            isImage: true,
            placeHolder: "Recommended Size:(280 X 220)px",
            url: "assets/ng/partials/onsitepush/templatesimg/previews/280x220.png"
        },
        confirmButton: {
            show: true,
            text: "Claim 50% Off",
            thankYouNote: "Thank You!",
            afterText: {
                text: "Continue Shopping",
                show: true
            },
            clipboardText: {
                text: "Copy to clipboard",
                show: true
            },
            afterClipboardText: {
                text: "Code copied to clipboard!",
                show: true
            },
            fontSize: "16px",
            fontFamily: "Arial, Helvetica, sans-serif",
            color: "#fff",
            colorMode: "hex",
            background: {
                color: "#152d3e",
                colorMode: "hex"
            }
        },
        closeButton: {
            show: true
        },
        showFields: {
            email: true,
            phone: false
        },
        coupon: {
            text: "COUPON4PREVIEW",
            show: true,
            fontSize: "16px",
            fontFamily: "Arial, Helvetica, sans-serif",
            color: "#152d3e",
            colorMode: "hex",
            border: {
                color: "#ccc",
                colorMode: "hex"
            }
        },
        ribbon: {
            text: "Big Sale 50% Off",
            show: true,
            fontSize: "16px",
            fontFamily: "Arial, Helvetica, sans-serif",
            color: "#fff",
            colorMode: "hex",
            enabled: true,
            background: {
                color: "#152d3e",
                colorMode: "hex"
            }
        },
        enableRedirect: {
            show: false,
        },
        dataCollection: true,
    },
    mkt_onsite_temp012: {
        uuid: '10ba39541e824cedb2fa63e70f16a069',
        title: {
            text: "A LIMITED-TIME DEAL",
            color: "#152d3e",
            colorMode: "hex",
            fontSize: "20px",
            fontFamily: "Arial, Helvetica, sans-serif"
        },
        description: {
            text: "Claim a one-in-a-lifetime bonus!",
            color: "#000000",
            colorMode: "hex",
            fontSize: "16px",
            fontFamily: "Arial, Helvetica, sans-serif"
        },
        background: {
            color: "#f2f2f2",
            colorMode: "hex",
            isImage: true,
            placeHolder: "Recommended Size:(280 X 220)px",
            url: "assets/ng/partials/onsitepush/templatesimg/previews/280x220.png"
        },
        confirmButton: {
            show: true,
            text: "Claim 50% Off",
            thankYouNote: "Thank You!",
            afterText: {
                text: "Continue Shopping",
                show: true
            },
            clipboardText: {
                text: "Copy to clipboard",
                show: true
            },
            afterClipboardText: {
                text: "Code copied to clipboard!",
                show: true
            },
            fontSize: "16px",
            fontFamily: "Arial, Helvetica, sans-serif",
            color: "#fff",
            colorMode: "hex",
            background: {
                color: "#152d3e",
                colorMode: "hex"
            }
        },
        closeButton: {
            show: true
        },
        showFields: {
            email: true,
            phone: false
        },
        coupon: {
            text: "COUPON4PREVIEW",
            show: true,
            fontSize: "16px",
            fontFamily: "Arial, Helvetica, sans-serif",
            color: "#152d3e",
            colorMode: "hex",
            border: {
                color: "#ccc",
                colorMode: "hex"
            }
        },
        ribbon: {
            text: "Big Sale 50% Off",
            show: true,
            fontSize: "16px",
            fontFamily: "Arial, Helvetica, sans-serif",
            color: "#fff",
            colorMode: "hex",
            enabled: true,
            background: {
                color: "#152d3e",
                colorMode: "hex"
            }
        },
        enableRedirect: {
            show: false,
        },
        dataCollection: true,
    },
    mkt_onsite_temp013: {
        uuid: '138094a7e8b64759a25d213903ab01e2',
        title: {
            text: "A LIMITED-TIME DEAL",
            color: "#152d3e",
            colorMode: "hex",
            fontSize: "24px",
            fontFamily: "Arial, Helvetica, sans-serif"
        },
        description: {
            text: "Claim a one-in-a-lifetime bonus!",
            color: "#a3a3a3",
            colorMode: "hex",
            fontSize: "14px",
            fontFamily: "Arial, Helvetica, sans-serif"
        },
        background: {
            color: "#ffffff",
            colorMode: "hex",
            isImage: true,
            placeHolder: "Recommended Size:(280 X 220)px",
            url: "assets/ng/partials/onsitepush/templatesimg/previews/280x220.png"
        },
        confirmButton: {
            show: true,
            text: "Claim 50% Off",
            thankYouNote: "Thank You!",
            afterText: {
                text: "Continue Shopping",
                show: true
            },
            clipboardText: {
                text: "Copy to clipboard",
                show: true
            },
            afterClipboardText: {
                text: "Code copied to clipboard!",
                show: true
            },
            fontSize: "14px",
            fontFamily: "Arial, Helvetica, sans-serif",
            color: "#fff",
            colorMode: "hex",
            background: {
                color: "#152d3e",
                colorMode: "hex"
            }
        },
        closeButton: {
            show: true
        },
        showFields: {
            email: true,
            phone: false
        },
        coupon: {
            text: "COUPON4PREVIEW",
            show: true,
            fontSize: "14px",
            fontFamily: "Arial, Helvetica, sans-serif",
            color: "#152d3e",
            colorMode: "hex",
            border: {
                color: "#ccc",
                colorMode: "hex"
            }
        },
        ribbon: {
            text: "We have something for you",
            show: true,
            fontSize: "14px",
            fontFamily: "Arial, Helvetica, sans-serif",
            color: "#fff",
            colorMode: "hex",
            enabled: true,
            background: {
                color: "#2868ff",
                colorMode: "hex"
            }

        },
        enableRedirect: {
            show: false,
        },
        dataCollection: true,
    },
    mkt_onsite_temp014: {
        uuid: '93d8513c075b4a80c5601b0b650e104',
        background: {
            color: "#fefefe",
            colorMode: "hex",
            isImage: true,
            placeHolder: "Required Image Size: 600*450px",
            url: "assets/ng/partials/onsitepush/templatesimg/previews/600x450.png",
            templatePosition: {
                show: true,
                value: "mkt-bottom-left"
            }
        },
        product: [{
            productTitle: "",
            Button: "",
            buttonLink: "",
            isImage: true,
            placeHolder: "Required Image Size: 120*200px",
            url: "",
        },],

        title: {
            text: "",
            color: "#ffffff",
            colorMode: "hex",
            fontSize: "30px",
            fontFamily: "Arial, Helvetica, sans-serif",
        },
        productTitle: {
            color: "#ffffff",
            colorMode: "hex",
            fontSize: "18px",
            fontFamily: "Arial, Helvetica, sans-serif",
        },
        confirmButton: {
            fontSize: "15px",
            fontFamily: "Arial, Helvetica, sans-serif",
            color: "#000000",
            colorMode: "hex",
            background: {
                color: "#fbdb06",
                colorMode: "hex"
            }
        },
        closeButton: {
            fontSize: "20px",
            show: true,
            color: "#fff",
            colorMode: "hex"
        },
        ribbon: {
            enabled: false,
        },
        enableRedirect: {
            show: true,
        }
    },
    mkt_onsite_temp015: {
        uuid: '8b95be7c82bd404d861187a9b67d2fba',
        title: {
            text: "SIGN UP TO RECEIVE EXCLUSIVE OFFERS AND NEWS!",
            color: "#ffffff",
            colorMode: "hex",
            fontSize: "30px",
        },
        confirmButton: {
            fontSize: "15px",
            fontFamily: "Arial, Helvetica, sans-serif",
            color: "#000000",
            colorMode: "hex",
            background: {
                color: "#fbdb06",
                colorMode: "hex"
            }
        },
        closeButton: {
            fontSize: "20px",
            show: true,
            color: "#fff",
            colorMode: "hex"
        },
        ribbon: {
            enabled: false,
        },
        enableRedirect: {
            show: true,
        }
    },
    mkt_onsite_temp016: {
        uuid: 'ad02301fdf774581bd22b48abdfa55bf',
        title: {
            text: "Unlock 20% Off your first purchase",
            color: "#000000",
            colorMode: "hex",
            fontSize: "20px",
        },
        background: {
            color: "#ffffff",
            colorMode: "hex",
            //isImage: true,
            //placeHolder: "Required Image Size: 600*450px",
            //url : "",
            /*templatePosition : {
                show : true,
                value : "mkt-bottom-left"
            }*/
        },
        confirmButton: {
            fontSize: "15px",
            //fontFamily: "Arial, Helvetica, sans-serif",
            color: "#ffffff",
            colorMode: "hex",
            text: "Claim Offer",
            background: {
                color: "#000000",
                colorMode: "hex"
            }
        },
        /*closeButton: {
            fontSize: "20px",
            show: true,
            color: "#fff",
            colorMode: "hex"
        },*/
        ribbon: {
            enabled: false,
        },
        magicLink: {
            selectedChannel: 'sms',
            selectedLink: ''
        },
        enableRedirect: {
            show: true,
        }
    },
    mkt_onsite_temp017: {
        uuid: 'b35cd5cf5ef34155959aafbd389ee24b',
        background: {
            color: "#ffffff",
            colorMode: "hex",
            //isImage: true,
            //placeHolder: "Required Image Size: 600*450px",
            //url : "",
            /*templatePosition : {
                show : true,
                value : "mkt-bottom-left"
            }*/
        },
        /*closeButton: {
            fontSize: "20px",
            show: true,
            color: "#fff",
            colorMode: "hex"
        },*/
        numberOfProducts: 3,

    },
    mkt_onsite_temp018: {
        uuid: 'c97750ebf87041e5811b24639bc75a17',
        title: {
            text: "We May Not Keep These in Stock...",
            color: "#25345A",
            colorMode: "hex",
            fontSize: "18px",
        },
        description: {
            text: "These shoes are getting lots of attention today. Don’t leave without checking them out!",
            color: "#481A00",
            colorMode: "hex",
            fontSize: "12px",
            fontFamily: "Arial, Helvetica, sans-serif"
        },
        background: {
            color: "#FFEFEB",
            colorMode: "hex",
            isImage: false,
        },
        closeButtonConfiguration: {
            color: "#FDA991",
            colorMode: "hex",
        },
        productViewButton: {
            text: "View",
            textColor: "#FFEFEB",
            background: "#44547C",
            colorMode: "hex"
        },
        numberOfProducts: 3,

    },
    mkt_onsite_temp019: {
        uuid: '99cf96805114482db4449ab470e39c8a',
        title: {
            text: "You Might Also Like !",
            color: "#606060",
            colorMode: "hex",
            fontSize: "14px",
        },
        background: {
            color: "#ffffff",
            colorMode: "hex",
            templatePosition: {
                show: true,
                value: "mkt-bottom-left",
                offset: {
                    unitType: '%',
                    value: 0,
                    onlyForMobile: false
                }
            }
        },
        addToCartButton: {
            label: {
                text: "Add",
                color: "#ffffff",
                colorMode: "hex",
            },
            background: {
                color: '#000000',
                colorMode: "hex"
            },
        },
        numberOfProducts: 1,
    },
    mkt_onsite_temp020: {
        uuid: 'dc2e1d6bbeb648a6bc18f5f7955f853d',
        title: {
            text: "<b>Hurry Up!</b> The offer will end soon",
            color: "#ffffff",
            colorMode: "hex",
            fontSize: "32px",
            fontFamily: "Arial, Helvetica, sans-serif"
        },
        background: {
            color: "#0F6E80",
            colorMode: "hex",
            isImage: true,
            placeHolder: "Recommended Size:(0 X 0)px",
            url: "assets/ng/partials/onsitepush/templatesimg/320x400.jpg",
            /*templatePosition : {
                show : true,
                value : "mkt-bottom-left"
            }*/
        },
        confirmButton: {
            text: "Grab it now",
            fontSize: "24px",
            fontFamily: "Arial, Helvetica, sans-serif",
            color: "#000000",
            colorMode: "hex",
            afterText: {
                text: "Continue Shopping",
                show: true
            },
            clipboardText: {
                text: "Copy to clipboard",
                show: true
            },
            afterClipboardText: {
                text: "Code copied to clipboard!",
                show: true
            },
            background: {
                color: "#ffffff",
                colorMode: "hex"
            }
        },
        closeButton: {
            show: true
        },
        coupon: {
            text: "Use code: <b>COUPON4PREVIEW</b>",
            show: true,
            fontSize: "18px",
            fontFamily: "Arial, Helvetica, sans-serif",
            color: "#ffffff",
            colorMode: "hex",
            border: {
                color: "#ffffff",
                colorMode: "hex"
            }
        },
        timer: {
            text: new Date(new Date().getTime()),
            show: true,
            /*fontSize: "26px",*/
            fontFamily: "Arial, Helvetica, sans-serif",
            color: "#ffffff",
            colorMode: "hex",
            background: {
                color: "#000000",
                colorMode: "hex"
            }
        },
        ribbon: {
            enabled: false,
        },
        enableRedirect: {
            show: true,
        }
    },
    mkt_onsite_temp021: {
        title: {
            text: "FREE",
            color: "#FFFFFF",
            colorMode: "hex",
            fontSize: "20px",
            fontFamily: "Arial, Helvetica, sans-serif"
        },
        description: {
            text: "SHIPPING",
            color: "#CE8280",
            colorMode: "hex",
            fontSize: "24px",
            fontFamily: "Arial, Helvetica, sans-serif"
        },
        background: {
            color: "#151518",
            colorMode: "hex",
            // isImage :true,
            // url:""
        },
        carousel: "true",
        timer: {
            text: new Date(new Date().getTime() + (2 * 24 * 60 * 60 * 1000)),
            show: true,
            fontSize: "26px",
            fontFamily: "Arial, Helvetica, sans-serif",
            color: "#000",
            colorMode: "hex",
            background: {
                color: "#FFFFFF",
                colorMode: "hex"
            }
        },
        confirmButton: {
            text: "SEND MY FREE SHOPPING COUPON",
            thankYouNote: "Thank You!",
            afterText: {
                text: "We have received your inputs, Enjoy your coupon",
                show: true
            },
            clipboardText: {
                text: "Copy to clipboard",
                show: true
            },
            afterClipboardText: {
                text: "Code copied to clipboard!",
                show: true
            },
            fontSize: "14px",
            fontFamily: "Arial, Helvetica, sans-serif",
            color: "#fff",
            colorMode: "hex",
            background: {
                color: "#CE8280",
                colorMode: "hex"
            }
        },

        closeButton: {
            show: true
        },
        showFields: {
            email: true,
            phone: false
        },
        coupon: {
            text: "COUPON4PREVIEW",
            show: true,
            fontSize: "16px",
            fontFamily: "Arial, Helvetica, sans-serif",
            color: "#ccc",
            colorMode: "hex",
            border: {
                color: "#ccc",
                colorMode: "hex"
            }
        },

        enableRedirect: {
            show: true,
        },
        dataCollection: true,
    },
}

export const onsiteTemplatesLayout = [{
    uuid: 'c71bf33b247444cca30cf28625221c5c',
    title: 'Show Coupon',
    description: 'Users get a discount coupon code by clicking a button.',
    imgUrl: '/images/nudge/layout1.png'
}]