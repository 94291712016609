import { Box, Flex, Icon, useColorModeValue, Text, CloseButton, Image, Collapse } from "@chakra-ui/react";
import { FiHome } from "react-icons/fi";
import { useSelector } from "react-redux";
import SelectedOrganization from "../components/SelectedOrganization";
import OrganizationSelectionModal from "../components/OrganizationSelectionModal";
import { NavLink, useMatch, useResolvedPath,useLocation } from "react-router-dom";
import { TiFlowSwitch } from "react-icons/ti";
import { IoIosPeople } from "react-icons/io";
import { FaWpforms, FaAngleDown } from "react-icons/fa";
import { MdOutlineCampaign } from "react-icons/md";
import { HiOutlineTemplate } from "react-icons/hi";
import { CgInsights } from "react-icons/cg";
import store from "../store/store";
import _ from "lodash";
import { MdGroupWork } from "react-icons/md";
import { Fragment, useState, useEffect } from "react";
import { getEnabledMenuItems } from "../Services/api";

const orgList = store.getState();

const LinkItems = [
  { name: 'Dashboard', icon: FiHome, to: '/' },
  { name: 'Flows', icon: TiFlowSwitch, to: '/flows' },
  {
    name: 'Audience', icon: IoIosPeople, to: '/', 
    children: [{
      name: 'Segment', to: '/audience/segment'
    },{
      name:"Orders", to:'/audience/orders',
    },
    {
      name:"Live Activities", to:'/audience/live-activities',
    },
    
  ]
  },
  { name: 'Campaign', icon: MdOutlineCampaign, to: '/campaign' },
  { name: 'Templates', icon: HiOutlineTemplate, to: '/templates' }
];


const NavItem = ({ itemName, icon, children, to, key, childrens, ...rest }) => {
  
  const resolved = useResolvedPath(to);
  let isMatched = false;
  
  if (itemName.toLowerCase() === 'dashboard' && window.location.pathname === '/') {
    isMatched = true;
  } else if (window.location.pathname.split('/')[1] === (itemName.toLowerCase())) {
    // console.log(window.location.pathname.split('/')[1] === (itemName.toLowerCase()),">>>>>>>>")
    isMatched = true;
  } 
  else {
    isMatched = false;
  }
  

  let match = isMatched;
  const [isCollapsed, setIsCollapsed] = useState(match);
  console.log(match,"match")

  if (!childrens) return (
    <NavLink to={to}>
      <Box
        as={'a'}
        style={{ textDecoration: 'none' }}
        _focus={{ boxShadow: 'none' }}>
        <Flex
          align="center"
          p="4"
          mx="4"
          borderRadius="lg"
          role="group"
          cursor="pointer"
          mt={"2px"}
          mb={"2px"}
          _hover={{
            bg: 'primary.main',
            color: 'white',
            marginBottom: '2px',
            marginTop: '2px'
          }}
          color={match ? 'white' : 'primary.main'}
          bg={match && 'primary.main'}
          {...rest}>
          {icon && (
            <Icon
              mr="4"
              fontSize="20"
              _groupHover={{
                color: 'white',
              }}
              as={icon}
              color={match ? 'white' : 'primary.main'}
            />
          )}
          {children}
        </Flex>
      </Box>
    </NavLink>
  );

  if (childrens) return (
    
    <Fragment>
      <Box
        onClick={() => setIsCollapsed(!isCollapsed)}
        style={{ textDecoration: 'none' }}
        _focus={{ boxShadow: 'none' }}>
        <Flex
          align="center"
          p="4"
          mx="4"
          borderRadius="lg"
          role="group"
          cursor="pointer"
          color={'primary.main'}
          mt={"2px"}
          mb={"2px"}
          
          {...rest}>
          {icon && (
            <Icon
              mr="4"
              fontSize="20"
              as={icon}
              color={'primary.main'}
            />
          )}
          {children}
          <Icon as={FaAngleDown} ml={6} placeItems={'end'} />
        </Flex>
      </Box>
      <Box height={isCollapsed ? 'auto' : 0} overflow={'hidden'} transition={'all .3s ease-in-out'}>
        {childrens.map(child => (
          <NavLink to={child.to}>
            <Flex
              align="center"
              p="1"
              ml={'60px'}
              borderRadius="lg"
              role="group"
              cursor="pointer"
              mt={"2px"}
              mb={"2px"}
            
              _hover={{
                bg: 'primary.main',
                color: 'white',
                marginBottom: '2px',
                marginTop: '2px'
              }}
              color={match ? 'white' : 'primary.main'}
              bg={match && 'primary.main'}
              w={'60%'}
              {...rest}>
              <Icon
                mr="4"
                fontSize="20"
                _groupHover={{
                  color: 'white',
                }}
                as={icon}
                opacity={0}
                color={match ? 'white' : 'primary.main'}
              />
              {child.name}
            </Flex>
          </NavLink>
        ))}
      </Box>
    </Fragment>
  );
};


export default function SidebarContent({ onClose, ...rest }) {
  const token = useSelector(state => state.auth.token);

  const [logo, setLogo] = useState(store.getState().auth?.white_label?.data?.lightBgLogo);
  let logoFromSession = null;
  const newLogo = useSelector((state) => state.auth.white_label) || sessionStorage.getItem('white_label');
  const mainLogo = newLogo.lightBgLogo || newLogo.data?.lightBgLogo;
  const [enabledMenuItems,setEnabledMenuItems] = useState([])

  const handleMenuItemChildren = (link) => {
    let childArr = []
    !!link?.children?.length && link?.children?.forEach((child)=>{ 
      // console.log("childrennn",child.name.toLocaleLowerCase().replaceAll("_"," "))
      let childName = child.name.toLocaleLowerCase().replaceAll(" ","_")
      if(enabledMenuItems.includes(childName)){
        debugger
        childArr.push(child) ; 
      }}
    )
    return childArr;
  }
  let generateMenuItems = () =>{
    let retVal = {};
    getEnabledMenuItems().then(res=>{
    setEnabledMenuItems(res.data)

    enabledMenuItems?.forEach((item)=>{
      retVal[item] = "";
    })
    
    console.log("itemsMapp",retVal)
    return retVal;
  })
  }
  useEffect(() => {
    try {
      logoFromSession = JSON.parse(sessionStorage.getItem('whiteLabel')).data?.lightBgLogo;
    } catch (e) {
    }
    setLogo(store.getState().auth?.white_label?.data?.lightBgLogo);
    generateMenuItems();
    // console.log("itemss",enabledMenuItems)
  }, []);

  return (
    <Box
      transition="3s ease"
      bg={'sidebar'}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.300', 'gray.700')}
      w={{ base: 'full', md: 60 }}
      pos="fixed"
      h="full"
      {...rest}>
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Image src={mainLogo} />
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      {LinkItems.map((link) => (
        enabledMenuItems.includes(link.name.toLowerCase()) &&
        <NavItem key={link.name} itemName={link.name} icon={link.icon} to={link.to} 
        
        childrens = { !!link?.children?.length && handleMenuItemChildren(link)}  
        >
          {link.name} 
        </NavItem>
      ))}
      <SelectedOrganization />
    </Box>
  );
}