import { Button, Popover, useDisclosure, Input, PopoverTrigger, PopoverContent, InputGroup, InputRightAddon, Icon } from '@chakra-ui/react'
import React, { Fragment } from 'react'
import { CustomPicker, SketchPicker } from 'react-color'
import { FaSquare } from "react-icons/fa";

function ColorPicker({ value, onColorSelect }) {
    const { isOpen, onToggle, onClose } = useDisclosure();
    const handleColorChange = (color) => {
        onColorSelect(color.hex);
        onToggle()
    }
    return (
        <Fragment>
            <Popover returnFocusOnClose={false} isOpen={isOpen} onClose={onClose} >
                <PopoverTrigger>
                    <InputGroup>
                        <Input value={value} readOnly></Input>
                        <InputRightAddon onClick={onToggle} cursor={'pointer'}><Icon as={FaSquare} color={value} boxSize={5}></Icon></InputRightAddon>
                    </InputGroup>
                </PopoverTrigger>
                <PopoverContent w={'auto'}>
                    <SketchPicker color={value} onChangeComplete={handleColorChange}></SketchPicker>
                </PopoverContent>
            </Popover>
        </Fragment>
    )
}

export default CustomPicker(ColorPicker)