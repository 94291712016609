import React, { useEffect, useState, useRef } from 'react';
import { Formik, Form, Field } from 'formik';
import { Flex, Text, Box, FormControl, FormLabel, Input, FormErrorMessage, VStack, Avatar, Button } from '@chakra-ui/react';
import { HiOutlineCloudUpload } from 'react-icons/hi';
import { editOrganizationSchema } from '../../_helpers/validationSchema';
import PrimaryButton from '../../components/themeComponents/PrimaryButton';
import { editOrganization, getOrgDetails, setOrgLogo } from '../../Services/AccountService';
import { useSelector,useDispatch } from 'react-redux';
import { connect } from 'react-redux'
import { setSelectedOrganization } from '../../Services/AuthService';
import { SET_ORG } from '../../store/Reducers/AuthReducer';
const initialValues = {
    companyName: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    postalCode: '',
    state: '',
    country: '',
};

const OrgInfo = ({orgIcon}) => {
    const [orgDetails, setOrgDetails] = useState(initialValues);    
    const desktopFileInputRef = useRef(null);
    const mobileFileInputRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    // const desktopLogo=localStorage.getItem('selectedOrg')

    // console.log(desktopLogo,"DKLLL")
    // const dispatch=useDispatch()

    const handleSubmit = (values) => {
        editOrganization(values)
            .then((res) => {
                console.log(res);
            })
            .catch(() => { });
    };

    const fetchOrgDetails = () => {
        getOrgDetails().then((res) => {
            setOrgDetails(res.data);
        });
    };

    useEffect(() => {
        fetchOrgDetails();
    }, []);

    const handleProfilePhotoChange = async(event, setFieldValue, field) => {
        const file = {media : event.currentTarget.files[0]};   
        if (file) {
            setIsLoading(true);
           await setOrgLogo(file).then((res) => {
                setFieldValue(res.data);
                // dispatch()
                const storageData = JSON.parse(localStorage.getItem('selectedOrg')) || {};
            storageData.orgIcon = res.data.media_url;
            localStorage.setItem('selectedOrg', JSON.stringify(storageData));
                // localStorage.setItem("selectedOrg.orgIcon", res.data.media_url);
                setIsLoading(false);
            }).catch((err) => {
                console.log(err);
            });
        }
    };

    const handleUploadButtonClick = (ref) => {
        if (ref.current) {
            ref.current.click();
        }
    };

    return (
        <>
            <Flex justify={'space-between'} gap={8}>
                <Box w='100%'>
                    {!!orgDetails.companyName && (
                        <Formik
                            initialValues={orgDetails}
                            validationSchema={editOrganizationSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ values, touched, errors, setFieldValue }) => (
                                <Form>
                                    <Text fontSize={'xl'}>Company Info</Text>
                                    <Box
                                        mt={'1rem'}
                                        backgroundColor={"#fff"}
                                        width={'100%'}
                                        boxShadow={'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'}
                                        maxW={"35vw"}
                                        p={"2rem"}
                                        borderRadius={"2px"}
                                    >
                                        <VStack alignItems={'baseline'} spacing={4}>
                                            <Field name="companyName">
                                                {({ field, form }) => (
                                                    <FormControl isInvalid={form.errors.companyName && form.touched.companyName}>
                                                        <FormLabel htmlFor="companyName">Company Name</FormLabel>
                                                        <Input {...field} id="companyName" placeholder="Enter company name" />
                                                        <FormErrorMessage>{form.errors.companyName}</FormErrorMessage>
                                                    </FormControl>
                                                )}
                                            </Field>
                                            <Field name="addressLine1">
                                                {({ field, form }) => (
                                                    <FormControl isInvalid={form.errors.addressLine1 && form.touched.addressLine1}>
                                                        <FormLabel htmlFor="addressLine1">Address Line 1</FormLabel>
                                                        <Input {...field} id="addressLine1" placeholder="Enter address line 1" />
                                                        <FormErrorMessage>{form.errors.addressLine1}</FormErrorMessage>
                                                    </FormControl>
                                                )}
                                            </Field>
                                            <Field name="addressLine2">
                                                {({ field }) => (
                                                    <FormControl>
                                                        <FormLabel htmlFor="addressLine2">Address Line 2</FormLabel>
                                                        <Input {...field} id="addressLine2" placeholder="Enter address line 2" />
                                                    </FormControl>
                                                )}
                                            </Field>
                                            <Field name="city">
                                                {({ field, form }) => (
                                                    <FormControl isInvalid={form.errors.city && form.touched.city}>
                                                        <FormLabel htmlFor="city">City</FormLabel>
                                                        <Input {...field} id="city" placeholder="Enter city" />
                                                        <FormErrorMessage>{form.errors.city}</FormErrorMessage>
                                                    </FormControl>
                                                )}
                                            </Field>
                                            <Field name="postalCode">
                                                {({ field, form }) => (
                                                    <FormControl isInvalid={form.errors.postalCode && form.touched.postalCode}>
                                                        <FormLabel htmlFor="postalCode">Postal Code</FormLabel>
                                                        <Input {...field} id="postalCode" placeholder="Enter postal code" />
                                                        <FormErrorMessage>{form.errors.postalCode}</FormErrorMessage>
                                                    </FormControl>
                                                )}
                                            </Field>
                                            <Field name="state">
                                                {({ field, form }) => (
                                                    <FormControl isInvalid={form.errors.state && form.touched.state}>
                                                        <FormLabel htmlFor="state">State</FormLabel>
                                                        <Input {...field} id="state" placeholder="Enter state" />
                                                        <FormErrorMessage>{form.errors.state}</FormErrorMessage>
                                                    </FormControl>
                                                )}
                                            </Field>
                                            <Field name="country">
                                                {({ field, form }) => (
                                                    <FormControl isInvalid={form.errors.country && form.touched.country}>
                                                        <FormLabel htmlFor="country">Country</FormLabel>
                                                        <Input {...field} id="country" type='text' placeholder="Country" />
                                                        <FormErrorMessage>{form.errors.country}</FormErrorMessage>
                                                    </FormControl>
                                                )}
                                            </Field>
                                            <PrimaryButton label={'Submit'} type={'submit'} />
                                        </VStack>
                                    </Box>
                                </Form>
                            )}
                        </Formik>
                    )}
                </Box>
                <Box gap={4} w='100%' p={2}>
                    {!!orgDetails.companyName && (
                        <Formik
                            initialValues={orgDetails}
                            validationSchema={editOrganizationSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ touched, errors, setFieldValue }) => (
                                <div>
                                    <Box>
                                        <Text fontSize={'xl'}>Organization Logo</Text>
                                        <Text as='em'>This logo will automatically be used while sending Push Notifications</Text>
                                    </Box>
                                    <Box pt={"4"} flexDirection={'column'}>
                                        <div>
                                            <Text fontSize={'xl'}>For Desktop-based Web Notifications</Text>
                                            <Box my={'1rem'} display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                                <Avatar size={'2xl'} src={orgIcon}  />
                                                <FormControl id="desktopLogo" isInvalid={touched.desktopLogo && errors.desktopLogo}>
                                                    <FormLabel my={'1rem'} textAlign={'center'} htmlFor="desktopLogo">
                                                        <Text as='em'whiteSpace="nowrap" >Image height should be greater than 480 pixels</Text>
                                                        <input
                                                            type="file"
                                                            accept="image/*"
                                                            ref={desktopFileInputRef}
                                                            style={{ display: 'none' }}
                                                            onChange={(event) => handleProfilePhotoChange(event, setFieldValue, 'desktopLogo')}
                                                        />
                                                        <PrimaryButton m={'3'} isLoading={isLoading} label={'Upload Logo'} rightIcon={<HiOutlineCloudUpload />} onClick={() => handleUploadButtonClick(desktopFileInputRef)} />
                                                        <FormErrorMessage>{errors.desktopLogo}</FormErrorMessage>
                                                    </FormLabel>
                                                </FormControl>
                                            </Box>
                                        </div>
                                        <div>
                                            <Text fontSize={'xl'}>For Mobile-based Web and App Notifications</Text>
                                            <Box my={'1rem'} display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                                <Avatar size={'2xl'} src={orgDetails.mobileLogo}  />
                                                <FormControl id="mobileLogo" isInvalid={touched.mobileLogo && errors.mobileLogo}>
                                                    <FormLabel my={'1rem'} textAlign={'center'} htmlFor="mobileLogo">
                                                        <Text as='em'>Image height should be greater than 480 pixels</Text>
                                                        <input
                                                            type="file"
                                                            accept="image/*"
                                                            ref={mobileFileInputRef}
                                                            style={{ display: 'none' }}
                                                            onChange={(event) => handleProfilePhotoChange(event, setFieldValue, 'mobileLogo')}
                                                        />
                                                        <PrimaryButton m={'3'} label={'Upload Logo'} rightIcon={<HiOutlineCloudUpload />} onClick={() => handleUploadButtonClick(mobileFileInputRef)} />
                                                        <FormErrorMessage>{errors.mobileLogo}</FormErrorMessage>
                                                    </FormLabel>
                                                </FormControl>
                                            </Box>
                                        </div>
                                    </Box>
                                </div>
                            )}
                        </Formik>
                    )}
                </Box>
            </Flex>
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        ...state,
        orgIcon: state.auth.selectedOrg.orgIcon
    }
}
export default connect(mapStateToProps)(OrgInfo);
// export default OrgInfo;
