import axiosInstance from "./axiosInstance";
import store from '../store/store'

export const getRecipes = async () => {
   const orgToken = store.getState().auth?.selectedOrg?.token;
   let url = `${process.env.REACT_APP_API_ENDPOINT}/dashboard/v6/automation/recipes?orgToken=${orgToken}`
   return await axiosInstance.get(url);
}

export const activateRecipe = async (recipeData) => {
   const orgToken = store.getState().auth?.selectedOrg?.token;
   let url = `${process.env.REACT_APP_API_ENDPOINT}/dashboard/v6/automation/recipes/create/${recipeData.readableName}?orgToken=${orgToken}`
   return await axiosInstance.post(url, recipeData);
}

export const displayRecipePreview = async (channel,type,recipeUuid,tempName,discountDataEncoded) => {
   const orgToken = store.getState().auth?.selectedOrg?.token;
   let url = `${process.env.REACT_APP_API_ENDPOINT}/automation-recipe/preview?channel=${channel}&type=${type}&recipeUuid=${recipeUuid}&tempName=${tempName}&discountData=${discountDataEncoded}`
   
   return await axiosInstance.get(url);
}

