import React, { useEffect, useState } from 'react';
import { Box, Image, Text, VStack, Spinner, Icon, useSafeLayoutEffect, HStack } from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import axios from 'axios';
import axiosInstance from '../../Services/axiosInstance';





const FacebookPages = () => {
  const [connectedPages, setConnectedPages] = useState({});
  const [fbPages, setFbPages] = useState([]);
  const [loading, setLoading] = useState(null);
  const getFbPagesList = () => {
    let url = process.env.REACT_APP_API_ENDPOINT + '/fb/pagelist/mkt?restful=true'
    axiosInstance.get(url).then((res) => {
      setFbPages(res?.data?.pageData)
      setConnectedPages(res?.data?.connectedPageData);
    }).catch((e) => console.log(e))
  }
  const handlePageClick = async (pageId, pageName) => {

    setLoading(pageId);
    let url = process.env.REACT_APP_API_ENDPOINT + '/fb/fbmessenger/credentials/mkt'
    try {
      const response = await axiosInstance.post(url, {
        pageId,
        pageName,
      });


      setConnectedPages(response?.data.data);

    } catch (error) {
      console.error('Error connecting page:', error);
    } finally {
      setLoading(null);
    }
  };
  useEffect(() => {
    getFbPagesList()
  }, [])

  return (
    <VStack spacing={4} align="stretch">
      <Text as={'heading'} >Facebook Pages</Text>
      {fbPages.map((page) => (
        <Box
          key={page.pageId}
          display="flex"
          alignItems="center"
          justifyContent={'space-between'}
          p={4}
          borderWidth="1px"
          borderRadius="lg"
          cursor="pointer"
          onClick={() => handlePageClick(page.pageId, page.name)}
          bg={connectedPages["pageId"] == page.pageId ? 'green.50' : 'white'}
        >
          <HStack>

            <Image src={page.iconUrl} alt={page.name} boxSize="50px" borderRadius="full" mr={4} />
            <Text fontSize="lg" fontWeight="bold">
              {page.name}
            </Text>
          </HStack>
          <HStack>

            {loading === page.pageId && <Spinner size="sm" ml={4} />}
            {connectedPages["pageId"] == page.pageId &&
              <>
                <CheckCircleIcon color="green.500" ml={4} />
                <Text ml={"5px"} >Connected</Text>
              </>
            }
          </HStack>
        </Box>
      ))}
    </VStack>
  );
};

export default FacebookPages;
