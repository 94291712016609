import React, { useState } from 'react'
import { Box, Text, FormControl, FormLabel, Input, FormErrorMessage, Button, Stack, Link, Alert, AlertIcon, AlertDescription, useToast, InputGroup, InputRightElement, Icon } from '@chakra-ui/react'
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import { register as values } from '../../_helpers/formValues';
import { register } from '../../Services/AuthService';
import { extractErrorMessageFromResponse } from '../../_helpers/helpers';
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { register as validationSchema } from '../../_helpers/validationSchema';
import PrimaryButton from '../../components/themeComponents/PrimaryButton';
import store from '../../store/store';
function Register() {
    const white_label = store.getState().auth.white_label;
    const [error, setError] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const toast = useToast();

    function registrationConfirm(action) {
        toast({
            title: 'Successfully registered. Navigating to login page.',
            status: 'success', duration: 1000, isClosable: true
        })
        navigate('/auth/login');
        action.setSubmitting(false)
    }

    const handleSubmit = async (values, action) => {
        action.setSubmitting(true);
        setError("");
        let registerReponse = await register(values).catch(error => {
            action.setSubmitting(false);
            console.log("in catch block", error)
            setError(extractErrorMessageFromResponse(error));
        });
        !!registerReponse && registrationConfirm(action) && action.setSubmitting(false)
    }

    const formik = useFormik({
        initialValues: values,
        onSubmit: handleSubmit,
        validationSchema: validationSchema
    })
    return (
        <Box maxW={'md'} minW={'xl'} boxShadow={'base'} borderRadius={'xl'} bg={'white'} px={5} py={10}>
            <Text fontSize={'2xl'} fontWeight={'bold'} color={'primary.main'}>Welcome to {white_label.entityName}</Text>
            <Text fontSize={'md'} color={'light'} mb={4}>Sign Up by entering information below</Text>
            {!!error && <Alert status='error' mb={4}>
                <AlertIcon />
                <AlertDescription fontSize={14}>{error}</AlertDescription>
            </Alert>}
            <form onSubmit={formik.handleSubmit}>
                <FormControl mb={4} id='full_name' isInvalid={formik.errors.full_name && formik.touched.full_name}>
                    <FormLabel color={'inputLabel'} display={'flex'}>
                        Full Name
                        <Text color={'red'} fontSize={15} right={0} position={'relative'} top={'1px'} left={'1px'}>*</Text>
                    </FormLabel>
                    <Input type='text' {...formik.getFieldProps('full_name')}></Input>
                    {
                        formik.errors.full_name && formik.touched.full_name && <FormErrorMessage>{formik.errors.full_name}</FormErrorMessage>
                    }
                </FormControl>
                <FormControl mb={4} id='email' isInvalid={formik.errors.email && formik.touched.email}>
                    <FormLabel color={'inputLabel'} display={'flex'}>
                        Work Email Address
                        <Text color={'red'} fontSize={15} right={0} position={'relative'} top={'1px'} left={'1px'}>*</Text>
                    </FormLabel>
                    <Input type='email' {...formik.getFieldProps("email")} />
                    {
                        formik.errors.email && formik.touched.email && <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                    }
                </FormControl>
                <FormControl mb={4} id='phone' isInvalid={formik.errors.phone && formik.touched.phone}>
                    <FormLabel color={'inputLabel'} display={'flex'}>
                        Phone
                        <Text color={'red'} fontSize={15} right={0} position={'relative'} top={'1px'} left={'1px'}>*</Text>
                    </FormLabel>
                    <Input type='tel' {...formik.getFieldProps("phone")}></Input>
                    {
                        formik.errors.phone && formik.touched.phone && <FormErrorMessage>{formik.errors.phone}</FormErrorMessage>
                    }
                </FormControl>
                <FormControl mb={4} id={'password'} isInvalid={formik.errors.password && formik.touched.password}>
                    <FormLabel color={'inputLabel'} display={'flex'}>
                        Password
                        <Text color={'red'} fontSize={15} right={0} position={'relative'} top={'1px'} left={'1px'}>*</Text>
                    </FormLabel>
                    <InputGroup>
                        <Input type={!!showPassword ? 'text' : 'password'} {...formik.getFieldProps('password')}></Input>
                        <InputRightElement cursor={'pointer'} onClick={() => setShowPassword(!showPassword)}>
                            {!!showPassword ? <Icon as={AiFillEye} /> : <Icon as={AiFillEyeInvisible} />}
                        </InputRightElement>
                    </InputGroup>
                    {
                        formik.errors.password && formik.touched.password && <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
                    }
                </FormControl>
                <FormControl mb={4} id='confirmPassword' isInvalid={formik.errors.confirmPassword && formik.touched.confirmPassword}>
                    <FormLabel color={'inputLabel'} display={'flex'}>
                        Confirm Password
                        <Text color={'red'} fontSize={15} right={0} position={'relative'} top={'1px'} left={'1px'}>*</Text>
                    </FormLabel>
                    <Input type='text' {...formik.getFieldProps('confirmPassword')}></Input>
                    {
                        formik.errors.confirmPassword && formik.touched.confirmPassword && <FormErrorMessage>{formik.errors.confirmPassword}</FormErrorMessage>
                    }
                </FormControl>
                <FormControl mb={4} id='org_domain' isInvalid={formik.errors.org_domain && formik.touched.org_domain}>
                    <FormLabel color={'inputLabel'} display={'flex'}>
                        Your Website Url
                        <Text color={'red'} fontSize={15} right={0} position={'relative'} top={'1px'} left={'1px'}>*</Text>
                    </FormLabel>
                    <Input type='text' {...formik.getFieldProps('org_domain')}></Input>
                    {
                        formik.errors.org_domain && formik.touched.org_domain && <FormErrorMessage>{formik.errors.org_domain}</FormErrorMessage>
                    }
                </FormControl>
                <PrimaryButton label={'Register'} isLoading={formik.isSubmitting} type={'submit'} width={'100%'}></PrimaryButton>
            </form>
            <Stack pt={6}>
                <Text align={'center'}>
                    Already an account? <Link as={RouterLink} color={'blue.400'} to="/auth/login">
                        Sign In
                    </Link>
                </Text>
            </Stack>
        </Box>
    )
}

export default Register
