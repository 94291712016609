import React, { useRef, useState } from 'react'
import { Box, Text, Flex, FormControl, FormLabel, AspectRatio, Image, FormHelperText, Icon, useDisclosure } from '@chakra-ui/react'
import Select from '../../../components/themeComponents/react-chakra-select'
import { DeviceFrameset } from 'react-device-frameset'
import { useQuery } from 'react-query'
import { fetchTemplate } from '../../../Services/api'
import { prepareTemplateList, smtpTypes } from '../../../_helpers/data_helper'
import { BsBox2Fill } from 'react-icons/bs'
import * as htmltoImage from "html-to-image"
import html2canvas from 'html2canvas'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import TemplatePreviewModal from './TemplatePreviewModal'
import _ from "lodash";
import MobilePreview from './MobilePreview'

function TemplateSelection({ selectedChannel, orgToken, formik }) {
    const { isOpen, onClose, onOpen } = useDisclosure()
    const [isPerviewGenerating, setIsPreviewGenerating] = useState(false)
    const [template, setTemplate] = useState({})
    const tempBodyRef = useRef(null)
    const templateQuery = useQuery(['fetchTemplate'], {
        queryFn: async () => {
            let res = await fetchTemplate(selectedChannel, orgToken);
            return prepareTemplateList(selectedChannel, res.data)
        }
    })
    // console.log(templateQuery,"templateQuery")
    async function generateEmailPreview() {
        setIsPreviewGenerating(true);
        try {
            const tempBody = tempBodyRef.current;
            const canvas = await htmltoImage.toPng(tempBody);
            // const dataUrl = canvas.toDataURL('image/png');
            formik.setFieldValue('tempImg', canvas)
            setIsPreviewGenerating(false);
        } catch (error) {
            console.log('Error in generating preview', error)
        }
    }
    const selectTempData = (e) => {
        console.log('on temp select', e)
        formik.setFieldValue("selectedTemp", e.uuid);
        formik.setFieldValue("selectedTempName", e.label);
        if (selectedChannel === 'email') {
            formik.setFieldValue('tempBody', e.body)
            // generateEmailPreview(e.body)
        }
        if (selectedChannel === 'whatsapp') {
            selectTemplate(e)
        }
        // formik.setFieldValue("tempBody", e.body)
    }
    const selectTemplate = (e) => {
        setTemplate(templateQuery.data.filter(template => template.uuid == e.uuid))
    }
    return (
        <>
        <Box w={'100%'} m={'0 auto'} mt={5} p={3} id='screenshot' bg={'white'}>
            <Text color={'heading'} as={'b'}>Template Information</Text>
            <Flex gap={12} mt={4}>
                <Box p={3} bg={'white'} w={'60%'} >
                    <FormControl>
                        <FormLabel color={'inputLabel'}>Template</FormLabel>
                        <Select 
                        options={templateQuery?.data} onChange={selectTempData}></Select>
                        {
                            !!formik.values.selectedTemp && <Box float={'right'} title='Preview' onClick={onOpen} cursor={'pointer'}>
                                {/* <Icon as={!!formik.values.selectedTemp ? FaEye : FaEyeSlash} cursor={'pointer'} mr={1}></Icon> */}
                            </Box>
                        }

                    </FormControl>
                    {
                        (selectedChannel === 'email') && <FormControl mt={3}>
                            <FormLabel color={'inputLabel'}>SMTP Settings</FormLabel>
                            <Select options={smtpTypes} value={_.filter(smtpTypes, { 'key': formik.values.selectedSmtpType })} onChange={(e) => formik.setFieldValue("selectedSmtpType", e.key)}></Select>
                        </FormControl>
                    }
                </Box>

                {/* <Box ratio={3 / 4} w={'40vw'} maxW={'530px'} bg="white" overflow={'scroll'} maxH={'450px'} >
                    <Box transform={'scale(0.8)'} dangerouslySetInnerHTML={{ __html: formik.values.tempBody }}></Box>
                </Box> */}
                {/* {
                    selectedChannel == 'whatsapp' ? <MobilePreview channel={'whatsapp'} message={formik.values.message} /> :
                        <TemplatePreviewModal isOpen={isOpen} onClose={onClose} tempBody={formik.values.tempBody}></TemplatePreviewModal>
                } */}

            <Box>
        {
            selectedChannel == 'whatsapp' ? <MobilePreview channel={'whatsapp'} message={formik.values.message} /> :
             <TemplatePreviewModal isOpen={isOpen} onClose={onClose} tempBody={formik.values.tempBody}></TemplatePreviewModal>
        }
        </Box>

            </Flex>
        </Box>
        

        </>
    )
}

export default TemplateSelection
