import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { Input, FormControl, FormLabel, FormErrorMessage } from '@chakra-ui/react';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import PrimaryButton from './themeComponents/PrimaryButton';
import { getShortenedUrl } from '../Services/api';
import { useToast } from '@chakra-ui/react';


function ShortenUrlModal({ isOpen, onClose, mainFormik }) {
  const validationSchema = object().shape({
    url: string().url('Invalid URL').required('URL is required'),
  });
  const toast = useToast();
  const formik = useFormik({
    initialValues: {
      url: '',
    },
    validationSchema,
    onSubmit: (values) => {
      getShortenedUrl(values.url).then((res) => {
        mainFormik.values.message = mainFormik.values.message + " " + res.data;
        onClose();
        toast({ title: 'URL shortened Successfully.', type: 'success' });
      }).catch((err) => {
        toast({ title: err?.message || 'Something went wrong' })
      })
    },
  });

  return (
    <Modal isOpen={isOpen} size={'xl'} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text>Shorten URL</Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pt={0}>
          <form onSubmit={formik.handleSubmit}>
            <FormControl isInvalid={formik.errors.url && formik.touched.url}>
              <FormLabel htmlFor="url">URL</FormLabel>
              <Input
                id="url"
                placeholder="Enter URL"
                {...formik.getFieldProps('url')}
              />
              <FormErrorMessage>{formik.errors.url}</FormErrorMessage>
            </FormControl>
            <PrimaryButton mt={4} float={'right'} label={'Submit'} type="submit">
            </PrimaryButton>
          </form>
        </ModalBody>
        <ModalFooter>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ShortenUrlModal
